import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        wrapper: {
            marginTop: 20,
            backgroundColor: "#131530",
            width: "100%",
            borderRadius: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            height: 220,
        },
        container1: {
            display: "flex",
            height: "33%",
            justifyContent: "center",
            flexDirection: "row",
            "&> .desc1": {
                display: "flex",
                flexDirection: "column",
                color: "white",
                marginLeft: 20,
                "&> div:first-child": {
                    color: "#B8B8C0",
                    fontWeight: 600,
                    fontSize: 12,
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                },
                "&>div:last-child": {
                    display: "flex",
                    flexDirection: "row",
                    color: "white",
                    opacity: 0.3,
                    fontSize: 10,
                    "&> p:first-child": {
                        marginRight: 6,
                    },
                },
            },
        },
        container4: {
            display: "flex",
            height: "33%",
            justifyContent: "center",
            "&> .desc1": {
                display: "flex",
                flexDirection: "column",
                color: "white",
                marginLeft: 20,
                "&> div:first-child": {
                    color: "#B8B8C0",
                    fontWeight: 600,
                    fontSize: 12,
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                },
                "&>div:last-child": {
                    display: "flex",
                    flexDirection: "row",
                    color: "white",
                    opacity: 0.3,
                    fontSize: 10,
                    "&> p:first-child": {
                        marginRight: 6,
                    },
                },
            },
        },
        timeline1: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            "&> div:first-child": {
                width: 31,
                height: 30,
                borderRadius: "100%",
                backgroundColor: "#2F3149",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: 700,
                color: "white",
            },
            "& > div:last-child": {
                backgroundColor: "#2F3149",
                width: 4,
                height: 30,
            },
        },
        timeline4: {
            "&> div": {
                width: 30,
                height: 30,
                borderRadius: "100%",
                backgroundColor: "#2F3149",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: 700,
                color: "white",
            },
        },
    };
});

export default useStyles;
