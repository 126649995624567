import { statusIdoPoolActions } from "../constants/status-ido-pool"

export const statusUpcoming = (msg: string)  => {
  return {
    type: statusIdoPoolActions.UPCOMING,
    payload: msg
  }
}

export const statusCalculation = (msg: string)  => {
  return {
    type: statusIdoPoolActions.CALCULATION,
    payload: msg
  }
}

export const statusBuyticket = (msg: string)  => {
  return {
    type: statusIdoPoolActions.BUYTICKET,
    payload: msg
  }
}

export const statusDistribution = (msg: string)  => {
  return {
    type: statusIdoPoolActions.DISTRIBUTION,
    payload: msg
  }
}

export const statusEnded = (msg: string)  => {
  return {
    type: statusIdoPoolActions.ENDED,
    payload: msg
  }
}
