import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
    return {
        walletBox: {
            padding: "23px 16px",
            maxWidth: 160,
            width: 160,
            height: 120,
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            transition: ".1s all linear",
            boxSizing: "border-box",
            backgroundColor: "#353751",
            borderRadius: 10,
            [theme.breakpoints.down("xs")]: {
                maxWidth: 137,
                width: 137,
                height: 108,
                padding: "17px 16px",
            },
            "&:hover": {
                backgroundColor: "#1B1F2D",
                borderRadius: 4,
            },

            "&:not(:first-child)": {
                marginLeft: 16,
            },
        },

        activeNetwork: {
            border: "1px solid #2DDBFF",
            minHeight: 120,
            position: "relative",
            [theme.breakpoints.down("xs")]: {
              minHeight: 108,
          },
        },

        walletBoxText: {
            color: "#999999",
            marginTop: 10,
            font: "normal normal normal 12px/18px Helvetica!important",
            textAlign: "center",
        },
        walletBoxIconWrap: {
            borderRadius: 4,
        },
        walletBoxIcon: {
            width: 40,
        },
        walletBoxCheck: {
            position: "absolute",
            top: 10,
            right: 10,
        },
        [theme.breakpoints.down("xs")]: {
            walletBox: {
                "&:not(:first-child)": {
                    marginLeft: 0,
                },
            },
        },
    };
});

export default useStyles;
