import { Button, Snackbar, TextField, Tooltip, withWidth } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { BaseRequest } from "../../../request/Request";
import { getUserInfoKYC } from "../../../store/actions/user-kyc";
import { userInfoKYC } from "../../../store/constants/user-kyc";
import useStyles from "./style";

const AccountInformation = ({ isShowSnackbar }: any) => {
    const styles = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { account: connectedAccount } = useWeb3React();
    // const { appChainID } = useTypedSelector((state: any) => state.appNetwork).data;
    const [onEditProfile, setOnEditProfile] = useState(false);
    // const { signature, signMessage } = useWalletSignature();
    const [reloadData, setReloadData] = useState();
    const { data: userInfoKYCData } = useSelector((state: any) => state.userInfoKYC);
    const [messageWaring, setMessageWaring] = useState(false);
    // const wallet = useSelector((state: any) => state.wallet);

    useEffect(() => {
        if (!connectedAccount) {
            setOnEditProfile(false);
        }
    }, [connectedAccount]);

    const { register, errors, handleSubmit } = useForm({
        mode: "onChange",
    });

    const onSetEditProfile = async () => {
        // if (!signature) {
        //     await signMessage();
        // } else {
        setOnEditProfile(true);
        // }
    };

    // useEffect(() => {
    //     if (signature && connectedAccount) {
    //         setOnEditProfile(true);
    //     }
    // }, [signature, connectedAccount]);

    const handleFormSubmit = async (data: any) => {
        const { email, user_twitter, user_telegram, user_discord } = data;
        try {
            const baseRequest = new BaseRequest();
            const response = (await baseRequest.put(
                `/users/update`,
                {
                    email,
                    user_twitter,
                    user_telegram,
                    user_discord,
                },
                true
            )) as any;
            setReloadData(response);
            isShowSnackbar();
        } catch (error: any) {
            toast.error(error?.message);
        }
        setReloadData(undefined);
        setOnEditProfile(false);
    };

    const renderErrorRequired = (errors: any, prop: string) => {
        if (errors[prop]) {
            if (errors[prop].type === "required") {
                return `${t("account.myProfile.validateFieldRequired")}`;
            }
        }
    };

    useEffect(() => {
        if (connectedAccount && localStorage.getItem("investor_access_token")) {
            dispatch(getUserInfoKYC());
        }
        dispatch({ type: userInfoKYC.USER_INFO_KYC_RESET });
    }, [dispatch, connectedAccount, reloadData, localStorage.getItem("investor_access_token")]);

    const handleKYC = () => {
        !userInfoKYCData?.email
            ? setMessageWaring(true)
            : window.open(
                  "https://verify-with.blockpass.org/?clientId=abc_47273&serviceName=ABC&env=prod",
                  "_blank"
              );
    };

    const handleCloseMessage = () => {
        setMessageWaring(false);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.headPage}>
                <h2 className={styles.title}>{t("account.myProfile.title")}</h2>
                <Button
                    variant="contained"
                    className={styles.btnEditProfile}
                    onClick={() => onSetEditProfile()}
                >
                    {t("account.myProfile.btnEdit")}
                    <img src="/images/account_v1/icons/icon_edit.svg" alt="" />
                </Button>
            </div>

            <div className={styles.mainInfomation}>
                <div className={styles.inputGroup}>
                    <span>{t("account.myProfile.textYourWalletAddress")}</span>
                    <span style={{ wordBreak: "break-word" }}>{connectedAccount}</span>
                </div>

                <div className={styles.inputGroup}>
                    <span>{t("account.myProfile.textKYCStatus")}</span>
                    {connectedAccount && (
                        <div className="flex">
                            {userInfoKYCData?.is_kyc !== 0 && userInfoKYCData?.is_kyc !== 1 ? (
                                <span>Loading...</span>
                            ) : (
                                <span>
                                    {userInfoKYCData && userInfoKYCData?.is_kyc === 0 && (
                                        <div className="unverified">
                                            <span>{t("account.myProfile.textUnverified")}</span>
                                            <img
                                                className={styles.iconStatus}
                                                src="/images/account_v1/icons/icon_unverified.svg"
                                                alt=""
                                            />
                                        </div>
                                    )}
                                    {userInfoKYCData && userInfoKYCData?.is_kyc === 0 && (
                                        <button className="verify-email" onClick={handleKYC}>
                                            {t("account.myProfile.btnKYC")}
                                        </button>
                                    )}

                                    {userInfoKYCData && userInfoKYCData?.is_kyc === 1 && (
                                        <div className="verified">
                                            <span>{t("account.myProfile.textVerified")}</span>
                                            <img
                                                className={styles.iconStatus}
                                                src="/images/account_v1/icons/icon_verified.svg"
                                                alt=""
                                            />
                                        </div>
                                    )}
                                </span>
                            )}
                        </div>
                    )}
                </div>

                <form id="hook-form" onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className={styles.inputGroup}>
                        <div className="box">
                            <span>{t("account.myProfile.textEmailAddress")}</span>
                            <Tooltip
                                title={t("account.myProfile.tooltipEmail")}
                                arrow
                                classes={{ tooltip: styles.tooltip }}
                            >
                                <img src="/images/icons/info.svg" alt="" />
                            </Tooltip>
                        </div>
                        {connectedAccount && (
                            <>
                                {onEditProfile ? (
                                    <div className={styles.groupInput}>
                                        <TextField
                                            className={styles.inputNewValue}
                                            defaultValue={userInfoKYCData?.email}
                                            placeholder={t(
                                                "account.myProfile.textPlaceholderInput"
                                            )}
                                            name="email"
                                            inputRef={register({
                                                required: true,
                                                maxLength: {
                                                    value: 60,
                                                    message: `${t(
                                                        "account.myProfile.validateInput"
                                                    )}`,
                                                },
                                                pattern: {
                                                    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                    message: `${t(
                                                        "account.myProfile.validateEmail"
                                                    )}`,
                                                },
                                            })}
                                        />
                                        <span className={styles.errorInput}>
                                            {errors.email && errors.email.type !== "required"
                                                ? errors.email.message
                                                : renderErrorRequired(errors, "email")}
                                        </span>
                                    </div>
                                ) : userInfoKYCData?.email ? (
                                    <span className={styles.nameSocial}>
                                        {userInfoKYCData?.email}
                                    </span>
                                ) : (
                                    <span>{t("account.myProfile.textUnknown")}</span>
                                )}
                            </>
                        )}
                    </div>

                    <div className={styles.inputGroup}>
                        <div className="box">
                            <span>{t("account.myProfile.textTwitterAccount")}</span>
                            <Tooltip
                                title={t("account.myProfile.tooltipAccount")}
                                arrow
                                classes={{ tooltip: styles.tooltip }}
                            >
                                <img src="/images/icons/info.svg" alt="" />
                            </Tooltip>
                        </div>
                        {connectedAccount && (
                            <>
                                {onEditProfile ? (
                                    <div className={styles.groupInput}>
                                        <TextField
                                            className={styles.inputNewValue}
                                            defaultValue={userInfoKYCData?.user_twitter}
                                            placeholder={t(
                                                "account.myProfile.textPlaceholderInput"
                                            )}
                                            inputRef={register({
                                                maxLength: {
                                                    value: 60,
                                                    message: `${t(
                                                        "account.myProfile.validateInput"
                                                    )}`,
                                                },
                                            })}
                                            name="user_twitter"
                                        />
                                    </div>
                                ) : userInfoKYCData?.user_twitter ? (
                                    <span className={styles.nameSocial}>
                                        {userInfoKYCData?.user_twitter ?? "-"}
                                    </span>
                                ) : (
                                    <span>{t("account.myProfile.textUnknown")}</span>
                                )}
                            </>
                        )}
                    </div>

                    <div className={styles.inputGroup}>
                        <div className="box">
                            <span>{t("account.myProfile.textTelegramAccount")}</span>
                            <Tooltip
                                title={t("account.myProfile.tooltipAccount")}
                                arrow
                                classes={{ tooltip: styles.tooltip }}
                            >
                                <img src="/images/icons/info.svg" alt="" />
                            </Tooltip>
                        </div>
                        {connectedAccount && (
                            <>
                                {onEditProfile ? (
                                    <div className={styles.groupInput}>
                                        <TextField
                                            className={styles.inputNewValue}
                                            defaultValue={userInfoKYCData?.user_telegram}
                                            placeholder={t(
                                                "account.myProfile.textPlaceholderInput"
                                            )}
                                            name="user_telegram"
                                            inputRef={register({
                                                maxLength: {
                                                    value: 60,
                                                    message: `${t(
                                                        "account.myProfile.validateInput"
                                                    )}`,
                                                },
                                            })}
                                        />
                                    </div>
                                ) : userInfoKYCData?.user_telegram ? (
                                    <span>{userInfoKYCData?.user_telegram ?? "-"}</span>
                                ) : (
                                    <span>{t("account.myProfile.textUnknown")}</span>
                                )}
                            </>
                        )}
                    </div>

                    <div className={styles.inputGroup}>
                        <div className="box">
                            <span>{t("account.myProfile.textDiscordAccount")}</span>
                            <Tooltip
                                title={t("account.myProfile.tooltipAccount")}
                                arrow
                                classes={{ tooltip: styles.tooltip }}
                            >
                                <img src="/images/icons/info.svg" alt="" />
                            </Tooltip>
                        </div>
                        {connectedAccount && (
                            <>
                                {onEditProfile ? (
                                    <div className={styles.groupInput}>
                                        <TextField
                                            className={styles.inputNewValue}
                                            defaultValue={userInfoKYCData?.user_discord}
                                            placeholder={t(
                                                "account.myProfile.textPlaceholderInput"
                                            )}
                                            name="user_discord"
                                            inputRef={register({
                                                maxLength: {
                                                    value: 60,
                                                    message: `${t(
                                                        "account.myProfile.validateInput"
                                                    )}`,
                                                },
                                            })}
                                        />
                                    </div>
                                ) : userInfoKYCData?.user_discord ? (
                                    <span>{userInfoKYCData?.user_discord ?? "-"}</span>
                                ) : (
                                    <span>{t("account.myProfile.textUnknown")}</span>
                                )}
                            </>
                        )}
                    </div>
                </form>
            </div>

            {connectedAccount && onEditProfile && (
                <div className={styles.footerPage}>
                    <Button
                        form="hook-form"
                        type="submit"
                        variant="contained"
                        className={styles.btnUpdateProfile}
                        onClick={() => handleFormSubmit}
                    >
                        {t("account.myProfile.btnUpdate")}
                    </Button>
                </div>
            )}

            <Snackbar
                open={messageWaring}
                onClose={handleCloseMessage}
                autoHideDuration={3000}
                message={
                    <div className="messageWaring">
                        <img
                            src="/images/home-page/iconI.svg"
                            alt="iconWarning"
                            className="iconWarning"
                        />
                        <div className="content">
                            <p>{t("account.myProfile.messWarningKYC")}</p>
                            <span>{t("account.myProfile.messWarningKYCProcess")}</span>
                        </div>
                        <img
                            src="/images/icons/close_toast.svg"
                            alt="iconClose"
                            className="iconClose"
                            onClick={handleCloseMessage}
                        />
                    </div>
                }
            />
        </div>
    );
};

export default withWidth()(AccountInformation);
