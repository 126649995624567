import { Button } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { statusApplySuccess } from "../../../store/actions/statusApplyWhitelist";
import { toastMessage } from "../../../store/actions/toast-message-buyticket";
import { findNetworkFromChainID } from "../../../utils/networkFromChainId";
import { checkApplyCompleted, checkApplyWhitelist, userMeData } from "../Api";
import ModalApplyWhitelist from "../Modal/ModalApplyWhitelist";
import ModalViewApplyWhitelist from "../Modal/ModalApplyWhitelist/ModalViewApply";
import useStyles from "./style";

type TParams = {
    id: "string";
};
export default function UpcomingStarter(props: any) {
    const styles = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const tierAccount = useSelector((state: any) => state.userTier.data.name);
    const status = useSelector((state: any) => state.statusIdoPool.message);
    const statusApplyWhitelist = useSelector((state: any) => state.statusApplyWhitelist.message);
    const IDODetail = useSelector((state: any) => state.idoDetail.data);
    const { appChainID } = useTypedSelector((state: any) => state.appNetwork).data;

    const history = useHistory();
    const { id } = useParams<TParams>();
    const [open, setOpen] = useState<boolean>(false);
    const [dataAccount, setDataAccount] = useState<any>();
    const [openViewApply, setOpenViewApply] = useState<boolean>(false);
    const [checkApply, setCheckApply] = useState<any>();

    const [dataWhitelist, setDataWhitelist] = useState<any>();

    useEffect(() => {
        if (status !== "Upcoming") return;
        userMeData().then((res) => {
            setDataAccount(res?.data);
        });
    }, [status]);

    const handleClickStake = () => {
        history.push("/staking-pools");
    };

    const renderOpenModalApply = () => (
        <ModalApplyWhitelist
            open={open}
            onClose={handleClose}
            checkApply={checkApply}
            dataAccount={dataAccount}
        />
    );

    const renderOpenModalViewApply = useCallback(
        () => (
            <ModalViewApplyWhitelist
                openViewApply={openViewApply}
                handleCloseModalApply={handleCloseModalApply}
                checkApply={checkApply}
                dataWhitelist={dataWhitelist}
                dataAccount={dataAccount}
            />
        ),
        [checkApply, dataAccount, dataWhitelist, openViewApply]
    );
    const handleOpenModalApply = () => {
        if (findNetworkFromChainID(Number(appChainID)) !== IDODetail?.network) {
            dispatch(
                toastMessage({
                    type: "wrongNetwork",
                    title: "Wrong network",
                    message: t("WarningMessage.pleaseSwitchNetworkApply").replace(
                        "NETWORK",
                        (IDODetail?.network).toUpperCase()
                    ),
                })
            );
            return;
        }
        setOpen(true);
        // renderOpenModalApply()
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenModalViewApply = () => {
        setOpenViewApply(true);
    };

    const handleCloseModalApply = () => {
        setOpenViewApply(false);
    };

    useEffect(() => {
        if (status !== "Upcoming") return;
        if (tierAccount !== "starter") return;
        checkApplyWhitelist(id).then((res) => {
            setCheckApply(res);
        });
    }, [id, status, tierAccount]);

    useEffect(() => {
        if (!checkApply?.data) return;
        if (
            (checkApply?.data?.isApply === true &&
                tierAccount === "starter" &&
                status === "Upcoming") ||
            statusApplyWhitelist === "success"
        ) {
            checkApplyCompleted(id).then((res) => {
                setDataWhitelist(res);
            });
        }
    }, [checkApply?.data, id, status, statusApplyWhitelist, tierAccount]);

    useEffect(() => {
        (tierAccount === "rookie" || tierAccount === "legend") &&
            dispatch(statusApplySuccess("success"));
    }, [dispatch, tierAccount]);

    const renderResultWhitelist = useCallback(() => {
        if (dataWhitelist)
            return (
                <>
                    {dataWhitelist?.data?.social_requirement === "None" ? (
                        <div className={styles.applyPending}>
                            <div>
                                <img src="images/home-page/iconCalculatingApply.svg" alt="" />
                                <p>{t("ProjectDetails.whitelistApply")}</p>
                            </div>
                            <Button onClick={handleOpenModalViewApply} className="btnView">
                                View Whitelist Status
                            </Button>
                            {renderOpenModalViewApply()}
                        </div>
                    ) : (
                        dataWhitelist?.data?.social_requirement === "Completed" && (
                            <div className={styles.applySuccessView}>
                                <div>
                                    <img src="images/home-page/iconApplySuccess.svg" alt="Apply" />
                                    <p>Whitelist Success</p>
                                </div>
                                <Button onClick={handleOpenModalViewApply} className="btnView">
                                    View Whitelist Status
                                </Button>
                                {renderOpenModalViewApply()}
                            </div>
                        )
                    )}
                </>
            );
    }, [dataWhitelist, styles.applyPending, styles.applySuccessView, t, renderOpenModalViewApply]);

    return (
        <>
            {checkApply?.data?.isApply === true || statusApplyWhitelist === "success" ? (
                renderResultWhitelist()
            ) : (
                <>
                    <div className={styles.desc}>
                        <div>
                            <p>{t("ProjectDetails.mustBeAtLeast")}</p>
                            <Button className="btnSuperRookie">
                                <img src="images/home-page/iconSword.svg" alt="" />
                                <p className={styles.super}>{t("ProjectDetails.superRookie")}</p>
                            </Button>
                        </div>
                        <p>{t("ProjectDetails.completeSocial")}</p>
                    </div>
                    <div className={styles.btn}>
                        <Button onClick={handleClickStake} className="btnStakeMore">
                            {t("ProjectDetails.btnStakeMore")}
                        </Button>
                        <Button onClick={handleOpenModalApply} className="btnApplyWhitelist">
                            {t("ProjectDetails.btnApply")}
                        </Button>
                        {renderOpenModalApply()}
                    </div>
                </>
            )}
        </>
    );
}
