import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        countdownPart: {
            display: "inline-block",
            listStyleType: "none",
            padding: "6px 4px",
            width: 70,
            height: 63,
            color: "white",
            font: "normal normal bold 18px/24px DM Sans",
            background: "rgba(255, 255, 255, 0.07)",
            borderRadius: 10,

            "& span": {
                display: "block",
                textAlign: "center",
            },

            "&.number": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                flexGrow: 1,
                minHeight: 70,
                fontWeight: "bold",
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                fontSize: 24,
                [theme.breakpoints.down("md")]: {
                    flexGrow: 0,
                },
                [theme.breakpoints.down("xs")]: {
                    minWidth: 65,
                    fontSize: 20,
                },
                // lineHeight: "28px",
            },
        },
        colon: {
            color: "white",
            fontSize: 20,
            opacity: 0.7,
            margin: "14px 5px 0 5px",
            [theme.breakpoints.down("xs")]: {
                margin: "20px 5px 0 5px",
                opacity: 0.3,
            },
        },

        listCountDown: {
            display: "flex",
            alignItems: "flex-start",

            [theme?.breakpoints?.down("sm")]: {
                textAlign: "center",
                justifyContent: "center",
            },
        },

        countdownInfo: {
            color: "#fff",
            opacity: 0.4,
            fontWeight: 400,
            fontSize: 12,
            lineHeight: "16px",
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            // marginTop: 4,
        },
        [theme.breakpoints.down("xs")]: {
            countdownPart: {
                padding: "10px 5px",

                "&.number": {
                    // padding: "5px 5px 15px 5px",
                },
            },
        },
    };
});

export default useStyles;
