import { createTheme, ThemeOptions } from '@material-ui/core/styles';
import { ZIndex } from '@material-ui/core/styles/zIndex';

declare module '@material-ui/core/styles/zIndex' {
  interface ZIndex {
    negative: number;
  }
}

declare module '@material-ui/core/styles' {
  interface Theme {
    zIndex: ZIndex;
  }

  interface ThemeOptions {
    custom?: any;
  }
}

export const createLemonadeTheme = (options: ThemeOptions = {}) => {
  return createTheme(options);
};

const themeOptions = {
  colors: {
    primary: '#FFCC00',
    secondary: '#3A39BB',
    primaryText: '#636363',
    secondaryText: '#363636',
    metamask: '#FF8F44',
    mainBackground: '#202020',
  },
  fontFamilys: {
    poppinsRegular: "PoppinsRegular",
    poppinsItalic: "poppinsItalic",
    poppinsBold: "PoppinsBold",
    poppinsSemiBold: "PoppinsSemiBold"
  },
};

const defaultTheme = createLemonadeTheme({
  custom: themeOptions,
});

export { themeOptions };
export default defaultTheme;