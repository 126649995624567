import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        modalStake: {
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            "& .MuiPaper-root": {
                [theme.breakpoints.down("xs")]: {
                    margin: "18px",
                },
            },
            "& .modal-content": {
                backgroundColor: "#242640",
                width: "500px",
                padding: "70px 54px 87px 54px",
                position: "relative",
                [theme.breakpoints.down("xs")]: {
                    padding: "40px 20px 20px",
                },
                borderRadius: "20px",
                color: "#FFFFFF",
            },
            "& .modal-content__head": {
                paddingTop: 5,
                paddingBottom: "25px",
                borderBottom: "1px solid rgba(255, 255, 255, .1)",
                [theme.breakpoints.down("xs")]: {
                    paddingBottom: "12px",
                },
                "& .btn-close": {
                    top: 25,
                    right: 25,
                    [theme.breakpoints.down("xs")]: {
                        top: 16,
                        right: 16,
                        width: 24,
                        height: 24,
                    },
                },
                "& .title": {
                    fontSize: "32px",
                    fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                    textAlign: "left",
                    [theme.breakpoints.down("xs")]: {
                        fontSize: 20,
                    },
                },
            },

            "& .modal-content__body ": {
                padding: "0",
                margin: "20px 0",
                overflowX: "hidden",
                "& .boxTokenType": {
                    paddingBottom: 10,
                    borderBottom: "1px solid rgba(255, 255, 255, .1)",
                },
                "& .token-type": {
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "14px",
                    alignItems: "center",
                    marginBottom: "14px",
                    color: "rgba(255,255,255,0.5)",
                },
                "& .token-detail": {
                    display: "flex",
                    alignItems: "center",
                    color: "rgba(255,255,255,1)",
                },

                "& .token-logo": {
                    width: "22px",
                    height: "22px",
                    borderRadius: "28px",
                    margin: "0 5px",
                },
                "& .warningBalance": {
                    color: "#FF3333",
                    fontSize: 12,
                    marginTop: 5,
                },
                "& .warningMinMax": {
                    color: "#FF3333",
                    fontSize: 12,
                    marginTop: 5,
                },
                "& .input-group": {
                    background: "transparent",
                    height: 48,
                    borderRadius: "12px",
                    padding: "0px 14px",
                    border: " 2px solid rgba(255, 255, 255, 0.2)",
                },
                "& .input-group input": {
                    cursor: "default",
                    padding: "0",
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    height: "100%",
                },
                "& .token-balance": {
                    color: "#AEAEAE",
                    marginTop: "15px",
                },
                "& .actionChangeAmount": {
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                    gap: "0px 5px",
                    "& button": {
                        height: "36px",
                        width: "94px",
                        margin: "auto 0px 10px",
                        borderRadius: "4px",
                        backgroundColor: "#353751 !important",
                        fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    },
                },
                "& .subtitle": {
                    marginBottom: "10px",
                    marginTop: "25px",
                    color: "rgba(255,255,255,0.5)",
                    fontSize: 14,
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                },
            },
            "& .modal-content__foot": {
                // borderTop: "1px solid #727272",
                padding: "40px 0px 0px",
                [theme.breakpoints.down("xs")]: {
                    padding: "17px 0px 0px",
                    flexDirection: "row !important",
                },
                "& button": {
                    height: "54px",
                    width: "185px",
                    borderRadius: "36px",
                    padding: "12px 30px",
                    margin: 0,
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                    [theme.breakpoints.down("xs")]: {
                        height: "40px",
                        width: "147px",
                        fontSize: 12,
                    },
                },
                "& button:first-child": {
                    background: "transparent",
                    borderRadius: "90px",
                    color: "rgba(255, 255, 255, .7)",
                    border: "2px solid rgba(255, 255, 255, .7)",
                },
                "& button:last-child": {
                    background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                    borderRadius: "90px",
                    color: "#FFFFFF",
                },
            },
        },
        notice: {
            font: "normal normal bold 14px/18px DM Sans",
            color: "#FFF",
            marginTop: "30px",
        },
        [theme.breakpoints.down("xs")]: {
            modalTransaction: {
                "& .modal-content__body": {
                    padding: "0",
                },
            },
        },
    };
});

export default useStyles;
