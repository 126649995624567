import { toast } from "react-toastify";
import { BaseRequest } from "../../../request/Request";

const account = localStorage.getItem("user");
export const checkApplyWhitelist = async (id: any) => {
    if (!account) return;
    const baseRequest = new BaseRequest();
    try {
        const response = (await baseRequest.get(`whitelist/check-apply/${id}`, true)) as any;
        const resObj = await response.json();
        return resObj;
    } catch (error) {
        toast.error("CheckApply Failed");
    }
};

export const checkApplyCompleted = async (id: any) => {
    if (!account) return;
    const baseRequest = new BaseRequest();
    try {
        const response = (await baseRequest.get(`whitelist/me/${id}`, true)) as any;
        const resObj = await response.json();
        return resObj;
    } catch (error) {
        toast.error("CheckApply Failed");
    }
};

export const userMeData = async () => {
    if (!account) return;
    const baseRequest = new BaseRequest();
    try {
        const response = (await baseRequest.get(`users/me`, true)) as any;
        const resObj = await response.json();
        return resObj;
    } catch (error) {
        console.log(error);
    }
};

export const snapshotAccount = async (id: any, currentAccount: any) => {
    if (!account) return;
    const baseRequest = new BaseRequest();
    try {
        const response = (await baseRequest.get(
            `ido/snapshot/${id}/${currentAccount}`,
            true
        )) as any;
        const resObj = await response.json();
        return resObj;
    } catch (error) {
        // toast.error("CheckApply Failed");
        console.log(error);
    }
};

export const myTicket = async (id: any) => {
    if (!account) return;
    const baseRequest = new BaseRequest();
    try {
        const response = (await baseRequest.get(`ticket/my-ticket/${id}`, true)) as any;
        const resObj = await response.json();
        return resObj;
    } catch (error) {
        // toast.error("CheckApply Failed");
        console.log(error);
    }
};

export const claimProof = async (id: any) => {
    if (!account) return;
    const baseRequest = new BaseRequest();
    try {
        const response = (await baseRequest.get(`ticket/claim-proof?ido_id=${id}`, true)) as any;
        const resObj = await response.json();
        return resObj;
    } catch (error) {
        // toast.error("CheckApply Failed");
        console.log(error);
    }
};

export const idoNewEst = async () => {
    const baseRequest = new BaseRequest();
    try {
        const response = (await baseRequest.get(`ido/newest_ido`)) as any;
        const resObj = await response.json();
        return resObj;
    } catch (error) {
        console.log(error);
    }
};
