import configureStore from "../store/configureStore";
import { logout } from "../store/actions/user";
import { toast } from "react-toastify";
import { walletActions } from "../store/constants/wallet";
const MESSAGE_INVESTOR_SIGNATURE = process.env.REACT_APP_MESSAGE_INVESTOR_SIGNATURE || "";

// const version = 9;
// console.log('Version: ', version);

export class BaseRequest {
    static getInstance() {
        return new this();
    }

    getSignatureMessage(isInvestor: boolean = false) {
        const msgSignature = MESSAGE_INVESTOR_SIGNATURE;
        return msgSignature;
    }

    getHeader(isInvestor: boolean = false) {
        const token = !isInvestor
            ? localStorage.getItem("access_token")
            : localStorage.getItem("investor_access_token");

        return {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
            msgSignature: this.getSignatureMessage(isInvestor),
        };
    }

    buildUrl(url: string) {
        // remove both leading and trailing a slash
        url = url.replace(/^\/+|\/+$/g, "");
        return `${this.getUrlPrefix()}/${url}`;
    }

    getUrlPrefix() {
        const BASE_URL = process.env.REACT_APP_API_BASE_URL;
        return BASE_URL;
    }

    async post(url: string, data: object, isInvestor: boolean = false) {
        let resObj: Response;

        try {
            return fetch(this.buildUrl(url), {
                method: "POST",
                headers: this.getHeader(),
                body: JSON.stringify(data),
            })
                .then((response) => {
                    resObj = response.clone();
                    return response.json();
                })
                .then((data) => {
                    if (data?.error && data?.error?.statusCode === 401) {
                        configureStore().store.dispatch(logout(isInvestor));
                        configureStore().store.dispatch({
                            type: walletActions.WALLET_CONNECT_SUCCESS_WITHOUT_LAYER2,
                        });
                        toast.error(data?.error?.details?.error);
                    }

                    return resObj;
                });
        } catch (e) {
            throw e;
        }
    }

    async postImage(url: string, data: FormData) {
        try {
            return fetch(this.buildUrl(url), {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                body: data,
            });
        } catch (e) {
            throw e;
        }
    }

    async put(url: string, data: object, isInvestor: boolean = false) {
        try {
            return this._responseHandler(
                fetch(this.buildUrl(url), {
                    method: "PUT",
                    headers: this.getHeader(isInvestor),
                    body: JSON.stringify(data),
                })
            );
        } catch (e) {
            throw e;
        }
    }

    async patch(url: string, data: object, isInvestor: boolean = false) {
        try {
            return this._responseHandler(
                fetch(this.buildUrl(url), {
                    method: "PATCH",
                    headers: this.getHeader(isInvestor),
                    body: JSON.stringify(data),
                })
            ).then(async (response: any) => {
                const data = await response.json();
                if (data?.error && data?.error?.statusCode === 401) {
                    configureStore().store.dispatch(logout(isInvestor));
                    configureStore().store.dispatch({
                        type: walletActions.WALLET_CONNECT_SUCCESS_WITHOUT_LAYER2,
                    });
                    toast.error(data?.error?.details?.error);
                }
                return data;
            });
        } catch (e) {
            throw e;
        }
    }

    async get(url: string, isInvestor: boolean = false) {
        let resObj: Response;

        try {
            return fetch(this.buildUrl(url), {
                method: "GET",
                headers: this.getHeader(isInvestor),
            })
                .then((response) => {
                    // if (response.status && response.status === 401) {
                    //     configureStore().store.dispatch(logout(isInvestor));
                    //     configureStore().store.dispatch({
                    //         type: walletActions.WALLET_CONNECT_SUCCESS_WITHOUT_LAYER2,
                    //     });
                    //     toast.error("jfkjsa");
                    // }
                    resObj = response.clone();
                    return response.json();
                })
                .then((data) => {
                    if (data?.error && data?.error?.statusCode === 401) {
                        configureStore().store.dispatch(logout(isInvestor));
                        configureStore().store.dispatch({
                            type: walletActions.WALLET_CONNECT_SUCCESS_WITHOUT_LAYER2,
                        });
                        toast.error(data?.error?.details?.error);
                    }
                    return resObj;
                });
        } catch (e) {
            throw e;
        }
    }

    async delete(url: string, data: object) {
        try {
            return this._responseHandler(
                fetch(this.buildUrl(url), {
                    method: "DELETE",
                    headers: this.getHeader(),
                    body: JSON.stringify(data),
                })
            );
        } catch (e) {
            throw e;
        }
    }

    async _responseHandler(response = {}) {
        return response;
    }
}
