import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useCommonStyle from "../../../styles/CommonStyle";
import Button from "../Button";
import useStyles from "./style";

const closeIcon = "/images/icons/close.svg";

const ModalWithdraw = (props: any) => {
    const styles = useStyles();
    const commonStyles = useCommonStyle();
    const { t } = useTranslation();
    const { open, onClose } = props;

    return (
        <Dialog
            open={open}
            keepMounted
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className={commonStyles.modal + " " + styles.modalWithdraw}
        >
            <div className="modal-content">
                <DialogTitle id="alert-dialog-slide-title" className="modal-content__head">
                    <img src={closeIcon} alt="" onClick={onClose} className="btn-close" />
                    <p className="title">{t("staking.modalWithdraw.title")}</p>
                </DialogTitle>
                <DialogContent className="modal-content__body">
                    <div>
                        {t("staking.modalWithdraw.content")}
                        <div className="">
                            {t("staking.modalWithdraw.text1")}{" "}
                            <span>
                                {t("staking.modalWithdraw.withdrawalFeePolicy")}
                                <img src="/images/icons/redirect.svg" alt="" />
                            </span>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className="modal-content__foot">
                    <Button
                        text={t("staking.modalWithdraw.btnOK")}
                        onClick={onClose}
                        backgroundColor="#FFD058"
                    />
                </DialogActions>
            </div>
        </Dialog>
    );
};

export default ModalWithdraw;
