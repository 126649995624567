import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        wrapper: {
            padding: "80px 100px 40px 100px",
            backgroundColor: "#030517",
            [theme.breakpoints.only("sm")]: {
                padding: "40px",
            },
            [theme.breakpoints.only("xs")]: {
                padding: "10px 10px 30px 10px",
            },
        },
        borderTop: {
            height: 4,
            background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
        },
        footer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontSize: 16,
            color: "#FFFFFF",
            [theme.breakpoints.only("xs")]: {
                flexDirection: "column",
                padding: "16px 16px 0 16px",
            },
            "&> .desc": {
                [theme.breakpoints.only("xs")]: {
                    display: "flex",
                    flexDirection: "column",
                    "&> img": {
                        width: 130,
                        height: 32,
                    },
                },
                "&> .logo": {
                    "&> img": {
                        width: 125,
                        height: 30,
                    },
                },
                "&> p:nth-child(2)": {
                    marginTop: 32,
                    opacity: 0.5,
                    [theme.breakpoints.only("xs")]: {
                        marginTop: 16,
                    },
                },
                "&> p:last-child": {
                    opacity: 0.5,
                },
            },
            "&> .community": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                [theme.breakpoints.only("xs")]: {
                    marginTop: 20,
                },
                "&> div": {
                    height: 32,
                },
                "&>div> p": {
                    opacity: 0.5,
                    marginBottom: 20,
                    display: "flex",
                    justifyContent: "flex-end",
                    [theme.breakpoints.only("xs")]: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        fontSize: 14,
                        fontWeight: 400,
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        marginBottom: 30,
                    },
                },
                "&>div>a>img": {
                    marginLeft: 24,
                    [theme.breakpoints.only("xs")]: {
                        marginLeft: 0,
                        marginRight: 17,
                        width: 20,
                        height: 15,
                    },
                },
            },
        },
        help: {
            marginTop: 40,
            borderTop: "1px solid #2F2E41",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            color: "white",
            [theme.breakpoints.only("xs")]: {
                marginTop: 20,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: -26,
            },
        },
        subContent: {
            marginTop: 30,
            fontWeight: 700,
            fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
            "&> span:first-child": {
                marginRight: 40,
                cursor: "pointer",
            },
            "&> span": {
                fontSize: 14,
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                color: "white",
                opacity: 0.7,
                cursor: "pointer",
            },
            [theme.breakpoints.only("xs")]: {
                width: "100%",
                textAlign: "center",
            },
        },
        endContent: {
            opacity: 0.5,
            marginTop: 30,
            [theme.breakpoints.only("xs")]: {
                marginTop: 14,
                width: "100%",
                paddingLeft: 18,
                textAlign: "center",
            },
        },
    };
});

export default useStyles;
