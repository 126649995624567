import useStyles from "./style";
import Item from "./Item";

export default function ListView({ handleSetTab, dateCalendarCurrent, dataView }: any) {
    const styles = useStyles();
    return (
        <div className={styles.listItem}>
            {Array.isArray(dataView) &&
                dataView?.map((item, index) => (
                    <Item
                        handleSetTab={handleSetTab}
                        dateCurrent={dateCalendarCurrent}
                        item={item}
                        key={index}
                    />
                ))}
        </div>
    );
}
