import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CalculatingAccount from "../Calculating";
import ModalYourTicket from "../Modal/ModalYourTickets";
import DistributionTicketCalculatingComplete from "./CalculatingComplete";
import useStyles from "./style";

// type TParams = {
//     id: "string";
// };
export default function DistributionTicket(props: any) {
    const styles = useStyles();
    const { t } = useTranslation();
    const dataYourTicket = props.data;

    const [open, setOpen] = useState<boolean>(false);
    const idoDetail = useSelector((state: any) => state.idoDetail?.data);

    const handleClose = () => {
        setOpen(false);
    };

    const renderOpenModalYourTicket = () => (
        <ModalYourTicket open={open} onClose={handleClose} dataYourTicket={dataYourTicket} />
    );

    const handleClick = () => {
        setOpen(true);
    };

    // console.log(idoDetail?.state);

    return (
        <>
            {idoDetail?.state === "CALCULATED" ? (
                <DistributionTicketCalculatingComplete dataYourTicket={dataYourTicket} />
            ) : (
                (idoDetail?.state === "SNAPSHOTTED" || idoDetail?.state === "DEPLOYED") &&
                (idoDetail?.state === "DEPLOYED" ? (
                    <CalculatingAccount />
                ) : (
                    <div className={styles.wrapper}>
                        <p>{t("ProjectDetails.calculatingDistribution")}</p>
                        <div className="desc">
                            <p>{t("ProjectDetails.distributionCalculationDesc1")}</p>
                            <p>{t("ProjectDetails.distributionCalculationDesc2")}</p>
                        </div>
                        <div className="amountTicket">
                            <p>{t("ProjectDetails.yourJoinedTickets")}</p>
                            <div>
                                <div>
                                    {dataYourTicket?.ticketBought
                                        ? dataYourTicket?.ticketBought
                                        : 0}
                                </div>
                                <div onClick={handleClick}>
                                    {t("ProjectDetails.viewTicket")}
                                    <img src="/images/home-page/iconWebsite.svg" alt="" />
                                </div>
                            </div>
                        </div>
                        {renderOpenModalYourTicket()}
                    </div>
                ))
            )}
        </>
    );
}
