export const TRANSACTION_ERROR = "Transaction failed. Please check blockchain to know more error.";
export const API_URL_PREFIX = "user";
export const ADMIN_URL_PREFIX = "dashboard";
export const IMAGE_URL_PREFIX = "image";
export const MAX_BUY_CAMPAIGN = 1000;
export const WHITELIST_LINK = "https://forms.gle/HiQkhaRM8mujeryq8";
export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const TELEGRAM_CODE_DAO_LINK = "https://t.me/CodeDAO_Community";
export const DISCORD_CODE_DAO_LINK = "https://discord.com/invite/Ur9cJ8yx";
export const TWITTER_CODE_DAO_LINK = "https://twitter.com/codedao_io";
export const MEDIUM_CODE_DAO_LINK = "https://medium.com/@social_code-dao.io";

export const TELEGRAM_LINK_URL = "https://t.me/";
export const TWITTER_LINK_URL = "https://twitter.com/";
export const DISCORD_LINK_URL = "https://discord.gg/";

export const NFT_PLUS_AMOUNT_PRODUCTION = process.env.NFT_PLUS_AMOUNT_PRODUCTION || "0";
export const POOL_STATUS = {
    TBA: 0,
    UPCOMING: 1,
    JOINING: 2,
    IN_PROGRESS: 3,
    FILLED: 4,
    CLOSED: 5,
    CLAIMABLE: 6,
};
export const POOL_STATUS_TEXT = {
    [POOL_STATUS.TBA]: "TBA",
    [POOL_STATUS.UPCOMING]: "Upcoming",
    [POOL_STATUS.FILLED]: "Filled",
    [POOL_STATUS.IN_PROGRESS]: "Swap",
    [POOL_STATUS.CLAIMABLE]: "Claimable",
    [POOL_STATUS.CLOSED]: "Ended",
};

export const NULL_AMOUNT = "N/A";
export const POOL_STATUS_JOINED = {
    // Pool Status for User Joined Pool (Version 3)
    NONE: "NONE",
    APPLIED_WHITELIST: "APPLIED_WHITELIST",
    WIN_WHITELIST: "WIN_WHITELIST",
    NOT_WIN_WHITELIST: "NOT_WIN_WHITELIST",
    CANCELED_WHITELIST: "CANCELED_WHITELIST",
    SWAPPING: "SWAPPING",
    CLAIMABLE: "CLAIMABLE",
    COMPLETED: "COMPLETED",
};

export const POOL_STATUS_KLAY = {
    // Pool Status for User Joined Pool (Version 3)
    BUY_TICKET: "Buy Ticket",
    CALCULATION: "Calculation",
    CLOSED: "Ended",
    DISTRIBUTION: "Distribution",
    UPCOMING: "Upcoming",
};

export const NETWORK = {
    ETHEREUM: "eth",
    BSC: "bsc",
    POLYGON: "polygon",
    KLAYTN: "klaytn",
};

export const ACCEPT_CURRENCY = {
    ETH: "eth",
    USDT: "usdt",
    USDC: "usdc",
};

export const BUY_TYPE = {
    WHITELIST_LOTTERY: "whitelist",
    FCFS: "fcfs",
};

export const POOL_TYPE = {
    SWAP: "swap",
    CLAIMABLE: "claimable",
};

export const PUBLIC_WINNER_STATUS = {
    PUBLIC: 1,
    PRIVATE: 0,
};
export const POOL_IS_PRIVATE = {
    PUBLIC: 0,
    PRIVATE: 1,
    SEED: 2,
    COMMUNITY: 3,
};

export const PICK_WINNER_RULE = {
    RULE_NORMAL: "rule-normal",
    RULE_WITH_WEIGHT_RATE: "rule-with-weight-rate",
    RULE_LUCKY_DOVE: "rule-lucky-dove", // extend from RULE_WITH_WEIGHT_RATE
};

export const USER_STATUS = {
    UNVERIFIED: 0,
    ACTIVE: 1,
    BLOCKED: 2,
    DELETED: 3,
};

export const TOKEN_STAKE_NAMES = {
    PKF: "PKF",
    LP_PKF: "PKF-ETH LP",
    EPKF: "ePKF",
    SPKF: "sPKF",
};

export interface TIERS_INFORMATION {
    id: number;
    name: string;
    min: number;
}

export const TIERS_1 = (data: TIERS_INFORMATION[]) => {
    return data?.reduce((result: any, item: TIERS_INFORMATION) => {
        result[item?.name] = item;
        return result;
    }, {});
};

export const TIERS = [
    {
        name: "Start",
        icon: "/images/icons/started.svg",
        bgColor: "#B8B8FF",
        value: 0,
        textColor: "#FFFFFF",
        valueToken: 0,
    },
    {
        name: "Super Rookie",
        bgColor: "#8181D8",
        icon: "/images/icons/super_rookie.svg",
        value: 50,
        textColor: "#FFFFFF",
        valueToken: 2000,
    },
    {
        name: "Legend",
        bgColor: "#6F44E9",
        icon: "/images/icons/legend.svg",
        value: 100,
        textColor: "#FFFFFF",
        valueToken: 5000,
    },
    {
        name: "Eagle",
        bgColor: "#4646FF",
        icon: "/images/icons/eagle.png",
        value: 75,
        textColor: "#FFFFFF",
    },
    {
        name: "Phoenix",
        bgColor: "",
        icon: "/images/icons/pheonix.png",
        value: 100,
        textColor: "#FFFFFF",
    },
];

export const TIER_LEVELS = {
    NONE: 0,
    DOVE: 1,
    HAWK: 2,
    EAGLE: 3,
    PHOENIX: 4,
};

export const TIER_NAMES = {
    0: "--",
    1: "Dove",
    2: "Hawk",
    3: "Eagle",
    4: "Phoenix",
};

export const KYC_STATUS = {
    INCOMPLETE: 0, // Blockpass verifications pending
    APPROVED: 1, // profile has been approved by Merchant
    RESUBMIT: 2, // Merchant has rejected one or more attributes
    WAITING: 3, // Merchant's review pending
    INREVIEW: 4, // in review by Merchant
};

export const GAS_LIMIT_CONFIGS = {
    APPROVE: "80000", // 46483
    DEPOSIT: "250000", // 195057
    CLAIM: "120000", // 81901
    APPROVE_SOTA_TOKEN: "200000",
    STAKE_SOTA_TIERS: "120000", // 79021
    UNSTAKE_SOTA_TIERS: "100000", // 72527
};

export const NETWORK_AVAILABLE = {
    ETH: "eth",
    BSC: "bsc",
    POLYGON: "polygon",
    KLAYTN: "klaytn",
};

export const ETHERSCAN_BASE_URL: any = {
    "1": "https://etherscan.io",
    "4": "https://rinkeby.etherscan.io",
    "5": "https://goerli.etherscan.io",
    "56": "https://bscscan.com",
    "97": "https://testnet.bscscan.com",
    "137": "https://polygonscan.com",
    "80001": "https://mumbai.polygonscan.com/",
};

export const CHAIN_ID_NETWORK: any = {
    BSC_TESTNET: 97,
    POLYGON_TESTNET: 80001,
    KLAYTN_TESTNET: 1001,
    POLYGON_MAINNET: 137,
    KLAYTN_MAINNET: 8217,
};

export const take_decimal_number = (num: string | number, n: number) => {
    let result = Math.trunc(Number(num) * Math.pow(10, n)) / Math.pow(10, n);
    return result;
};

export const take_decimal_numberFormatThousands = (num: string | number) => {
    let result = Math.trunc(Number(num) * Math.pow(10, 6)) / Math.pow(10, 6);
    const newResult = result
        .toString()
        .split(".")[0]
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        .concat(result.toString().split(".")[1] ? "." + result.toString().split(".")[1] : "");
    return newResult;
};

export const PAGE_SIZE = 9;
