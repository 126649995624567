import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { BaseRequest } from "../../request/Request";
import { poolNoReward } from "../constants/pool-noreward";

export const getPoolNoRewardCODE = () => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => any
  ) => {
    dispatch({ type: poolNoReward.POOL_NOREWARD_CODE_LOADING });
    try {
      const baseRequest = new BaseRequest();
      const response = (await baseRequest.get(
        `pools/no-reward/code`,
        true
      )) as any;
      const resObj = await response.json();
      if (!resObj.data) {
        dispatch({
          type: poolNoReward.POOL_NOREWARD_CODE_LOADING,
          payload: new Error("Invalid pool payload"),
        });
        return;
      }
      dispatch({
        type: poolNoReward.POOL_NOREWARD_CODE_SUCCESS,
        payload: resObj.data,
      });
    } catch (error) {
      dispatch({
        type: poolNoReward.POOL_NOREWARD_CODE_FAILURE,
        payload: error,
      });
    }
  };
};

export const getPoolNoRewardLP = () => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => any
  ) => {
    dispatch({ type: poolNoReward.POOL_NOREWARD_LP_LOADING });
    const { page_size, page_number } = getState().poolNoRewardLP;
    try {
      const baseRequest = new BaseRequest();
      const response = (await baseRequest.get(
        `pools/no-reward/lps?page_size=${page_size}&page_number=${page_number}`,
        true
      )) as any;
      const resObj = await response.json();
      if (!resObj) {
        dispatch({
          type: poolNoReward.POOL_NOREWARD_LP_LOADING,
          payload: new Error("Invalid pool payload"),
        });
        return;
      }
      dispatch({
        type: poolNoReward.POOL_NOREWARD_LP_SUCCESS,
        payload: resObj,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: poolNoReward.POOL_NOREWARD_LP_FAILURE,
        payload: error,
      });
    }
  };
};
