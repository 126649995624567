import { AnyAction } from "redux";
import { toastMessageBuyTicketAction } from "../constants/toast-message-buyticket";

export type ToastMessageType = {
    type: string;
    title: string;
    message: string;
};

const initialState = {
    type: "",
    title: "",
    message: "",
};
export const toastMessageReducer = (state: ToastMessageType = initialState, action: AnyAction) => {
    switch (action.type) {
        case toastMessageBuyTicketAction.SUCCESS: {
            return {
                type: action.payload.type,
                title: action.payload.title,
                message: action.payload.message,
            };
        }

        default: {
            return state;
        }
    }
};
