import { AnyAction } from "redux";

type StateType = {
    networkID: string;
};

const initialState = {
    networkID: "",
};

export const networkWalletReducer = (state: StateType = initialState, action: AnyAction) => {
    switch (action.type) {
        case "CHANGE_NETWORK_ID": {
            return {
                ...state,
                networkID: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};
