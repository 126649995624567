import Pagination from "@material-ui/lab/Pagination";
import { useStyles } from "./style";

type Props = {
    count: number;
    onChange: (page: number) => void;
    currentPage: number;
};

export default function PaginationCustom({ count, onChange, currentPage }: Props) {
    const styles = useStyles();

    return (
        <>
            {count > 1 && (
                <div className={styles.pagination}>
                    <Pagination
                        count={count}
                        shape="rounded"
                        defaultPage={1}
                        siblingCount={1}
                        boundaryCount={count <= 10 ? 5 : 1}
                        onChange={(e, page: number) => onChange(page)}
                        page={currentPage}
                    />
                </div>
            )}
        </>
    );
}
