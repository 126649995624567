import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        wrapper: {
            marginTop: 25,
            // borderTop: '1px solid #2B2C45',
            // paddingTop: 26,
        },
        btnSwapTier: {
            background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
            borderRadius: 90,
            color: "white",
            fontWeight: 700,
            fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
            fontSize: 16,
            textTransform: "capitalize",
            padding: "4px 30px",
            marginBottom: "10px",
        },
        snapshotIn: {
            "&> p": {
                marginBottom: 12,
                fontSize: 14,
                fontWeight: 400,
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                color: "white",
                opacity: 0.5,
            },
        },
        deposit: {
            marginTop: 18,
            display: "flex",
            flexDirection: "column",
            borderBottom: "1px solid #2B2C45",
            paddingBottom: 26,
            borderTop: "1px solid #2B2C45",
            paddingTop: 20,
            "&> div:first-child": {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                "&> p": {
                    fontSize: 14,
                    color: "white",
                    opacity: 0.8,
                    fontWeight: 600,
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                },
                "&> div": {
                    display: "flex",
                    flexDirection: "row",
                    "&> p": {
                        fontSize: 14,
                        color: "white",
                        fontWeight: 400,
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    },
                    "&> p:first-child": {
                        marginRight: 6,
                        opacity: 0.5,
                    },
                },
            },
            "&> div:nth-child(2)": {
                marginTop: 10,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                background: "1B1D38",
                border: "2px solid #2A2C44",
                alignItems: "center",
                borderRadius: 12,
                padding: "10px 22px",
                "&> div:first-child": {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    color: "white",
                    opacity: 0.5,
                    "&> p:first-child": {
                        fontWeight: 600,
                        fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                        fontSize: 16,
                    },
                    "&> p:last-child": {
                        marginLeft: 8,
                        opacity: 0.3,
                        fontWeight: 400,
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        fontSize: 14,
                    },
                },
                "&> div:last-child": {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    color: "white",
                    opacity: 0.5,
                    "&> p": {
                        fontWeight: 600,
                        fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                        marginLeft: 8,
                        fontSize: 16,
                    },
                },
            },
            "&> div:last-child": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 8,
                "&> p": {
                    fontWeight: 400,
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    fontSize: 14,
                    color: "#2DDBFF",
                    marginRight: 6,
                    cursor: "pointer",
                },
                "&> img": {
                    cursor: "pointer",
                },
            },
        },
    };
});

export default useStyles;
