import { combineReducers } from "redux";
import { affiliateCampaignReducer, affiliateLinkGenerateReducer } from "./affiliate";
import { alertReducer } from "./alert";
import { amountBuyticketReducer } from "./amount-buy-ticket";
import { appNetworkReducer, connectorReducer } from "./appNetwork";
import { balanceReducer } from "./balance";
import {
    campaignAffiliateCreateReducer,
    campaignCreateReducer,
    campaignDetailReducer,
    campaignEditReducer,
    campaignErc20RateSetReducer,
    campaignICORegisterReducer,
    campaignLatestActiveReducer,
    campaignLatestReducer,
    campaignProcessingReducer,
    campaignRefundTokensReducer,
    campaignStatusToggleReducer,
    campaignsReducer,
} from "./campaign";
import { networkWalletReducer } from "./change-network";
import { claimUserInfoReducer } from "./claim-user-info";
import { gasFeePolygonReducer } from "./gas-fee-polygon";
import { getIdoDetailReducer } from "./ido-detail";
import { getListIdoReducer } from "./list-ido";
import { messageReducer } from "./message";
import { getPoolNoRewardCODEReducer, getPoolNoRewardLPReducer } from "./pool-noreward";
import { settingDeactivateReducer } from "./setting-deactivate";
import { settingDetailReducer } from "./setting-detail";
import { settingFeeRateReducer } from "./setting-fee-rate";
import { settingOwnerReducer } from "./setting-owner";
import { settingRevenueAddressReducer } from "./setting-revenue-address";
import { showBuyTicketReducer } from "./showBuyTicket";
import {
    depositReducer,
    getTiersReducer,
    getUserInfoLegacyReducer,
    getUserInfoReducer,
    getUserTierReducer,
    ratesReducer,
    withdrawFeeReducer,
    withdrawPercentReducer,
    withdrawReducer,
} from "./sota-tiers";
import { approveReducer, getAllowanceReducer } from "./sota-token";
import { statusIdoPoolReducer } from "./status-ido-pool";
import { statusApplyReducer } from "./statusApplyWhitelist";
import { toastMessageReducer } from "./toast-message-buyticket";
import { createTokenReducer, getTokensReducer } from "./token";
import { transactionCampaignReducer } from "./transaction";
import { usdtAllowanceReducer } from "./usdt-allowance";
import { usdtApproveReducer } from "./usdt-approve";
import { usdtDetailReducer } from "./usdt-detail";
import userReducer, {
    investorReducer,
    investorRegisterReducer,
    userConnectReducer,
    userProfileReducer,
    userProfileUpdateReducer,
    userRegisterReducer,
} from "./user";
import { getUserInfoKYCReducer } from "./user-kyc";
import { walletReducer } from "./wallet";

const rootReducer = combineReducers({
    user: userReducer,
    investor: investorReducer,
    investorRegister: investorRegisterReducer,
    userConnect: userConnectReducer,
    userRegister: userRegisterReducer,
    userProfile: userProfileReducer,
    userProfileUpdate: userProfileUpdateReducer,
    campaigns: campaignsReducer,
    campaignProcessing: campaignProcessingReducer,
    campaignCreate: campaignCreateReducer,
    campaignEdit: campaignEditReducer,
    campaignDetail: campaignDetailReducer,
    campaignICORegister: campaignICORegisterReducer,
    campaignAffiliateCreate: campaignAffiliateCreateReducer,
    campaignErc20RateSet: campaignErc20RateSetReducer,
    campaignLatest: campaignLatestReducer,
    campaignLatestActive: campaignLatestActiveReducer,
    campaignStatusToggle: campaignStatusToggleReducer,
    campaignRefundTokens: campaignRefundTokensReducer,
    transactionCampaign: transactionCampaignReducer,
    affiliateCampaign: affiliateCampaignReducer,
    affiliateLinkGenerate: affiliateLinkGenerateReducer,
    claimUserInfo: claimUserInfoReducer,
    usdtAllowance: usdtAllowanceReducer,
    usdtApprove: usdtApproveReducer,
    settingDetail: settingDetailReducer,
    settingFeeRate: settingFeeRateReducer,
    settingRevenueAddress: settingRevenueAddressReducer,
    settingOwner: settingOwnerReducer,
    settingDeactivate: settingDeactivateReducer,
    tokensByUser: getTokensReducer,
    tokenCreateByUser: createTokenReducer,
    balance: balanceReducer,
    usdtDetail: usdtDetailReducer,
    alert: alertReducer,
    appNetwork: appNetworkReducer,
    connector: connectorReducer,
    wallet: walletReducer,
    tiers: getTiersReducer,
    userTier: getUserTierReducer,
    deposit: depositReducer,
    withdraw: withdrawReducer,
    userInfo: getUserInfoReducer,
    userInfoLegacy: getUserInfoLegacyReducer,
    allowance: getAllowanceReducer,
    approve: approveReducer,
    withdrawFee: withdrawFeeReducer,
    withdrawPercent: withdrawPercentReducer,
    rates: ratesReducer,
    messages: messageReducer,
    poolNoRewardCODE: getPoolNoRewardCODEReducer,
    poolNoRewardLP: getPoolNoRewardLPReducer,
    statusApplyWhitelist: statusApplyReducer,
    showBuyTicket: showBuyTicketReducer,
    statusIdoPool: statusIdoPoolReducer,
    amountBuyticket: amountBuyticketReducer,
    toastMessage: toastMessageReducer,
    userInfoKYC: getUserInfoKYCReducer,
    idoDetail: getIdoDetailReducer,
    listIdo: getListIdoReducer,
    networkWallet: networkWalletReducer,
    gasFeePolygon: gasFeePolygonReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
