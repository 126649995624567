import { statusApplyActions } from "../constants/statusApplyWhitelist";

export const statusApplySuccess = (msg: {}) => {
    return {
        type: statusApplyActions.SUCCESS_MESSAGE,
        payload: msg,
    };
};

export const statusApplyError = (msg: string) => {
    return {
        type: statusApplyActions.ERROR_MESSAGE,
        payload: msg,
    };
};
