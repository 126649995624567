import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        wrapper: {
            "& .MuiSnackbar-root": {
                right: "0px !important",
                left: "unset",
                transform: "translate(0,0)",
                top: "110px",
                justifyContent: "flex-end",
                height: 100,
                width: 460,
                borderRadius: "10px 0px 0px 10px",
                [theme.breakpoints.down("md")]: {
                    left: "0% !important",
                    width: "100%",
                },
                [theme.breakpoints.down("sm")]: {
                    left: "0% !important",
                    width: "100%",
                    top: "100px !important",
                    borderRadius: "0px 0px 0px 0px",
                },
                [theme.breakpoints.down("sm")]: {
                    top: "80px !important",
                },
                "& .MuiSnackbarContent-root": {
                    background: "#E4C96A",
                    width: 460,
                    minHeight: 100,
                    borderRadius: "10px 0px 0px 10px",
                    [theme.breakpoints.down("md")]: {
                        height: "auto",
                    },
                    [theme.breakpoints.down("sm")]: {
                        borderRadius: "0px 0px 0px 0px",
                    },
                    "& p": {
                        fontSize: 18,
                        fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                        [theme.breakpoints.down("sm")]: {
                            fontSize: 14,
                        },
                    },
                    "& span": {
                        fontSize: 14,
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        [theme.breakpoints.down("sm")]: {
                            fontSize: 12,
                        },
                    },
                },

                "& .messageWaring": {
                    display: "flex",
                    alignItems: "flex-start",
                    "& .iconWarning": {
                        width: 27,
                        height: 27,
                        marginRight: 15,
                    },
                },
                "& .iconClose": {
                    cursor: "pointer",
                },
            },
            "& .messageWaringNetwork": {
                top: "100px !important",
                left: "unset",
                "& .MuiSnackbarContent-root": {
                    background: "#FF3333 !important",
                    minHeight: 100,
                },
            },
        },
        description: {
            font: "normal normal normal 14px/18px DM Sans",
            color: "#FFF",
            textAlign: "center",
            marginTop: "16px",
            marginBottom: "32px",
        },
        loader: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
        loaderText: {
            fontWeight: 700,
            marginTop: 20,
            color: "#999999",
            font: "normal normal bold 14px/18px DM Sans",
        },
        group: {
            marginTop: "16px",

            "& .input-group": {
                background: "#222228",
                border: "1px solid #44454B",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                padding: "4px",
            },

            "& input": {
                color: "#fff",
                background: "#222228",
                border: "none",
                outline: "none",
                padding: "6px 8px",
                flex: 1,
                fontSize: "16px",
                lineHeight: "24px",
            },
            "& button#btn-max-deposit": {
                font: "normal normal bold 12px/14px DM Sans",
                color: "#FFF",
                backgroundColor: "#6398FF",
                borderRadius: "4px",
                width: "auto",
                padding: "4px 10px",
                marginRight: "6px",
                cursor: "pointer",
            },
            "& .balance": {
                color: "#FFF",
                font: "normal normal 500 16px/24px DM Sans",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginLeft: "4px",

                [theme.breakpoints.down("sm")]: {
                    textAlign: "left",
                    flexDirection: "column",
                },

                "& span:last-child": {
                    fontWeight: "bold",
                    fontSize: "20px",
                    lineHeight: "24px",
                    color: "#6398FF",

                    [theme.breakpoints.down("sm")]: {
                        marginTop: 4,
                    },
                },
            },
            "& .subtitle": {
                color: "#FFF !important",
                font: "normal normal 500 16px/24px DM Sans !important",
                margin: "19px 4px 6px",
            },
        },
        stages: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",

            "& .stage": {
                flex: "0 0 49%",
                padding: "6px",
                textAlign: "center",
                borderBottom: "2px solid #C4C4C4",
                color: "white",
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",

                "& img": {
                    marginRight: "8px",
                },
            },

            "& .stage.active": {
                color: "#6398FF",
                borderBottomColor: "#6398FF",
            },
        },
        message: {
            color: "white",
            fontSize: 14,
            position: "absolute",
            top: 0,
            left: "50%",
            width: "1120px",
            // maxWidth: 'calc(100vw - 80px)',
            transform: "translateX(-50%)",
            padding: "12px 10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#591425",
            marginBottom: 15,
            borderRadius: "8px",
            [theme.breakpoints.down("xs")]: {
                borderRadius: "12px",
                width: "90%",
            },
        },
        messageDuration: {
            color: "white",
            fontSize: 14,
            width: "100%",
            padding: "12px 20px",
            display: "flex",
            alignItems: "center",
            background: "#591425",
            marginBottom: 15,
            borderRadius: "8px",
        },
    };
});

export const useSwitchStyle = makeStyles((theme) => {
    return {
        root: {
            width: 38,
            height: 21,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 2,
            "&$checked": {
                transform: "translateX(16px)",
                color: theme.palette.common.white,
                "& + $track": {
                    backgroundColor: "#3232DC",
                    opacity: 1,
                    border: "none",
                },
            },
            "&$focusVisible $thumb": {
                color: "#3232DC",
                border: "6px solid #fff",
            },
        },
        thumb: {
            width: 18,
            height: 18,
        },
        track: {
            borderRadius: 26 / 2,
            border: `1px solid #5F5F62`,
            backgroundColor: "#5F5F62",
            opacity: 1,
            transition: theme.transitions.create(["background-color", "border"]),
        },
        checked: {},
        focusVisible: {},
    };
});

export default useStyles;
