import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        itemDetail: {
            cursor: "pointer",
            "& .listItem": {
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                maxHeight: "130px",
                [theme.breakpoints.down("sm")]: {
                    overflowY: "hidden",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    maxHeight: "100%",
                },
            },

            "& .info": {
                display: "flex",
                alignItems: "center",
                marginRight: 100,
                marginBottom: 20,
                [theme.breakpoints.down("sm")]: {
                    marginRight: 0,
                    marginBottom: 16,
                    height: 65,
                },
                "& .line": {
                    width: 4,
                    height: 48,
                    background: "#337FE3",
                    marginRight: 15,
                    [theme.breakpoints.down("sm")]: {
                        height: 34,
                    },
                },
                "& .yellow": {
                    color: "#E4C96A",
                    background: "rgba(228, 201, 106, 1) !important",
                },
                "& .red": {
                    color: "#D64646",
                    background: "rgba(214, 70, 70, 1) !important",
                },
                "& .green": {
                    color: "#3C974B",
                    background: "rgba(60, 151, 75, 1) !important",
                },
                "& .blue": {
                    color: "#337FE3",
                    background: "rgba(51, 127, 227, 1) !important",
                },
                "& .purple": {
                    color: "#9747FF",
                    background: "rgba(151, 71, 255, 1) !important",
                },
                " & .box": {
                    "& p": {
                        fontSize: 16,
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        lineHeight: "24px",
                        opacity: "0.5",
                        [theme.breakpoints.down("sm")]: {
                            fontSize: 14,
                            lineHeight: "16px",
                        },
                    },
                    "& > span": {
                        fontSize: 18,
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        whiteSpace: "wrap !important",
                        [theme.breakpoints.down("sm")]: {
                            fontSize: 16,
                            lineHeight: "10px",
                            whiteSpace: "wrap !important",
                        },
                    },
                },
            },
        },
    };
});
export default useStyles;
