import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        wrapper: {
            marginTop: 20,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            [theme.breakpoints.down("md")]: {
                flexDirection: "column",
            },
            "&> div:first-child": {
                padding: 30,
                backgroundColor: "#131530",
                width: "49%",
                borderRadius: 20,
                [theme.breakpoints.down("md")]: {
                    width: "100%",
                },
                [theme.breakpoints.down("xs")]: {
                    padding: "20px 16px",
                },
                "&> .events": {
                    fontSize: 16,
                    fontWeight: 600,
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    color: "white",
                    borderBottom: "1px solid #2B2C45",
                    paddingBottom: 8,
                },
                "&> div:last-child": {
                    "&> div": {
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 20,
                        borderBottom: "1px solid #2B2C45",
                        paddingBottom: 24,
                        "&> a": {
                            fontSize: 16,
                            fontWeight: 400,
                            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                            color: "#9747FF",
                        },
                        "&> p": {
                            width: "96%",
                            marginTop: 6,
                            fontSize: 14,
                            fontWeight: 400,
                            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                            color: "white",
                            opacity: 0.5,
                        },
                    },
                    "&> div:last-child": {
                        borderBottom: "none",
                    },
                },
            },
            "&> .competitor": {
                padding: 30,
                backgroundColor: "#131530",
                width: "49%",
                borderRadius: 20,
                [theme.breakpoints.down("md")]: {
                    marginTop: 20,
                    padding: 16,
                    width: "100%",
                },
                [theme.breakpoints.down("xs")]: {
                    padding: "20px 16px",
                },
                "&> .events": {
                    fontSize: 16,
                    fontWeight: 600,
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    color: "white",
                    borderBottom: "1px solid #2B2C45",
                    paddingBottom: 8,
                    [theme.breakpoints.down("md")]: {
                        padding: 16,
                    },
                    [theme.breakpoints.down("xs")]: {
                        padding: "0 0 8px 0",
                    },
                },
                "&> .codeDaoRating": {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 30,
                    [theme.breakpoints.down("xs")]: {
                        marginTop: 24,
                    },
                    "&> p": {
                        fontSize: 16,
                        fontWeight: 600,
                        fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                        color: "white",
                        marginRight: 8,
                    },
                    "&> .iconI": {
                        position: "relative",
                        cursor: "pointer",
                        "&> img": {
                            marginTop: 7,
                            marginLeft: 7,
                            width: 15,
                            height: 15,
                            // opacity: 0.4,
                        },
                        "&> .onHoverI": {
                            position: "absolute",
                            visibility: "hidden",
                            color: "white",
                            opacity: 0,
                            fontWeight: 400,
                            fontSize: 11,
                            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                            width: 185,
                            backgroundColor: "#3E405B",
                            borderRadius: 5,
                            padding: 12,
                            top: "calc(100% + 6px)",
                            left: 0,
                            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                            transition: "all 200ms",
                            zIndex: 3,
                            [theme.breakpoints.down("sm")]: {
                                left: -90,
                            },
                            [theme.breakpoints.down("xs")]: {
                                left: -150,
                            },
                            "&> p": {
                                opacity: 0.7,
                                whiteSpace: "pre-line",
                            },
                            " &::after": {
                                content: '""',
                                position: "absolute",
                                display: "block",
                                backgroundColor: "#3E405B",
                                left: 10,
                                top: -9,
                                width: 10,
                                height: 10,
                                clipPath: "polygon(50% 20%, 0% 100%, 100% 100%)",
                                [theme.breakpoints.down("sm")]: {
                                    left: 100,
                                },
                                [theme.breakpoints.down("xs")]: {
                                    left: 160,
                                },
                            },
                        },
                        "&:hover > .onHoverI": {
                            visibility: "visible",
                            opacity: 1,
                        },
                    },
                },
                "&> .lineChart": {
                    display: "flex",
                    justifyContent: "center",
                    [theme.breakpoints.down("md")]: {
                        justifyContent: "center",
                    },
                    "&> div": {
                        width: "60%",
                        height: "60%",
                        [theme.breakpoints.down("md")]: {
                            width: "50%",
                            height: "50%",
                        },
                        [theme.breakpoints.down("xs")]: {
                            width: "90%",
                            height: "90%",
                        },
                    },
                },
            },
        },

        marketToolTip: {
            // display: "flex",
            // flexDirection: "row",
            // alignItems: "center",
            height: 38,
            "& p": {
                display: "inline-block",
            },
            "&> .iconI": {
                display: "inline-block",
                position: "relative",
                cursor: "pointer",
                "&> img": {
                    marginTop: 7,
                    marginLeft: 7,
                    width: 15,
                    height: 15,
                    opacity: 0.4,
                    marginBottom: -4,
                },
                "&> .onHoverI": {
                    position: "absolute",
                    visibility: "hidden",
                    color: "white",
                    opacity: 0,
                    fontWeight: 400,
                    fontSize: 11,
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    width: 185,
                    backgroundColor: "#3E405B",
                    borderRadius: 5,
                    padding: 12,
                    top: "calc(100% + 6px)",
                    left: 0,
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    transition: "all 200ms",
                    zIndex: 3,
                    [theme.breakpoints.down("sm")]: {
                        left: -90,
                    },
                    [theme.breakpoints.down("xs")]: {
                        left: -150,
                    },
                    "&> p": {
                        opacity: 0.7,
                        whiteSpace: "pre-line",
                    },
                    " &::after": {
                        content: '""',
                        position: "absolute",
                        display: "block",
                        backgroundColor: "#3E405B",
                        left: 10,
                        top: -9,
                        width: 10,
                        height: 10,
                        clipPath: "polygon(50% 20%, 0% 100%, 100% 100%)",
                        [theme.breakpoints.down("sm")]: {
                            left: 100,
                        },
                        [theme.breakpoints.down("xs")]: {
                            left: 160,
                        },
                    },
                },
                "&:hover > .onHoverI": {
                    visibility: "visible",
                    opacity: 1,
                },
            },
        },
        compareToolTip: {
            // display: "flex",
            // flexDirection: "row",
            // alignItems: "center",
            height: 50,
            "& p": {
                display: "inline-block",
            },
            "&> .iconI": {
                position: "relative",
                display: "inline-block",
                cursor: "pointer",
                "&> img": {
                    marginTop: 7,
                    marginLeft: 4,
                    width: 15,
                    height: 15,
                    opacity: 0.4,
                    marginBottom: -4,
                },
                "&> .onHoverI": {
                    position: "absolute",
                    visibility: "hidden",
                    color: "white",
                    opacity: 0,
                    fontWeight: 400,
                    fontSize: 11,
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    width: 185,
                    backgroundColor: "#3E405B",
                    borderRadius: 5,
                    padding: 12,
                    top: "calc(100% + 6px)",
                    left: -170,
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    transition: "all 200ms",
                    zIndex: 3,
                    [theme.breakpoints.down("sm")]: {
                        left: -90,
                    },
                    [theme.breakpoints.down("xs")]: {
                        left: -150,
                    },
                    "&> p": {
                        opacity: 0.7,
                        whiteSpace: "pre-line",
                    },
                    " &::after": {
                        content: '""',
                        position: "absolute",
                        display: "block",
                        backgroundColor: "#3E405B",
                        left: 172,
                        top: -9,
                        width: 10,
                        height: 10,
                        clipPath: "polygon(50% 20%, 0% 100%, 100% 100%)",
                        [theme.breakpoints.down("sm")]: {
                            left: 100,
                        },
                        [theme.breakpoints.down("xs")]: {
                            left: 160,
                        },
                    },
                },
                "&:hover > .onHoverI": {
                    visibility: "visible",
                    opacity: 1,
                },
            },
        },
        tableCompetitor: {
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            [theme.breakpoints.down("xs")]: {
                marginTop: 12,
            },
            "& .boxTableCompetitor": {
                padding: "25px 0px",
                background: "#131530",
                borderRadius: "20px",
                [theme.breakpoints.down("sm")]: {
                    padding: "20px 20px",
                },
                [theme.breakpoints.down("sm")]: {
                    padding: "20px 0px",
                },
                width: "100%",
                "& .titleTrans": {
                    color: "white",
                    fontSize: 16,
                    fontWeight: 600,
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    paddingBottom: 8,
                    borderBottom: "1px solid rgba(255, 255, 255, .1)",
                    marginBottom: 15,
                },
                "& .MuiTableContainer-root": {
                    margin: "0px auto",
                    borderRadius: "10px",
                    width: "100%",
                    overflow: "auto",
                    minHeight: 200,
                    [theme.breakpoints.up("sm")]: {},
                    [theme.breakpoints.up("md")]: {
                        margin: "0px auto",
                    },
                    [theme.breakpoints.up("lg")]: {},
                },
                "& .tableTransition": {
                    backgroundColor: "transparent",
                    borderCollapse: "collapse",
                    borderSpacing: 0,
                    width: "100%",
                    minWidth: "430px",
                    "& .MuiTableHead-root": {
                        height: 38,
                    },
                    "& .MuiTableRow-head": {
                        background: "rgba(42, 44, 69, 1)",
                        height: 38,
                        "& .MuiTableCell-head": {
                            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                            fontSize: 14,
                            padding: 0,
                            border: "none",
                            [theme.breakpoints.up("sm")]: {},
                            [theme.breakpoints.up("md")]: {
                                fontSize: 14,
                            },
                            [theme.breakpoints.up("lg")]: {},
                        },
                        "& .MuiTableCell-head:not(:first-child)": {
                            paddingLeft: 40,
                        },
                    },
                    "& .MuiTableBody-root": {
                        background: "#1F213B",
                    },

                    "& .MuiTableCell-body": {
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        fontSize: 14,
                        border: "none",
                        padding: "16px 0px",
                        whiteSpace: "pre",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: 100,
                    },
                    "& .MuiTableCell-body:not(:first-child)": {
                        paddingLeft: 42,
                    },
                    "& .MuiTableRow-root": {
                        borderBottom: "1px solid #2A2C45",
                        "& .MuiTableCell-body": {
                            color: "rgba(255, 255, 255, .7)",
                        },
                        "& .MuiTableCell-body:first-child": {
                            paddingLeft: 10,
                        },
                        "& .MuiTableCell-body:last-child": {
                            textTransform: "left",
                        },
                        "& .MuiTableCell-head": {
                            color: "rgba(255, 255, 255, 0.3)",
                            whiteSpace: "pre",
                        },
                    },
                },
                "& .noDataTransaction": {
                    padding: "64px 0px 27px 0px",
                    margin: "0px auto",
                    width: "316px",
                },
            },
        },
        tableTier: {},
    };
});

export default useStyles;
