import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Route,
    RouteComponentProps,
    HashRouter as Router,
    Switch,
    withRouter,
} from "react-router-dom";
import { AppContext } from "./AppContext";
import useProviderConnect from "./components/Base/HeaderDefaultLayout/hooks/useProviderConnect";
import { NetworkUpdateType, settingAppNetwork } from "./store/actions/appNetwork";

import ErrorBoundary from "./components/Base/ErrorBoundary";
import PrivateRoute from "./components/Base/PrivateRoute";
import { clearAlert } from "./store/actions/alert";
import AppContainer from "./AppContainer";
import AccountV2 from "./pages/AccountV2";
import StakingPools from "./pages/StakingPools";

//@ts-ignore
import { NotificationContainer, NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
// import ComingSoon from "./pages/ComingSoon/ComingSoon";
import { CalendarProject } from "./pages/IDOHomePage/Calendar/Calendar";
import ProjectDetail from "./pages/ProjectDetail";
// import PrivacyPolicy from "./pages/Dashboard/TermsAndPrivacy/PrivacyPolicy";
// import Terms from "./pages/Dashboard/TermsAndPrivacy/Terms";
import HomePage from "./pages/IDOHomePage/HomePage";
import NotFoundDetailPage from "./pages/NotFoundPage/NotFoundDetailPage";

/**
 * Main App routes.
 */
const Routes: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
    const dispatch = useDispatch();
    const alert = useSelector((state: any) => state.alert);
    const { history } = props;

    const { deactivate } = useWeb3React();
    const [binanceAvailable, setBinanceAvailable] = useState(false);
    const [openConnectWallet, setOpenConnectWallet] = useState<boolean>(false);
    const [currentConnectedWallet, setCurrentConnectedWallet] = useState<any>(undefined);

    const logout = () => {
        deactivate();
        dispatch(settingAppNetwork(NetworkUpdateType.Wallet, undefined));
        setCurrentConnectedWallet(undefined);
        handleConnectorDisconnect();
    };

    const {
        handleProviderChosen,
        connectWalletLoading,
        currentConnector,
        walletName,
        setWalletName,
        loginError,
        appNetworkLoading,
        handleConnectorDisconnect,
    } = useProviderConnect(setOpenConnectWallet, openConnectWallet, binanceAvailable);

    useEffect(() => {
        document.onreadystatechange = function () {
            if (document.readyState == "complete") {
                setBinanceAvailable(true);
            }
        };
    }, []);

    useEffect(() => {
        const { type, message } = alert;
        if (type && message) {
            NotificationManager[type](message);
        }
    }, [alert]);

    useEffect(() => {
        history.listen((location, action) => {
            dispatch(clearAlert());
        });
    }, []);

    return (
        <AppContext.Provider
            value={{
                binanceAvailable,
                handleProviderChosen,
                connectWalletLoading,
                walletName,
                setWalletName,
                loginError,
                appNetworkLoading,
                handleConnectorDisconnect,
                currentConnector,
                logout,
                setCurrentConnectedWallet,
                openConnectWallet,
                setOpenConnectWallet,
                currentConnectedWallet,
            }}
        >
            <div>
                <Switch>
                    {/* <Route path={`${"/buy-token/:id"}`} component={BuyToken} /> */}
                    {/* <Route path={"/confirm-email/:token"} component={ConfirmEmail} /> */}
                    {/* <Route path={"/network-change"} component={NetworkChange} /> */}
                    {/* <Route path={"/change-password/:role?"} component={ChangePassword} /> */}
                    <PrivateRoute path={"/account"} component={AccountV2} />
                    {/* <Route path={"/stake"} component={Deposit} />
                    <Route path={"/unstake"} component={Withdraw} />
                    <Route path={"/pools"} component={Pools} /> */}
                    <Route path={"/staking-pools"} component={StakingPools} />
                    <Route path={"/calendar"} component={CalendarProject} />
                    <Route exact path={"/project-detail/:id"} component={ProjectDetail} />
                    <Route exact path={"/"} component={HomePage} />
                    {/* <Route path={"/coming-soon"} component={ComingSoon} /> */}
                    <Route exact path={"/not-found"} component={NotFoundDetailPage} />
                    {/* <Route component={NotFoundPage} /> */}
                </Switch>
            </div>
        </AppContext.Provider>
    );
};

const RoutesHistory = withRouter(Routes);

const routing = function createRouting() {
    return (
        <>
            <NotificationContainer />
            <Router>
                <AppContainer>
                    <ErrorBoundary>
                        <RoutesHistory />
                    </ErrorBoundary>
                </AppContainer>
            </Router>
        </>
    );
};
/**
 * Wrap the app routes into router
 *
 * PROPS
 * =============================================================================
 * @returns {React.Node}
 */
export default routing;
