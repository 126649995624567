import { Theme, createStyles } from "@material-ui/core/styles";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
            background: "#020616",
            paddingTop: 0,
            borderRadius: 20,
        },
        title: {
            font: "inherit",
        },
        closeButton: {
            position: "absolute",
            right: 25,
            top: 25,
            color: "white",
            opacity: 0.5,
            backgroundColor: "#262530",
            padding: 8,
            border: "2px solid rgba(255, 255, 255, 0.2)",
            [theme.breakpoints.down("xs")]: {
                right: 16,
                top: 16,
                padding: 4,
            },
            "&:hover": {
                backgroundColor: "#262530",
            },
        },
        svgIcon: {
            fontSize: 5,
            [theme.breakpoints.down("xs")]: {
                fontSize: 4,
            },
        },
    });

export default styles;
