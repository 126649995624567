import { amountBuyticketActions } from "./../constants/amount-buy-ticket";
import { AnyAction } from "redux";
import { useEffect } from "react";

type StateType = {
    type: string;
    currentAmount: number;
    totalAmount: number;
};

const initialState = {
    type: "",
    currentAmount: 0,
    totalAmount: 0,
};

export const amountBuyticketReducer = (state: StateType = initialState, action: AnyAction) => {
    switch (action.type) {
        case amountBuyticketActions.CURRENT_AMOUNT: {
            return {
                type: "",
                currentAmount: action.payload,
                totalAmount: state.totalAmount,
            };
        }

        case amountBuyticketActions.TOTAL_AMOUNT: {
            return {
                type: "amount",
                currentAmount: state.currentAmount,
                totalAmount: state.totalAmount + state.currentAmount,
            };
        }

        case amountBuyticketActions.RESET_TOTAL_AMOUNT: {
            return {
                ...state,
                totalAmount: 0,
            };
        }

        default: {
            return state;
        }
    }
};
