import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { BaseRequest } from "../../../request/Request";
import {
    statusBuyticket,
    statusCalculation,
    statusDistribution,
    statusEnded,
    statusUpcoming,
} from "../../../store/actions/status-ido-pool";

type TParams = {
    id: "string";
};

function useHandleTime() {
    const [snapshot, setSnapshot] = useState<any>(10);
    const [openTime, setOpenTime] = useState<any>(1);
    const [closeTime, setCloseTime] = useState<any>(100);
    const [ticketCloseTime, setTicketCloseTime] = useState<any>(1000);

    const { id } = useParams<TParams>();
    const dispatch = useDispatch();

    const [idoDetail, setIdoDetail] = useState<any>();
    useEffect(() => {
        (async () => {
            const baseRequest = new BaseRequest();
            const response = (await baseRequest.get(`ido/${id}/detail`, true)) as any;
            const resObj = await response.json();
            setIdoDetail(resObj);
            return resObj;
        })();
    }, []);

    const convertTime = (name: string, type: string) => {
        return moment(name).format(type);
    };

    const testStartSnapshot = idoDetail?.data?.start_staking_snapshot;
    const testStartTicketOpen = idoDetail?.data?.start_ticket_open;
    const testStartTicketClose = idoDetail?.data?.start_ticket_close;
    const testStartPoolEnd = idoDetail?.data?.start_pool_end;

    // convert Time Snapshot
    const timeStartSnapshot = convertTime(testStartSnapshot, "MM/DD/YYYY HH:mm:ss");
    const timeHoursSnapshot = convertTime(testStartSnapshot, "HH:mm:ss");
    const timeDaySnapshot = convertTime(testStartSnapshot, "MM/DD/YYYY");
    const covertTimeStampSnapshot = Math.round(new Date(timeStartSnapshot).getTime() / 1000);

    // convert Time Tickets openTime
    const timeStartTicketOpen = convertTime(testStartTicketOpen, "MM/DD/YYYY HH:mm:ss");
    const timeHoursTicketOpen = convertTime(testStartTicketOpen, "HH:mm:ss");
    const timeDayTicketOpen = convertTime(testStartTicketOpen, "MM/DD/YYYY");
    const covertTimeStampTicketOpen = Math.round(new Date(timeStartTicketOpen).getTime() / 1000);

    // convert time Ticket close
    const timeStartTicketClose = convertTime(testStartTicketClose, "MM/DD/YYYY HH:mm:ss");
    const timeHoursTicketClose = convertTime(testStartTicketClose, "HH:mm:ss");
    const timeDayTicketClose = convertTime(testStartTicketClose, "MM/DD/YYYY");
    const covertTimeStampTicketClose = Math.round(new Date(timeStartTicketClose).getTime() / 1000);

    // convert time Pool end
    const timeStartPoolEnd = convertTime(testStartPoolEnd, "MM/DD/YYYY HH:mm:ss");
    const timeHoursPoolEnd = convertTime(testStartPoolEnd, "HH:mm:ss");
    const timeDayPoolEnd = convertTime(testStartPoolEnd, "MM/DD/YYYY");
    const covertTimeStampPoolEnd = Math.round(new Date(timeStartPoolEnd).getTime() / 1000);

    useEffect(() => {
        return () => {
            dispatch(statusUpcoming(""));
        };
    }, [dispatch]);

    const dispatchStatusIDO = useCallback(() => {
        // console.log({snapshot, openTime, ticketCloseTime, closeTime})
        if (snapshot > 0) {
            return dispatch(statusUpcoming("Upcoming"));
        } else if (snapshot <= 0 && openTime > 0) {
            return dispatch(statusCalculation("Calculation"));
        } else if (openTime <= 0 && ticketCloseTime > 0) {
            return dispatch(statusBuyticket("Buy Ticket"));
        } else if (ticketCloseTime <= 0 && closeTime > 0) {
            return dispatch(statusDistribution("Distribution"));
        } else if (closeTime <= 0) {
            return dispatch(statusEnded("Ended"));
        }
    }, [dispatch, snapshot, openTime, ticketCloseTime, closeTime]);

    useEffect(() => {
        dispatchStatusIDO();
    }, [dispatchStatusIDO]);

    useEffect(() => {
        if (!idoDetail) return;
        const timeOpenSnapshot = covertTimeStampSnapshot - Date.now() / 1000;
        const time = timeOpenSnapshot * 1000;
        console.log({covertTimeStampSnapshot, now: Date.now() / 1000, time, timeOpenSnapshot})

        if (timeOpenSnapshot > 0) {
            const timeout = setTimeout(() => {
                setSnapshot(timeOpenSnapshot);
            }, time);
            return () => {
                clearTimeout(timeout);
            };
        } else {
            setSnapshot(timeOpenSnapshot);
        }
    }, [covertTimeStampSnapshot, idoDetail]);

    useEffect(() => {
        if (!idoDetail) return;

        const timeOpen = covertTimeStampTicketOpen - Date.now() / 1000;
        const time = timeOpen * 1000;
        if (timeOpen > 0) {
            const timeout = setTimeout(() => {
                // const timeOpen = covertTimeStampTicketOpen - Date.now() / 1000;
                setOpenTime(timeOpen);
            }, time);
            return () => {
                clearTimeout(timeout);
            };
        } else {
            setOpenTime(timeOpen);
        }
    }, [covertTimeStampTicketOpen, idoDetail]);

    useEffect(() => {
        if (!idoDetail) return;
        const timeTicketClose = covertTimeStampTicketClose - Date.now() / 1000;
        const time = timeTicketClose * 1000;
        if (timeTicketClose > 0) {
            const timeout = setTimeout(() => {
                // const timeTicketClose = covertTimeStampTicketClose - Date.now() / 1000;
                setTicketCloseTime(timeTicketClose);
            }, time);
            return () => {
                clearTimeout(timeout);
            };
        }  else {
            setTicketCloseTime(timeTicketClose);
        }
    }, [covertTimeStampTicketClose, idoDetail]);

    useEffect(() => {
        if (!idoDetail) return;
        const timeClose = covertTimeStampPoolEnd - Date.now() / 1000;
        const time = timeClose * 1000;
        if (timeClose > 0) {
            const timeout = setTimeout(() => {
                setCloseTime(timeClose);
            }, time);
    
            return () => {
                clearTimeout(timeout);
            };
        } else {
            setCloseTime(timeClose);
        }
    }, [covertTimeStampPoolEnd, idoDetail]);

    return {
        timeHoursSnapshot,
        timeDaySnapshot,
        timeHoursTicketOpen,
        timeDayTicketOpen,
        timeHoursTicketClose,
        timeDayTicketClose,
        timeHoursPoolEnd,
        timeDayPoolEnd,
        timeStartSnapshot,
        timeStartTicketOpen,
        timeStartTicketClose,
        timeStartPoolEnd,
    };
}
export default useHandleTime;
