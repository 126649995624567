import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
    return {
        mainLayout: {
            gridArea: "main",
            width: "100%",
            backgroundColor: "#030517",
            position: "relative",
            zIndex: 100,
            overflow: "hidden",
            "& .stoneLeft": {
                position: "absolute",
                top: 190,
                left: 155,
                zIndex: 1,
                [theme.breakpoints.down("xs")]: {
                    top: 4,
                    left: "-40px",
                },
            },
            "& .stoneRight": {
                position: "absolute",
                top: 260,
                right: 93,
                zIndex: 1,
                [theme.breakpoints.down("xs")]: {
                    display: "none",
                },
            },
            "& .stoneRightMobile": {
                position: "absolute",
                top: 120,
                right: 93,
                zIndex: 1,
                display: "none",
                [theme.breakpoints.down("xs")]: {
                    display: "block",
                    top: 200,
                    right: "0px",
                },
            },
            "& .shawDownLeft": {
                position: "absolute",
                width: 243,
                height: 243,
                backgroundColor: "#791CD4",
                top: 175,
                left: -57,
                borderRadius: "50%",
                opacity: 0.6,
                filter: "blur(110px)",
                zIndex: 1,
                [theme.breakpoints.down("xs")]: {
                    filter: "blur(150px)",
                },
            },
            "& .shawDownRight": {
                position: "absolute",
                width: 468,
                height: 468,
                backgroundColor: "#2DDBFF",
                top: 345,
                right: -121,
                borderRadius: "50%",
                opacity: 0.2,
                filter: "blur(130px)",
                zIndex: 1,
                [theme.breakpoints.down("xs")]: {
                    display: "none",
                },
            },
        },

        alertVerifyEmail: {
            position: "relative",
            width: "100%",
            maxWidth: "1040px",
            margin: "0 auto",
            padding: 9,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            background: "#D0AA4D",
            marginBottom: 15,
            borderRadius: "8px",
            minHeight: 42,

            [theme.breakpoints.down("sm")]: {
                alignItems: "flex-start",
                width: "90%",
            },

            "& img": {
                marginTop: 3,
                [theme.breakpoints.down("sm")]: {
                    marginTop: 6,
                },
            },

            "& .btn-close": {
                position: "absolute",
                top: "50%",
                right: "15px",
                transform: "translateY(-50%)",
            },

            "& span": {
                font: "normal normal 400 14px/24px Helvetica",
                color: "#070A1B",
            },

            "& a": {
                color: "#3232DC",
            },
        },

        errorSwich: {
            marginBottom: 20,
        },
    };
});

export default useStyles;
