import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        stakeMore: {
            borderTop: "1px solid #2B2C45",
            paddingTop: 24,
            marginTop: 28,
            "&> p": {
                color: "white",
                fontSize: 14,
                opacity: 0.5,
                fontWeight: 400,
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            },
            "&> p:nth-child(2)": {
                marginTop: 6,
            },
            "&> .paragraph": {
                marginTop: 30,
            },
            "&> .btnStakeMoreRankLegend": {
                width: "100%",
                marginTop: 80,
                color: "white",
                fontSize: 16,
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                padding: "15px 0",
                background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                borderRadius: 90,
                textTransform: "capitalize",
                [theme.breakpoints.down("xs")]: {
                    marginTop: 43,
                },
            },
        },
    };
});

export default useStyles;
