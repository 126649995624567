import Web3 from "web3";
import WalletLink from "walletlink";
import { connectorNames, ConnectorNames, connectorsByName } from "../constants/connectors";
import {
    ETH_CHAIN_ID,
    BSC_CHAIN_ID,
    POLYGON_CHAIN_ID,
    KLAYTN_CHAIN_ID,
} from "../constants/network";
import { NETWORK_AVAILABLE } from "../constants";
import { caver } from "../store/actions/user";

const POOL_ABI = require("../abi/Pool.json");

const BSC_NETWORK_URL = process.env.REACT_APP_BSC_RPC_URL || "";
const POLYGON_NETWORK_URL = process.env.REACT_APP_POLYGON_RPC_URL || "";
const KLAYTN_NETWORK_URL = process.env.REACT_APP_KLAYTN_RPC_URL || "";
export const MAX_INT = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

export enum SmartContractMethod {
    Write = "Write",
    Read = "Read",
}

type smartContractMethod = Extract<
    SmartContractMethod,
    SmartContractMethod.Write | SmartContractMethod.Read
>;

export const getWeb3Instance = () => {
    const windowObj = window as any;
    const { ethereum, web3 } = windowObj;
    if (ethereum && ethereum.isMetaMask) {
        return new Web3(ethereum);
    }
    if (web3) {
        return new Web3(web3.currentProvider);
    }
    return null;
};

export const isMetaMaskInstalled = () => {
    const windowObj = window as any;
    const { ethereum } = windowObj;
    return ethereum && ethereum.isMetaMask;
};

export const getProviderByNetwork = (
    networkName: connectorNames,
    appChainID: string,
    typeMethod: smartContractMethod,
    forceUsingEther: boolean
) => {
    if (forceUsingEther) {
        return new Web3.providers.HttpProvider(POLYGON_NETWORK_URL);
    }

    if (appChainID && typeMethod === SmartContractMethod.Read) {
        switch (appChainID) {
            case BSC_CHAIN_ID:
                return new Web3.providers.HttpProvider(BSC_NETWORK_URL);
            case POLYGON_CHAIN_ID:
                return new Web3.providers.HttpProvider(POLYGON_NETWORK_URL);
            default:
                return new Web3.providers.HttpProvider(POLYGON_NETWORK_URL);
        }
    }

    const provider = connectorsByName[networkName as connectorNames] as any;
    return provider;
};

export const getContractInstance = (
    ABIContract: any,
    contractAddress: string,
    networkName: connectorNames = ConnectorNames.MetaMask,
    appChainID: string = ETH_CHAIN_ID as string,
    typeMethod: smartContractMethod = SmartContractMethod.Read,
    forceUsingEther: boolean = false
) => {
    const provider = getProviderByNetwork(
        networkName as connectorNames,
        appChainID,
        typeMethod,
        forceUsingEther
    );

    if (provider) {
        const web3Instance = new Web3(provider);
        return new web3Instance.eth.Contract(ABIContract, contractAddress);
    }

    return;
};

export const getContractReadInstance = (
    ABIContract: any,
    contractAddress: string,
    appChainID: string
) => {
    let provider;
    switch (appChainID) {
        case NETWORK_AVAILABLE.BSC:
            provider = new Web3.providers.HttpProvider(BSC_NETWORK_URL);
            break;

        case NETWORK_AVAILABLE.POLYGON:
            provider = new Web3.providers.HttpProvider(POLYGON_NETWORK_URL);
            break;
    }
    if (!provider) {
        return;
    }

    const web3Instance = new Web3(provider);

    return new web3Instance.eth.Contract(ABIContract, contractAddress);
};

export const getContractConnect = async (
    abi: any,
    address: string,
    appChainID: string,
    wallet: string = ConnectorNames.MetaMask,
    method?: string,
) => {
    if (address) {
        if (wallet === ConnectorNames.Kaikas) {
            const myContract = new caver.klay.Contract(abi, address);
            return myContract;
        } else {
            let web3;
            switch (appChainID) {
                case POLYGON_CHAIN_ID:
                    if (method === "write") {
                        web3 = new Web3(Web3.givenProvider)
                        console.log("write")
                    } else {
                        web3 = new Web3(POLYGON_NETWORK_URL);
                    }
                    break;
    
                case KLAYTN_CHAIN_ID:
                    web3 = new Web3(Web3.givenProvider || KLAYTN_NETWORK_URL);
                    break;
                default:
                    web3 = new Web3(POLYGON_NETWORK_URL || Web3.givenProvider);
            }
            let res = await new web3.eth.Contract(abi, address);
            return res;
        }
    } else {
        return;
    }
};

export const getContractInstanceWeb3 = (networkAvailable: string) => {
    let provider;
    switch (networkAvailable) {
        case NETWORK_AVAILABLE.BSC:
            provider = new Web3.providers.HttpProvider(BSC_NETWORK_URL);
            return new Web3(provider);

        case NETWORK_AVAILABLE.POLYGON:
            provider = new Web3.providers.HttpProvider(POLYGON_NETWORK_URL);
            return new Web3(provider);
        case NETWORK_AVAILABLE.KLAYTN:
            provider = new Web3.providers.HttpProvider(KLAYTN_NETWORK_URL);
            return new Web3(provider);
        default:
            return null;
    }
};

export const getPoolContract = ({ networkAvailable, poolHash }: any) => {
    const web3Instance = getContractInstanceWeb3(networkAvailable);
    if (!web3Instance) {
        return null;
    }

    return new web3Instance.eth.Contract(POOL_ABI, poolHash);
};

export const convertFromWei = (value: any, unit = "ether") => {
    return Web3.utils.fromWei(value);
};

export const convertToWei = (value: any, unit = "ether") => {
    return Web3.utils.toWei(value);
};

export const isValidAddress = (address: string) => {
    return Web3.utils.isAddress(address);
};

export const convertToBN = (number: string) => {
    return Web3.utils.toBN(number);
};
