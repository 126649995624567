import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        wrapper: {
            marginTop: 20,
            // padding: 30,
            backgroundColor: "#131530",
            width: "100%",
            borderRadius: 20,
            display: "flex",
            flexDirection: "row",
        },
        container1: {
            width: "33%",
            "&> .desc1": {
                marginTop: 10,
                "&> div:first-child": {
                    fontWeight: 600,
                    fontSize: 12,
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    color: "white",
                },
                "&> div:last-child": {
                    color: "white",
                    opacity: 0.3,
                    fontSize: 10,
                    "& p": {
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    },
                },
            },
        },
        container2: {
            width: "33%",
            position: "relative",
            "&> .desc2": {
                marginTop: 10,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                left: -18,

                "&> div:first-child": {
                    fontWeight: 600,
                    fontSize: 12,
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    color: "white",
                },
                "&> div:last-child": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    color: "white",
                    opacity: 0.3,
                    fontSize: 10,
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    "& p": {
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    },
                },
            },
        },
        container3: {
            width: "33%",
            position: "relative",
            "&> .desc3": {
                marginTop: 10,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                left: -18,
                "&> div:first-child": {
                    fontWeight: 600,
                    fontSize: 12,
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    color: "white",
                },
                "&> div:last-child": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    color: "white",
                    opacity: 0.3,
                    fontSize: 10,
                    "& p": {
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    },
                },
            },
        },
        container4: {
            position: "relative",
            "&> .desc4": {
                position: "absolute",
                marginTop: 10,
                display: "flex",
                flexDirection: "column",
                left: -70,
                width: 100,
                "&> div:first-child": {
                    fontWeight: 600,
                    fontSize: 12,
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    display: "flex",
                    justifyContent: "flex-end",
                    color: "white",
                },
                "&> div:last-child": {
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                    "&> p": {
                        opacity: 0.3,
                        color: "white",
                        display: "flex",
                        justifyContent: "flex-end",
                        fontWeight: 400,
                        fontSize: 10,
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        "& p": {
                            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        },
                    },
                },
            },
        },
        timeLineTier1: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            "&> div:first-child": {
                width: 31,
                height: 30,
                borderRadius: "100%",
                backgroundColor: "#2F3149",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: 700,
                color: "white",
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                [theme.breakpoints.down("sm")]: {
                    width: 33,
                },
            },
            "&> div:last-child": {
                backgroundColor: "#2F3149",
                width: "84%",
                height: 6,
                // ["@media (max-width:1500px)"]: {
                //     width: "84%",
                // },
                [theme.breakpoints.down("md")]: {
                    width: "91.5%",
                },
            },
        },
        timeLineTier2: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            "&> div:first-child": {
                width: 31,
                height: 30,
                borderRadius: "100%",
                backgroundColor: "#2F3149",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                color: "white",
                [theme.breakpoints.down("sm")]: {
                    width: 33,
                },
            },
            "&> div:last-child": {
                backgroundColor: "#2F3149",
                width: "84%",
                height: 6,
                // ["@media (max-width:1500px)"]: {
                //     width: "84%",
                // },
                [theme.breakpoints.down("md")]: {
                    width: "91.5%",
                },
            },
        },
        timeLineTier3: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            "&> div:first-child": {
                width: 31,
                height: 30,
                borderRadius: "100%",
                backgroundColor: "#2F3149",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                color: "white",
                [theme.breakpoints.down("sm")]: {
                    width: 33,
                },
            },
            "&> div:last-child": {
                backgroundColor: "#2F3149",
                width: "84%",
                height: 6,
                // ["@media (max-width:1500px)"]: {
                //     width: "84%",
                // },
                [theme.breakpoints.down("md")]: {
                    width: "91.5%",
                },
                [theme.breakpoints.down("sm")]: {
                    width: "88%",
                },
            },
        },
        timeLineTier4: {
            "&> div": {
                width: 30,
                height: 30,
                borderRadius: "100%",
                backgroundColor: "#2F3149",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                color: "white",
            },
        },
    };
});

export default useStyles;
