import { Hidden } from "@material-ui/core";
import i18n from "i18next";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import { TELEGRAM_LINK_URL, TWITTER_LINK_URL } from "../../constants";
import { useSocket } from "../../hooks/useSocket";
import { getIdoDetail } from "../../store/actions/ido-detail";
import { statusApplySuccess } from "../../store/actions/statusApplyWhitelist";
import { toastMessage } from "../../store/actions/toast-message-buyticket";
import { formatPrecisionAmount } from "../../utils";
import { SocketEvent } from "../../utils/types/socket";
import { convertLang } from "../IDOHomePage/CardItem";
import ModalLoadingHomePage from "../StakingPools/ModalLoading/ModalLoadingHomePage";
import Events from "./Event";
import ParticipateIdo from "./ParticipateIdo";
import Steps from "./Steps";
import StepsMobile from "./Steps/StepsMobile";
import useStyles from "./style";
import Summary from "./Summary";
import TimeLine from "./TimeLine";
import TimeLineMobile from "./TimeLine/TimeLineMobile";
import ToastMessage from "./ToastMessage";

type TParams = {
    id: "string";
};

const KLAYTN_URL = process.env.REACT_APP_KLAYTN_BASE_URL || "";
const BSCSCAN_URL = process.env.REACT_APP_BSCSCAN_BASE_URL || "";
const POLSCAN_URL = process.env.REACT_APP_POLSCAN_BASE_URL || "";
export default function ProjectDetail(props: any) {
    const { id } = useParams<TParams>();
    const styles = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const status = useSelector((state: any) => state.statusIdoPool.message);
    const tierAccount = useSelector((state: any) => state.userTier.data.name);
    const { data: idoDetail } = useSelector((state: any) => state.idoDetail);
    const [loading, setLoading] = useState<boolean>(false);

    const { socket, registerListener } = useSocket();

    useEffect(() => {
        socket
            .connect()
            .on("connect_error", () => {})
            .on("disconnect", () => {})
            .on("connect", () => {});
        return () => {
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        registerListener(SocketEvent.Ido_detail_changed, (data) => {
            if (Number(data.ido_id) === Number(id)) {
                dispatch(getIdoDetail(id));
            }
        });
    }, []);

    const getNetworkIdo = useCallback((): string => {
        if (!idoDetail?.token_address) return "";
        if (idoDetail?.network === "bsc") {
            const contractNetworkIdo = `${BSCSCAN_URL}/address/${idoDetail?.token_address}` || "";
            return contractNetworkIdo;
        } else if (idoDetail?.network === "klaytn") {
            const contractNetworkIdo = `${KLAYTN_URL}/${idoDetail?.token_address}` || "";
            return contractNetworkIdo;
        } else if (idoDetail?.network === "polygon") {
            const contractNetworkIdo = `${POLSCAN_URL}/address/${idoDetail?.token_address}` || "";
            return contractNetworkIdo;
        }
        return `${BSCSCAN_URL}/${idoDetail?.token_address}`;
    }, [idoDetail?.network, idoDetail?.token_address]);

    useEffect(() => {
        document.title = t(`CodeDAO | IDO ${convertLang(idoDetail?.project_name, i18n.language)}`);
    }, [idoDetail?.project_name, t]);

    useEffect(() => {
        return () => {
            dispatch(toastMessage({ type: "", title: "", message: "" }));
        };
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const fetchIdoDetail = useCallback(async () => {
        setLoading(true);
        await dispatch(getIdoDetail(id));
        setTimeout(() => {
            setLoading(false);
        }, 700);
    }, [dispatch, id]);
    useEffect(() => {
        fetchIdoDetail();
    }, [fetchIdoDetail]);

    const renderNetwork = () => {
        return (
            <>
                {(() => {
                    switch (idoDetail?.network) {
                        case "bsc": {
                            return (
                                <p>
                                    Bsc{" "}
                                    <img
                                        src="/images/home-page/iconNetworkBsc.svg"
                                        width="17px"
                                        height="17px"
                                        alt=""
                                    />
                                </p>
                            );
                        }
                        case "polygon": {
                            return (
                                <p>
                                    Polygon{" "}
                                    <img
                                        src="/images/home-page/iconNetworkPolygon.svg"
                                        width="17px"
                                        height="17px"
                                        alt=""
                                    />
                                </p>
                            );
                        }
                        case "klaytn": {
                            return (
                                <p>
                                    Klaytn{" "}
                                    <img
                                        src="/images/home-page/iconNetworkKlay.svg"
                                        width="17px"
                                        height="17px"
                                        alt=""
                                    />
                                </p>
                            );
                        }
                        default:
                            return null;
                    }
                })()}
            </>
        );
    };

    const renderCategory = (data: any, key: string) => {
        try {
            const result = JSON.parse(data);
            if (Array.isArray(result)) {
                return result;
            } else {
                return JSON.parse(result[key === "en" ? key : "kr"]);
            }
        } catch (e) {
            return [];
        }
    };

    useEffect(() => {
        if (tierAccount === "rookie" || tierAccount === "legend") {
            dispatch(statusApplySuccess("success"));
        } else if (tierAccount === "starter") {
            dispatch(statusApplySuccess("error"));
        }
    }, [dispatch, tierAccount]);

    const dayLinerVesting = Math.floor(idoDetail?.linear_vesting / (3600 * 24));
    const hoursLinerVesting = Math.floor((idoDetail?.linear_vesting % (3600 * 24)) / 3600);

    return (
        <DefaultLayout defaultLayout={false}>
            <div className={styles.wrapper}>
                <Hidden xsDown>
                    <ToastMessage />
                </Hidden>
                <div>
                    <div>
                        <div className={styles.projectName}>
                            <img
                                src={idoDetail?.cover_photo}
                                onError={(e) => {
                                    (e.target as HTMLImageElement).onerror = null;
                                    (e.target as HTMLImageElement).src =
                                        "/images/home-page/imgDefault.svg";
                                }}
                                alt=""
                            />
                            {/* <p>{state?.&& state?.project_name}</p> */}
                            <p>
                                {convertLang(idoDetail?.project_name, i18n.language)} (
                                {idoDetail.token_symbol ? idoDetail?.token_symbol : ""})
                            </p>
                        </div>
                        <div className={styles.statusProject}>
                            <div className="status">
                                <p className={status === "Buy Ticket" ? "Buyticket" : status}>
                                    <img
                                        src={`/images/home-page/iconClock${
                                            status === "Buy Ticket" ? "Buyticket" : status
                                        }.svg`}
                                        alt=""
                                    />
                                    {status}
                                </p>
                                {idoDetail &&
                                    renderCategory(idoDetail?.category, i18n.language)?.map(
                                        (item: string, index: number) => <p key={index}>{item}</p>
                                    )}
                            </div>
                            <div className="icon">
                                <a
                                    href={`${TELEGRAM_LINK_URL}${idoDetail?.ido_telegram}` || "/"}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <img
                                        src="/images/home-page/iconTelegram.svg"
                                        alt=""
                                        width="20px"
                                        height="16.77px"
                                    />
                                </a>
                                <a
                                    href={`${TWITTER_LINK_URL}${idoDetail?.ido_twitter}` || "/"}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <img
                                        src="/images/home-page/logoTwitter.svg"
                                        alt=""
                                        width="18.92px"
                                        height="15.38px"
                                    />
                                </a>
                                <a
                                    href={idoDetail?.ido_website || "/"}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <img
                                        src="/images/home-page/iconEarn.svg"
                                        width="17.33px"
                                        height="17.33px"
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={styles.container}>
                        <div className={styles.poolTimeLine}>
                            <p>{t("ProjectDetails.poolTimeline")}</p>
                            <Hidden xsDown>
                                <TimeLine />
                            </Hidden>

                            <Hidden smUp>
                                <TimeLineMobile />
                            </Hidden>

                            <div className={styles.details}>
                                <p>{t("ProjectDetails.poolDetails")}</p>
                                <div className={styles.desc}>
                                    <div>
                                        <p>{t("ProjectDetails.token")} :</p>
                                        <p className="viewContractToken">
                                            {idoDetail?.token_symbol ? idoDetail?.token_symbol : ""}
                                            {idoDetail?.token_address ? (
                                                <a
                                                    href={getNetworkIdo()}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                >
                                                    View contract
                                                    <img
                                                        src="/images/home-page/iconWebsite.svg"
                                                        alt=""
                                                        width={6}
                                                        height={6}
                                                    />
                                                </a>
                                            ) : (
                                                ""
                                            )}
                                        </p>
                                    </div>
                                    <div>
                                        <p>{t("ProjectDetails.salePrice")}</p>
                                        <p>
                                            <p>
                                                {idoDetail?.conversion_rate
                                                    ? formatPrecisionAmount(
                                                          idoDetail?.conversion_rate
                                                      )
                                                    : 0}{" "}
                                                USDC
                                            </p>
                                        </p>
                                    </div>
                                    <div>
                                        <p>{t("ProjectDetails.totalFundraise")}</p>
                                        <p>
                                            {idoDetail?.amount
                                                ? formatPrecisionAmount(idoDetail?.amount)
                                                : 0}{" "}
                                            {idoDetail?.token_symbol ? idoDetail?.token_symbol : ""}
                                        </p>
                                    </div>
                                    <div>
                                        <p>{t("ProjectDetails.website")}</p>
                                        {idoDetail?.ido_website ? (
                                            <a
                                                href={idoDetail?.ido_website}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                            >
                                                {idoDetail?.ido_website}{" "}
                                                <img
                                                    src="/images/home-page/iconWebsite.svg"
                                                    alt=""
                                                />
                                            </a>
                                        ) : (
                                            <p>-</p>
                                        )}
                                    </div>
                                    <div>
                                        <p>{t("ProjectDetails.chain")}</p>
                                        {renderNetwork()}
                                    </div>
                                    <div>
                                        <p>{t("ProjectDetails.linearVesting")}</p>
                                        {idoDetail?.TGE === 100 ? (
                                            <p>100%</p>
                                        ) : (
                                            <p>
                                                {dayLinerVesting ? dayLinerVesting : 0} days{" "}
                                                {hoursLinerVesting ? hoursLinerVesting : 0} hours
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ParticipateIdo />
                    </div>
                </div>
                <Summary />
                <Events />
                <Hidden xsDown>
                    <Steps />
                </Hidden>

                <Hidden smUp>
                    <StepsMobile />
                </Hidden>
                <ModalLoadingHomePage
                    open={loading}
                    onClose={() => {
                        setLoading(false);
                    }}
                />
            </div>
        </DefaultLayout>
    );
}
