import { BscConnector } from "@binance-chain/bsc-connector";
import { WalletConnectConnector as klipWalletConnector } from "@kanthakran/klip-connector";
import { AbstractConnector } from "@web3-react/abstract-connector";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { KaikasConnector } from "kaikas-connector";
import { FortmaticConnector } from "../connectors/Fortmatic";
import {
    APP_NETWORKS_NAME,
    BSC_CHAIN_ID,
    FORMATIC_KEY,
    POLYGON_CHAIN_ID,
} from "./network";

const METAMASK_DEEPLINK = process.env.REACT_APP_METAMASK_DEEPLINK;

export const bscConnector = new BscConnector({}) as any;
export const kaikasConnector = new KaikasConnector({}) as any;
export const klipConnector = new klipWalletConnector({
    qrcodeModal: {
        open: (uri: string, cb: any, opts?: any) => {},
        close: () => {},
    },
}) as any;
export const injected = new InjectedConnector({});

const originalChainIdChangeHandler = bscConnector.handleChainChanged;

//@ts-ignore
bscConnector.handleChainChanged = (chainId: string) => {
    const chainIdNum = Number(chainId);
    console.debug("Handling 'chainChanged' event with payload", chainId, isNaN(chainIdNum));
    if (isNaN(chainIdNum)) {
        bscConnector.emitError("NaN ChainId");
        return;
    }
    //@ts-ignore
    originalChainIdChangeHandler(chainId);
};

// mainnet only
export const walletLinkConnect = new WalletLinkConnector({
    url: process.env.REACT_APP_NETWORK_URL || "",
    appName: "CodeDAO",
    appLogoUrl: "https://landing.klaydao.sotatek.works/image/LogoImage.svg",
    darkMode: true,
    // supportedChainIds: [1,4,5],
});

// mainnet only
export const walletConnect = new WalletConnectConnector({
    rpc: { [Number(POLYGON_CHAIN_ID)]: "https://rpc-mainnet.maticvigil.com" },
    bridge: "https://bridge.walletconnect.org",
    qrcode: true,
    // pollingInterval: 10000
});

export const walletConnectBsc = new WalletConnectConnector({
    // rpc: { 56: 'https://bsc-dataseed.binance.org/' },
    rpc: { [Number(BSC_CHAIN_ID)]: "https://bsc-dataseed.binance.org/" },
    bridge: "https://bridge.walletconnect.org",
    qrcode: true,
    // pollingInterval: 10000
});

export const walletConnectPolygon = new WalletConnectConnector({
    rpc: { [Number(POLYGON_CHAIN_ID)]: "https://rpc-mainnet.maticvigil.com" },
    bridge: "https://bridge.walletconnect.org",
    qrcode: true,
    // pollingInterval: 10000
});

// mainnet only
export const fortmatic = new FortmaticConnector({
    apiKey: FORMATIC_KEY ?? "",
    chainId: 1,
});

export interface WalletInfo {
    connector?: AbstractConnector;
    name: string;
    // iconName: string
    description: string;
    href: string | null;
    // color: string
    primary?: true;
    mobile?: true;
    mobileOnly?: true;
    disableIcon: string;
    icon: string;
    deepLink?: string;
}

export enum ConnectorNames {
    MetaMask = "MetaMask",
    BSC = "Binance Wallet",
    WalletConnect = "WalletConnect",
    // WalletConnectBsc = "WalletConnect",
    WalletConnectPolygon = "WalletConnect",
    WalletLinkConnect = "Coinbase Wallet",
    Fortmatic = "Fortmatic",
    Kaikas = "Kaikas",
    Klip = "Klip",
}

export type connectorNames = Extract<
    ConnectorNames,
    | ConnectorNames.MetaMask
    | ConnectorNames.BSC
    | ConnectorNames.WalletConnect
    | ConnectorNames.WalletLinkConnect
    | ConnectorNames.Fortmatic
    | ConnectorNames.Kaikas
>;

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
    METAMASK: {
        connector: injected,
        name: ConnectorNames.MetaMask,
        icon: "/images/metamask.svg",
        disableIcon: "/images/metamask-disabled.svg",
        description: "Easy-to-use browser extension.",
        href: null,
        mobile: true,
        deepLink: METAMASK_DEEPLINK,
    },
    KAIKAS: {
        connector: kaikasConnector,
        name: ConnectorNames.Kaikas,
        icon: "/images/kaikas-connect.svg",
        description: "kaikas-connect",
        disableIcon: "/images/kaikas-connect-disabled.svg",
        href: null,
        mobile: true,
    },
    // KLIP: {
    //   connector: klipConnector,
    //   name: ConnectorNames.Klip,
    //   icon: "/images/klip-wallet.svg",
    //   description: "klip-wallet",
    //   disableIcon: "/images/klip-wallet-disabled.svg",
    //   href: null,
    //   mobile: true,
    // },
    // BSC_WALLET: {
    //     connector: bscConnector,
    //     name: ConnectorNames.BSC,
    //     icon: "/images/injected-binance.svg",
    //     description: "Connect to Trust Wallet, Rainbow Wallet and more...",
    //     disableIcon: "/images/injected-binance-disabled.svg",
    //     href: null,
    // },
    // FORTMATIC: {
    //   connector: fortmatic,
    //   name: ConnectorNames.Fortmatic,
    //   icon: '/images/fortmatic.svg',
    //   description: 'Login using Fortmatic hosted wallet',
    //   disableIcon: '/images/fortmatic-disabled.svg',
    //   href: null,
    //   mobile: true
    // },
};

export const SUPPORTED_WALLETS_BSC: { [key: string]: WalletInfo } = {
    METAMASK: SUPPORTED_WALLETS.METAMASK,
    BSC_WALLET: SUPPORTED_WALLETS.BSC_WALLET,
    WALLET_CONNECT: SUPPORTED_WALLETS.WALLET_CONNECT,
};

export const SUPPORTED_WALLETS_POLYGON: { [key: string]: WalletInfo } = {
    METAMASK: SUPPORTED_WALLETS.METAMASK,
    WALLET_CONNECT: {
        connector: walletConnectPolygon,
        name: ConnectorNames.WalletConnect,
        icon: "/images/trustwallet.svg",
        description: "Connect to Trust Wallet, Rainbow Wallet and more...",
        disableIcon: "/images/trustwallet-disabled.svg",
        href: null,
    },
    // BSC_WALLET: {
    //     connector: bscConnector,
    //     name: ConnectorNames.BSC,
    //     icon: "/images/injected-binance.svg",
    //     description: "Connect to Trust Wallet, Rainbow Wallet and more...",
    //     disableIcon: "/images/injected-binance-disabled.svg",
    //     href: null,
    // },
    WALLET_LINK: {
        connector: walletLinkConnect,
        name: ConnectorNames.WalletLinkConnect,
        icon: "/images/wallet-link/wallet-link.svg",
        description: "Connect to Coinbase Wallet and more...",
        disableIcon: "/images/wallet-link/wallet-link-disabled.svg",
        href: null,
        mobile: true,
    },
};

export const connectorsByName: { [key in ConnectorNames]: AbstractConnector } = {
    [ConnectorNames.MetaMask]: injected,
    [ConnectorNames.BSC]: bscConnector,
    [ConnectorNames.Fortmatic]: fortmatic,
    // [ConnectorNames.WalletConnect]: walletConnect,
    // [ConnectorNames.WalletConnectBsc]: walletConnectBsc,
    // [ConnectorNames.WalletConnectPolygon]: walletConnectPolygon,
    [ConnectorNames.WalletLinkConnect]: walletLinkConnect,
    [ConnectorNames.Kaikas]: kaikasConnector,
    [ConnectorNames.Klip]: kaikasConnector,
    [ConnectorNames.WalletConnectPolygon]: walletConnectPolygon
};

export const connectorsSupportByNetwork: {
    [key: string]: { [key: string]: WalletInfo };
} = {
    [APP_NETWORKS_NAME.POLYGON]: SUPPORTED_WALLETS_POLYGON,
    // [APP_NETWORKS_NAME.BSC]: SUPPORTED_WALLETS_BSC,
    [APP_NETWORKS_NAME.KLAYTN]: SUPPORTED_WALLETS,
};
