import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        wrapper: {
            marginTop: 20,
            backgroundColor: "#131530",
            width: "100%",
            borderRadius: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: 20,
            "&> div:first-child": {
                width: "100%",

                "&> p": {
                    borderBottom: "1px solid #2B2C45",
                    paddingBottom: 10,
                    display: "flex",
                    flexDirection: "row",
                    fontSize: 16,
                    fontWeight: 600,
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    color: "#fff",
                },
            },
            "&> div:last-child": {
                marginTop: 14,
                height: 360,
            },
        },
        container: {
            display: "flex",
            flexDirection: "row",
            height: "20%",
        },
        container5: {
            display: "flex",
            flexDirection: "row",
            marginTop: -2,
        },
        timeline: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            "&> div:first-child": {
                backgroundImage: "url(/images/home-page/imgTier.svg)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "bg-contain",
                backgroundPosition: "center",
                width: "47px",
                height: "47px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&> p": {
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                    color: "white",
                },
            },
            "& > div:last-child": {
                backgroundColor: "#2F3149",
                width: 4,
                height: 30,
            },
        },
        timeline5: {
            "&> div:first-child": {
                backgroundImage: "url(/images/home-page/imgTier.svg)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "bg-contain",
                backgroundPosition: "center",
                width: "47px",
                height: "47px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&> p": {
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                    color: "white",
                },
            },
        },
        desc: {
            color: "white",
            opacity: 0.7,
            fontWeight: 600,
            fontSize: 12,
            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
            marginTop: 13,
            marginLeft: 8,
        },
    };
});

export default useStyles;
