export const ListStatusIDO = [
    { value: "All", label: "All" },
    { value: "Upcoming", label: "Upcoming" },
    { value: "Calculation", label: "Calculation" },
    { value: "Buy Ticket", label: "Buy Ticket" },
    { value: "Distribution", label: "Distribution" },
    { value: "Ended", label: "Ended" },
];

export const splitStringMaxLength = (value: string, max: number) => {
    if (value && value.length > max) {
        return `${value.substring(0, max)}...`;
    }
    return value;
};
