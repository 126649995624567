import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useCommonStyle from "../../../styles/CommonStyle";
import useStyles from "./style";
import style from "./style.module.css";

const ModalLoading = (props: any) => {
    const styles = useStyles();
    const commonStyles = useCommonStyle();
    const { t } = useTranslation();
    const { open } = props;

    return (
        <>
            <Dialog
                open={open}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className={commonStyles.modal + " " + styles.modalLoading}
            >
                <div className="modal-content">
                    <DialogTitle
                        id="alert-dialog-slide-title"
                        className="modal-content__head"
                    ></DialogTitle>
                    <DialogContent className="modal-content__body">
                        <div className={style.loader}></div>
                        <p>{t("staking.modalLoading.content")}</p>
                        <span>{t("staking.modalLoading.text")}</span>
                    </DialogContent>
                </div>
            </Dialog>
        </>
    );
};

export default ModalLoading;
