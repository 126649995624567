import { Dialog, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import useStyles from "./style";

type Props = {
    onClose: () => void;
    open: boolean;
    img: string;
};
export default function ModalImgSNS({ open, onClose, img }: Props) {
    const styles = useStyles();

    return (
        <Dialog className={styles.wrapper} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle>
                <IconButton aria-label="close" className={styles.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <div className={styles.content}>
                    <img src={img} alt="" />
                </div>
            </DialogContent>
        </Dialog>
    );
}
