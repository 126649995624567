import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatPrecisionAmount } from "../../../../utils";
import { convertLang } from "../../../IDOHomePage/CardItem";
import useStyles from "./style";

export default function TableMobile(props: any) {
    const styles = useStyles();
    const { t } = useTranslation();
    const { competior_benmark } = useSelector((state: any) => state.idoDetail.data);

    return (
        <div>
            {competior_benmark?.map((item: any, index: number) => (
                <div key={index} className={styles.container}>
                    <div>
                        <div className={styles.content}>
                            <div>
                                <p>{t("ProjectDetails.Competitor.name")}</p>
                                <p>{convertLang(item?.name, i18n.language)}</p>
                            </div>
                            <div>
                                <p>{t("ProjectDetails.Competitor.ticker")}</p>
                                <p>{item?.ticker}</p>
                            </div>
                        </div>
                        <div className={styles.content}>
                            <div>
                                <p>{t("ProjectDetails.Competitor.marketCap")}</p>
                                <p>{formatPrecisionAmount(item?.market_cap)}</p>
                            </div>
                            <div>
                                <p>Max Supply</p>
                                <p>{formatPrecisionAmount(item?.FDV)}</p>
                            </div>
                        </div>
                        <div className={styles.content}>
                            <div>
                                <p>{t("ProjectDetails.Competitor.compareToCompetitor")}</p>
                                <p>
                                    {item?.market_cap_description
                                        ? item?.market_cap_description
                                        : "-"}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
