import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        wrapper: {
            width: 880,
            height: 760,
            margin: "auto",
            position: "relative",
            ["@media (max-width:1500px)"]: {
                height: 760,
            },
            [theme.breakpoints.down("sm")]: {
                width: "100%",
                height: 620,
            },
            [theme.breakpoints.down("xs")]: {
                width: "100%",
                height: 650,
            },
            "& .MuiPaper-root": {
                maxWidth: 880,
                width: 880,
                // height: 760,
                backgroundColor: "#242640",
                padding: "20px 30px",
                borderRadius: 15,
                ["@media (max-width:1500px)"]: {
                    // height: 760,
                },
                [theme.breakpoints.down("sm")]: {
                    maxWidth: "100%",
                    width: "100%",
                    // height: 620,
                    padding: "16px 10px",
                    margin: "0 30px",
                },
                [theme.breakpoints.down("xs")]: {
                    width: "100%",
                    // height: 650,
                    padding: "20px 10px 10px 0px",
                    marginLeft: 12,
                    marginRight: 12,
                },
            },
            "& .MuiDialog-paperScrollPaper": {
                backgroundColor: "#242640",
                color: "white",
            },
            "& .MuiTypography-h6": {
                fontSize: 32,
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                [theme.breakpoints.down("xs")]: {
                    marginTop: 20,
                    fontSize: 20,
                },
            },
            "& .MuiSnackbarContent-root": {
                background: "#E4C96A",
                minHeight: 104,
                width: 470,
                borderRadius: "10px 0px 0px 10px",
                // [theme.breakpoints.down("sm")]: {
                //     // height: 104,
                //     width: 470,
                // },
            },
            "& .MuiSnackbar-anchorOriginBottomCenter": {
                transform: "translateX(0)",
                bottom: 0,
                top: -600,
                right: 200,
                [theme.breakpoints.down("md")]: {
                    right: 0,
                },
                [theme.breakpoints.down("sm")]: {
                    left: 100,
                    width: 500,
                },
                [theme.breakpoints.down("xs")]: {
                    left: 0,
                    width: "100%",
                },
            },
            "& .MuiSnackbarContent-message": {
                [theme.breakpoints.down("xs")]: {
                    width: "100% ",
                },
            },
            "& .messageWaring": {
                display: "flex",
                alignItems: "flex-start",
                [theme.breakpoints.down("xs")]: {
                    width: "100%",
                    justifyContent: "center",
                    textAlign: "center",
                },
                "& .content": {
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    [theme.breakpoints.down("xs")]: {
                        width: "100%",
                    },
                    "& p": {
                        fontWeight: 600,
                        fontSize: 18,
                        fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    },
                    "& .goToMyProfile": {
                        fontWeight: 600,
                        fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                        textDecoration: "underline",
                        cursor: "pointer",
                    },
                },
                "& .iconWarning": {
                    width: 27,
                    height: 27,
                    marginRight: 16,
                    [theme.breakpoints.down("xs")]: {
                        marginLeft: 20,
                    },
                },
                "& .iconClose": {
                    marginLeft: 10,
                    marginTop: -10,
                    cursor: "pointer",
                },
            },
        },
        messageWaring: {
            display: "flex",
            alignItems: "flex-start",
            [theme.breakpoints.down("xs")]: {
                width: "100%",
                justifyContent: "center",
                textAlign: "center",
            },
            "& .content": {
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                [theme.breakpoints.down("xs")]: {
                    width: "100%",
                },
                "& p": {
                    fontWeight: 600,
                    fontSize: 18,
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                },
                "& .goToMyProfile": {
                    fontWeight: 600,
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    textDecoration: "underline",
                    cursor: "pointer",
                },
            },
            "& .iconWarning": {
                width: 27,
                height: 27,
                marginRight: 16,
                [theme.breakpoints.down("xs")]: {
                    marginLeft: 20,
                },
            },
            "& .iconClose": {
                marginLeft: 10,
                marginTop: -10,
                cursor: "pointer",
            },
        },
        // title: {
        //   fontSize:32,
        //   fontWeight: 700,
        // },
        textContent: {
            color: "white",
            opacity: 0.5,
            fontSize: 14,
            borderBottom: "1px solid #393B53",
            paddingBottom: 20,
            [theme.breakpoints.down("xs")]: {},
        },
        textTitle: {
            marginTop: 20,
            color: "white",
            fontWeight: 600,
            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
            fontSize: 16,
            [theme.breakpoints.down("xs")]: {
                fontSize: 14,
            },
        },
        accountUser: {
            marginTop: 22,
            borderBottom: "1px solid #393B53",
            paddingBottom: 26,
            "&> div:first-child": {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                [theme.breakpoints.down("sm")]: {
                    flexDirection: "column",
                    justifyContent: "center",
                },
            },
        },
        twitterAccount: {
            display: "flex",
            flexDirection: "column",
            "&> div:first-child": {
                display: "flex",
                flexDirection: "row",
                fontWeight: 400,
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                color: "white",
                marginBottom: 12,
                fontSize: 14,
                [theme.breakpoints.down("sm")]: {
                    marginTop: 20,
                },
                "&> p:first-child": {
                    opacity: 0.5,
                },
                // "&> p:last-child": {
                //     marginLeft: 6,
                //     // color: "red",
                // },
            },
            "&> div:last-child": {
                display: "flex",
                alignItems: "center",
                width: 280,
                height: 40,
                borderRadius: "12px",
                border: "2px solid rgba(255, 255, 255, 0.15)",
                // marginRight: 12,
                padding: 12,
                justifyContent: "space-between",
                [theme.breakpoints.down("sm")]: {
                    width: "100%",
                },
                "&>input": {
                    marginLeft: 20,
                    border: "none",
                    color: "white",
                    outline: "none",
                    borderTopRightRadius: "12px",
                    borderBottomRightRadius: "12px",
                    height: "40px",
                    background: "none",
                },
            },
        },
        discordAccount: {
            marginTop: 22,
            display: "flex",
            flexDirection: "column",
            "&> div:first-child": {
                display: "flex",
                flexDirection: "row",
                fontWeight: 400,
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                color: "white",
                marginBottom: 12,
                fontSize: 14,
                "&> p:first-child": {
                    opacity: 0.5,
                },
                // "&> p:last-child": {
                //     marginLeft: 6,
                //     color: "red",
                // },
            },
            "&> div:last-child": {
                display: "flex",
                alignItems: "center",
                width: 280,
                height: 40,
                borderRadius: "12px",
                border: "2px solid rgba(255, 255, 255, 0.15)",
                padding: 12,
                justifyContent: "space-between",
                [theme.breakpoints.down("sm")]: {
                    width: "100%",
                },
                "&>input": {
                    marginLeft: 20,
                    border: "none",
                    color: "white",
                    outline: "none",
                    borderTopRightRadius: "12px",
                    borderBottomRightRadius: "12px",
                    height: "40px",
                    background: "none",
                },
            },
        },
        btn: {
            marginTop: 30,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
            },
            "&> .btnStakeMore": {
                color: "white",
                fontSize: 16,
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                padding: "10px 104px",
                background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                borderRadius: 90,
                textTransform: "capitalize",
                whiteSpace: "pre",
                overflow: "hidden",
                textOverflow: "ellipsis",
                [theme.breakpoints.down("sm")]: {
                    marginBottom: 20,
                },
                [theme.breakpoints.down("xs")]: {
                    padding: "12px 50px",
                    fontSize: 12,
                },
            },
            "&> .btnStakeMoreDisabled": {
                color: "white",
                fontSize: 16,
                opacity: 0.7,
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                padding: "10px 104px",
                background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                borderRadius: 90,
                textTransform: "capitalize",
                whiteSpace: "pre",
                overflow: "hidden",
                textOverflow: "ellipsis",
                [theme.breakpoints.down("sm")]: {
                    marginBottom: 20,
                },
                [theme.breakpoints.down("xs")]: {
                    padding: "12px 50px",
                    fontSize: 12,
                },
            },
            "&> .btnCancel": {
                color: "#9747FF",
                fontSize: 16,
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                padding: "10px 138px",
                background: "none",
                border: "2px solid #9747FF",
                borderRadius: 90,
                textTransform: "capitalize",
                whiteSpace: "pre",
                overflow: "hidden",
                textOverflow: "ellipsis",
                [theme.breakpoints.down("xs")]: {
                    color: "#2DDBFF",
                    border: "2px solid #2DDBFF",
                    fontSize: 12,
                },
            },
        },
        closeButton: {
            position: "absolute",
            right: 25,
            top: 25,
            color: "white",
            opacity: 0.5,
            backgroundColor: "#262530",
            padding: 5,
            border: "2px solid rgba(255, 255, 255, 0.2)",
            [theme.breakpoints.down("xs")]: {
                padding: 3,
                top: 14,
                right: 14,
            },

            "&:hover": {
                backgroundColor: "#262530",
            },
        },
        svgIcon: {
            fontSize: 5,
        },
    };
});

export default useStyles;
