import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
    return {
        wrapper: {
            width: 860,
            height: 800,
            [theme.breakpoints.down("sm")]: {
                width: 620,
                height: 620,
            },
            [theme.breakpoints.down("xs")]: {
                width: 380,
                height: 380,
            },
            // backgroundColor: "#242640",
            margin: "auto",
            "& .MuiPaper-root": {
                maxWidth: 860,
                width: 860,
                height: 800,
                backgroundColor: "#242640",
                padding: "30px 30px",
                borderRadius: 15,
                [theme.breakpoints.down("sm")]: {
                    maxWidth: 620,
                    width: 620,
                    height: 620,
                },
                [theme.breakpoints.down("xs")]: {
                    maxWidth: 380,
                    width: 380,
                    height: 380,
                    padding: "20px 10px 10px 0px",
                },
            },
            "& .MuiDialog-paperScrollPaper": {
                backgroundColor: "#242640",
                color: "white",
            },
        },
        content: {
            backgroundColor: "#383A54",
            width: "100%",
            height: "100%",
            borderRadius: 20,
            " & img": {
                width: "100%",
                height: "100%",
                objectFit: "cover",
            },
        },
        closeButton: {
            position: "absolute",
            right: 14,
            top: 14,
            color: "white",
            opacity: 0.5,
            backgroundColor: "#262530",
            padding: 4,
            border: "2px solid rgba(255, 255, 255, 0.2)",

            "&:hover": {
                backgroundColor: "#262530",
            },
        },
        svgIcon: {
            fontSize: 5,
        },
    };
});

export default useStyles;
