import { makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

interface Props {
    spacing: number;
}

const useStyles = makeStyles<Theme, Props>((theme: any) => ({
    button: {
        backgroundColor: "#FFCC00",
        border: "none",
        borderRadius: 10,
        display: "inline-block",
        fontFamily: "Roboto-Medium",
        cursor: "pointer",
        transition: ".2s all ease-in",
        "&:focus": {
            outline: "none",
        },
        "&:hover": {
            boxShadow: "0px 15px 20px rgba(0, 0, 0, .1)",
            transform: "translateY(-7px)",
        },
    },
    buttonContent: {
        display: "flex",
        padding: "12px 15px",
        alignItems: "center",
        fontSize: 14,
        color: "white",
        paddingLeft: 0,
        fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
    },
    buttonText: {
        marginLeft: (props) => props.spacing,
        fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
        fontSize: 16,
    },
}));

export default useStyles;
