import { Button } from "@material-ui/core";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import BUY_TICKET_ABI from "../../../../abi/BuyTicket.json";
import erc20ABI from "../../../../abi/Erc20.json";
import VESTING_ABI from "../../../../abi/Vesting.json";
import { AppContext } from "../../../../AppContext";
import { NETWORK } from "../../../../constants";
import { ConnectorNames } from "../../../../constants/connectors";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { getContractConnect, getContractInstanceWeb3, getWeb3Instance } from "../../../../services/web3";
import { toastMessage } from "../../../../store/actions/toast-message-buyticket";
import { findNetworkFromChainID } from "../../../../utils/networkFromChainId";
import ModalLoading from "../../../StakingPools/ModalLoading";
import { claimProof } from "../../Api";
import { TRANSACTION_TIMEOUT } from "../../BuyTicket/TierAccountBuyTicket/TierStarterAndRookie";
import ModalYourTicket from "../../Modal/ModalYourTickets";
import useStyles from "./style";
import { POLYGON_CHAIN_ID } from "../../../../constants/network";
import Web3 from "web3";

export default function DistributionTicketCalculatingComplete(props: any) {
    const dataYourTicket = props.dataYourTicket;
    const styles = useStyles();
    const { t } = useTranslation();

    const idoDetail = useSelector((state: any) => state.idoDetail?.data);
    const { appChainID } = useTypedSelector((state: any) => state.appNetwork).data;

    const dispatch = useDispatch();
    const account = localStorage.getItem("user");
    const [claimRefund, setClaimRefund] = useState<boolean>(false);

    // const [dataYourTicket, setDataYourTicket] = useState<any>();
    const [dataClaimProof, setDataClaimProof] = useState<any>();
    const [loadingTransaction, setLoadingTransaction] = useState<boolean>(false);
    const [amountTokenClaim, setAmountTokenClaim] = useState<number>();
    const [amountUSDC, setAmountUSDC] = useState<any>();
    const [amountTokenClaimed, setAmountTokenClaimed] = useState<any>();
    const [checkIsVesting, setCheckIsVesting] = useState<any>();

    const [open, setOpen] = useState<boolean>(false);
    const [swap, setSwap] = useState<boolean>(false);

    const { currentConnectedWallet } = useContext(AppContext);
    const currentAccount = currentConnectedWallet && currentConnectedWallet.addresses[0];

    const timeDayPoolEnd = moment(idoDetail?.start_pool_end).format("MM/DD/YYYY");
    const timeHoursPoolEnd = moment(idoDetail?.start_pool_end).format("HH:mm:ss");

    const getContractVesting = useCallback((): string => {
        if (idoDetail?.network === "bsc") {
            const contractVesting = process.env.REACT_APP_VESTING_CONTRACT_BSC || "";
            return contractVesting;
        } else if (idoDetail?.network === "klaytn") {
            const contractVesting = process.env.REACT_APP_VESTING_CONTRACT_KLAYTN || "";
            return contractVesting;
        } else if (idoDetail?.network === "polygon") {
            const contractVesting = process.env.REACT_APP_VESTING_CONTRACT_POLYGON || "";
            return contractVesting;
        }
        return process.env.REACT_APP_BALANCE_USDC_BSC || "";
    }, [idoDetail?.network]);

    useEffect(() => {
        if (!account) return;
        if (!dataYourTicket?.winningTicket?.length || dataYourTicket?.winningTicket?.length <= 0)
            return;
        claimProof(idoDetail?.id).then((res) => {
            setDataClaimProof(res?.data);
        });
    }, [account, dataYourTicket?.winningTicket?.length, idoDetail?.id]);

    useEffect(() => {
        if (dataYourTicket?.loseTicket?.length <= 0) return;
        (async () => {
            await getAmountUsdc();
        })();
    }, []);

    useEffect(() => {
        if (checkIsVesting === true) {
            (async () => {
                await getAmountToken();
            })();
        }
    }, [checkIsVesting, idoDetail, currentAccount]);

    const getTokenClaimed = async () => {
        let wallet = localStorage.getItem("walletconnect-name");
        if (!wallet) {
            wallet = "";
        }
        const contract = await getContractConnect(
            VESTING_ABI,
            getContractVesting(),
            appChainID,
            wallet
        );
        try {
            const amountTokenClaimed = await contract?.methods
                .userVestingSchedule(account, idoDetail?.contract_address)
                .call();
            setAmountTokenClaimed(amountTokenClaimed);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (!idoDetail?.contract_address) return;
        if (checkIsVesting === true) {
            (async () => {
                await getTokenClaimed();
            })();
        }
    }, [checkIsVesting, account, idoDetail, amountTokenClaim]);

    const handleClose = () => {
        setOpen(false);
    };

    const renderOpenModalYourTicket = () => (
        <ModalYourTicket open={open} onClose={handleClose} dataYourTicket={dataYourTicket} />
    );

    const handleClick = () => {
        setOpen(true);
    };

    const getAmountUsdc = async () => {
        if (!idoDetail || !currentAccount) return;
        let wallet = localStorage.getItem("walletconnect-name");
        if (!wallet) {
            wallet = "";
        }
        setLoadingTransaction(true);
        try {
            const contract = await getContractConnect(
                BUY_TICKET_ABI,
                idoDetail?.contract_address,
                appChainID,
                wallet
            );
            const amountClaimUsdc = await contract?.methods.userUSDC(currentAccount).call();
            setAmountUSDC(amountClaimUsdc);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingTransaction(false);
        }
    };

    const isUserVesting = useCallback(
        async (
            abi: any = erc20ABI,
            walletAddress: string,
            contractAddress: string,
            idoAddress: string
        ) => {
            let wallet = localStorage.getItem("walletconnect-name");
            if (!wallet) {
                wallet = "";
            }

            setLoadingTransaction(true);
            try {
                const contract = await getContractConnect(abi, contractAddress, appChainID, wallet);
                return await contract?.methods.userVesting(walletAddress, idoAddress).call();
            } catch (error) {
                console.log(error);
            } finally {
                setLoadingTransaction(false);
            }
        },
        [appChainID]
    );

    const handleSwapToken = async () => {
        if (findNetworkFromChainID(appChainID) !== idoDetail?.network) {
            dispatch(
                toastMessage({
                    type: "wrongNetwork",
                    title: "Wrong network",
                    message: t("WarningMessage.pleaseSwitchNetworkClaim").replace(
                        "NETWORK",
                        (idoDetail?.network).toUpperCase()
                    ),
                })
            );
            return;
        }
        setSwap(true);
        if (checkIsVesting === false) {
            // try {
            setLoadingTransaction(false);
            const { time_out } = await createVestingSchedule();
            if (!time_out) {
                setLoadingTransaction(false);
                await getAmountToken();
            } else {
                setSwap(true);
                setLoadingTransaction(false);
                dispatch(
                    toastMessage({
                        type: "transactionPending",
                        title: "Transaction Pending",
                        message: "Please wait for transaction success and reload page",
                    })
                );
            }
        }
    };

    const checkIsUsetVesting = useCallback(async () => {
        if (
            !idoDetail?.contract_address ||
            !currentAccount ||
            dataYourTicket?.winningTicket?.length <= 0
        )
            return;

        const checkVesting = await isUserVesting(
            VESTING_ABI,
            currentAccount,
            getContractVesting(),
            idoDetail?.contract_address
        );
        setCheckIsVesting(checkVesting);
    }, [
        currentAccount,
        dataYourTicket?.winningTicket?.length,
        getContractVesting,
        idoDetail?.contract_address,
        isUserVesting,
    ]);

    useEffect(() => {
        checkIsUsetVesting();
    }, [checkIsUsetVesting]);

    const createVestingSchedule = async (): Promise<{ time_out: boolean }> => {
        return new Promise(async (resolve, reject) => {
            if (!dataClaimProof) return;
            let timeOut;
            let obj = {};
            let wallet = localStorage.getItem("walletconnect-name");
            if (!wallet) {
                wallet = "";
            }
            try {
                setLoadingTransaction(true);
                if (idoDetail?.network === NETWORK.KLAYTN && wallet === ConnectorNames.MetaMask) {
                    const web3 = getContractInstanceWeb3(NETWORK.KLAYTN);
                    if (web3) {
                        const gas = await web3.eth.getGasPrice();
                        obj = {
                            maxFeePerGas: gas,
                            maxPriorityFeePerGas: gas,
                        };
                    }
                }
                const contract = await getContractConnect(
                    BUY_TICKET_ABI,
                    idoDetail?.contract_address,
                    appChainID,
                    wallet,
                    "write"
                );
                if (wallet === ConnectorNames.Kaikas) {
                    try {
                        await contract?.methods
                            .createVestingSchedule(
                                dataClaimProof?.amountIDOtoken,
                                dataClaimProof?.amountUSDC,
                                dataClaimProof?.sc_tiket_id,
                                dataClaimProof?.total_ticket_buy,
                                dataClaimProof?.proof
                            )
                            .send({
                                from: dataClaimProof?.wallet_address,
                                gas: 8000000,
                            });
                        checkIsUsetVesting();
                    } catch (e) {
                        setLoadingTransaction(false);
                        setSwap(false);
                        toast.error("Swap token Failed");
                        reject(e);
                    }
                } else {
                    let payload;
                    if (appChainID === POLYGON_CHAIN_ID) {
                        const web3Instance = await getWeb3Instance();
                        const gasPrice = await web3Instance?.eth.getGasPrice();
                        const gas_multiplier = process.env.GAS_MULTIPLIER ? parseFloat(process.env.GAS_MULTIPLIER) : 1.1;
                        if (gasPrice) {
                            payload = {
                                gasPrice: Web3.utils.toHex(Math.floor(gas_multiplier * Number(gasPrice))),
                                from: dataClaimProof?.wallet_address,
                            }
                        } else {
                            payload = {
                                from: dataClaimProof?.wallet_address,
                                gas: 8000000,
                            }
                        }
                        
                    } else {
                        payload = {
                            from: dataClaimProof?.wallet_address,
                            gas: 8000000,
                        }
                    }
                    await contract?.methods
                        .createVestingSchedule(
                            dataClaimProof?.amountIDOtoken,
                            dataClaimProof?.amountUSDC,
                            dataClaimProof?.sc_tiket_id,
                            dataClaimProof?.total_ticket_buy,
                            dataClaimProof?.proof
                        )
                        .send({
                            ...obj,
                            ...payload
                        })
                        .on("transactionHash", () => {
                            timeOut = setTimeout(() => {
                                resolve({
                                    time_out: true,
                                });
                            }, TRANSACTION_TIMEOUT);
                        });
                    checkIsUsetVesting();
                }
            } catch (e) {
                setLoadingTransaction(false);
                setSwap(false);
                toast.error("Swap token Failed");
                reject(e);
            }
            clearTimeout(timeOut);
            resolve({
                time_out: false,
            });
        });
    };

    const getAmountToken = async () => {
        if (!idoDetail || !currentAccount) return;
        let wallet = localStorage.getItem("walletconnect-name");
        if (!wallet) {
            wallet = "";
        }
        try {
            const contract = await getContractConnect(
                VESTING_ABI,
                getContractVesting(),
                appChainID,
                wallet
            );
            const amountClaimToken = await contract?.methods
                .claimableToken(currentAccount, idoDetail?.contract_address)
                .call();
            // console.log("amountClaimToken", amountClaimToken);
            setAmountTokenClaim(amountClaimToken);
            return amountClaimToken;
        } catch (error) {
            console.error(error);
        }
    };

    const claimToken = useCallback(async (): Promise<{ time_out: boolean }> => {
        return new Promise(async (resolve, reject) => {
            if (!idoDetail || !dataClaimProof) return;
            let timeOut;
            let obj = {};
            let wallet = localStorage.getItem("walletconnect-name");
            if (!wallet) {
                wallet = "";
            }

            if (idoDetail?.network === NETWORK.KLAYTN && wallet === ConnectorNames.MetaMask) {
                const web3 = getContractInstanceWeb3(NETWORK.KLAYTN);
                if (web3) {
                    const gas = await web3.eth.getGasPrice();
                    obj = {
                        maxFeePerGas: gas,
                        maxPriorityFeePerGas: gas,
                    };
                }
            }
            try {
                const contract = await getContractConnect(
                    VESTING_ABI,
                    getContractVesting(),
                    appChainID,
                    wallet,
                    "write"
                );

                if (wallet === ConnectorNames.Kaikas) {
                    try {
                        await contract?.methods.claimToken(idoDetail?.contract_address).send({
                            from: dataClaimProof?.wallet_address,
                            gas: 8000000,
                        });
                    } catch (e) {
                        reject(e);
                    }
                } else {
                    let payload;
                    if (appChainID === POLYGON_CHAIN_ID) {
                        const web3Instance = await getWeb3Instance();
                        const gasPrice = await web3Instance?.eth.getGasPrice();
                        const gas_multiplier = process.env.GAS_MULTIPLIER ? parseFloat(process.env.GAS_MULTIPLIER) : 1.1;
                        if (gasPrice) {
                            payload = {
                                gasPrice: Web3.utils.toHex(Math.floor(gas_multiplier * Number(gasPrice))),
                                from: dataClaimProof?.wallet_address,
                            }
                        } else {
                            payload = {
                                from: dataClaimProof?.wallet_address,
                                gas: 8000000,
                            }
                        }
                        
                    } else {
                        payload = {
                            from: dataClaimProof?.wallet_address,
                            gas: 8000000,
                        }
                    }
                    await contract?.methods
                        .claimToken(idoDetail?.contract_address)
                        .send({
                            ...obj,
                            ...payload
                        })
                        .on("transactionHash", () => {
                            timeOut = setTimeout(() => {
                                resolve({
                                    time_out: true,
                                });
                            }, TRANSACTION_TIMEOUT);
                        });
                }
            } catch (e) {
                reject(e);
            }
            clearTimeout(timeOut);
            resolve({
                time_out: false,
            });
        });
    }, [appChainID, dataClaimProof, getContractVesting, idoDetail]);

    const claimUsdc = useCallback(async (): Promise<{ time_out: boolean }> => {
        return new Promise(async (resolve, reject) => {
            if (!dataClaimProof) return;
            let timeOut;
            let obj = {};
            let wallet = localStorage.getItem("walletconnect-name");
            if (!wallet) {
                wallet = "";
            }

            if (idoDetail?.network === NETWORK.KLAYTN && wallet === ConnectorNames.MetaMask) {
                const web3 = getContractInstanceWeb3(NETWORK.KLAYTN);
                if (web3) {
                    const gas = await web3.eth.getGasPrice();
                    obj = {
                        maxFeePerGas: gas,
                        maxPriorityFeePerGas: gas,
                    };
                }
            }
            try {
                const contract = await getContractConnect(
                    BUY_TICKET_ABI,
                    idoDetail?.contract_address,
                    appChainID,
                    wallet,
                    "write"
                );
                if (wallet === ConnectorNames.Kaikas) {
                    try {
                        await contract?.methods
                            .swapToUSDCToken(
                                dataClaimProof?.amountIDOtoken,
                                dataClaimProof?.amountUSDC,
                                dataClaimProof?.sc_tiket_id,
                                dataClaimProof?.total_ticket_buy,
                                dataClaimProof?.proof
                            )
                            .send({
                                from: dataClaimProof?.wallet_address,
                                gas: 8000000,
                            });
                    } catch (e) {
                        reject(e);
                    }
                } else {
                    let payload;
                    if (appChainID === POLYGON_CHAIN_ID) {
                        const web3Instance = await getWeb3Instance();
                        const gasPrice = await web3Instance?.eth.getGasPrice();
                        const gas_multiplier = process.env.GAS_MULTIPLIER ? parseFloat(process.env.GAS_MULTIPLIER) : 1.1;
                        if (gasPrice) {
                            payload = {
                                gasPrice: Web3.utils.toHex(Math.floor(gas_multiplier * Number(gasPrice))),
                                from: dataClaimProof?.wallet_address,
                            }
                        } else {
                            payload = {
                                from: dataClaimProof?.wallet_address,
                                gas: 8000000,
                            }
                        }
                        
                    } else {
                        payload = {
                            from: dataClaimProof?.wallet_address,
                            gas: 8000000,
                        }
                    }
                    await contract?.methods
                        .swapToUSDCToken(
                            dataClaimProof?.amountIDOtoken,
                            dataClaimProof?.amountUSDC,
                            dataClaimProof?.sc_tiket_id,
                            dataClaimProof?.total_ticket_buy,
                            dataClaimProof?.proof
                        )
                        .send({
                            ...obj,
                            ...payload
                        })
                        .on("transactionHash", () => {
                            timeOut = setTimeout(() => {
                                resolve({
                                    time_out: true,
                                });
                            }, TRANSACTION_TIMEOUT);
                        });
                }
            } catch (e) {
                reject(e);
            }
            clearTimeout(timeOut);
            resolve({
                time_out: false,
            });
        });
    }, [appChainID, dataClaimProof, idoDetail?.contract_address, idoDetail?.network]);

    const handleClaimToken = useCallback(async () => {
        try {
            setLoadingTransaction(true);
            const { time_out } = await claimToken();
            if (!time_out) {
                setLoadingTransaction(false);
                await getAmountToken();
                await getTokenClaimed();
                toast.success("Claim token successfully");
            } else {
                setLoadingTransaction(false);
                dispatch(
                    toastMessage({
                        type: "transactionPending",
                        title: "Transaction Pending",
                        message: "Please wait for transaction success and reload page",
                    })
                );
            }
        } catch (error) {
            setLoadingTransaction(false);
            toast.error("Claim token failed");
            console.error(error);
        }
    }, [claimToken, dispatch, getAmountToken(), getTokenClaimed]);

    const handleClaimRefundUsdc = useCallback(async () => {
        try {
            setLoadingTransaction(true);
            const { time_out } = await claimUsdc();
            if (!time_out) {
                setLoadingTransaction(false);
                setClaimRefund(true);
                toast.success("Claim USDC successfully");
            } else {
                setLoadingTransaction(false);
                dispatch(
                    toastMessage({
                        type: "transactionPending",
                        title: "Transaction Pending",
                        message: "Please wait for transaction success and reload page",
                    })
                );
            }
        } catch (error) {
            setLoadingTransaction(false);
            toast.error("Claim USDC Failed");
            console.error(error);
        }
    }, [claimUsdc, dispatch]);

    const amountUsdcClaim = amountUSDC ? amountUSDC / 10 ** dataClaimProof?.idoTokenDecimal : 0;

    const renderClaimUsdc = useCallback(() => {
        if (!amountUSDC || dataYourTicket?.loseTicket?.length <= 0) return;
        return (
            <>
                <div>
                    <div className={styles.claimUsdc}>
                        <p>{t("ProjectDetails.claimableUsdc")}</p>
                    </div>
                    {claimRefund === true || amountUsdcClaim === 0 ? (
                        <div className="claimed">
                            <img src="/images/home-page/iconClaimed.svg" alt="" />
                            <div>{t("ProjectDetails.claimed")}</div>
                        </div>
                    ) : (
                        <>
                            <p className="amountClaim">
                                {/* {(totalAmount - ticketsWin) * 10} USDC */}
                                {Number(amountUsdcClaim) === 0
                                    ? amountUSDC
                                    : dataYourTicket?.loseTicket?.length * idoDetail?.price}{" "}
                                USDC
                            </p>
                            {amountUSDC > 0 ? (
                                <Button onClick={handleClaimRefundUsdc} className="btnClaim">
                                    {t("ProjectDetails.btnClaim")}
                                </Button>
                            ) : (
                                <Button
                                    onClick={handleClaimRefundUsdc}
                                    disabled
                                    className="btnClaim"
                                    style={{ opacity: 0.7 }}
                                >
                                    {t("ProjectDetails.btnClaim")}
                                </Button>
                            )}
                        </>
                    )}
                </div>
            </>
        );
    }, [
        amountUSDC,
        amountUsdcClaim,
        claimRefund,
        dataYourTicket?.loseTicket?.length,
        handleClaimRefundUsdc,
        idoDetail?.price,
        styles.claimUsdc,
        t,
    ]);

    const renderSwapToken = useCallback(() => {
        return (
            <>
                {swap === false && (
                    <div className={styles.swapToken}>
                        <p>Swap winning tickets to get project token</p>
                        <Button onClick={handleSwapToken} className="btnClaim">
                            Swap
                        </Button>
                    </div>
                )}
                {swap === true && (
                    <div className={styles.swapToken}>
                        <p>Swap winning tickets to get project token</p>
                        <Button disabled style={{ opacity: 0.7 }} className="btnClaim">
                            Processing...
                        </Button>
                    </div>
                )}
            </>
        );
    }, [handleSwapToken, styles.swapToken, swap, checkIsVesting]);

    const renderAmountTokenClaim = useCallback(() => {
        if (Number(amountTokenClaimed?.TGE) <= 0 || Number(amountTokenClaimed?.TGEAmount > 0)) {
            return (
                <p className="amountClaim">
                    {`${
                        amountTokenClaim
                            ? (amountTokenClaim / 10 ** dataClaimProof?.idoTokenDecimal).toFixed(1)
                            : 0
                    } ${idoDetail?.token_symbol}`}
                </p>
            );
        } else if (
            Number(amountTokenClaimed?.TGE) > 0 &&
            Number(amountTokenClaimed?.TGEAmount <= 0)
        ) {
            return (
                <p className="amountClaim">
                    {(
                        ((amountTokenClaimed?.TGE / 10000) * amountTokenClaimed?.amountTotal) /
                        10 ** dataClaimProof?.idoTokenDecimal
                    ).toFixed(1)}{" "}
                    {idoDetail ? idoDetail?.token_symbol : ""}
                </p>
            );
        }
    }, [
        amountTokenClaim,
        amountTokenClaimed?.TGE,
        amountTokenClaimed?.TGEAmount,
        amountTokenClaimed?.amountTotal,
        dataClaimProof?.idoTokenDecimal,
        idoDetail,
    ]);
    const renderClaimToken = useCallback(() => {
        if (dataYourTicket?.winningTicket?.length <= 0) return;
        return (
            <>
                {checkIsVesting === true
                    ? amountTokenClaim && (
                          <div>
                              <div>
                                  <p>{t("ProjectDetails.claimableProjectToken")}</p>
                                  <div className="iconI">
                                      <img src="/images/home-page/iconI.svg" alt="" />
                                      <div className="onHoverI">
                                          <p>{t("ProjectDetails.contentHover")}</p>
                                      </div>
                                  </div>
                              </div>
                              {Number(amountTokenClaim) === 0 ? (
                                  <div className="claimed">
                                      <img src="/images/home-page/iconClaimed.svg" alt="" />
                                      <div>{t("ProjectDetails.claimed")}</div>
                                  </div>
                              ) : (
                                  <>
                                      {renderAmountTokenClaim()}
                                      <Button onClick={handleClaimToken} className="btnClaim">
                                          {t("ProjectDetails.btnClaim")}
                                      </Button>
                                  </>
                              )}
                          </div>
                      )
                    : renderSwapToken()}
            </>
        );
    }, [
        amountTokenClaim,
        checkIsVesting,
        dataYourTicket?.winningTicket?.length,
        handleClaimToken,
        renderAmountTokenClaim,
        renderSwapToken,
        t,
    ]);

    return (
        <div className={styles.wrapper}>
            <div>
                <div className="amountTicket">
                    <p>{t("ProjectDetails.yourJoinedTickets")}</p>
                    <div>
                        <div>{dataYourTicket ? dataYourTicket?.ticketBought : 0}</div>
                        <div onClick={handleClick}>
                            {t("ProjectDetails.viewTicket")}
                            <img src="/images/home-page/iconWebsite.svg" alt="" />
                        </div>
                    </div>
                    {renderOpenModalYourTicket()}
                </div>
                <div className="amountTicket">
                    <p>{t("ProjectDetails.yourWinningTickets")}</p>
                    <p>
                        {dataYourTicket?.winningTicket ? dataYourTicket?.winningTicket?.length : 0}
                    </p>
                </div>
            </div>
            <div>
                <div className={styles.claimRefundToken}>
                    {renderClaimUsdc()}
                    {renderClaimToken()}
                </div>
            </div>
            <div>
                <div>
                    <p>{t("ProjectDetails.vestingEnd")}</p>
                    <p>
                        {timeDayPoolEnd} {timeHoursPoolEnd}
                    </p>
                </div>
                <div>
                    <p>{t("ProjectDetails.totalTokenWon")}</p>
                    <p>
                        {dataClaimProof?.amountIDOtoken
                            ? (
                                  dataClaimProof?.amountIDOtoken /
                                  10 ** dataClaimProof?.idoTokenDecimal
                              ).toFixed(1)
                            : 0}{" "}
                        {idoDetail ? idoDetail?.token_symbol : ""}
                    </p>
                </div>
                <div>
                    <p>{t("ProjectDetails.claimedToken")}</p>
                    <p>
                        {amountTokenClaimed?.TGEAmount
                            ? (
                                  (Number(amountTokenClaimed?.TGEAmount) +
                                      Number(amountTokenClaimed?.claimed)) /
                                  10 ** dataClaimProof?.idoTokenDecimal
                              ).toFixed(2)
                            : 0}{" "}
                        {idoDetail ? idoDetail?.token_symbol : ""} (
                        {!amountTokenClaimed?.claimed
                            ? 0
                            : (
                                  ((Number(amountTokenClaimed?.TGEAmount) +
                                      Number(amountTokenClaimed?.claimed)) *
                                      100) /
                                  Number(amountTokenClaimed?.amountTotal)
                              ).toFixed(1)}
                        %)
                    </p>
                </div>
            </div>
            <ModalLoading
                open={loadingTransaction}
                onClose={() => {
                    setLoadingTransaction(false);
                }}
            />
        </div>
    );
}
