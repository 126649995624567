import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        dialog: {
            "& .MuiPaper-root": {
                background: "#242640",
                padding: "40px 70px",
                maxWidth: 900,
                borderRadius: 20,
            },
        },
        privacyPolicy: {
            color: "white",
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            fontSize: 14,
            lineHeight: "22px",
            opacity: 0.5,
        },
        policyDesc: {
            // marginTop: 50,
            "&> .header": {
                "&>div .title": {
                    fontSize: 20,
                    marginBottom: 8,
                    fontWeight: 700,
                    fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                },
                "&>div> p": {
                    fontSize: 14,
                    fontWeight: 400,
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                },
                "&>div> ul": {
                    listStyleType: "disc",
                    marginLeft: 40,
                    padding: "10px 0",
                    "&> li": {
                        "&> p": {
                            display: "flex",
                            flexDirection: "row",
                        },
                    },
                },
            },
        },
        policyExplain: {
            "& > div": {
                marginTop: 30,
            },
            "& p": {
                marginTop: 30,
            },
            "& ul": {
                marginTop: 20,
                paddingLeft: 30,
            },
            "& li": {
                marginTop: 30,
                listStyleType: "disc",
            },
            "& span": {
                textDecoration: "underline",
            },
        },
        header: {
            fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "32px",
            lineHeight: "42px",
            color: "white",
        },
        subHeader: {
            textAlign: "center",
            marginTop: 25,
        },
        scrollBar: {
            "&::-webkit-scrollbar": {
                width: "10px",
            },
            "&::-webkit-scrollbar-track": {
                background: "rgba(255, 255, 255, 0.1)",
                borderRadius: "999px",
            },

            "&::-webkit-scrollbar-thumb": {
                background: "rgba(255, 255, 255, 0.5)",
                "border-radius": "999px",
                width: "10px",
                height: "60px",
                borderRadius: "999px",
            },
        },
        closeBtn: {
            background: "#9747FF",
            width: "fit-content",
            display: "block",
            marginLeft: "auto",
            padding: "12px 36px",
            marginTop: "28px",
            marginRight: "40px",
            fontSize: 12,
            lineHeight: "16px",
            borderRadius: "9999px",
            fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
            textTransform: "inherit",
            "&:hover": {
                background: "#9753ef",
            },
        },
    };
});

export default useStyles;
