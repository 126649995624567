import { float } from "hardhat/internal/core/params/argumentTypes";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        wrapper: {
            display: "flex",
            flexDirection: "column",
            marginTop: 28,
            "&> p": {
                marginTop: 6,
                color: "#FF3333",
                fontWeight: 400,
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                fontSize: 12,
            },
            "&> .btnBuyTicket": {
                marginTop: 25,
                background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                borderRadius: 90,
                color: "white",
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                fontSize: 16,
                textTransform: "capitalize",
                padding: "15px 0",
            },
            "&> .btnBuyTicketDisable": {
                marginTop: 25,
                background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                borderRadius: 90,
                opacity: 0.7,
                color: "white",
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                fontSize: 16,
                textTransform: "capitalize",
                padding: "15px 0",
            },
        },
        amountMinMax: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: 14,
            fontWeight: 400,
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            color: "white",
            [theme.breakpoints.down("xs")]: {
                flexDirection: "column",
                "&>div": {
                    width: "100%",
                },
            },
            "&> div:first-child": {
                "&> p": {
                    opacity: 0.5,
                    [theme.breakpoints.down("xs")]: {
                        marginBottom: 10,
                    },
                },
            },
            "&> div:last-child": {
                display: "flex",
                flexDirection: "row",

                "&> .amountMin": {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginRight: 10,
                    "&> p:first-child": {
                        opacity: 0.5,
                    },
                    "&> p:nth-child(2)": {
                        margin: "0 6px",
                    },
                },
                "&> div:last-child": {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    "&> p:first-child": {
                        opacity: 0.5,
                    },
                    "&> p:nth-child(2)": {
                        margin: "0 6px",
                    },
                    "&> img": {
                        marginBottom: 4,
                    },
                },
            },
        },
        inputAmount: {
            marginTop: 8,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "6px 20px",
            background: "none",
            border: "2px solid rgba(255, 255, 255, 0.2)",
            borderRadius: 12,
            [theme.breakpoints.down("xs")]: {
                padding: "6px 12px",
            },
            "&>div >input": {
                border: "none",
                color: "white",
                outline: "none",
                background: "none",
                minWidth: 120,
                // [theme.breakpoints.down("md")]: {
                //     width: 600,
                // },
                // [theme.breakpoints.down("sm")]: {
                //     minWidth: 100,
                // },
                // [theme.breakpoints.down("xs")]: {
                //     minWidth: 50,
                // },
            },
            "&>div:last-child": {
                [theme.breakpoints.down("xs")]: {
                    display: "flex",
                    flexDirection: "column",
                },
            },
            "&>div .btnMin": {
                color: "white",
                background: "#0F69E1",
                borderRadius: 33,
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                fontSize: 12,
            },
            "&>div .btnMax": {
                marginLeft: 10,
                color: "white",
                background: "#9747FF",
                borderRadius: 33,
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                fontSize: 12,
                [theme.breakpoints.down("xs")]: {
                    marginLeft: 0,
                    marginTop: 4,
                },
            },
        },
        titleBuyTicket: {
            fontWeight: 600,
            fontSize: 14,
            color: "#fff",
            opacity: 0.8,
        },
        iconI: {
            position: "relative",
            cursor: "pointer",
            "&> img": {
                marginTop: 5,
                width: 15,
                height: 15,
            },
            "&> .onHoverI": {
                position: "absolute",
                visibility: "hidden",
                color: "white",
                opacity: 0,
                fontWeight: 400,
                fontSize: 11,
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                width: 185,
                backgroundColor: "#3E405B",
                borderRadius: 5,
                padding: 12,
                top: "calc(100% + 6px)",
                left: -150,
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                transition: "all 200ms",
                zIndex: 3,
                [theme.breakpoints.down("sm")]: {
                    left: -90,
                },
                [theme.breakpoints.down("xs")]: {
                    left: -150,
                },
                "&> p": {
                    opacity: 0.7,
                },
                " &::after": {
                    content: '""',
                    position: "absolute",
                    display: "block",
                    backgroundColor: "#3E405B",
                    left: 160,
                    top: -9,
                    width: 10,
                    height: 10,
                    clipPath: "polygon(50% 20%, 0% 100%, 100% 100%)",
                    [theme.breakpoints.down("sm")]: {
                        left: 100,
                    },
                    [theme.breakpoints.down("xs")]: {
                        left: 160,
                    },
                },
            },
            "&:hover > .onHoverI": {
                visibility: "visible",
                opacity: 1,
            },
        },
    };
});

export default useStyles;
