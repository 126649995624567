import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        tabPointHistory: {
            "& .MuiTableContainer-root": {
                margin: "0px auto",
                borderRadius: "10px",
                width: "100%",
                overflow: "auto",
                [theme.breakpoints.up("sm")]: {},
                [theme.breakpoints.up("md")]: {
                    margin: "0px auto",
                },
                [theme.breakpoints.up("lg")]: {},
            },
            "& .tableTransition": {
                backgroundColor: "transparent",
                borderCollapse: "collapse",
                borderSpacing: 0,
                width: "100%",
                minWidth: "900px",
                "& .MuiTableHead-root": {
                    height: 38,
                },
                "& .MuiTableRow-head": {
                    background: "rgba(42, 44, 69, 1)",
                    height: 38,
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    "& .MuiTableCell-head": {
                        fontSize: 14,
                        padding: 0,
                        border: "none",
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        [theme.breakpoints.up("sm")]: {},
                        [theme.breakpoints.up("md")]: {
                            fontSize: 14,
                        },
                        [theme.breakpoints.up("lg")]: {},
                    },
                    "& .MuiTableCell-head:not(:first-child)": {
                        paddingLeft: 20,
                    },
                },
                "& .MuiTableBody-root": {
                    background: "#1F213B",
                },

                "& .MuiTableCell-body": {
                    fontSize: 14,
                    border: "none",
                    padding: "12px 0px",
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    textTransform: "capitalize",
                    [theme.breakpoints.up("sm")]: {},
                    [theme.breakpoints.up("md")]: {
                        fontSize: 14,
                    },
                    [theme.breakpoints.up("lg")]: {},
                },
                "& .MuiTableCell-body:not(:first-child)": {
                    paddingLeft: 20,
                },
                "& .MuiTableRow-root": {
                    borderBottom: "1px solid #2A2C45",
                    "& .MuiTableCell-body": {
                        color: "#fff",
                    },
                    "& .MuiTableCell-body:first-child": {
                        color: "#2DDBFF",
                        paddingLeft: 25,
                        textOverflow: "ellipsis",
                        maxWidth: 140,
                        width: 140,
                        overflow: "hidden",
                        "& a": {
                            color: "#2DDBFF",
                        },
                    },
                    "& .MuiTableCell-body:last-child": {
                        paddingRight: 50,
                        width: 175,
                        textTransform: "left",
                    },
                    "& .MuiTableCell-head": {
                        color: "rgba(255, 255, 255, 0.3)",
                    },
                },
            },
            "& .customPagination": {
                "& li:nth-child(2)": {
                    marginRight: 56,
                },
            },
            "& .paginationPools": {
                marginTop: 20,
                marginBottom: 52,
            },
            "& .noDataTransaction": {
                padding: "64px 0px 27px 0px",
                margin: "0px auto",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "#1F213B",
                "& .box": {
                    width: "100%",
                    height: "234px",
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    "& img": {
                        mixBlendMode: "luminosity",
                    },
                    "& p": {
                        position: "absolute",
                        bottom: 40,
                        left: "50%",
                        transform: "translateX(-50%)",
                        fontSize: 16,
                        color: "rgba(255, 255, 255, .3)",
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    },
                },
            },
        },

        w25: {
            width: "25%",
        },

        w50: {
            width: "50%",
        },

        minW124: {
            minWidth: 124,
        },

        tableContainer: {
            background: "#191920",
            boxShadow: "none",
        },

        color6398FF: {
            color: "#6398FF",
        },

        stakedTx: {
            width: 64,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            cursor: "pointer",
        },

        unStakedTx: {
            width: 170,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            display: "block",
            cursor: "pointer",
        },

        iconInfo: {
            top: 2,
            left: 3,
            position: "relative",
        },

        infoTab: {
            padding: "0px 26px",
            [theme.breakpoints.down("sm")]: {
                padding: "0px",
            },
        },

        transactionList: {
            fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
            margin: "35px 0px 12px 0px",
            [theme.breakpoints.down("sm")]: {
                fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                fontSize: 14,
                margin: "25px 0px 12px 0px",
            },
        },

        intemInfoTab: {
            marginBottom: 20,
            display: "flex",
            flexWrap: "wrap",
            fontSize: 14,
            lineHeight: "22px",
            color: "#FFFFFF",

            [theme.breakpoints.down("sm")]: {
                justifyContent: "space-between",
                marginBottom: 12,
            },
        },

        nameInfoTab: {
            width: "50%",
            maxWidth: 194,
            marginRight: "60px",
            fontSize: 14,
            lineHeight: "22px",
            color: "rgba(255, 255, 255, .3)",
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            [theme.breakpoints.down("sm")]: {
                marginRight: 0,
                fontSize: 12,
                lineHeight: "17px",
            },
        },

        valueInfoTab: {
            color: "rgba(255, 255, 255, .8)",
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            [theme.breakpoints.down("sm")]: {
                fontSize: 12,
                lineHeight: "17px",
            },
        },
        noteInfoTab: {
            display: "flex",
            fontSize: 14,
            color: "#D01F36",
            "& svg": {
                marginTop: 4,
                marginRight: 5,
            },
        },

        headTabTable: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,

            [theme.breakpoints.down("sm")]: {
                alignItems: "flex-start",
                flexDirection: "column",
            },
        },

        leftHeadTabTable: {
            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
            fontSize: 14,
            lineHeight: "18px",
            color: "#AEAEAE",

            [theme.breakpoints.down("sm")]: {
                marginBottom: 18,
            },
        },

        historySwitchContainer: {
            display: "flex",
            minWidth: 320,
            borderRadius: 100,
            padding: 5,
            backgroundColor: "#222228",
            cursor: "pointer",
        },
        historyTab: {
            borderRadius: 30,
            width: "50%",
            padding: 5,
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
        },
        historyTabActive: {
            borderRadius: 30,
            padding: 5,
            width: "50%",
            color: "white",
            backgroundColor: "#3232DC",
            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
        },

        rightHeadTabTable: {
            display: "flex",
            alignItems: "center",
        },

        boxChecked: {
            display: "block",
            position: "relative",
            paddingLeft: 22,
            cursor: "pointer",
            userSelect: "none",
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            fontSize: 14,
            lineHeight: "20px",
            color: "#FFFFFF",
            marginLeft: 16,

            [theme.breakpoints.down("sm")]: {
                marginLeft: 0,

                "&:first-child": {
                    display: "none",
                },
            },

            "& input": {
                position: "absolute",
                opacity: 0,
                cursor: "pointer",
                height: 0,
                width: 0,
            },

            "& .checkmark": {
                position: "absolute",
                top: 2,
                left: 0,
                height: 16,
                width: 16,
                backgroundColor: "#444449",
                border: "1px solid #58585A",
                borderRadius: 4,

                "&:after": {
                    content: '""',
                    left: 4,
                    top: 2,
                    width: 4,
                    height: 7,
                    border: "solid white",
                    borderWidth: "0 2px 2px 0",
                    transform: "rotate(45deg)",
                    position: "absolute",
                    display: "none",
                },

                "&.checked": {
                    backgroundColor: "#2196F3 !important",
                    display: "block",

                    "&:after": {
                        display: "block !important",
                    },
                },
            },
        },

        tableHead: {
            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
            fontSize: 14,
            lineHeight: "18px",
            color: "#AEAEAE",

            "& .MuiTableCell-head": {
                color: "#AEAEAE",
            },
        },

        dadText: {
            height: 42,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
            fontSize: 14,
            lineHeight: "18px",
            color: "#AEAEAE",
        },

        cellHeadDad: {
            padding: 0,
            verticalAlign: "top",
            border: "1px solid #37373D",
        },

        childText: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },

        cellHeadChild: {
            height: 58,
            padding: "0 2px",
            border: "none",
            verticalAlign: "middle",
            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
            fontSize: 14,
            lineHeight: "18px",
            color: "#AEAEAE",
            borderTop: "1px solid #37373D",
            borderRight: "1px solid #37373D",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",

            "&:last-child": {
                borderRight: "none",
            },
        },

        tableRow: {
            background: "#222228",
        },

        cellBodyDad: {
            padding: 0,
            border: "1px solid #37373D",
        },

        cellBodyChild: {
            height: 46,
            padding: "0 2px",
            border: "none",
            verticalAlign: "middle",
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            fontSize: 14,
            lineHeight: "20px",
            color: "#FFFFFF",
            borderRight: "1px solid #37373D",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            whiteWpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",

            "&.hidden": {
                border: "1px solid #2B4881",
            },

            "&:last-child": {
                borderRight: "none",
            },
        },

        boxHistory: {
            marginTop: -2,
            border: "1px solid #2B4881",
            position: "relative",
            marginBottom: -1,
        },

        tableRowHistory: {
            "& th": {
                height: 38,
                borderBottom: "1px solid #37373D",
                fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                fontSize: 12,
                lineHeight: "16px",
                color: "#FFFFFF",
            },
            "& td": {
                height: 34,
                borderTop: "none",
                borderBottom: "none",
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                fontSize: 12,
                lineHeight: "18px",
                color: "#FFFFFF",
            },
        },

        btnShow: {
            color: "#FFFFFF",
            fontSize: 17,
        },

        btnHidden: {
            color: "#6398FF",
            fontSize: 17,
        },

        tableMobile: {
            background: "#222228",
            borderRadius: 8,
            padding: "8px 12px",
        },

        rowMobile: {
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            borderBottom: "1px solid rgb(255 255 255 / 10%)",
            padding: "12px 0",

            "&:last-child": {
                borderBottom: "none",
            },
        },

        cellMobile: {},

        nameCellMobile: {
            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
            fontSize: 14,
            lineHeight: "18px",
            color: "#AEAEAE",
            marginBottom: 8,
        },

        valueCellMobile: {
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            fontSize: 14,
            lineHeight: "20px",
            color: "#FFFFFF",

            "& a": {
                color: "#6398FF",
                cursor: "pointer",
            },
        },

        pagination: {
            "& *": {
                color: "white",
            },
        },

        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
    };
});

export default useStyles;
