import {
    Hidden,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import {
    Chart as ChartJS,
    Filler,
    Legend,
    LineElement,
    PointElement,
    RadialLinearScale,
    Tooltip,
} from "chart.js";
import { useCallback } from "react";
import { Radar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatPrecisionAmount } from "../../../utils";
import useStyles from "./style";
import TableMobile from "./TableMobile";
import i18n from "i18next";
import { convertLang } from "../../IDOHomePage/CardItem";

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

export default function Events(props: any) {
    const styles = useStyles();
    const { t } = useTranslation();
    const { events, competior_benmark, ratings } = useSelector(
        (state: any) => state.idoDetail?.data
    );

    const ratingsName =
        ratings &&
        (ratings || [])?.map((rating: any) => {
            return convertLang(rating.name, i18n.language);
        });

    const dataRatings =
        ratings &&
        (ratings || [])?.map((rating: any) => {
            return rating.score;
        });

    const renderData = useCallback(() => {
        // if (!ratingsName) return;
        const options = {
            plugins: {
                legend: {
                    display: false,
                },
            },
            scales: {
                r: {
                    ticks: {
                        display: false,
                    },
                    pointLabels: {
                        font: {
                            size: 12,
                        },
                        color: "rgba(255, 255, 255, 0.5)",
                    },
                    angleLines: {
                        display: true,
                        color: "#fff",
                        lineWidth: 2,
                    },
                },
            },
        };

        const data = {
            labels: ratingsName
                ? ratingsName
                : ["Team", "Tokenomics", "Backers", "Whitepaper", "Social", "Roadmap"],
            datasets: [
                {
                    data: [1, 1, 1, 1, 1, 1],
                    backgroundColor: "#2DDBFF00",
                    borderColor: "rgba(255, 99, 132, 0)",
                    borderWidth: 0,
                    pointRadius: 0,
                },
                {
                    data: dataRatings ? dataRatings : [5, 3, 5, 4.3, 4, 2],
                    backgroundColor: "#2DDBFF99",
                    borderColor: "#2DDBFF",
                    borderWidth: 1,
                    pointRadius: 1,
                },
                {
                    data: [10, 10, 10, 10, 10, 10],
                    backgroundColor: "#0F69E199",
                    borderColor: "rgba(255, 99, 132, 0)",
                    borderWidth: 1,
                    pointRadius: 0,
                },
                {
                    data: [12, 12, 12, 12, 12, 12],
                    backgroundColor: "#12224B99",
                    borderColor: "rgba(255, 99, 132, 0)",
                    borderWidth: 0,
                    pointRadius: 0,
                },
            ],
        };
        return (
            <Radar
                // options={options}
                data={data}
                options={options}
                // {...props}
            />
        );
    }, [dataRatings, ratingsName]);

    return (
        <div className={styles.wrapper}>
            <div>
                <div className="events">{t("ProjectDetails.events")}</div>
                <div>
                    {events?.map((item: any, index: number) => (
                        <div key={index}>
                            {/* <p>Events {index + 1}</p> */}
                            <a
                                style={{ color: "#9747FF" }}
                                href={item?.url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {convertLang(item?.name, i18n.language)}
                            </a>
                            <p> {convertLang(item?.text, i18n.language)}</p>
                            {/* <p>{convertLang(state?.text, i18n.language)}</p> */}
                        </div>
                    ))}
                </div>
            </div>
            <div className="competitor">
                <div className="events">{t("ProjectDetails.competitorBenchmarking")}</div>
                <Hidden xsDown>
                    <div className={styles.tableCompetitor}>
                        <div className="boxTableCompetitor">
                            <TableContainer>
                                <Table className="tableTransition" aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ paddingLeft: 10 }}>
                                                {t("ProjectDetails.Competitor.name")}
                                            </TableCell>

                                            <TableCell>
                                                {t("ProjectDetails.Competitor.ticker")}
                                            </TableCell>
                                            <TableCell className={styles.marketToolTip}>
                                                <p>{t("ProjectDetails.Competitor.marketCap")}</p>
                                                <div className="iconI">
                                                    <img
                                                        src="/images/home-page/iconHoverCompare.svg"
                                                        alt=""
                                                        width={14}
                                                        height={10}
                                                        className="iconI"
                                                    />
                                                    <div className="onHoverI">
                                                        <p>{t("ProjectDetails.marketTooltip")}</p>
                                                    </div>
                                                </div>
                                            </TableCell>
                                            {/* <TableCell>Max Supply</TableCell> */}
                                            <TableCell className={styles.compareToolTip}>
                                                {" "}
                                                <p>
                                                    {t(
                                                        "ProjectDetails.Competitor.compareToCompetitor"
                                                    )}
                                                </p>
                                                <div className="iconI">
                                                    <img
                                                        src="/images/home-page/iconHoverCompare.svg"
                                                        alt=""
                                                        width={14}
                                                        height={10}
                                                        className="iconI"
                                                    />
                                                    <div className="onHoverI">
                                                        {/* <p
                                                            style={{
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            Compare to Competitor's market cap:
                                                        </p> */}
                                                        <p>
                                                            {t("ProjectDetails.maxSupplyTooltip")}
                                                        </p>
                                                    </div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Array.isArray(competior_benmark) &&
                                            competior_benmark.length > 0 &&
                                            competior_benmark?.map((row: any, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell component="td" scope="row">
                                                        {convertLang(row?.name, i18n.language)}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row?.ticker ? row?.ticker : ""}
                                                    </TableCell>
                                                    <TableCell>
                                                        {formatPrecisionAmount(row?.market_cap)}
                                                    </TableCell>
                                                    {/* <TableCell>
                                                        {formatPrecisionAmount(row?.FDV)}
                                                    </TableCell> */}
                                                    <TableCell>
                                                        {row?.market_cap_description
                                                            ? row?.market_cap_description
                                                            : "-"}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                                {Array.isArray(competior_benmark) && competior_benmark.length <= 0 && (
                                    <div className="noDataTransaction">
                                        <img src="/images/account_v1/no_transaction.svg" alt="" />
                                    </div>
                                )}
                            </TableContainer>
                        </div>
                    </div>
                </Hidden>
                <Hidden smUp>
                    <TableMobile />
                </Hidden>
                <div className="codeDaoRating">
                    <p>{t("ProjectDetails.codeDaoRating")}</p>
                    <div className="iconI">
                        <img
                            src="images/home-page/iconI.svg"
                            // className="iconI"
                            alt=""
                            width="15px"
                            height="15px"
                        />
                        <div className="onHoverI">
                            <p>{t("ProjectDetails.tooltipRating")}</p>
                        </div>
                    </div>
                </div>
                <div className="lineChart">
                    <div>{renderData()}</div>
                </div>
            </div>
        </div>
    );
}
