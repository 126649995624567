import { NETWORK } from "../constants";

export function findNetworkFromChainID(chain_id: number): string {
    if (Number(chain_id) === 56 || Number(chain_id) === 97) {
        return NETWORK.BSC;
    } else if (Number(chain_id) === 137 || Number(chain_id) === 80001) {
        return NETWORK.POLYGON;
    } else if (Number(chain_id) === 8217 || Number(chain_id) === 1001) {
        return NETWORK.KLAYTN;
    }
}
