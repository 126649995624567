import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import CloseIcon from "@material-ui/icons/Close";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AppContext } from "../../../../AppContext";
import { connectorsSupportByNetwork, SUPPORTED_WALLETS } from "../../../../constants/connectors";
import {
    appNetworkType,
    APP_NETWORKS,
    APP_NETWORKS_NAME,
    ETH_CHAIN_ID,
    POLYGON_CHAIN_ID,
} from "../../../../constants/network";
import PrivacyPolicy from "../../../../pages/Dashboard/TermsAndPrivacy/PrivacyPolicy";
import Terms from "../../../../pages/Dashboard/TermsAndPrivacy/Terms";
import ConnectWalletBox from "../ConnectWalletBox";
import { HeaderContext, HeaderContextType } from "../context/HeaderContext";
import useStyles from "./style";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
            background: "#020616",
            paddingTop: 0,
            borderRadius: 20,
        },
        closeButton: {
            position: "absolute",
            right: 25,
            top: 25,
            color: "white",
            opacity: 0.5,
            backgroundColor: "#262530",
            padding: 8,
            border: "2px solid rgba(255, 255, 255, 0.2)",

            [theme.breakpoints.down("xs")]: {
                right: 20,
                top: 20,
                padding: 4,
            },
            "&:hover": {
                backgroundColor: "#262530",
            },
        },
        svgIcon: {
            fontSize: 5,
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
    customClass: string;
}

export interface ComponentProps {
    opened: boolean;
    handleClose: () => void;
    width: any;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, customClass, onClose, ...other } = props;

    const customStyles = {
        color: "white",
    };

    return (
        <MuiDialogTitle
            disableTypography
            className={`${classes.root} ${customClass}`}
            {...other}
            style={customStyles}
        >
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
        color: "#999999",
    },
}))(MuiDialogContent);

const ConnectWalletModalHomePage: React.FC<ComponentProps> = (props: ComponentProps) => {
    const styles = useStyles();
    const { t } = useTranslation();
    const { opened, handleClose } = props;
    const [paramsCodeQR, setParamsCodeQR] = useState<any>("");
    const { setAgreedTerms, agreedTerms } = useContext<HeaderContextType>(HeaderContext);
    const { appChainID } = useSelector((state: any) => state.appNetwork).data;
    const [stringCode, setStringCode] = useState<any>("");
    const [openTerms, setOpenTerms] = useState<boolean>(false);
    const [openPolicy, setOpenPolicy] = useState<boolean>(false);
    const { walletName, handleProviderChosen, connectWalletLoading } = useContext(AppContext);
    const connectorsByNetwork = (() => {
        switch (appChainID) {
            case POLYGON_CHAIN_ID:
                return connectorsSupportByNetwork[APP_NETWORKS_NAME.POLYGON];

            case ETH_CHAIN_ID:
            default:
                return SUPPORTED_WALLETS;
        }
    })();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.target && setAgreedTerms(event.target.checked);
    };

    // const callQRCodeKlipWallet = async () => {
    //     const buildUrl = (url: string) => {
    //         url = url.replace(/^\/+|\/+$/g, "");
    //         return `${`https://a2a-api.klipwallet.com/v2/a2a`}/${url}`;
    //     };

    //     return fetch(buildUrl("prepare"), {
    //         method: "POST",
    //         body: JSON.stringify({
    //             bapp: { name: "definix" },
    //             type: "auth",
    //         }),
    //     })
    //         .then(async (response) => {
    //             const res = await response.json();
    //             setParamsCodeQR(
    //                 `https://klipwallet.com/?target=/a2a?request_key=${res.request_key}`
    //             );
    //             setStringCode(res.request_key);
    //         })
    //         .then((data) => {
    //             console.log(data);
    //         });
    // };

    const listenKakaolTalkQR = async (request_key: string) => {
        const buildUrl = (url: string) => {
            url = url.replace(/^\/+|\/+$/g, "");
            return `${`https://a2a-api.klipwallet.com/v2/a2a`}/${url}`;
        };

        return fetch(buildUrl(`result?request_key=${request_key}`), {
            method: "GET",
        })
            .then(async (response) => {
                const res = await response.json();
                console.log("responseresponse", res);
            })
            .then((data) => {
                console.log("datadata", data);
            });
    };

    useEffect(() => {
        if (paramsCodeQR && stringCode) {
            const intervalProgress = setInterval(() => {
                listenKakaolTalkQR(stringCode);
            }, 1000);

            return () => {
                intervalProgress && clearInterval(intervalProgress);
            };
        }
    }, [paramsCodeQR, stringCode]);

    const onCloseModal = () => {
        setParamsCodeQR("");
        handleClose();
    };

    const handleCloseModal = useCallback(() => {
        setOpenTerms(false);
        setOpenPolicy(false);
    }, []);

    const renderOpenModalTerms = useCallback(
        () => <Terms opened={openTerms} handleClose={handleCloseModal} />,
        [handleCloseModal, openTerms]
    );

    const renderOpenModalPolicy = useCallback(
        () => <PrivacyPolicy opened={openPolicy} handleClose={handleCloseModal} />,
        [handleCloseModal, openPolicy]
    );

    const openModalTerms = () => {
        setOpenTerms(true);
    };

    const openModalPolicy = () => {
        setOpenPolicy(true);
    };

    return (
        <Dialog open={opened} onClose={onCloseModal} className={styles.dialog}>
            <DialogTitle
                id="customized-dialog-title"
                onClose={onCloseModal}
                customClass={styles.dialogTitle}
            >
                {t("header.connectWallet")}
            </DialogTitle>

            {paramsCodeQR ? (
                <div className={styles.boxQrCode}>
                    <QRCode value={paramsCodeQR} />
                </div>
            ) : (
                <DialogContent>
                    <Typography gutterBottom className={styles.dialogContentTypo}>
                        1. {t("header.accept")}
                    </Typography>
                    <div className={`${styles.dialogContentBlock} ${styles.dialogPrivacy}`}>
                        <Checkbox
                            checked={agreedTerms}
                            onChange={handleChange}
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            className={styles.dialogCheckbox}
                        />

                        <span className={styles.dialogPrivacyText}>
                            {t("header.descRead")}
                            <span
                                onClick={openModalTerms}
                                className={styles.dialogPrivacyHighlight}
                                // to="/terms"
                            >
                                {" "}
                                {t("header.terms")}
                            </span>{" "}
                            {t("header.and")}&nbsp;
                            <span
                                onClick={openModalPolicy}
                                className={styles.dialogPrivacyHighlight}
                                // to="/privacy"
                            >
                                {" "}
                                {t("header.privacy")}
                            </span>
                        </span>
                        {renderOpenModalTerms()}
                        {renderOpenModalPolicy()}
                    </div>
                    <Typography gutterBottom className={styles.dialogContentTypo}>
                        2. {t("header.chooseNetwork")}
                    </Typography>
                    <div className={`${styles.dialogContentBlock} ${styles.dialogNetworks}`}>
                        {Object.keys(APP_NETWORKS).map((key: string) => {
                            const network = APP_NETWORKS[key as appNetworkType];
                            return <ConnectWalletBox key={key} appNetwork={network} isAppNetwork />;
                        })}
                    </div>
                    <Typography gutterBottom className={styles.dialogContentTypo}>
                        3. {t("header.chooseWallet")}
                    </Typography>
                    <div className={`${styles.dialogContentBlock} ${styles.dialogNetworks}`}>
                        {Object.keys(connectorsByNetwork).map((key: string) => {
                            const network = connectorsByNetwork[key];
                            const isMobile = isWidthDown("xs", props.width);
                            const showConnectorInMobile = isMobile ? network.mobile : true;
                            return (
                                showConnectorInMobile && (
                                    <ConnectWalletBox
                                        key={key}
                                        wallet={network}
                                        isAppNetwork={false}
                                        handleProviderChosen={handleProviderChosen}
                                        connectWalletLoading={connectWalletLoading}
                                        walletName={walletName}
                                    />
                                )
                            );
                        })}
                    </div>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default withWidth()(ConnectWalletModalHomePage);
