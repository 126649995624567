import { AnyAction } from "redux";
import { showBuyTicketActions } from "../constants/showBuyTicket";

type StateType = {
    type: string;
    message: string;
};

const initialState = {
    type: "",
    message: "",
};
export const showBuyTicketReducer = (state: StateType = initialState, action: AnyAction) => {
    switch (action.type) {
        case showBuyTicketActions.SUCCESS: {
            return {
                type: "success",
                message: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};
