import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        modalUnStake: {
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            "& .MuiPaper-root": {
                [theme.breakpoints.down("xs")]: {
                    margin: "18px",
                },
            },
            "& .modal-content": {
                backgroundColor: "#242640",
                width: "500px",
                padding: "70px 54px 39px 54px",
                position: "relative",
                [theme.breakpoints.down("xs")]: {
                    padding: "40px 20px 20px",
                },
                borderRadius: "20px",
                color: "#FFFFFF",
            },
            "& .modal-content__head": {
                padding: "5px 0px",

                "& .btn-close": {
                    top: 25,
                    right: 25,
                    [theme.breakpoints.down("xs")]: {
                        top: 16,
                        right: 16,
                        width: 24,
                        height: 24,
                    },
                },
                "& .headerWarning": {
                    backgroundColor: "rgba(255, 51, 51, .3)",
                    borderRadius: "5px",
                    marginTop: 20,
                    "& .waringBox": {
                        display: "flex",
                        alignItems: "flex-start",
                        padding: "17px 27px 18px 0px",
                        "& img": {
                            padding: "0px 15px",
                        },
                        "& p": {
                            textAlign: "left",
                            fontSize: 14,
                            lineHeight: "16px",
                        },
                    },
                },
            },
            "& .modal-content__head .title": {
                fontSize: "32px",
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                textAlign: "left",
                [theme.breakpoints.down("xs")]: {
                    fontSize: 20,
                },
            },
            "& .modal-content__body": {
                padding: "0",
                margin: "15px 0",
                overflowX: "hidden",
                "& .boxTokenType": {
                    paddingBottom: 10,
                    borderBottom: "1px solid rgba(255, 255, 255, .1)",
                },
                "& .token-type": {
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "14px",
                    alignItems: "center",
                    marginBottom: "14px",
                    color: "rgba(255,255,255,0.5)",
                    "& .withdrawFeePolicy": {
                        display: "flex",
                        alignItems: "center",
                        color: "#2DDBFF",
                        fontSize: 12,
                        "& img": {
                            marginLeft: 8,
                        },
                    },
                },
                "& .token-detail": {
                    display: "flex",
                    alignItems: "center",
                    color: "rgba(255,255,255,1)",
                },

                "& .token-logo": {
                    width: "22px",
                    height: "22px",
                    borderRadius: "28px",
                    margin: "0 5px",
                },
                "& .warningStaked": {
                    color: "#FF3333",
                    fontSize: 12,
                    marginTop: 5,
                },
                "& .input-group": {
                    background: "transparent",
                    height: 48,
                    borderRadius: "12px",
                    padding: "0px 14px",
                    border: " 2px solid rgba(255, 255, 255, 0.2)",
                },
                "& .input-group input": {
                    cursor: "default",
                    padding: "0",
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    height: "100%",
                },
                "& .token-balance": {
                    color: "#AEAEAE",
                    marginTop: "15px",
                },
                "& .actionChangeAmount": {
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                    gap: "0px 5px",
                    borderBottom: "1px solid rgba(255, 255, 255, .1)",
                    paddingBottom: 20,
                    marginBottom: 20,
                    "& button": {
                        height: "36px",
                        width: "94px",
                        margin: "auto 0px 10px",
                        borderRadius: "4px",
                        backgroundColor: "#353751 !important",
                        fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    },
                },
                "& .subtitle": {
                    marginBottom: "10px",
                    marginTop: "25px",
                    fontWeight: 700,
                    fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                    color: "rgba(255,255,255,0.5)",
                    fontSize: 14,
                },
            },
            "& .modal-content__foot": {
                padding: "40px 0px 0px",
                [theme.breakpoints.down("xs")]: {
                    padding: "17px 0px 0px",
                    flexDirection: "row !important",
                },
                "& button": {
                    height: "54px",
                    width: "100%",
                    padding: "12px 30px",
                    margin: 0,
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                    backgroundColor: "transparent !important",
                    borderRadius: "90px",
                    color: "#9747FF",
                    border: "2px solid #9747FF",
                    [theme.breakpoints.down("xs")]: {
                        height: "40px",
                        fontSize: 12,
                    },
                },
            },
        },
        notice: {
            font: "normal normal bold 14px/18px DM Sans",
            color: "#FFF",
            marginTop: "30px",
        },
        [theme.breakpoints.down("xs")]: {
            modalTransaction: {
                "& .modal-content__body": {
                    padding: "0",
                },
            },
        },
    };
});

export default useStyles;
