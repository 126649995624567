import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        modalWithdraw: {
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            "& .MuiPaper-root": {
                [theme.breakpoints.down("xs")]: {
                    margin: "18px",
                },
            },
            "& .modal-content": {
                backgroundColor: "#242640",
                width: "500px",
                padding: "70px 55px 63px 55px",
                position: "relative",
                [theme.breakpoints.down("xs")]: {
                    padding: "40px 20px 20px",
                },
                borderRadius: "20px",
                color: "#FFFFFF",
            },
            "& .modal-content__head": {
                paddingBottom: "25px",
                borderBottom: "1px solid rgba(255, 255, 255, .1)",
                "& .btn-close": {
                    top: 25,
                    right: 25,
                    [theme.breakpoints.down("xs")]: {
                        top: 16,
                        right: 16,
                        width: 24,
                        height: 24,
                    },
                },
            },
            "& .modal-content__head .title": {
                fontSize: "32px",
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                textAlign: "left",
                [theme.breakpoints.down("xs")]: {
                    fontSize: 20,
                },
            },
            "& .modal-content__body": {
                padding: "0",
                margin: "20px 0",
                fontSize: 16,
                "& span": {
                    fontSize: 14,
                    color: "#2DDBFF",
                },
                "& img": {
                    marginLeft: 8,
                },
            },
            "& .modal-content__foot": {
                padding: "23px 0px 0px",
                [theme.breakpoints.down("xs")]: {
                    padding: "17px 0px 0px",
                    flexDirection: "row !important",
                },
                "& button": {
                    height: "54px",
                    width: "100%",
                    padding: "12px 30px",
                    margin: 0,
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                    background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                    borderRadius: "90px",
                    color: "#FFFFFF",
                    [theme.breakpoints.down("xs")]: {
                        height: "40px",
                        fontSize: 12,
                    },
                },
            },
        },
    };
});

export default useStyles;
