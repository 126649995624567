import { Hidden } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppContext } from "../../../AppContext";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import ModalSignatureRequired from "../../../pages/ModalSignatureRequired";
import ToastMessage from "../../../pages/ProjectDetail/ToastMessage";
import { login } from "../../../store/actions/user";
import { useCommonStyle } from "../../../styles";
import FooterLandingLayout from "../../Base/FooterLandingLayout";
import HeaderDefaultLayout from "../../Base/HeaderDefaultLayout";
import MainDefaultLayout from "../../Base/MainDefaultLayout";

const DefaultLayout = (props: any) => {
    const { account: connectedAccount, library } = useWeb3React();
    const wallet = useSelector((state: any) => state.wallet);
    const { appChainID } = useTypedSelector((state: any) => state.appNetwork).data;
    const commonStyle = useCommonStyle();
    const dispatch = useDispatch();
    const { logout: disconnectWallett } = useContext(AppContext);

    useEffect(() => {
        if (
            !localStorage.getItem("investor_access_token") &&
            connectedAccount &&
            wallet?.twoFactor === "Layer1" &&
            appChainID
        ) {
            dispatch(login(connectedAccount, library, appChainID, disconnectWallett));
        }
        return;
    }, [connectedAccount, wallet?.twoFactor, appChainID, dispatch, library]);

    return (
        <div className={commonStyle.DefaultLayout}>
            <div className={commonStyle.bgBody}>
                <HeaderDefaultLayout isKYC={props.isKYC} />
                <Hidden smUp>
                    <ToastMessage />
                </Hidden>
                <MainDefaultLayout defaultLayout={props.defaultLayout}>
                    {props.children}
                </MainDefaultLayout>
                {connectedAccount && !localStorage.getItem("investor_access_token") && (
                    <ModalSignatureRequired open={true} />
                )}
                <FooterLandingLayout />
            </div>
        </div>
    );
};

export default DefaultLayout;
