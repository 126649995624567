const UPCOMING = 'STATUS_UPCOMING';
const CALCULATION = 'STATUS_CALCULATION';
const BUYTICKET = 'STATUS_BUYTICKET';
const DISTRIBUTION = 'STATUS_DISTRIBUTION';
const ENDED = 'STATUS_ENDED';


export const statusIdoPoolActions = {
  UPCOMING,
  CALCULATION,
  BUYTICKET,
  DISTRIBUTION,
  ENDED,
}
