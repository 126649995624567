import { Button, Slider } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AppContext } from "../../../../AppContext";
import { formatPrecisionAmount } from "../../../../utils";
import { useStyles } from "./style";
type Props = {};

export default function CurrentRank({}: Props) {
    const styles = useStyles();
    const { t } = useTranslation();
    const { openConnectWallet, setOpenConnectWallet, connectWalletLoading } =
        useContext(AppContext);
    const { account } = useWeb3React();
    const { data: userTier } = useSelector((state: any) => state.userTier);
    const tierInfo = useSelector((state: any) => state.tiers.data);

    const getInfoTier = (name: string) => {
        switch (name) {
            case "legend": {
                return (
                    <div className="infoCurrentRank">
                        <img src="/images/icons/legend.svg" alt="" />
                        <span>Legend</span>
                    </div>
                );
            }
            case "rookie": {
                return (
                    <div className="infoCurrentRank">
                        <img src="/images/icons/super_rookie.svg" alt="" />
                        <span>Super Rookie</span>
                    </div>
                );
            }
            default: {
                return (
                    <div className="infoCurrentRank">
                        <img src="/images/icons/started.svg" alt="" />
                        <span>Starter</span>
                    </div>
                );
            }
        }
    };

    const convertSliderTier = (name: string) => {
        switch (name) {
            case "legend": {
                return 100;
            }
            case "rookie": {
                return 50;
            }
            default: {
                return 0;
            }
        }
    };

    const tier = [
        {
            value: 0,
            label: (
                <div className={styles.infoTierSlider}>
                    <div className={styles.activeDot + " dotTier"}>
                        <img src="/images/staking/tier/dot.svg" className="dot" alt="" />
                        <img src="/images/icons/started.svg" className="iconDot" alt="" />
                        <img
                            src="/images/staking/tier/dotActive.svg"
                            className="dotActive"
                            alt=""
                        />
                    </div>
                    <p>{t("staking.tier.timelineTier.starter")}</p>
                    <p>{t("staking.tier.timelineTier.starterDesc")} </p>
                </div>
            ),
        },
        {
            value: 50,
            label: (
                <div className={styles.infoTierSlider2}>
                    <div
                        className={`${
                            userTier?.min >= Number(tierInfo[tierInfo?.length - 2]?.min || 0) &&
                            styles.activeDot
                        } dotTier`}
                    >
                        <img src="/images/staking/tier/dot.svg" className="dot" alt="" />
                        <img src="/images/icons/super_rookie.svg" className="iconDot" alt="" />
                        {userTier?.min >= Number(tierInfo[tierInfo?.length - 2]?.min || 0) && (
                            <img
                                src="/images/staking/tier/dotActive.svg"
                                className="dotActive"
                                alt=""
                            />
                        )}
                    </div>
                    <p>{t("staking.tier.timelineTier.super")}</p>
                    <p>{formatPrecisionAmount(tierInfo[1]?.min)} CODE</p>
                </div>
            ),
        },
        {
            value: 100,
            label: (
                <div className={styles.infoTierSlider + " " + styles.infoTierSlider3}>
                    <div
                        className={`${
                            userTier?.min >= Number(tierInfo[tierInfo?.length - 1]?.min || 0) &&
                            styles.activeDot
                        } dotTier`}
                    >
                        <img src="/images/staking/tier/dot.svg" className="dot" alt="" />
                        <img src="/images/icons/legend.svg" className="iconDot" alt="" />
                        {userTier?.min >= Number(tierInfo[tierInfo?.length - 1]?.min || 0) && (
                            <img
                                src="/images/staking/tier/dotActive.svg"
                                className="dotActive"
                                alt=""
                            />
                        )}
                    </div>
                    <p>{t("staking.tier.timelineTier.legend")}</p>
                    <p>{formatPrecisionAmount(tierInfo[tierInfo?.length - 1]?.min) || 0} CODE</p>
                </div>
            ),
        },
    ];

    return (
        <div className={styles.title}>
            <div className="box">
                <p className="text">{t("staking.tier.titleCurrentRank")}</p>
                {account ? (
                    <div className="boxBottom">
                        <div className="boxTier">
                            <div className="currentRank">
                                <p>{t("staking.tier.titleYourCurrentRank")}</p>
                                {getInfoTier(userTier?.name)}
                            </div>
                            <Slider
                                defaultValue={0}
                                aria-labelledby="discrete-slider-custom"
                                valueLabelDisplay="auto"
                                marks={tier}
                                value={convertSliderTier(userTier?.name)}
                                className="timeLineTier"
                            />
                        </div>
                        <Link to="/account?tab=1&slug=1" className="viewTier">
                            <div className="textViewTier">
                                {t("staking.tier.linkHistory")}
                                <img src="/images/icons/redirect.svg" alt="" />
                            </div>
                        </Link>
                    </div>
                ) : (
                    <div className="connectWallet">
                        <p style={{}} className="textConnectWallet">
                            {t("staking.tier.textConnect")}
                        </p>
                        <Button
                            variant="contained"
                            className="btnConnect"
                            onClick={() => {
                                !openConnectWallet &&
                                    !connectWalletLoading &&
                                    setOpenConnectWallet &&
                                    setOpenConnectWallet(true);
                            }}
                        >
                            {t("staking.tier.textConnectWallet")}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}
