import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        wrapper: {
            position: "relative",
            zIndex: 9,
            // padding: 100,
            // [theme.breakpoints.down("md")]: {
            //   padding: "10px 10px",
            // },
            // "&> .title": {
            //   fontWeight: "bold",
            //   marginTop: 50,
            //   display: "flex",
            //   justifyContent: "center",
            //   fontSize: 36,
            //   lineHeight: "28px",
            //   color: "#FFFFFF",
            //   [theme.breakpoints.down("md")]: {
            //     fontWeight: 500,
            //     fontSize: 26,
            //   },
            // [theme.breakpoints.down("sm")]: {
            //   fontWeight: 500,
            //   fontSize: 20,
            //   marginTop: 30,
            // },
            // },
        },
        projectCountdown: {
            padding: "100px 84px",
            maxWidth: 1440,
            margin: "100px auto",
            // backgroundImage: "url(/images/home-page/bgHomepageUp.png)",
            // backgroundRepeat: "no-repeat",
            // backgroundPosition: "0 -150px",
            // backgroundSize: "cover",
            [theme.breakpoints.down("md")]: {
                padding: "100px 60px 60px 60px",
            },
            [theme.breakpoints.down("sm")]: {
                padding: "100px 32px 32px 32px",
                margin: "0px auto",
            },
            [theme.breakpoints.down("xs")]: {
                padding: "100px 16px 16px 16px",
            },
        },
        projectListIdo: {
            margin: "115px auto",
            backgroundImage: "url(/images/home-page/bgHomepageDown.png)",
            backgroundRepeat: "no-repeat",
            // backgroundPosition: "0 -150px",
            backgroundSize: "cover",
            [theme.breakpoints.down("lg")]: {
                padding: 100,
            },
            [theme.breakpoints.down("md")]: {
                padding: 60,
            },
            [theme.breakpoints.down("sm")]: {
                padding: 32,
            },
            [theme.breakpoints.down("xs")]: {
                padding: "32px 16px",
                margin: "0px auto",
            },
            "& .box": {
                maxWidth: 1440,
                padding: "110px 100px",
                margin: "0px auto",
                [theme.breakpoints.down("lg")]: {
                    padding: 0,
                },
                "& .noDataTransaction": {
                    padding: "64px 0px 27px 0px",
                    margin: "0px auto",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    "& .box": {
                        width: "100%",
                        height: "234px",
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        "& img": {
                            mixBlendMode: "luminosity",
                        },
                        "& p": {
                            position: "absolute",
                            bottom: 40,
                            left: "50%",
                            transform: "translateX(-50%)",
                            fontSize: 16,
                            color: "rgba(255, 255, 255, .3)",
                            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        },
                    },
                },
            },
        },
        countdown: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            [theme.breakpoints.down("md")]: {
                "&> img": {
                    width: 103,
                    height: 119,
                },
            },
            [theme.breakpoints.down("sm")]: {
                "&> img": {
                    width: 103,
                    height: 119,
                    marginTop: 44,
                },
            },
            "&> h2": {
                fontSize: 48,
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                background:
                    "linear-gradient(260.09deg, #AF5CFF -4.1%, #FFFFFF 40.03%, #FFFFFF 64.8%, #5FE3F3 111.46%)",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                color: "transparent",
                [theme.breakpoints.down("sm")]: {
                    textAlign: "center",
                },
                [theme.breakpoints.down("xs")]: {
                    fontSize: 24,
                    padding: "0 30px",
                },
            },
            "&> p": {
                marginTop: 10,
                fontSize: 14,
                color: "#FFFFFF",
                opacity: 0.5,
                textAlign: "center",
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                [theme.breakpoints.down("xs")]: {
                    padding: "0 20px",
                },
            },
        },

        container: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 30,
        },

        cyballCard: {
            display: "flex",
            flexDirection: "row",
            marginTop: "140px",
            ["@media (max-width:1380px)"]: {
                flexDirection: "column",
                alignItems: "center",
            },
            [theme.breakpoints.down("md")]: {
                flexDirection: "column",
            },
            [theme.breakpoints.down("xs")]: {
                marginTop: 60,
            },
            "&> .imgTicket": {
                zIndex: 1,
                "&>div": {
                    position: "relative",
                    "&> img": {
                        borderRadius: 30,
                        width: 675,
                        height: 550,
                        objectFit: "cover",
                    },
                    "&::after": {
                        content: '""',
                        position: "absolute",
                        top: "8px",
                        left: "12px",
                        display: "block",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#14162C",
                        zIndex: -1,
                        borderRadius: "35px",
                        ["@media (max-width:1380px)"]: {
                            display: "none",
                        },
                        // ["@media (max-width:1328px)"]: {
                        //     width: "62%",
                        // },
                        // [theme.breakpoints.down("md")]: {
                        //     display: "none",
                        // },
                    },
                    [theme.breakpoints.down("sm")]: {
                        "&> img": {
                            width: 580,
                            height: 473,
                        },
                    },
                    [theme.breakpoints.down("xs")]: {
                        "&> img": {
                            width: 343,
                            height: 279,
                        },
                    },
                },
                [theme.breakpoints.down("md")]: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                },
            },

            // [theme.breakpoints.down("md")]: {
            //   flexDirection: "column",
            //   alignItems: "center",
            //   marginTop: "60px",
            //   "&> img": {
            //     width: 450,
            //     height: 450,
            //   },
            // },

            // [theme.breakpoints.down("xs")]: {
            //   "&> img": {
            //     width: 354,
            //     height: 354,
            //   },
            // },
            "&> .detailTicket": {
                marginLeft: "100px",
                marginTop: "12px",
                width: "100%",
                ["@media (max-width:1380px)"]: {
                    // width: 500,
                    marginLeft: "0",
                    width: 520,
                    marginTop: 70,
                },
                // ["@media (max-width:800px)"]: {
                //     width: 500,
                // },
                [theme.breakpoints.down("xs")]: {
                    width: "100%",
                },
                "&>p": {
                    fontWeight: 700,
                    fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                    fontSize: "48px",
                    color: "white",
                    marginBottom: "16px",
                    [theme.breakpoints.down("xs")]: {
                        fontSize: 30,
                    },
                },
                "&>div>button": {
                    background: "rgba(75, 131, 217, 0.2)",
                    fontSize: 14,
                    height: "34px",
                    lineHeight: "34px",
                    padding: "0 16px",
                    color: "#0F69E1",
                    border: "none",
                    borderRadius: "5px",
                    textTransform: "capitalize",
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    marginRight: "16px",
                    // "&:first-child": {
                    // },
                    [theme.breakpoints.down("xs")]: {
                        fontSize: 14,
                        padding: "0px 15px",
                    },
                },
                "&> .desc": {
                    marginTop: 18,
                    color: "white",
                    opacity: 0.5,
                    fontSize: 16,
                    "&> div": {
                        display: "-webkit-box",
                        "-webkit-line-clamp": 3,
                        "-webkit-box-orient": "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    },
                    [theme.breakpoints.down("md")]: {
                        fontSize: 14,
                    },
                    [theme.breakpoints.down("xs")]: {
                        fontSize: 14,
                    },
                },
                "&> .follower": {
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 32,
                    // marginRight: 40,
                    justifyContent: "space-between",
                    [theme.breakpoints.down("xs")]: {
                        flexDirection: "row",
                        marginRight: 0,
                    },
                    "&>div:first-child": {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: 500,
                        backgroundColor: "#14162C",
                        borderRadius: "20px",
                        width: 242,
                        height: 140,
                        [theme.breakpoints.down("md")]: {
                            fontSize: 18,
                        },
                        [theme.breakpoints.down("xs")]: {
                            width: "48%",
                            // height: 140,
                            fontSize: 14,
                        },

                        "&> p:nth-child(2)": {
                            marginTop: 10,
                            marginBottom: 2,
                            color: "white",
                            fontSize: 18,
                            fontWeight: 600,
                            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                            [theme.breakpoints.down("xs")]: {
                                fontSize: 14,
                            },
                        },
                        "&> p:last-child": {
                            color: "white",
                            opacity: 0.3,
                            fontSize: 14,
                            fontWeight: 400,
                            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                            [theme.breakpoints.down("xs")]: {
                                fontSize: 12,
                            },
                        },
                    },
                    "&>div:last-child": {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: 500,
                        backgroundColor: "#14162C",
                        borderRadius: "20px",
                        width: 242,
                        height: 140,
                        [theme.breakpoints.down("md")]: {
                            fontSize: 18,
                        },
                        [theme.breakpoints.down("xs")]: {
                            width: "48%",
                            fontSize: 14,
                        },
                        "&> .vote-result": {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "row",
                            marginTop: 10,
                            marginBottom: 4,
                            "&> p": {
                                fontSize: 18,
                                color: "#FFFFFF",
                                fontWeight: 600,
                                fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                                [theme.breakpoints.down("xs")]: {
                                    fontSize: 14,
                                },
                            },

                            "&> .iconI": {
                                position: "relative",
                                cursor: "pointer",
                                "&> img": {
                                    marginTop: -2,
                                    marginLeft: 7,
                                    width: 15,
                                    height: 15,
                                },
                                "&> .onHoverI": {
                                    position: "absolute",
                                    visibility: "hidden",
                                    color: "white",
                                    opacity: 0,
                                    fontWeight: 400,
                                    fontSize: 11,
                                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                                    width: 185,
                                    backgroundColor: "#3E405B",
                                    borderRadius: 5,
                                    padding: 12,
                                    top: "calc(100% + 6px)",
                                    left: 0,
                                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                    transition: "all 200ms",
                                    zIndex: 3,
                                    [theme.breakpoints.down("sm")]: {
                                        left: -90,
                                    },
                                    [theme.breakpoints.down("xs")]: {
                                        left: -150,
                                    },
                                    "&> p": {
                                        opacity: 0.7,
                                    },
                                    " &::after": {
                                        content: '""',
                                        position: "absolute",
                                        display: "block",
                                        backgroundColor: "#3E405B",
                                        left: 10,
                                        top: -9,
                                        width: 10,
                                        height: 10,
                                        clipPath: "polygon(50% 20%, 0% 100%, 100% 100%)",
                                        [theme.breakpoints.down("sm")]: {
                                            left: 100,
                                        },
                                        [theme.breakpoints.down("xs")]: {
                                            left: 160,
                                        },
                                    },
                                },
                                "&:hover > .onHoverI": {
                                    visibility: "visible",
                                    opacity: 1,
                                },
                            },
                        },
                        "&> p:last-child": {
                            color: "#9747FF",
                            fontWeight: 400,
                            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                            fontSize: 14,
                            [theme.breakpoints.down("xs")]: {
                                fontSize: 12,
                            },
                        },
                    },
                },
                "&> .btn-read-more": {
                    marginTop: 20,
                    // marginRight: "24px",
                    width: "102%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    [theme.breakpoints.down("xs")]: {
                        marginRight: 0,
                    },
                    "&> .btnReadmore": {
                        background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                        height: "48px",
                        lineHeight: "48px",
                        padding: "0 26px",
                        color: "white",
                        border: "none",
                        borderRadius: "60px",
                        fontWeight: 700,
                        fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                        fontSize: 16,
                        cursor: "pointer",
                        width: "100%",
                        // textTransform: "none !important",
                        // "&:first-letter": {
                        //     textTransform: "lowercase",
                        // },
                        [theme.breakpoints.down("md")]: {
                            marginBottom: 20,
                            fontSize: 20,
                        },
                        [theme.breakpoints.down("md")]: {
                            fontSize: 18,
                        },
                        [theme.breakpoints.down("xs")]: {
                            width: "100%",
                            marginBottom: 4,
                            fontSize: 12,
                            marginRight: 0,
                        },
                    },
                },
                "&> .openTicket": {
                    marginTop: 22,
                    display: "flex",
                    flexDirection: "row",
                    color: "white",
                    [theme.breakpoints.down("xs")]: {
                        marginBottom: 70,
                    },
                    "&> p:first-child": {
                        fontWeight: 400,
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        fontSize: 14,
                        opacity: 0.3,
                        marginRight: 6,
                    },
                    "&> p:last-child": {
                        fontWeight: 600,
                        fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                        fontSize: 14,
                        opacity: 0.7,
                    },
                },
            },
        },
        btnViewCalendar: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#9747FF",
            padding: "12px 23px",
            textTransform: "capitalize",
            borderRadius: 60,
            color: "#FFFFFF",
            fontSize: 12,
            fontWeight: 700,
            fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
            "&:hover": {
                backgroundColor: "#9747FF",
            },
            [theme.breakpoints.down("md")]: {
                marginTop: 16,
            },
        },
        projects: {
            "&>p": {
                color: "white",
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                fontSize: 36,
                [theme.breakpoints.down("sm")]: {
                    paddingRight: 80,
                },
                [theme.breakpoints.down("xs")]: {
                    paddingLeft: 4,
                    fontSize: 26,
                },
            },
        },
        headerProjects: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "flex-end",
            marginTop: 40,
            [theme.breakpoints.down("md")]: {
                flexDirection: "column",
                alignItems: "flex-start",
            },
            // [theme.breakpoints.down("xs")]: {
            //   padding: "0 10px",
            // },
            "&>div:first-child": {
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                [theme.breakpoints.down("sm")]: {
                    flexDirection: "column",
                    alignItems: "flex-start",
                },
                "&> div:nth-child(2)": {
                    display: "flex",
                    flexDirection: "row",
                    [theme.breakpoints.down("sm")]: {
                        marginTop: 16,
                    },
                    "&> .search": {
                        display: "flex",
                        alignItems: "center",
                        width: 225,
                        height: 40,
                        borderRadius: "10px",
                        border: "2px solid rgba(255, 255, 255, 0.15)",
                        marginRight: 12,
                        [theme.breakpoints.down("xs")]: {
                            width: 166,
                        },
                        "&>img": {
                            marginLeft: 12,
                            marginRight: 12,
                            [theme.breakpoints.down("xs")]: {
                                marginLeft: 12,
                                width: "40%",
                                height: "40%",
                            },
                        },

                        "&>input": {
                            border: "none",
                            color: "white",
                            outline: "none",
                            borderTopRightRadius: "10px",
                            borderBottomRightRadius: "10px",
                            height: "40px",
                            background: "none",
                        },
                    },
                    "&> .btnFilter": {
                        width: 100,
                        height: 40,
                        border: " 2px solid rgba(255, 255, 255, 0.5)",
                        borderRadius: 60,
                        background: "none",
                        color: "white",
                        fontWeight: 700,
                        fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                        opacity: 0.7,
                        fontSize: 12,
                        textTransform: "capitalize",
                    },
                },
            },

            "&>div:last-child": {
                // marginTop: 44,
                display: "flex",
                alignItems: "flex-end",
                // "&> button": {
                //     display: "flex",
                //     flexDirection: "row",
                //     justifyContent: "center",
                //     alignItems: "center",
                //     backgroundColor: "#9747FF",
                //     padding: "12px 23px",
                //     textTransform: "capitalize",
                //     borderRadius: 60,
                //     color: "#FFFFFF",
                //     fontSize: 12,
                //     fontWeight: 700,
                //     fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                //     "&> .imgCalendar": {
                //         paddingLeft: 6,
                //     },
                //     [theme.breakpoints.down("md")]: {
                //         marginTop: 16,
                //     },
                // },
            },

            "& .myIdo": {
                [theme.breakpoints.down("md")]: {
                    marginTop: 20,
                },
                "&>p": {
                    color: "white",
                    fontSize: "14px",
                    fontWeight: 400,
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    opacity: 0.3,
                    marginBottom: "25px",
                    [theme.breakpoints.down("xs")]: {
                        fontSize: 20,
                    },
                },
                "&>div>button": {
                    background: "#38383D",
                    height: "42px",
                    lineHeight: "42px",
                    padding: "0 16px",
                    color: "#AEAEAE",
                    border: "none",
                    borderRadius: "20px",
                    fontSize: 16,
                    marginRight: 6,
                    cursor: "pointer",

                    [theme.breakpoints.down("xs")]: {
                        fontSize: 14,
                    },
                },
                "&>div .active": {
                    background: "#3232DC",
                    color: "white",
                    padding: "0 20px",
                },
            },
        },
        filterProjects: {
            display: "flex",
            flexDirection: "row",
            "& .status": {
                display: "flex",
                flexDirection: "column",
                [theme.breakpoints.down("xs")]: {
                    width: "48%",
                    justifyContent: "space-between",
                    marginRight: 4,
                },
            },
            "& .status>p": {
                color: "white",
                fontSize: "14px",
                fontWeight: 400,
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                opacity: 0.3,
                marginBottom: "10px",
                [theme.breakpoints.down("xs")]: {
                    fontSize: 14,
                },
            },
            "& .status>div>button": {
                background: "none",
                width: 180,
                height: 40,
                padding: "0 16px",
                color: "#FFFFFF",
                border: "2px solid rgba(255, 255, 255, 0.15)",
                borderRadius: 10,
                fontSize: 14,
                cursor: "pointer",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginRight: 12,
                [theme.breakpoints.down("xs")]: {
                    fontSize: 14,
                },
            },
            "& .status>div .active": {
                background: "#3232DC",
                color: "white",
                padding: "0 20px",
            },
        },
        filterStatus: {
            background: "none",
            width: 180,
            height: 40,
            // padding: "0 16px",
            color: "#FFFFFF",
            border: "2px solid rgba(255, 255, 255, 0.15)",
            borderRadius: 10,
            fontSize: 14,
            cursor: "pointer",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginRight: 12,
            position: "relative",
            [theme.breakpoints.down("xs")]: {
                fontSize: 14,
                width: 166,
            },
            "&> p": {
                paddingLeft: 16,
                paddingRight: 94,
                // [theme.breakpoints.down("xs")]: {
                //     paddingLeft: 14,
                // },
            },
            "&> img": {
                padding: "0 16px",
            },
            "&> .btnStatusBox": {
                zIndex: 1,
                position: "absolute",
                top: "100%",
                left: "0",
                right: "0",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                padding: "20px 0px 20px 20px",
                backgroundColor: "rgba(57, 58, 85, 1)",
                borderRadius: 10,
                "& .MuiCheckbox-root": {
                    color: "rgba(255, 255, 255, 0.8)",
                },
                "&> p": {
                    fontWeight: 400,
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    padding: "8px",
                },
                "&> p:hover": {
                    color: "#9747FF",
                },
                "&> p:last-child": {
                    marginBottom: 10,
                },
            },
        },
        filterStatusMyIdo: {
            background: "none",
            width: 180,
            height: 40,
            // padding: "0 16px",
            color: "#FFFFFF",
            border: "2px solid rgba(255, 255, 255, 0.15)",
            borderRadius: 10,
            fontSize: 14,
            cursor: "pointer",
            display: "flex",
            flexDirection: "row",
            // justifyContent: "space-between",
            alignItems: "center",
            marginRight: 12,
            position: "relative",
            [theme.breakpoints.down("xs")]: {
                fontSize: 14,
                width: 166,
            },
            "&> p": {
                minWidth: 134,
                paddingLeft: 16,
                [theme.breakpoints.down("xs")]: {
                    minWidth: 120,
                },
            },
            "&> img": {
                padding: "0 16px",
            },
            "&> .btnStatusBox": {
                zIndex: 1,
                position: "absolute",
                top: "100%",
                left: "0",
                right: "0",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                padding: "20px 0px 20px 20px",
                backgroundColor: "rgba(57, 58, 85, 1)",
                borderRadius: 10,
                "& .MuiCheckbox-root": {
                    color: "rgba(255, 255, 255, 0.8)",
                },
                "& .MuiSvgIcon-root": {
                    // "& .colorCheckBoxStatus": {
                    //     color: "#9747FF",
                    // },
                    // background: "#9747FF",
                    // filter: "invert(33%) sepia(40%) saturate(5792%) hue-rotate(251deg) brightness(101%) contrast(101%)",
                },
                "&> p": {
                    fontWeight: 400,
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    padding: "8px",
                },
                "&> p:hover": {
                    color: "#9747FF",
                },
                "&> p:last-child": {
                    marginBottom: 10,
                },
            },
        },
        card: {
            marginTop: 60,
            marginLeft: 0,
            display: "flex",
            justifyContent: "center",
            gridColumnGap: 30,
            gridRowGap: 40,
            alignItems: "center",
            flexWrap: "wrap",
            ["@media (max-width:1334px)"]: {
                // width: "80%",
            },
            ["@media (max-width:870px)"]: {
                gridColumnGap: 0,
                gridRowGap: 40,
            },
            ["@media (max-width:600px)"]: {
                gridColumnGap: 0,
                gridRowGap: 40,
            },
            ["@media (max-width:480px)"]: {
                gridColumnGap: 0,
                gridRowGap: 40,
            },
            ["@media (max-width:400px)"]: {
                gridColumnGap: 0,
                gridRowGap: 40,
            },
            [theme.breakpoints.down("md")]: {},
            [theme.breakpoints.down("sm")]: {},
        },
        paginationIDO: {
            marginTop: 40,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            [theme.breakpoints.down("sm")]: {
                marginTop: 30,
                marginBottom: 40,
            },
            "&> button": {
                width: 50,
                height: 50,
                marginRight: 30,
                cursor: "pointer",
                background: "none",
                color: "white",
                opacity: 0.5,
                fontSize: 16,
                border: "2px solid rgba(255, 255, 255, 0.1)",
                [theme.breakpoints.down("xs")]: {
                    width: 26,
                    height: 26,
                    marginRight: 20,
                },
            },
            "&> .active": {
                backgroundColor: "#0F69E1",
            },
            "&> p": {
                color: "white",
                opacity: 0.5,
                fontSize: 20,
                marginRight: 30,
                [theme.breakpoints.down("xs")]: {
                    fontSize: 20,
                    marginRight: 20,
                },
            },
        },
        borderTop: {
            height: 4,
            background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
        },
        selectStatusIDO: {
            display: "flex",
            width: "100%",
            padding: "0 16px",
            height: 40,
            justifyContent: "space-between",
            alignItems: "center",
            "&> p": {
                paddingLeft: 10,
                paddingRight: 94,
                [theme.breakpoints.down("xs")]: {
                    paddingLeft: 0,
                },
            },
            "&> img": {
                padding: 0,
            },
        },
        colorLabelMyIdo: {
            color: "#9747FF",
        },
    };
});

export default useStyles;
