import { Button } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import moment from "moment";
import { memo, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { AppContext } from "../../AppContext";
import Countdown from "../../components/Base/Countdown";
import ModalLoadingHomePage from "../StakingPools/ModalLoading/ModalLoadingHomePage";
import { myTicket, snapshotAccount } from "./Api";
import TierAccountBuyTicket from "./BuyTicket/TierAccountBuyTicket";
import CalculatingAccount from "./Calculating";
import DistributionTicket from "./Distribution";
import ModalStakeMore from "./Modal/ModalLegend";
import useStyles from "./styleParticipateIdo";
import UpcomingStarter from "./UpcomingStarter";

type TParams = {
    id: "string";
};

function ParticipateIdo(props: any) {
    const styles = useStyles();
    const { t } = useTranslation();

    const status = useSelector((state: any) => state.statusIdoPool.message);
    const dataIdoDetail = useSelector((state: any) => state.idoDetail.data);
    const { id } = useParams<TParams>();
    const tierAccount = useSelector((state: any) => state.userTier.data.name);

    // const account = localStorage.getItem("user");

    const { account } = useWeb3React();
    const [dataSnapshot, setDataSnapshot] = useState<any>();
    const [dataYourTicket, setDataYourTicket] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const { openConnectWallet, setOpenConnectWallet, connectWalletLoading } =
        useContext(AppContext);

    const convertTimeSnapshot = moment(dataIdoDetail?.start_staking_snapshot).format(
        "MM/DD/YYYY HH:mm:ss"
    );

    useEffect(() => {
        if (!account) return;
        if (
            dataIdoDetail?.state === "SNAPSHOTTED" ||
            dataIdoDetail?.state === "CALCULATED" ||
            dataIdoDetail?.state === "ENDED"
        ) {
            snapshotAccount(id, account).then((res) => {
                setDataSnapshot(res);
            });
            myTicket(id).then((res) => {
                setDataYourTicket(res);
            });
        }
    }, [account, dataIdoDetail?.state, id]);

    const renderUpcoming = useCallback(() => {
        return (
            <div className={styles.snapshotIn}>
                <p>{t("ProjectDetails.snapshotIn")}</p>
                <Countdown startDate={new Date(convertTimeSnapshot)} />
                {tierAccount === "starter" ? <UpcomingStarter /> : <ModalStakeMore />}
            </div>
        );
    }, [convertTimeSnapshot, styles.snapshotIn, t, tierAccount]);

    const renderBuyTicket = useCallback(() => {
        if (dataIdoDetail?.state === "SNAPSHOTTED") {
            return <TierAccountBuyTicket dataSnapshot={dataSnapshot} />;
        } else if (dataIdoDetail?.state !== "SNAPSHOTTED") {
            return <CalculatingAccount dataSnapshot={dataSnapshot} />;
        }
    }, [dataIdoDetail?.state, dataSnapshot]);

    const renderSnapshotIn = useCallback(() => {
        switch (status) {
            case "Upcoming":
                return renderUpcoming();
            case "Calculation":
                return <CalculatingAccount dataSnapshot={dataSnapshot} />;
            case "Buy Ticket":
                return dataSnapshot?.tier === "Super Rookie" ||
                    dataSnapshot?.tier === "Legend" ||
                    dataSnapshot?.aprove_whitelist === true ? (
                    renderBuyTicket()
                ) : (
                    <CalculatingAccount dataSnapshot={dataSnapshot} />
                );
            case "Distribution":
                return dataSnapshot?.tier === "Super Rookie" ||
                    dataSnapshot?.tier === "Legend" ||
                    dataSnapshot?.aprove_whitelist === true ? (
                    <DistributionTicket data={dataYourTicket} />
                ) : (
                    <CalculatingAccount dataSnapshot={dataSnapshot} />
                );
            case "Ended":
                return dataSnapshot?.tier === "Super Rookie" ||
                    dataSnapshot?.tier === "Legend" ||
                    dataSnapshot?.aprove_whitelist === true ? (
                    <DistributionTicket data={dataYourTicket} />
                ) : (
                    <CalculatingAccount dataSnapshot={dataSnapshot} />
                );
            default:
                return null;
        }
    }, [dataSnapshot, dataYourTicket, renderBuyTicket, renderUpcoming, status]);

    return (
        <div className={styles.wrapper}>
            <p>{t("ProjectDetails.participateIdo")}</p>
            {account ? (
                renderSnapshotIn()
            ) : (
                <div className="connect">
                    <p>{t("ProjectDetails.connectWalletToParticipate")}</p>
                    <Button
                        onClick={() => {
                            !openConnectWallet &&
                                !connectWalletLoading &&
                                setOpenConnectWallet &&
                                setOpenConnectWallet(true);
                        }}
                        className="btnConnect"
                    >
                        {t("ProjectDetails.connectWallet")}
                    </Button>
                </div>
            )}
            <ModalLoadingHomePage
                open={loading}
                onClose={() => {
                    setLoading(false);
                }}
            />
        </div>
    );
}

export default memo(ParticipateIdo);
