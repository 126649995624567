import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { take_decimal_number, take_decimal_numberFormatThousands } from "../../../constants";
import { convertFromWei } from "../../../services/web3";
import useCommonStyle from "../../../styles/CommonStyle";
import Button from "../Button";
import useStyles from "./style";

const closeIcon = "/images/icons/close.svg";

const ModalClaim = (props: any) => {
    const styles = useStyles();
    const commonStyles = useCommonStyle();
    const { t } = useTranslation();

    const { open, onConfirm, onClose, pendingReward, checkClick } = props;

    return (
        <>
            {pendingReward && (
                <Dialog
                    open={open}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    className={commonStyles.modal + " " + styles.modalStake}
                >
                    <div className="modal-content">
                        <DialogTitle id="alert-dialog-slide-title" className="modal-content__head">
                            <img src={closeIcon} alt="" onClick={onClose} className="btn-close" />
                            <div className="title">{t("staking.modalClaim.title")}</div>
                        </DialogTitle>
                        <DialogContent className="modal-content__body">
                            <div className="token-type">
                                <div>{t("staking.modalClaim.token")}</div>
                                <div className="token-detail">
                                    <div>CODE</div>
                                </div>
                            </div>
                            <div className="token-type">
                                <div>{t("staking.modalClaim.rewardAmount")}</div>
                                <div className="token-detail">
                                    <div>
                                        {take_decimal_numberFormatThousands(
                                            convertFromWei(pendingReward || "0")
                                        ) || 0}
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions className="modal-content__foot">
                            <Button
                                text={t("staking.modalClaim.title")}
                                onClick={onConfirm}
                                backgroundColor="#FFD058"
                                disabled={checkClick}
                            />
                        </DialogActions>
                    </div>
                </Dialog>
            )}
        </>
    );
};

export default ModalClaim;
