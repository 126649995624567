import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useStyles from "./style";

type CountDownProps = {
    startDate?: Date;
    getCurrentDateRealTime?: (currentDate: Date) => void;
    status?: string;
};

const CountdownIdoItems: React.FC<CountDownProps> = ({
    startDate,
    getCurrentDateRealTime,
    status,
}: CountDownProps) => {
    const styles = useStyles();
    const { t } = useTranslation();
    // const status = useSelector((state: any) => state.statusIdoPool.message);
    // const [second, setSecond] = useState("0");
    const [minute, setMinute] = useState("0");
    const [hour, setHour] = useState("0");
    const [day, setDay] = useState("0");

    const emitCurrentDate = useCallback(
        (now: Date) => {
            getCurrentDateRealTime && getCurrentDateRealTime(now);
        },
        [getCurrentDateRealTime]
    );

    useEffect(() => {
        let countDownInterval = undefined as any;

        if (startDate && startDate >= new Date()) {
            const second = 1000,
                minute = second * 60,
                hour = minute * 60,
                day = hour * 24;

            let countDown = startDate.getTime();
            countDownInterval = setInterval(function () {
                let now = new Date().getTime(),
                    distance = countDown - now;

                if (distance >= 0) {
                    const currentDay = Math.floor(distance / day);
                    const currentHour = Math.floor((distance % day) / hour);
                    const currentMinute = Math.ceil((distance % hour) / minute);
                    // const currentSecond = Math.floor((distance % minute) / second);

                    setDay(currentDay < 10 && currentDay > 0 ? `${currentDay}` : `${currentDay}`);
                    setHour(
                        currentHour < 10 && currentHour > 0 ? `${currentHour}` : `${currentHour}`
                    );
                    setMinute(
                        currentMinute < 10 && currentMinute > 0
                            ? `0${currentMinute}`
                            : `${currentMinute}`
                    );
                    // setSecond(currentSecond < 10 ? `0${currentSecond}` : `${currentSecond}`);

                    emitCurrentDate(new Date(now));
                }

                //do something later when date is reached
                if (distance <= 0 && countDownInterval) {
                    clearInterval(countDownInterval);
                    window.location.reload();
                }
                //seconds
            }, 0);
        } else {
            // setSecond("0");
            setMinute("0");
            setHour("0");
            setDay("0");
        }

        return () => {
            clearInterval(countDownInterval);
        };
    }, [emitCurrentDate, startDate]);

    if (!startDate) {
        return <></>;
    }

    const renderStatus = () => {
        return (
            <>
                {(() => {
                    switch (status) {
                        case "1": {
                            return <p>{t("IdoHomePage.snapshotOpenIn")}</p>;
                        }
                        case "2": {
                            return <p>{t("IdoHomePage.buyticketOpenIn")}</p>;
                        }
                        case "3": {
                            return <p>{t("IdoHomePage.buyticketCloseIn")}</p>;
                        }
                        case "4": {
                            return <p>{t("IdoHomePage.distributionEndIn")}</p>;
                        }
                        case "5": {
                            return <p style={{ opacity: 0 }}>""</p>;
                        }
                        default:
                            return null;
                    }
                })()}
            </>
        );
    };

    const renderCountDown = () => {
        return (
            <>
                {(() => {
                    switch (status) {
                        case "1":
                            return (
                                <div className={styles.statusProject}>
                                    {renderStatus()}
                                    <div className="Upcoming">
                                        <img
                                            src={`/images/home-page/iconClockUpcoming.svg`}
                                            alt=""
                                        />
                                        <div>
                                            {day === "0" ? <p></p> : <p>{day}d</p>}
                                            {day === "0" ? (
                                                <p></p>
                                            ) : (
                                                <p className="middleDayHours">:</p>
                                            )}
                                            <p>{hour}h</p>
                                            <p className="middleHoursMinutes">:</p>
                                            <p>{minute}m</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        case "2":
                            return (
                                <div className={styles.statusProject}>
                                    {renderStatus()}
                                    <div className="Calculation">
                                        <img
                                            src={`/images/home-page/iconClockCalculation.svg`}
                                            alt=""
                                        />
                                        <div>
                                            {day === "0" ? <p></p> : <p>{day}d</p>}
                                            {day === "0" ? (
                                                <p></p>
                                            ) : (
                                                <p className="middleDayHours">:</p>
                                            )}
                                            <p>{hour}h</p>
                                            <p className="middleHoursMinutes">:</p>
                                            <p>{minute}m</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        case "3":
                            return (
                                <div className={styles.statusProject}>
                                    {renderStatus()}
                                    <div>
                                        <img
                                            src={`/images/home-page/iconClockBuyticket.svg`}
                                            alt=""
                                        />
                                        <div className="BuyTicket">
                                            {day === "0" ? <p></p> : <p>{day}d</p>}
                                            {day === "0" ? (
                                                <p></p>
                                            ) : (
                                                <p className="middleDayHours">:</p>
                                            )}
                                            <p>{hour}h</p>
                                            <p className="middleHoursMinutes">:</p>
                                            <p>{minute}m</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        case "4":
                            return (
                                <div className={styles.statusProject}>
                                    {renderStatus()}
                                    <div className="Distribution">
                                        <img
                                            src={`/images/home-page/iconClockDistribution.svg`}
                                            alt=""
                                        />
                                        <div>
                                            {day === "0" ? <p></p> : <p>{day}d</p>}
                                            {day === "0" ? (
                                                <p></p>
                                            ) : (
                                                <p className="middleDayHours">:</p>
                                            )}
                                            <p>{hour}h</p>
                                            <p className="middleHoursMinutes">:</p>
                                            <p>{minute}m</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        case "5":
                            return (
                                <div className={styles.statusProject}>
                                    {renderStatus()}
                                    <div className="Ended">
                                        <img src={`/images/home-page/iconClockEnded.svg`} alt="" />
                                        <div className="Ended">{t("IdoHomePage.ended")}</div>
                                    </div>
                                </div>
                            );
                        default:
                            return null;
                    }
                })()}
            </>
        );
    };

    return (
        // <div id="countdown">
        //     <ul className={styles.listCountDown}>
        //         <li className={styles.countdownPart + " number"}>
        //             <span id="days">{day}</span>
        //             <span className={styles.countdownInfo}>Days</span>
        //         </li>
        //         <li className={styles.colon}>:</li>
        //         <li className={styles.countdownPart + " number"}>
        //             <span id="hours">{hour}</span>
        //             <span className={styles.countdownInfo}>Hours</span>
        //         </li>
        //         <li className={styles.colon}>:</li>
        //         <li className={styles.countdownPart + " number"}>
        //             <span id="minutes">{minute}</span>
        //             <Hidden xsDown>
        //                 <span className={styles.countdownInfo}>Minutes</span>
        //             </Hidden>
        //             <Hidden smUp>
        //                 <span className={styles.countdownInfo}>Mins</span>
        //             </Hidden>
        //         </li>
        //         {/* <li className={styles.colon}>:</li> */}
        //         {/* <li className={styles.countdownPart + " number"}>
        //             <span id="seconds">{second}</span>
        //             <Hidden xsDown>
        //                 <span className={styles.countdownInfo}>Seconds</span>
        //             </Hidden>
        //             <Hidden smUp>
        //                 <span className={styles.countdownInfo}>Secs</span>
        //             </Hidden>
        //         </li> */}
        //     </ul>
        // </div>
        <>{renderCountDown()}</>
    );
};

export default CountdownIdoItems;
