import { Button } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StakingContext } from "..";
import { AppContext } from "../../../AppContext";
import { take_decimal_numberFormatThousands } from "../../../constants";
import { PoolLPI } from "../../../store/constants/pool-noreward";

export interface InfoToken {
    token_decimal: number | string;
    token_symbol: string;
    total_staked: number | string;
}

type Props = {
    pool: PoolLPI;
    handleOpenModalStake: (
        tokenAddress: string,
        contractAddress: string,
        isReward: boolean
    ) => void;
    handleOpenModalUnStake: (
        tokenAddress: string,
        contractAddress: string,
        isReward: boolean
    ) => void;
    handleSetInfoToken: (data: InfoToken) => void;
    handleOpenMessageWaring: () => void;
};

export default function PoolLP({
    pool,
    handleOpenModalStake,
    handleOpenModalUnStake,
    handleSetInfoToken,
    handleOpenMessageWaring,
}: Props) {
    const { account } = useWeb3React();
    const { t } = useTranslation();
    const { openConnectWallet, setOpenConnectWallet, connectWalletLoading } =
        useContext(AppContext);
    const { token_symbol, total_staked, token_address, token_decimal, conversion_rate, image } =
        pool;
    const poolNoRewardCODE = useSelector((state: any) => state.poolNoRewardCODE.data);
    const { checkNetwork } = useContext(StakingContext);

    return (
        <div>
            <div className="stakeToken">
                <div className="box">
                    <div className="col1">
                        <img src={image || "/images/icons/defaultLP.svg"} alt="" />
                        <div className="infoLP">
                            <p>
                                {t("staking.poolLP.textStakeUniswap")} {token_symbol}
                            </p>
                            <a
                                className="viewContract"
                                href={`${process.env.REACT_APP_POLSCAN_BASE_URL}/address/${token_address}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <span>{t("staking.poolLP.btnViewContract")}</span>
                                <img src="/images/icons/redirect.svg" alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="col2">
                        <p>{t("staking.poolLP.textConversionRate")}</p>
                        <span>
                            1 CODE ~ {conversion_rate} {token_symbol}{" "}
                        </span>
                    </div>
                    <div className="col3">
                        <p>{t("staking.poolLP.textYourStake")} </p>
                        <span>
                            {take_decimal_numberFormatThousands(
                                new BigNumber(total_staked)
                                    .dividedBy(10 ** Number(token_decimal))
                                    .toNumber() || 0
                            ) +
                                " " +
                                token_symbol}
                        </span>
                    </div>
                    {account ? (
                        <div className="actionsStakeToken">
                            <Button
                                variant="contained"
                                className="btnStake"
                                onClick={() => {
                                    handleOpenModalStake(
                                        token_address, // tokenAddress
                                        poolNoRewardCODE?.contract_address as string,
                                        false
                                    );
                                    handleSetInfoToken({
                                        token_symbol,
                                        token_decimal,
                                        total_staked,
                                    }); //symbol get Balance
                                }}
                                disabled={
                                    !checkNetwork ||
                                    localStorage.getItem("walletconnect-name") === "Kaikas"
                                }
                            >
                                {t("staking.poolLP.btnStake")}
                            </Button>
                            <Button
                                variant="contained"
                                className="btnUnStake"
                                onClick={() => {
                                    if (Number(total_staked) <= 0) {
                                        handleOpenMessageWaring();
                                        return;
                                    }
                                    handleOpenModalUnStake(
                                        token_address, // tokenAddress
                                        poolNoRewardCODE?.contract_address as string,
                                        false
                                    );
                                    handleSetInfoToken({
                                        token_symbol,
                                        token_decimal,
                                        total_staked,
                                    }); // symbol get Balance
                                }}
                                disabled={
                                    !checkNetwork ||
                                    localStorage.getItem("walletconnect-name") === "Kaikas"
                                }
                            >
                                {t("staking.poolLP.btnUnstake")}
                            </Button>
                        </div>
                    ) : (
                        <div className="actionConnectLP">
                            <Button
                                variant="contained"
                                className="btn"
                                onClick={() => {
                                    !openConnectWallet &&
                                        !connectWalletLoading &&
                                        setOpenConnectWallet &&
                                        setOpenConnectWallet(true);
                                }}
                            >
                                {t("staking.poolLP.textConnect")}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
