import {
    Button,
    Chip,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import _ from "lodash";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { StakingContext } from "..";
import KlayDaoReward_ABI from "../../../abi/KlayDaoReward.json";
import { AppContext } from "../../../AppContext";
import { take_decimal_number, take_decimal_numberFormatThousands } from "../../../constants";
import { convertTextAddress } from "../../../constants/formatData";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { convertFromWei, getContractConnect } from "../../../services/web3";
import { scrollIntoView } from "../../../utils/formatNumber";
import { getTransactionReward } from "../../../utils/stakingAPI";
import { convertColumnAmount } from "../../AccountV2/PointHistory";
import PaginationCustom from "../../PaginationCustom";
import ModalLoadingHomePage from "../ModalLoading/ModalLoadingHomePage";
import ModalWithdraw from "../ModalWithdraw";
import { InfoToken } from "../PoolLP";
import { useStyles } from "./style";
import { POLYGON_CHAIN_ID } from "../../../constants/network";

type Props = {
    handleOpenModalStake: (
        tokenAddress: string,
        contractAddress: string,
        isReward: boolean
    ) => void;
    handleOpenModalUnStake: (
        tokenAddress: string,
        contractAddress: string,
        isReward: boolean
    ) => void;
    handleSetInfoToken: (data: InfoToken) => void;
    handleOpenModalClaimReward: (value: string) => void;
    minMaxStake: any;
    reloadDataClaim: any;
    reloadDataReward: any;
    handleOpenMessageWaring: () => void;
};

export default function RewardStaking({
    handleOpenModalStake,
    handleOpenModalUnStake,
    handleSetInfoToken,
    handleOpenModalClaimReward,
    minMaxStake,
    reloadDataClaim,
    reloadDataReward,
    handleOpenMessageWaring,
}: Props) {
    const { account } = useWeb3React();
    const { appChainID } = useTypedSelector((state) => state.appNetwork).data;
    const poolNoRewardCODE = useSelector((state: any) => state.poolNoRewardCODE.data);
    const { openConnectWallet, setOpenConnectWallet, connectWalletLoading } =
        useContext(AppContext);
    const styles = useStyles();
    const { t } = useTranslation();
    const [userInfo, setUserInfo] = useState<any>({});
    const [reward, setReward] = useState<string>("");
    const [withdrawFeeAPR, setWithdrawFeeAPR] = useState<string>("");
    const [maxTotalStake, setMaxTotalStake] = useState<string>("");
    const [currentTotalStake, setCurrentTotalStake] = useState<string>("");
    const [transactions, setTransactions] = useState<any[]>([]);
    const [countTransaction, setCountTransaction] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [APR, setAPR] = useState();
    const { checkNetwork } = useContext(StakingContext);
    const transactionRef = useRef<any>();
    const [openModalWithdraw, setOpenModalWithdraw] = useState<boolean>(false);

    const getUserInfo = async () => {
        const contract = await getContractConnect(
            KlayDaoReward_ABI,
            process.env.REACT_APP_REWARD as string,
            appChainID
        );
        try {
            const result = await contract?.methods.userInfo(account).call();
            setUserInfo(result);
        } catch (error) {}
    };

    const getWithdrawFeeAPR = async (abi: any, contractAddress: string, userLogin: string) => {
        const contract = await getContractConnect(abi, contractAddress, POLYGON_CHAIN_ID);
        try {
            const withdrawFeeAPR = await contract?.methods.calculateAPR(userLogin).call();
            const APR = await contract?.methods.fixedAPR().call();
            console.log({ withdrawFeeAPR, APR });
            setWithdrawFeeAPR(withdrawFeeAPR);
            setAPR(APR);
        } catch (error: any) {
            console.log({ error });
            toast.error(error);
        }
    };

    const getCurrentTotalStake = async () => {
        const contract = await getContractConnect(
            KlayDaoReward_ABI,
            process.env.REACT_APP_REWARD as string,
            POLYGON_CHAIN_ID
        );
        try {
            const currentTotalStake = await contract?.methods.currentTotalStaked().call();
            setCurrentTotalStake(currentTotalStake);
        } catch (error: any) {
            toast.error(error);
        }
    };

    const getMaxTotalStake = async () => {
        const contract = await getContractConnect(
            KlayDaoReward_ABI,
            process.env.REACT_APP_REWARD as string,
            POLYGON_CHAIN_ID
        );
        try {
            const maxTotalStake = await contract?.methods.maxTotalStake().call();
            setMaxTotalStake(maxTotalStake);
        } catch (error) {}
    };

    const getRewardClaim = async () => {
        const contract = await getContractConnect(
            KlayDaoReward_ABI,
            process.env.REACT_APP_REWARD as string,
            appChainID
        );
        try {
            const reward = await contract?.methods.pendingReward(account).call();
            setReward(reward as string);
        } catch (error) {}
    };

    useEffect(() => {
        if (!account) return;
        const intervalId = setInterval(async () => {
            await getRewardClaim();
        }, 5000);
        return () => clearInterval(intervalId);
    }, [account]);

    useEffect(() => {
        if (!account) return;
        (async () => {
            console.log("Run here");
            await getRewardClaim();
            await getWithdrawFeeAPR(
                KlayDaoReward_ABI,
                process.env.REACT_APP_REWARD as string,
                account
            );
            await getUserInfo();
        })();
    }, [account, reloadDataReward, appChainID, reloadDataClaim]);

    useEffect(() => {
        (async () => {
            await getMaxTotalStake();
            await getCurrentTotalStake();
        })();
    }, [reloadDataReward, appChainID]);

    useEffect(() => {
        if (!account || !localStorage.getItem("investor_access_token")) return;
        (async () => {
            setLoading(true);
            const [res, error] = await getTransactionReward({
                page_size: 5,
                page_number: pageNumber,
            });
            if (res) {
                setTransactions(res.data);
                setCountTransaction(res.meta?.count);
            }
            if (error) toast.error(error);
            setTimeout(() => {
                setLoading(false);
            }, 500);
        })();
    }, [
        account,
        localStorage.getItem("investor_access_token"),
        pageNumber,
        reloadDataReward,
        reloadDataClaim,
    ]);

    return (
        <>
            {userInfo && minMaxStake && (
                <div className={styles.container}>
                    <div className={styles.infoReward}>
                        <div className="boxInfo">
                            <p className="title">{t("staking.reward.title")}</p>
                            <div className="content">
                                <div className={styles.rowTop}>
                                    <div className="header">
                                        <div className="titleRowTop">
                                            <div className="circle"></div>
                                            <div className="text">
                                                <h4>{t("staking.reward.info.name")}</h4>
                                                <span>{t("staking.reward.info.text")}</span>
                                            </div>
                                        </div>
                                        <div className="">
                                            <Chip label={t("staking.reward.info.btnFlexedRate")} />
                                            <Chip label={t("staking.reward.info.btnFlexible")} />
                                        </div>
                                    </div>
                                    <Grid container className="info">
                                        <Grid item xs={12} md={6} lg={3}>
                                            <div className="item">
                                                <p>{t("staking.reward.info.yourStake")}</p>
                                                <div className="infoItem">
                                                    <p>
                                                        {account
                                                            ? take_decimal_numberFormatThousands(
                                                                  convertFromWei(
                                                                      userInfo?.amount || "0"
                                                                  )
                                                              ) || 0
                                                            : 0}{" "}
                                                        CODE
                                                    </p>
                                                    <span>
                                                        $
                                                        {account
                                                            ? take_decimal_numberFormatThousands(
                                                                  convertFromWei(
                                                                      userInfo?.amount || "0"
                                                                  )
                                                              ) || 0
                                                            : 0}{" "}
                                                    </span>
                                                </div>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} md={6} lg={2}>
                                            <div className="item">
                                                <p>{t("staking.reward.info.APR")}</p>
                                                <div className="infoItem">
                                                    <p>{Number(APR) / 100 || 0}%</p>
                                                    <span></span>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={2}>
                                            <div className="item">
                                                <p>{t("staking.reward.info.claimableReward")}</p>
                                                <div className="infoItem">
                                                    <p>
                                                        {account
                                                            ? take_decimal_numberFormatThousands(
                                                                  convertFromWei(reward as string)
                                                              ) || 0
                                                            : 0}{" "}
                                                        CODE
                                                    </p>
                                                    <span>
                                                        $
                                                        {account
                                                            ? take_decimal_numberFormatThousands(
                                                                  convertFromWei(reward as string)
                                                              ) || 0
                                                            : 0}{" "}
                                                    </span>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={2}>
                                            <div className="item">
                                                <p>{t("staking.reward.info.withdrawalFee")}</p>
                                                <div
                                                    className="infoItem"
                                                    onClick={() => setOpenModalWithdraw(true)}
                                                >
                                                    <p className="withdrawalFee">
                                                        {account
                                                            ? Number(withdrawFeeAPR) > 0
                                                                ? `${take_decimal_number(
                                                                      Number(withdrawFeeAPR) /
                                                                          10 ** 12,
                                                                      2
                                                                  )}%`
                                                                : "0%"
                                                            : "0%"}
                                                    </p>
                                                    <span></span>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={2}>
                                            <div className="item">
                                                <p>{t("staking.reward.totalClaimedReward")}</p>
                                                <div className="infoItem">
                                                    <p>
                                                        {account
                                                            ? take_decimal_numberFormatThousands(
                                                                  convertFromWei(
                                                                      userInfo?.totalRewardEarned ||
                                                                          "0"
                                                                  )
                                                              ) || 0
                                                            : 0}{" "}
                                                        CODE
                                                    </p>
                                                    <span></span>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>

                                <div className={styles.rowBottom}>
                                    <div className="col1">
                                        <div className="item">
                                            <span>
                                                {t("staking.reward.info.totalPoolAmountPool")}
                                            </span>
                                            <span>
                                                {take_decimal_numberFormatThousands(
                                                    convertFromWei(currentTotalStake || "0")
                                                )}
                                                /
                                                {take_decimal_numberFormatThousands(
                                                    convertFromWei(maxTotalStake || "0")
                                                )}
                                                (
                                                {Number(
                                                    ((Number(
                                                        take_decimal_number(
                                                            convertFromWei(
                                                                currentTotalStake || "0"
                                                            ),
                                                            0
                                                        )
                                                    ) || 0) *
                                                        100) /
                                                        Number(
                                                            take_decimal_number(
                                                                convertFromWei(
                                                                    maxTotalStake || "0"
                                                                ),
                                                                0
                                                            ) || 1
                                                        )
                                                ).toFixed(2)}
                                                %)
                                            </span>
                                        </div>
                                        <div className="item">
                                            <span>{t("staking.reward.info.minIndividual")}</span>
                                            <span>
                                                {take_decimal_numberFormatThousands(
                                                    minMaxStake?.minStake || 0
                                                )}{" "}
                                                CODE / {t("staking.reward.info.textPerson")}
                                            </span>
                                        </div>
                                        <div className="item">
                                            <span>{t("staking.reward.info.maxIndividual")}</span>
                                            <span>
                                                {take_decimal_numberFormatThousands(
                                                    minMaxStake?.maxStake || 0
                                                )}{" "}
                                                CODE / {t("staking.reward.info.textPerson")}
                                            </span>
                                        </div>
                                    </div>

                                    {account ? (
                                        <div className="col2">
                                            <Button
                                                variant="contained"
                                                className="btnStake"
                                                onClick={() => {
                                                    handleOpenModalStake(
                                                        poolNoRewardCODE?.token_address as string,
                                                        process.env.REACT_APP_REWARD as string,
                                                        true
                                                    );
                                                    handleSetInfoToken({
                                                        token_symbol: poolNoRewardCODE?.stake_type,
                                                        token_decimal: "18",
                                                        total_staked: convertFromWei(
                                                            userInfo?.amount || "0"
                                                        ),
                                                    });
                                                }}
                                                disabled={
                                                    Number(
                                                        convertFromWei(currentTotalStake || "0")
                                                    ) -
                                                        Number(
                                                            convertFromWei(maxTotalStake || "0")
                                                        ) ===
                                                        0 || !checkNetwork
                                                }
                                            >
                                                {t("staking.reward.info.btnStake")}
                                            </Button>
                                            {withdrawFeeAPR && (
                                                <Button
                                                    variant="contained"
                                                    className="btnUnStake"
                                                    onClick={() => {
                                                        if (
                                                            Number(
                                                                convertFromWei(
                                                                    userInfo?.amount || "0"
                                                                )
                                                            ) <= 0
                                                        ) {
                                                            handleOpenMessageWaring();
                                                            return;
                                                        }
                                                        handleOpenModalUnStake(
                                                            process.env
                                                                .REACT_APP_CODE_TOKEN as string,
                                                            process.env.REACT_APP_REWARD as string,
                                                            true
                                                        );
                                                        handleSetInfoToken({
                                                            token_symbol:
                                                                poolNoRewardCODE?.stake_type,
                                                            token_decimal: "18",
                                                            total_staked: convertFromWei(
                                                                userInfo?.amount || "0"
                                                            ),
                                                        });
                                                    }}
                                                    disabled={!checkNetwork}
                                                >
                                                    {t("staking.reward.info.btnUnstake")}
                                                </Button>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="col2Connect">
                                            <Button
                                                variant="contained"
                                                className="btnConnect"
                                                onClick={() => {
                                                    !openConnectWallet &&
                                                        !connectWalletLoading &&
                                                        setOpenConnectWallet &&
                                                        setOpenConnectWallet(true);
                                                }}
                                            >
                                                {t("staking.reward.textConnect")}
                                            </Button>
                                        </div>
                                    )}
                                    {account && (
                                        <div className="col3">
                                            <div className="">
                                                <Button
                                                    variant="contained"
                                                    className="btnClaim"
                                                    onClick={() =>
                                                        handleOpenModalClaimReward(reward)
                                                    }
                                                    disabled={Number(reward) <= 0 || !checkNetwork}
                                                >
                                                    {t("staking.reward.info.btnClaimReward")}
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.transitionHistory} ref={transactionRef}>
                        <div className="boxTransitionHistory">
                            <p className="titleTrans">
                                {t("staking.reward.transactionHistory.title")}
                            </p>
                            {account ? (
                                <TableContainer>
                                    <Table className="tableTransition" aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ paddingLeft: 25 }}>
                                                    {t(
                                                        "staking.reward.transactionHistory.table.col1"
                                                    )}
                                                </TableCell>

                                                <TableCell>
                                                    {t(
                                                        "staking.reward.transactionHistory.table.col2"
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {t(
                                                        "staking.reward.transactionHistory.table.col3"
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {t(
                                                        "staking.reward.transactionHistory.table.col4"
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Array.isArray(transactions) &&
                                                transactions.length > 0 &&
                                                transactions?.map(
                                                    (transaction: any, index: number) => (
                                                        <TableRow key={index}>
                                                            <TableCell component="th" scope="row">
                                                                {_.capitalize(transaction.action)}
                                                            </TableCell>
                                                            <TableCell>
                                                                {convertColumnAmount(
                                                                    Number(transaction?.amount) /
                                                                        10 **
                                                                            Number(
                                                                                transaction
                                                                                    ?.pool_reward
                                                                                    ?.token_decimal ||
                                                                                    18
                                                                            ) || 1,
                                                                    6
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {moment(
                                                                    new Date(
                                                                        transaction?.created_at
                                                                    )
                                                                ).format("MMM D, YYYY HH:mm:ss")}
                                                            </TableCell>
                                                            <TableCell>
                                                                {convertTextAddress(
                                                                    6,
                                                                    4,
                                                                    transaction.txhash
                                                                )}
                                                                {transaction.txhash && (
                                                                    <a
                                                                        href={`${process.env.REACT_APP_POLSCAN_BASE_URL}/tx/${transaction?.txhash}`}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        <img
                                                                            src="/images/icons/redirect.svg"
                                                                            alt="txhash"
                                                                            style={{
                                                                                marginLeft: 8,
                                                                            }}
                                                                        />
                                                                    </a>
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                        </TableBody>
                                    </Table>
                                    {Array.isArray(transactions) && !(transactions.length > 0) && (
                                        <div className="noDataTransaction">
                                            <div className="box">
                                                <img
                                                    src="/images/account_v1/no_transaction.svg"
                                                    alt=""
                                                />
                                                <p>
                                                    {t(
                                                        "staking.reward.transactionHistory.noDataTransaction"
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </TableContainer>
                            ) : (
                                <div className="connectWallet">
                                    <p style={{}} className="textConnectWallet">
                                        {t("staking.reward.textConnectShowHistory")}
                                    </p>
                                    <Button
                                        variant="contained"
                                        className="btnConnect"
                                        onClick={() => {
                                            !openConnectWallet &&
                                                !connectWalletLoading &&
                                                setOpenConnectWallet &&
                                                setOpenConnectWallet(true);
                                        }}
                                    >
                                        {t("staking.tier.textConnectWallet")}
                                    </Button>
                                </div>
                            )}
                            {Array.isArray(transactions) &&
                            transactions.length > 0 &&
                            countTransaction &&
                            countTransaction > 5 ? (
                                <div className="paginationPools">
                                    <PaginationCustom
                                        count={Math.ceil(Number(countTransaction) / 5) || 1}
                                        onChange={(page: number) => {
                                            scrollIntoView(transactionRef);
                                            setPageNumber(page - 1);
                                        }}
                                        currentPage={pageNumber + 1}
                                    />
                                </div>
                            ) : (
                                <div style={{ paddingBottom: 40 }}></div>
                            )}
                        </div>
                    </div>
                    <ModalLoadingHomePage
                        open={loading}
                        onClose={() => {
                            setLoading(false);
                        }}
                    />
                    <ModalWithdraw
                        open={openModalWithdraw}
                        onClose={() => setOpenModalWithdraw(false)}
                    />
                </div>
            )}
        </>
    );
}
