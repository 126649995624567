import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        desc: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 25,
            paddingTop: 21,
            borderTop: "1px solid #2B2C45",
            flexWrap: "wrap",

            [theme.breakpoints.down("xs")]: {
                flexDirection: "column",
                alignItems: "flex-start",
                marginTop: 16,
            },
            "& p": {
                fontSize: 14,
                fontWeight: 400,
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                color: "white",
                opacity: 0.5,
                whiteSpace: "nowrap",
            },
            "&> div": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                "&> .btnSuperRookie": {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "rgba(151, 71, 255, 0.2)",
                    borderRadius: 70,
                    border: "none",
                    padding: "8px 20px",
                    margin: "0px 8px",
                    width: 145,
                    textTransform: "capitalize",
                    fontSize: 12,
                    [theme.breakpoints.down("sm")]: {
                        margin: "10px 4px",
                    },
                    [theme.breakpoints.down("xs")]: {
                        margin: "10px 0",
                    },
                    "& p": {
                        color: "#fff",
                        opacity: 1,
                    },
                },
                "&> p": {
                    fontSize: 14,
                    fontWeight: 400,
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    color: "white",
                    opacity: 0.5,
                    [theme.breakpoints.down("xs")]: {
                        marginRight: 8,
                    },
                },
            },
        },
        super: {
            marginLeft: 10,
        },
        btn: {
            marginTop: 100,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "0px 20px",
            [theme.breakpoints.down("xs")]: {
                flexDirection: "column",
                marginTop: 35,
            },
            "&> .btnStakeMore": {
                color: "white",
                fontSize: 16,
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                padding: "10px 0px",
                background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                borderRadius: 90,
                textTransform: "capitalize",
                width: "50%",
                [theme.breakpoints.down("xs")]: {
                    fontSize: 13,
                    padding: "12px 75px",
                    width: "100%",
                },
            },
            "&> .btnApplyWhitelist": {
                color: "#9747FF",
                fontSize: 16,
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                padding: "10px 0px",
                background: "none",
                border: "2px solid #9747FF",
                borderRadius: 90,
                textTransform: "capitalize",
                width: "50%",
                [theme.breakpoints.down("xs")]: {
                    marginTop: "10px",
                    fontSize: 13,
                    border: "2px solid #2DDBFF",
                    color: "#2DDBFF",
                    width: "100%",
                },
            },
        },
        applyPending: {
            minHeight: 300,
            display: "flex",
            justifyContent: "flex-end",
            fontSize: 18,
            flexDirection: "column",
            "&> div": {
                display: "flex",
                flexDirection: "row",
                color: "white",
                marginBottom: 16,
                "&> img": {
                    marginRight: 10,
                    opacity: 0.3,
                },
                "&> p": {
                    opacity: 0.3,
                    fontSize: 18,
                    fontWeight: 600,
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    marginBottom: -8,
                    [theme.breakpoints.down("xs")]: {
                        fontSize: 14,
                    },
                },
                [theme.breakpoints.down("xs")]: {
                    marginTop: 16,
                    borderTop: "1px solid #2B2C45",
                    paddingTop: 20,
                    minHeight: 30,
                },
            },
            "&> .btnView": {
                background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                borderRadius: 90,
                color: "#FFFFFF",
                width: 340,
                padding: "15px",
                textTransform: "capitalize",
                fontSize: 16,
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                [theme.breakpoints.down("xs")]: {
                    fontSize: 14,
                    width: "100%",
                },
            },
        },
        applySuccessView: {
            minHeight: 300,
            display: "flex",
            justifyContent: "flex-end",
            fontSize: 18,
            flexDirection: "column",
            "&> div": {
                display: "flex",
                flexDirection: "row",
                color: "#3C974B",
                marginBottom: 16,
                "&> img": {
                    marginRight: 10,
                },
            },
            "&> .btnView": {
                background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                borderRadius: 90,
                color: "#FFFFFF",
                width: 340,
                padding: "15px",
                textTransform: "capitalize",
                fontSize: 16,
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
            },
        },

        applyErrorView: {
            minHeight: 300,
            display: "flex",
            justifyContent: "flex-end",
            fontSize: 18,
            flexDirection: "column",
            "&> div": {
                display: "flex",
                flexDirection: "row",
                color: "#FF3333",
                marginBottom: 16,
                "&> img": {
                    marginRight: 10,
                },
            },
            "&> .btnView": {
                background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                borderRadius: 90,
                color: "#FFFFFF",
                width: 340,
                padding: "15px",
                textTransform: "capitalize",
                fontSize: 16,
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
            },
        },
        condition: {
            borderTop: "1px solid #2B2C45",
            paddingTop: 24,
            marginTop: 26,
            display: "flex",
            flexDirection: "column",
            fontSize: 14,
            color: "white",
            opacity: 0.5,
            fontWeight: 400,
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            "&> p:first-child": {
                marginBottom: 10,
            },
        },
    };
});

export default useStyles;
