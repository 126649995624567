import { Redirect, Route, RouteProps } from "react-router-dom";

interface PrivateRouteProps extends RouteProps {
    // tslint:disable-next-line:no-any
    component: any;
}

const PrivateRoute = (props: PrivateRouteProps) => {
    const { component: Component, ...rest } = props;

    const account = localStorage.getItem("user");

    return (
        <Route
            {...rest}
            render={(props) => (account ? <Component {...props} /> : <Redirect to={"/"} />)}
        />
    );
};

export default PrivateRoute;
