import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        modalStake: {
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            "& .MuiPaper-root": {
                [theme.breakpoints.down("xs")]: {
                    margin: "18px",
                },
            },
            "& .modal-content": {
                backgroundColor: "#242640",
                width: "500px",
                position: "relative",
                padding: "70px 54px 87px 54px",
                [theme.breakpoints.down("xs")]: {
                    padding: "40px 20px 20px",
                },
                borderRadius: "20px",
                color: "#FFFFFF",
            },
            "& .modal-content__head": {
                paddingBottom: "25px",
                borderBottom: "1px solid rgba(255, 255, 255, .1)",
                "& .btn-close": {
                    top: 25,
                    right: 25,
                    [theme.breakpoints.down("xs")]: {
                        top: 16,
                        right: 16,
                        width: 24,
                        height: 24,
                    },
                },
            },
            "& .modal-content__head .title": {
                fontSize: "32px",
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                textAlign: "left",
                [theme.breakpoints.down("xs")]: {
                    fontSize: 20,
                },
            },
            "& .modal-content__body": {
                padding: "0",
                margin: "20px 0",

                "& .token-type": {
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "14px",
                    alignItems: "center",
                    marginBottom: "14px",
                    color: "rgba(255,255,255,0.5)",
                },
                "& .token-detail": {
                    display: "flex",
                    alignItems: "center",
                    color: "rgba(255,255,255,1)",
                },
                "& .token-logo": {
                    width: "22px",
                    height: "22px",
                    borderRadius: "28px",
                    margin: "0 5px",
                },
            },
            "& .modal-content__foot": {
                padding: "40px 0px 0px",
                [theme.breakpoints.down("xs")]: {
                    padding: "17px 0px 0px",
                    flexDirection: "row !important",
                },
                "& button": {
                    height: "54px",
                    width: "100%",
                    padding: "12px 30px",
                    margin: 0,
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                    background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                    borderRadius: "90px",
                    color: "#FFFFFF",
                    [theme.breakpoints.down("xs")]: {
                        height: "40px",
                        fontSize: 12,
                    },
                },
            },
        },
    };
});

export default useStyles;
