import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DISCORD_LINK_URL, TELEGRAM_LINK_URL, TWITTER_LINK_URL } from "../../../../../constants";
import { convertLang } from "../../../../IDOHomePage/CardItem";
import useStyles from "./style";

export default function TableApplyWhitelistMobile(props: any) {
    const styles = useStyles();
    const IDODetail = useSelector((state: any) => state.idoDetail?.data);
    // const { t } = useTranslation();

    const { t } = useTranslation();
    return (
        <div>
            <div className={styles.container}>
                <div className="account">
                    <p>{t("ProjectDetails.modalApplyWhitelist.account")}</p>
                    <p>{t("ProjectDetails.modalApplyWhitelist.officialTwitter")}</p>
                </div>
                <div className="cyball">
                    <div>
                        <img src="images/home-page/iconCodeDao.svg" alt="codeDAO" />
                        <p>{convertLang(IDODetail?.project_name, i18n.language)}</p>
                    </div>
                    <a
                        style={{ color: "#2DDBFF" }}
                        href={`${TWITTER_LINK_URL}${IDODetail?.ido_twitter}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {IDODetail?.ido_twitter ? "@" + IDODetail?.ido_twitter : "-"}
                    </a>
                </div>
                <div className="cyball">
                    <div>
                        <img src="images/home-page/iconCodeDao.svg" alt="codeDAO" />
                        <p>CODEDAO</p>
                    </div>
                    <a
                        style={{ color: "#2DDBFF" }}
                        href={`${TWITTER_LINK_URL}${IDODetail?.platform_twitter}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {IDODetail?.platform_twitter ? "@" + IDODetail?.platform_twitter : "-"}
                    </a>
                </div>
            </div>
            <div className={styles.container}>
                <div className="account">
                    <p>{t("ProjectDetails.modalApplyWhitelist.account")}</p>
                    <p>{t("ProjectDetails.modalApplyWhitelist.discordChannel")}</p>
                </div>
                <div className="cyball">
                    <div>
                        <img src="images/home-page/iconCodeDao.svg" alt="codeDAO" />
                        <p>{convertLang(IDODetail?.project_name, i18n.language)}</p>
                    </div>
                    <p>
                        <a
                            style={{ color: "#2DDBFF" }}
                            href={IDODetail?.ido_discord_link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {IDODetail?.ido_discord_link ? IDODetail?.ido_discord_link : "-"}
                        </a>
                    </p>
                </div>
                <div className="cyball">
                    <div>
                        <img src="images/home-page/iconCodeDao.svg" alt="codeDAO" />
                        <p>CODEDAO</p>
                    </div>
                    <a
                        style={{ color: "#2DDBFF" }}
                        href={IDODetail?.platform_discord_link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {IDODetail?.platform_discord_link ? IDODetail?.platform_discord_link : "-"}
                    </a>
                </div>
            </div>
            <div className={styles.container}>
                <div className="account">
                    <p>{t("ProjectDetails.modalApplyWhitelist.account")}</p>
                    <p>{t("ProjectDetails.modalApplyWhitelist.telegramGroup")}</p>
                </div>
                <div className="cyball">
                    <div>
                        <img src="images/home-page/iconCodeDao.svg" alt="codeDAO" />
                        <p>{convertLang(IDODetail?.project_name, i18n.language)}</p>
                    </div>
                    <a
                        style={{ color: "#2DDBFF" }}
                        href={`${TELEGRAM_LINK_URL}${IDODetail?.ido_telegram}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {IDODetail?.ido_telegram ? "telegram/" + IDODetail?.ido_telegram : "-"}
                    </a>
                </div>
                <div className="cyball">
                    <div>
                        <img src="images/home-page/iconCodeDao.svg" alt="codeDAO" />
                        <p>CODEDAO</p>
                    </div>
                    <a
                        style={{ color: "#2DDBFF" }}
                        href={`${TELEGRAM_LINK_URL}${IDODetail?.platform_telegram}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {IDODetail?.platform_telegram
                            ? "telegram/" + IDODetail?.platform_telegram
                            : "-"}
                    </a>
                </div>
            </div>
        </div>
    );
}
