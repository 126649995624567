import { Snackbar } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { createContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import erc20ABI from "../../abi/Erc20.json";
import KlayDaoReward_ABI from "../../abi/KlayDaoReward.json";
import KlayDaoTiers from "../../abi/KlayDaoTiers.json";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import { ETH_CHAIN_ID, POLYGON_CHAIN_ID } from "../../constants/network";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { convertFromWei, getContractConnect, getWeb3Instance } from "../../services/web3";
import { getPoolNoRewardCODE, getPoolNoRewardLP } from "../../store/actions/pool-noreward";
import { getUserTier } from "../../store/actions/sota-tiers";
import { poolNoReward } from "../../store/constants/pool-noreward";
import { TRANSACTION_TIMEOUT } from "../ProjectDetail/BuyTicket/TierAccountBuyTicket/TierStarterAndRookie";
import StakingHeader from "./Header";
import ModalClaim from "./ModalClaim";
import ModalConfirm from "./ModalConfirm";
import ModalLoading from "./ModalLoading";
import ModalLoadingHomePage from "./ModalLoading/ModalLoadingHomePage";
import ModalStake from "./ModalStake";
import ModalUnstake from "./ModalUnstake";
import { InfoToken } from "./PoolLP";
import useStyles from "./style";
import TabStaking from "./Tab";
import Web3 from "web3";

export const StakingContext = createContext({ checkNetwork: false });

const StakingPools = (props: any) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // Start Staking Logic
    const { appChainID, walletChainID } = useTypedSelector((state: any) => state.appNetwork).data;
    const { account: connectedAccount } = useWeb3React();
    const { data: tokenBalance = {} } = useSelector((state: any) => state.balance);
    const { networkID: networkWallet } = useSelector((state: any) => state.networkWallet);

    // Filter
    const [amount, setAmount] = useState<BigNumber | number | string | undefined>();

    // Transaction
    const [openModalStake, setOpenModalStake] = useState(false);
    const [openModalUnstake, setOpenModalUnstake] = useState(false);
    const [openModalClaimReward, setOpenModalClaimReward] = useState(false);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [checkClick, setCheckClick] = useState(false);
    const [messageWaring, setMessageWaring] = useState(false);
    const [transactionPending, setTransactionPending] = useState(false);
    const [messageWaringNetwork, setMessageWaringNetwork] = useState(false);
    const [checkNetwork, setCheckNetwork] = useState<boolean>(false);

    const [textError, setTextError] = useState<string>("");

    const [isReward, setIsReward] = useState(false);
    const [infoToken, setInfoToken] = useState<InfoToken>();
    const [tokenAddress, setTokenAddress] = useState<string>();
    const [contractAddress, setContractAddress] = useState<string>();
    const [loadingTransaction, setLoadingTransaction] = useState<boolean>(false);
    const [pendingReward, setPendingReward] = useState<string>("");
    const [reloadDataNoReward, setReloadDataNoReward] = useState<any>("0");
    const [reloadDataReward, setReloadDataReward] = useState<any>("0");
    const [reloadDataClaim, setReloadDataClaim] = useState<any>("0");
    const [minMaxStake, setMinMaxStake] = useState<any>({});
    const [balanceCODE, setBalanceCODE] = useState("");
    const [tokenLocal, setTokenLocal] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const { page_number } = useSelector((state: any) => state.poolNoRewardLP);
    const poolNoRewardCODE = useSelector((state: any) => state.poolNoRewardCODE.data);

    useEffect(() => {
        document.title = t("CodeDAO | Staking");
    }, [t]);

    //get balance code
    useEffect(() => {
        if (!connectedAccount) {
            setBalanceCODE("0");
            return;
        }
        (async () => {
            if (poolNoRewardCODE.token_address) {
                const tokenContract = await getContractConnect(
                    erc20ABI,
                    poolNoRewardCODE.token_address,
                    appChainID
                );
                if (tokenContract) {
                    const balanceOf = await tokenContract.methods
                        .balanceOf(connectedAccount)
                        .call();
                    setBalanceCODE(convertFromWei(balanceOf));
                }
            }
        })();
    }, [appChainID, connectedAccount, poolNoRewardCODE]);

    const wrongChain = useMemo(() => {
        return appChainID !== ETH_CHAIN_ID || appChainID !== walletChainID;
    }, [appChainID, walletChainID]);

    const resetAmount = () => {
        setAmount(undefined);
    };

    const closeModalStake = () => {
        setOpenModalStake(false);
        resetAmount();
    };

    const closeModalUnStake = () => {
        setOpenModalUnstake(false);
        resetAmount();
    };

    const handleApprove = async (
        abi: any = erc20ABI,
        contractAddress: string,
        spenderAddress: string,
        account: string
    ): Promise<{ time_out: boolean }> => {
        return new Promise(async (resolve, reject) => {
            let timeOut;
            try {
                const contract = await getContractConnect(
                    abi,
                    contractAddress,
                    appChainID,
                    "",
                    "write"
                );
                let payload;
                if (appChainID === POLYGON_CHAIN_ID) {
                    const web3Instance = await getWeb3Instance();
                    const gasPrice = await web3Instance?.eth.getGasPrice();
                    const gas_multiplier = process.env.GAS_MULTIPLIER ? parseFloat(process.env.GAS_MULTIPLIER) : 1.1;
                    if (gasPrice) {
                        payload = {
                            gasPrice: Web3.utils.toHex(Math.floor(gas_multiplier * Number(gasPrice))),
                            from: account,
                        }
                    } else {
                        payload = {
                            from: account,
                            gas: 8000000,
                        }
                    }
                   
                } else {
                    payload = {
                        from: account,
                        gas: 8000000,
                    }
                }
                await contract?.methods
                    .approve(spenderAddress, "0x" + "f".repeat(64))
                    .send({
                        ...payload
                    })
                    .on("transactionHash", () => {
                        timeOut = setTimeout(() => {
                            resolve({
                                time_out: true,
                            });
                        }, TRANSACTION_TIMEOUT);
                    });
            } catch (error) {
                setLoadingTransaction(false);
                reject({
                    time_out: true,
                });
            }
            clearTimeout(timeOut);
            resolve({
                time_out: false,
            });
        });
    };

    const handleStake = async (
        abi: any,
        contractAddress: string,
        tokenAddress: string,
        amount: string,
        loginUser: string
    ): Promise<{ time_out_stake: boolean }> => {
        return new Promise(async (resolve, reject) => {
            let timeOut;

            const contract = await getContractConnect(
                abi,
                contractAddress,
                appChainID,
                "",
                "write"
            );
            let payload;
            if (appChainID === POLYGON_CHAIN_ID) {
                const web3Instance = await getWeb3Instance();
                const gasPrice = await web3Instance?.eth.getGasPrice();
                const gas_multiplier = process.env.GAS_MULTIPLIER ? parseFloat(process.env.GAS_MULTIPLIER) : 1.1;
                if (gasPrice) {
                    payload = {
                        from: loginUser,
                        gasPrice: Web3.utils.toHex(Math.floor(gas_multiplier * Number(gasPrice))),
                    }
                } else {
                    payload = {
                        from: loginUser,
                        gas: 8000000,
                    }
                }
                
            } else {
                payload = {
                    from: loginUser,
                    gas: 8000000,
                }
            }
            console.log({payload})
            try {
                isReward
                    ? await contract?.methods
                          .deposit(amount)
                          .send({ ...payload })
                          .on("transactionHash", () => {
                              timeOut = setTimeout(() => {
                                  resolve({
                                      time_out_stake: true,
                                  });
                              }, TRANSACTION_TIMEOUT);
                          })
                    : await contract?.methods
                          .deposit(tokenAddress, amount)
                          .send({ ...payload })
                          .on("transactionHash", () => {
                              timeOut = setTimeout(() => {
                                  resolve({
                                      time_out_stake: true,
                                  });
                              }, TRANSACTION_TIMEOUT);
                          });
            } catch (e: any) {
                console.log({ e });
                reject(e);
            }
            clearTimeout(timeOut);
            resolve({
                time_out_stake: false,
            });
        });
    };

    const handleUnStake = async (
        abi: any,
        contractAddress: string,
        tokenAddress: string,
        amount: string,
        loginUser: string
    ): Promise<{ time_out_unstake: boolean }> => {
        return new Promise(async (resolve, reject) => {
            let timeOut;
            const contract = await getContractConnect(
                abi,
                contractAddress,
                appChainID,
                "",
                "write"
            );
            try {
                let payload;
                if (appChainID === POLYGON_CHAIN_ID) {
                    const web3Instance = await getWeb3Instance();
                    const gasPrice = await web3Instance?.eth.getGasPrice();
                    const gas_multiplier = process.env.GAS_MULTIPLIER ? parseFloat(process.env.GAS_MULTIPLIER) : 1.1;
                    if (gasPrice) {
                        payload = {
                            from: loginUser,
                            gasPrice: Web3.utils.toHex(Math.floor(gas_multiplier * Number(gasPrice)))
                        }
                    } else {
                        payload = {
                            from: loginUser,
                            gas: 8000000,
                        }
                    }
                   
                } else {
                    payload = {
                        from: loginUser,
                        gas: 8000000,
                    }
                }
                isReward
                    ? await contract?.methods
                          .withdraw(amount)
                          .send({ ...payload })
                          .on("transactionHash", () => {
                              timeOut = setTimeout(() => {
                                  resolve({
                                      time_out_unstake: true,
                                  });
                              }, TRANSACTION_TIMEOUT);
                          })
                    : await contract?.methods
                          .withdraw(amount, tokenAddress)
                          .send({ ...payload })
                          .on("transactionHash", () => {
                              timeOut = setTimeout(() => {
                                  resolve({
                                      time_out_unstake: true,
                                  });
                              }, TRANSACTION_TIMEOUT);
                          });
            } catch (e: any) {
                reject(e);
            }
            clearTimeout(timeOut);
            resolve({
                time_out_unstake: false,
            });
        });
    };

    const handleClaim = async (
        abi: any,
        contractAddress: string,
        account: string
    ): Promise<{ time_out_claim: boolean }> => {
        return new Promise(async (resolve, reject) => {
            let timeOut;
            const contract = await getContractConnect(
                abi,
                contractAddress,
                appChainID,
                "",
                "write"
            );
            try {
                let payload;
                if (appChainID === POLYGON_CHAIN_ID) {
                    const web3Instance = await getWeb3Instance();
                    const gasPrice = await web3Instance?.eth.getGasPrice();
                    const gas_multiplier = process.env.GAS_MULTIPLIER ? parseFloat(process.env.GAS_MULTIPLIER) : 1.1;
                    if (gasPrice) {
                        payload = {
                            gasPrice: Web3.utils.toHex(Math.floor(gas_multiplier * Number(gasPrice))),
                            from: account,
                        }
                    } else {
                        payload = {
                            from: account,
                            gas: 8000000,
                        }
                    }
                   
                } else {
                    payload = {
                        from: account,
                        gas: 8000000,
                    }
                }
                await contract?.methods
                    .claim()
                    .send({...payload})
                    .on("transactionHash", () => {
                        timeOut = setTimeout(() => {
                            resolve({
                                time_out_claim: true,
                            });
                        }, TRANSACTION_TIMEOUT);
                    });
            } catch (e: any) {
                reject(e);
            }
            clearTimeout(timeOut);
            resolve({
                time_out_claim: false,
            });
        });
    };

    const isApprove = async (
        abi: any = erc20ABI,
        contractAddress: string,
        spenderAddress: string,
        userLogin: string
    ) => {
        const contract = await getContractConnect(abi, contractAddress, appChainID);
        try {
            setLoadingTransaction(true);
            return await contract?.methods.allowance(userLogin, spenderAddress).call();
        } catch (error) {
            setLoadingTransaction(false);
        }
        setLoadingTransaction(false);
    };

    const handleStakeToken = async ({ tokenAddress, spenderApprove, token_decimal }: any) => {
        const web3 = await getWeb3Instance();
        if (!connectedAccount || !amount) return;
        resetAmount();
        let totalApprove = await isApprove(
            erc20ABI,
            tokenAddress,
            spenderApprove,
            connectedAccount
        );
        closeModalStake();
        setOpenModalConfirm(false);
        if (
            Number(amount) > Number(convertFromWei(totalApprove)) ||
            Number(convertFromWei(totalApprove)) <= 0 ||
            !totalApprove
        ) {
            try {
                const { time_out } = await handleApprove(
                    erc20ABI,
                    tokenAddress,
                    spenderApprove,
                    connectedAccount
                );
                if (!time_out) {
                    try {
                        isReward ? setReloadDataReward("0") : setReloadDataNoReward("0");

                        const { time_out_stake } = await handleStake(
                            isReward ? KlayDaoReward_ABI : KlayDaoTiers,
                            isReward
                                ? (process.env.REACT_APP_REWARD as string)
                                : poolNoRewardCODE?.contract_address,
                            tokenAddress,
                            new BigNumber(amount)
                                .multipliedBy(10 ** Number(token_decimal))
                                .toString(),
                            connectedAccount
                        );
                        if (!time_out_stake) {
                            toast.success("Stake Success");
                            isReward ? setReloadDataReward("") : setReloadDataNoReward("");
                        } else {
                            setTransactionPending(true);
                        }
                    } catch (error: any) {
                        console.log({ error });
                        toast.error(`Stake Failed`);
                    }
                } else {
                    setTransactionPending(true);
                }
            } catch (error: any) {
                toast.error(`Approve Failed`);
            }
            setLoadingTransaction(false);
            closeModalStake();
        } else {
            try {
                setLoadingTransaction(true);
                closeModalStake();
                isReward ? setReloadDataReward("0") : setReloadDataNoReward("0");
                const { time_out_stake } = await handleStake(
                    isReward ? KlayDaoReward_ABI : KlayDaoTiers,
                    isReward
                        ? (process.env.REACT_APP_REWARD as string)
                        : (poolNoRewardCODE?.contract_address as string),
                    tokenAddress,
                    new BigNumber(amount).multipliedBy(10 ** Number(token_decimal)).toString(),
                    connectedAccount
                );
                if (!time_out_stake) {
                    toast.success("Stake Success");
                    isReward ? setReloadDataReward("") : setReloadDataNoReward("");
                } else {
                    setTransactionPending(true);
                }
            } catch (error: any) {
                toast.error(`Stake Failed`);
            }
            setLoadingTransaction(false);
            closeModalStake();
        }
    };

    const handleUnStakeToken = async ({ tokenAddress, token_decimal, isReward }: any) => {
        if (!connectedAccount || !amount) return;
        if (isReward) {
            if (Number(infoToken.total_staked) === Number(amount)) {
                setTextError("");
            } else if (
                (Number(minMaxStake.minStake) || 0) >
                Number(infoToken.total_staked) - Number(amount)
            ) {
                setTextError(t("staking.modalUnStake.warningMinStake"));
                return;
            }
        }
        try {
            resetAmount();
            setLoadingTransaction(true);
            closeModalUnStake();
            isReward ? setReloadDataReward("0") : setReloadDataNoReward("0");
            const { time_out_unstake } = await handleUnStake(
                isReward ? KlayDaoReward_ABI : KlayDaoTiers,
                isReward
                    ? (process.env.REACT_APP_REWARD as string)
                    : (poolNoRewardCODE?.contract_address as string),
                tokenAddress,
                new BigNumber(amount).multipliedBy(10 ** Number(token_decimal)).toString(),
                connectedAccount
            );
            if (!time_out_unstake) {
                toast.success("Unstake Success");
                isReward ? setReloadDataReward("") : setReloadDataNoReward("");
            } else {
                setTransactionPending(true);
            }
        } catch (error: any) {
            toast.error(`Please refresh the page as the network is currently busy.`);
        }
        setLoadingTransaction(false);
        closeModalUnStake();
    };

    const handleClaimToken = async (abi: any, contractAddress: string, account: string) => {
        try {
            setOpenModalClaimReward(false);
            setLoadingTransaction(true);
            setReloadDataClaim("0");
            const { time_out_claim } = await handleClaim(abi, contractAddress, account);
            if (!time_out_claim) {
                toast.success("Claim Successful");
                setReloadDataClaim("");
            } else {
                setTransactionPending(true);
            }
        } catch (error: any) {
            toast.error(`Claim Failed`);
        }
        setLoadingTransaction(false);
        setCheckClick(false);
    };

    const getMinMaxStake = async () => {
        const contract = await getContractConnect(
            KlayDaoReward_ABI,
            process.env.REACT_APP_REWARD as string,
            process.env.REACT_APP_POLYGON_CHAIN_ID as string
        );
        if (contract) {
            const maxStake = await contract?.methods.maxStake().call();
            const minStake = await contract?.methods.minStake().call();
            setMinMaxStake({
                maxStake: convertFromWei(maxStake),
                minStake: convertFromWei(minStake),
            });
        }
    };

    const handleCloseMessage = () => {
        setMessageWaring(false);
    };

    const handleCloseMsgTxPending = () => {
        setTransactionPending(false);
    };

    const handleOpenMessageWaring = () => {
        setMessageWaring(true);
    };

    useEffect(() => {
        (async () => {
            await getMinMaxStake();
        })();
    }, [appChainID]);

    window.addEventListener("storage", () =>
        setTokenLocal(localStorage.getItem("investor_access_token"))
    );

    useEffect(() => {
        if (!connectedAccount || !localStorage.getItem("investor_access_token")) {
            dispatch({ type: poolNoReward.POOL_NOREWARD_CODE_LOADING });
            return;
        }
        (async () => {
            setLoading(true);
            await dispatch(getUserTier());
            await dispatch(getPoolNoRewardCODE());
            setLoading(false);
        })();
    }, [
        dispatch,
        connectedAccount,
        localStorage.getItem("investor_access_token"),
        reloadDataNoReward,
    ]);

    useEffect(() => {
        if (!connectedAccount || !localStorage.getItem("investor_access_token")) {
            dispatch({ type: poolNoReward.POOL_NOREWARD_LP_LOADING });
            return;
        }

        dispatch(getPoolNoRewardLP());
    }, [
        dispatch,
        page_number,
        connectedAccount,
        localStorage.getItem("investor_access_token"),
        reloadDataNoReward,
    ]);

    useEffect(() => {
        if (networkWallet && networkWallet.toString() !== process.env.REACT_APP_POLYGON_CHAIN_ID) {
            setCheckNetwork(false);
            setMessageWaringNetwork(true);
        } else {
            setCheckNetwork(true);
            setMessageWaringNetwork(false);
        }
    }, [networkWallet, connectedAccount]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // End Staking Logic
    return (
        <DefaultLayout>
            <StakingContext.Provider value={{ checkNetwork: checkNetwork }}>
                <div
                    className={styles.wrapper}
                    style={{
                        marginBottom: 100,
                        paddingTop: 100,
                        position: "relative",
                        zIndex: 100,
                    }}
                >
                    <div
                    // className="content"
                    >
                        <StakingHeader />
                        <TabStaking
                            handleOpenModalStake={(
                                tokenAddress: string,
                                contractAddress: string,
                                isReward: boolean
                            ) => {
                                setOpenModalStake(true);
                                setTokenAddress(tokenAddress);
                                setIsReward(isReward);
                                setContractAddress(contractAddress);
                            }}
                            handleSetInfoToken={(data: InfoToken) => setInfoToken(data)}
                            handleOpenModalUnStake={(
                                tokenAddress: string,
                                contractAddress: string,
                                isReward: boolean
                            ) => {
                                setOpenModalUnstake(true);
                                setTokenAddress(tokenAddress);
                                setIsReward(isReward);
                                setContractAddress(contractAddress);
                            }}
                            handleOpenModalClaimReward={(value: string) => {
                                setOpenModalClaimReward(true);
                                setPendingReward(value);
                            }}
                            reloadDataClaim={reloadDataClaim}
                            reloadDataReward={reloadDataReward}
                            balanceCODE={balanceCODE}
                            minMaxStake={minMaxStake}
                            handleOpenMessageWaring={handleOpenMessageWaring}
                        />
                    </div>

                    {openModalStake && (
                        <ModalStake
                            open={openModalStake}
                            setAmount={(amount: number) => setAmount(amount)}
                            amount={amount}
                            onClose={closeModalStake}
                            infoToken={infoToken}
                            onConfirm={handleStakeToken}
                            tokenAddress={tokenAddress}
                            isReward={isReward}
                            contractAddress={contractAddress}
                            handleOpenModalConfirm={() => {
                                setOpenModalConfirm(true);
                                setOpenModalStake(false);
                            }}
                            min={minMaxStake?.minStake}
                            max={minMaxStake?.maxStake}
                        />
                    )}

                    {openModalUnstake && (
                        <ModalUnstake
                            open={openModalUnstake}
                            setAmount={(amount: number) => setAmount(amount)}
                            amount={amount}
                            onClose={() => {
                                setOpenModalUnstake(false);
                                resetAmount();
                            }}
                            tokenDetails={tokenBalance}
                            infoToken={infoToken}
                            onConfirm={handleUnStakeToken}
                            tokenAddress={tokenAddress}
                            isReward={isReward}
                            contractAddress={contractAddress}
                            textError={textError}
                            setTextError={(value) => setTextError(value)}
                        />
                    )}

                    <ModalLoading
                        open={loadingTransaction}
                        onClose={() => {
                            setLoadingTransaction(false);
                        }}
                    />
                    <ModalClaim
                        open={openModalClaimReward}
                        onClose={() => setOpenModalClaimReward(false)}
                        onConfirm={() => {
                            handleClaimToken(
                                KlayDaoReward_ABI,
                                process.env.REACT_APP_REWARD as string,
                                connectedAccount as string
                            );
                            setCheckClick(true);
                        }}
                        pendingReward={pendingReward}
                        checkClick={checkClick}
                    />
                    <ModalConfirm
                        open={openModalConfirm}
                        onConfirm={handleStakeToken}
                        onClose={() => {
                            setOpenModalConfirm(false);
                            resetAmount();
                        }}
                        tokenAddress={tokenAddress}
                        isReward={isReward}
                    />
                    <Snackbar
                        open={messageWaring}
                        onClose={handleCloseMessage}
                        autoHideDuration={3000}
                        message={
                            <div className="messageWaring">
                                <img
                                    src="/images/home-page/iconI.svg"
                                    alt="iconWarning"
                                    className="iconWarning"
                                />
                                <div className="content">
                                    <p>{t("staking.modalWarningUnStake.title")}</p>
                                    <span>{t("staking.modalWarningUnStake.content")}</span>
                                </div>
                                <img
                                    src="/images/icons/close_toast.svg"
                                    alt="iconClose"
                                    className="iconClose"
                                    onClick={handleCloseMessage}
                                />
                            </div>
                        }
                    />

                    <Snackbar
                        open={transactionPending}
                        onClose={handleCloseMsgTxPending}
                        autoHideDuration={20000}
                        message={
                            <div className="messageWaring">
                                <img
                                    src="/images/home-page/iconI.svg"
                                    alt="iconWarning"
                                    className="iconWarning"
                                />
                                <div className="content">
                                    <p>Transaction Pending</p>
                                    <span>Please wait for transaction success and reload page</span>
                                </div>
                                <img
                                    src="/images/icons/close_toast.svg"
                                    alt="iconClose"
                                    className="iconClose"
                                    onClick={handleCloseMsgTxPending}
                                />
                            </div>
                        }
                    />

                    <Snackbar
                        open={
                            !checkNetwork || localStorage.getItem("walletconnect-name") === "Kaikas"
                        }
                        // onClose={() => setMessageWaringNetwork(false)}
                        className="messageWaringNetwork"
                        message={
                            <div className="messageWaring">
                                <img
                                    src="/images/icons/icon_prohibited.svg"
                                    alt="iconWarning"
                                    className="iconWarning"
                                />
                                <div className="content">
                                    <p>{t("staking.modalWarningNetwork.title")}</p>
                                    <span>{t("staking.modalWarningNetwork.content")}</span>
                                </div>
                                {/* <img
                                    src="/images/icons/close_toast.svg"
                                    alt="iconClose"
                                    className="iconClose"
                                    onClick={() => setMessageWaringNetwork(false)}
                                /> */}
                            </div>
                        }
                    />
                    <ModalLoadingHomePage
                        open={loading}
                        onClose={() => {
                            setLoading(false);
                        }}
                    />
                </div>
            </StakingContext.Provider>
        </DefaultLayout>
    );
};

export default withRouter(StakingPools);
