import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { take_decimal_numberFormatThousands } from "../../../../constants";
import { useStyles } from "./style";
type Props = {};

interface Row {
    tier: string;
    stater: string;
    super_rookey: string;
    legend: string;
}

export default function TierSystem({}: Props) {
    const styles = useStyles();
    const { t } = useTranslation();
    const tierInfo = useSelector((state: any) => state.tiers.data);

    const rows = [
        {
            tier: `${t("staking.tier.table.row1.tier")}`,
            stater: `${t("staking.tier.table.row1.starter")}`,
            super_rookey: `${t("staking.tier.table.row1.super").replace(
                "AMOUNT",
                take_decimal_numberFormatThousands(tierInfo[tierInfo?.length - 2]?.min || 0)
            )}`,
            legend: `${t("staking.tier.table.row1.legend").replace(
                "AMOUNT",
                take_decimal_numberFormatThousands(tierInfo[tierInfo?.length - 1]?.min || 0)
            )}`,
        },
        {
            tier: `${t("staking.tier.table.row2.tier")}`,
            stater: `${t("staking.tier.table.row2.starter")}`,
            super_rookey: `${t("staking.tier.table.row2.super")}`,
            legend: `${t("staking.tier.table.row2.legend")}`,
        },
        // {
        //     tier: `${t("staking.tier.table.row3.tier")}`,
        //     stater: `${t("staking.tier.table.row3.starter")}`,
        //     super_rookey: `${t("staking.tier.table.row3.super")}`,
        //     legend: `${t("staking.tier.table.row3.legend")}`,
        // },
        {
            tier: `${t("staking.tier.table.row4.tier")}`,
            stater: `${t("staking.tier.table.row4.starter")}`,
            super_rookey: `${t("staking.tier.table.row4.super")}`,
            legend: `${t("staking.tier.table.row4.legend")}`,
        },
        {
            tier: `${t("staking.tier.table.row5.tier")}`,
            stater: `${t("staking.tier.table.row5.starter")}`,
            super_rookey: `${t("staking.tier.table.row5.super")}`,
            legend: `${t("staking.tier.table.row5.legend")}`,
        },
    ];

    return (
        <div className={styles.tierSystem}>
            <div className="boxTierSystem">
                <p>{t("staking.tier.tierSystem")}</p>
                <TableContainer>
                    <Table className="tableTier" aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className="tableCellHead">
                                    {t("staking.tier.table.title.tier")}
                                </TableCell>
                                <TableCell
                                    style={{ textAlign: "center", backgroundColor: "#1F334E" }}
                                    className="tableCellHead"
                                >
                                    <div>
                                        <img
                                            src="images/staking/tierSystem/iconStarter.svg"
                                            alt=""
                                        />
                                        {t("staking.tier.table.title.starter")}
                                    </div>
                                </TableCell>
                                <TableCell
                                    style={{ textAlign: "center", backgroundColor: "#1A3569" }}
                                    className="tableCellHead"
                                >
                                    <div>
                                        <img
                                            src="images/staking/tierSystem/iconSuperRookie.svg"
                                            alt=""
                                        />

                                        {t("staking.tier.table.title.super")}
                                    </div>
                                </TableCell>
                                <TableCell
                                    style={{ textAlign: "center", backgroundColor: "#36285F" }}
                                    className="tableCellHead"
                                >
                                    <div>
                                        <img
                                            src="images/staking/tierSystem/iconLegend.svg"
                                            alt=""
                                        />
                                        {t("staking.tier.table.title.legend")}
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row: Row, index: number) => (
                                <TableRow key={index} className="tableRow">
                                    <TableCell className="tableCellBody" component="th" scope="row">
                                        {row.tier}
                                    </TableCell>
                                    <TableCell
                                        style={{ textAlign: "center", backgroundColor: "#1F2A45" }}
                                        className="tableCellBody"
                                    >
                                        {row.stater}
                                    </TableCell>
                                    <TableCell
                                        style={{ textAlign: "center", backgroundColor: "#1C2C54" }}
                                        className="tableCellBody"
                                    >
                                        {row.super_rookey}
                                    </TableCell>
                                    <TableCell
                                        style={{ textAlign: "center", backgroundColor: "#2B254E" }}
                                        className="tableCellBody"
                                    >
                                        {row.legend}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}
