import { AnyAction } from "redux";
import { statusApplyActions } from "../constants/statusApplyWhitelist";

type StateType = {
    type: string;
    message: string;
};

const initialState = {
    type: "",
    message: "",
};

// dispatch(statusApplyReducer(alertActions.SUCCESS_MESSAGE , "success"))
export const statusApplyReducer = (state: StateType = initialState, action: AnyAction) => {
    switch (action.type) {
        case statusApplyActions.SUCCESS_MESSAGE: {
            return {
                type: "success",
                message: action.payload,
            };
        }

        case statusApplyActions.ERROR_MESSAGE: {
            return {
                type: "error",
                message: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};
