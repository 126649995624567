import DefaultLayout from "../../../components/Layout/DefaultLayout";

const NotFoundDetailPage = () => {
    return (
        <DefaultLayout defaultLayout={false}>
            <h1
                style={{
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "500px",
                }}
            >
                Not Found !!!
            </h1>
        </DefaultLayout>
    );
};

export default NotFoundDetailPage;
