import i18n from "i18next";
import moment from "moment";
import { useHistory } from "react-router";
import { convertLang } from "../../../CardItem";
import { randomColorEvent } from "../../Calendar";
import useStyles from "./style";

export default function Item({ handleSetTab, dateCurrent, item }: any) {
    const styles = useStyles();
    const history = useHistory();

    return (
        <div className={styles.items}>
            <div
                className="month"
                onClick={() => handleSetTab("ListItemDetail", new Date(item?.date))}
            >
                <p>{moment(new Date(item?.date)).format("DD")}</p>
                <span>{moment(new Date(item?.date)).format("ddd")}</span>
            </div>
            <div className={styles.item}>
                {Array.isArray(item?.values) &&
                    item?.values?.map((it: any, index: number) => (
                        <div
                            className="info"
                            onClick={() => history.push(`/project-detail/${it?.id}`)}
                            key={index}
                        >
                            <div className={`${randomColorEvent(it?.event)} line`}></div>
                            <div className="box">
                                <p>{moment(new Date(it?.date)).format("hh:mm A")}</p>
                                <span>
                                    {convertLang(it?.project_name, i18n.language)}{" "}
                                    <span style={{ textTransform: "capitalize" }}>{it?.event}</span>{" "}
                                </span>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
}
