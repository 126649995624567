import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ModalImgSNS from "../Modal/MoldalImgSNS";
import useStyles from "./style";
import i18n from "i18next";
import { convertLang } from "../../IDOHomePage/CardItem";

export default function Summary(props: any) {
    const styles = useStyles();
    const { t } = useTranslation();
    const [tabs, setTabs] = useState<string>("Summary");
    const [open, setOpen] = useState<boolean>(false);
    // const state = useSelector((state: any) => state.idoDetail.data);
    const [imgModalImgSNS, setImgModalImgSNS] = useState("");
    const { project_description, FAQ, sns } = useSelector((state: any) => state.idoDetail?.data);

    const handleClickImgSNS = (url: string) => {
        setOpen(true);
        setImgModalImgSNS(url);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const renderOpenModalApply = () => (
        <ModalImgSNS open={open} onClose={handleClose} img={imgModalImgSNS} />
    );

    const renderContent = () => {
        if (tabs === "Summary") {
            return (
                <div className="summary">
                    {project_description && (
                        <div
                            className="ql-editor"
                            id="editor"
                            dangerouslySetInnerHTML={{
                                __html: convertLang(project_description, i18n.language),
                            }}
                        />
                    )}

                    {/* <div>
                        {convertLang(project_description, i18n.language).map(
                            (desc: any, index: any) => (
                                <p key={index} dangerouslySetInnerHTML={{ __html: desc }} />
                            )
                        )}
                    </div> */}
                </div>
            );
        } else if (tabs === "FAQ") {
            return (
                <div className="summary">
                    {/* <div>
                        <p>{FAQ}</p>
                    </div> */}

                    {FAQ && (
                        <div
                            className="ql-editor"
                            id="editor"
                            dangerouslySetInnerHTML={{
                                __html: convertLang(FAQ, i18n.language),
                            }}
                        />
                    )}
                </div>
            );
        } else if (tabs === "SNS") {
            return (
                <div className="snsTotal">
                    <div className="sns">
                        {sns?.map((item: any, index: number) => (
                            <img
                                onClick={() => handleClickImgSNS(item?.url)}
                                src={item?.url}
                                alt=""
                                key={index}
                            ></img>
                        ))}
                        {renderOpenModalApply()}
                    </div>
                </div>
            );
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className="tab">
                {tabs === "Summary" ? (
                    <div className="active" onClick={() => setTabs("Summary")}>
                        {t("ProjectDetails.summary")}
                    </div>
                ) : (
                    <div onClick={() => setTabs("Summary")}>{t("ProjectDetails.summary")}</div>
                )}
                {tabs === "FAQ" ? (
                    <div className="active" onClick={() => setTabs("FAQ")}>
                        {t("ProjectDetails.faq")}
                    </div>
                ) : (
                    <div onClick={() => setTabs("FAQ")}>{t("ProjectDetails.faq")}</div>
                )}
                {/* {tabs === "SNS" ? (
                    <div className="active" onClick={() => setTabs("SNS")}>
                        {t("ProjectDetails.sns")}
                    </div>
                ) : (
                    <div onClick={() => setTabs("SNS")}>{t("ProjectDetails.sns")}</div>
                )} */}
            </div>
            {renderContent()}
        </div>
    );
}
