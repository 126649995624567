import { isWidthDown, isWidthUp, withWidth } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useWeb3React } from "@web3-react/core";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AppContext } from "../../../../AppContext";
import { take_decimal_numberFormatThousands } from "../../../../constants";
import { ConnectorNames } from "../../../../constants/connectors";
import { APP_NETWORKS_SUPPORT } from "../../../../constants/network";
import { getWeb3Instance } from "../../../../services/web3";
import { checkIsWalletLink, disconnectWalletLink } from "../../../../utils";
import { trimMiddlePartAddress } from "../../../../utils/accountAddress";
import useStyles from "./style";

const linkIcon = "/images/hyperlink.svg";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
            paddingTop: 0,
            borderRadius: 50,
            display: "flex",
            justifyContent: "space-between",
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: "black",
            backgroundColor: "#4B4B4B",
            padding: 4,

            "&:hover": {
                backgroundColor: "#D4D4D4",
            },
        },
        svgIcon: {
            fontSize: 5,
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
    customClass: string;
}

export interface ComponentProps {
    opened: boolean;
    handleClose: () => void;
    currentWallet: any;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, customClass, onClose, ...other } = props;

    const customStyles = {
        color: "white",
        padding: 0,
    };

    return (
        <MuiDialogTitle
            disableTypography
            className={`${classes.root} ${customClass}`}
            {...other}
            style={customStyles}
        >
            <Typography variant="h6">{children}</Typography>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
        color: "#999999",
    },
}))(MuiDialogContent);

const WalletDisconnectHomePage: React.FC<ComponentProps> = (props: any) => {
    const styles = useStyles();
    const { logout: disconnectWallet } = useContext(AppContext);
    const { appChainID } = useSelector((state: any) => state.appNetwork).data;
    const { t } = useTranslation();
    const { opened, handleClose, currentWallet } = props;

    const walletName = currentWallet && currentWallet.title;
    const address = currentWallet ? currentWallet.addresses[0] : "";
    const networkInfo: any = APP_NETWORKS_SUPPORT[appChainID] || {};
    // const networkName = appChainID === ETH_CHAIN_ID ? 'Ethereum': 'Binance Smart Chain';
    // const currency = appChainID === ETH_CHAIN_ID ? 'ETH': 'BNB'
    const walletIconPath = currentWallet ? `/images/${currentWallet.typeId}.svg` : "";
    const [balance, setBalance] = useState<number>();

    const { connector, account } = useWeb3React();

    const handleAccountLogout = async () => {
        if (
            walletName === ConnectorNames.WalletConnectPolygon &&
            localStorage.getItem("walletconnect-name")
        ) {
            localStorage.removeItem("walletconnect-name");
            localStorage.removeItem("walletconnect");
        }

        // Disconnect WalletLink
        if (checkIsWalletLink(connector)) {
            connector && disconnectWalletLink(connector);
        }
        handleClose();
        disconnectWallet && disconnectWallet();
        localStorage.removeItem("investor_access_token");
        localStorage.removeItem("access_token");
        localStorage.removeItem("user");
    };

    useEffect(() => {
        if (!account) return;
        const web3 = getWeb3Instance();
        (async () => {
            const balanceOf = await web3?.eth.getBalance(account);
            setBalance(Number(web3?.utils.fromWei(balanceOf || "0")));
        })();
    }, [account, appChainID]);

    return (
        <Dialog open={opened} onClose={handleClose} className={styles.dialog}>
            <DialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
                customClass={styles.dialogTitle}
            >
                <span>
                    {t("header.account")}&nbsp;&nbsp;
                    <Link to="/account">
                        <img src={linkIcon} alt="" />
                    </Link>
                </span>
            </DialogTitle>
            <DialogContent className={styles.dialogContent}>
                <div className={styles.accountDetails}>
                    {/* <div className={styles.accountDetailsIcon}>
                        <img src="images/home-page/logoHomepage.svg" style={{ width: "100%" }} alt="" />
                    </div> */}
                    <div className={styles.accountDetailBlocks}>
                        <div className={styles.accountDetailBlock}>
                            <span className={styles.accountDetailBlockLabel}>
                                {t("header.balance")}
                            </span>
                            <p className={styles.accountDetailBlockText}>
                                {take_decimal_numberFormatThousands(balance || 0)}{" "}
                                {networkInfo.currency}
                            </p>
                        </div>
                        <div className={styles.accountDetailBlock}>
                            <span className={styles.accountDetailBlockLabel}>
                                {t("header.network")}
                            </span>
                            <p className={styles.accountDetailBlockText}>
                                {networkInfo.networkName}
                            </p>
                        </div>
                        <div className={styles.accountDetailBlock}>
                            <span className={styles.accountDetailBlockLabel}>
                                {t("header.wallet")}
                            </span>
                            <p className={styles.accountDetailBlockText}>{walletName}</p>
                        </div>
                    </div>
                </div>
                <div className={styles.accountDetailAddress}>
                    {walletIconPath && (
                        <img
                            src={walletIconPath}
                            alt={walletName}
                            className={styles.walletNameIcon}
                        />
                    )}
                    <span className={styles.accountDetailAddressText}>
                        {isWidthUp("sm", props.width) && address}
                        {isWidthDown("xs", props.width) && trimMiddlePartAddress(address, 10)}
                    </span>
                </div>
                <div className={styles.accountDetailCta}>
                    <div className={styles.accountDetailDisconnect} onClick={handleAccountLogout}>
                        <img
                            src="/images/disconnect.svg"
                            className={styles.accountDetailCtaIcon}
                            alt=""
                        />
                        <span>{t("header.disconnect")}</span>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default withWidth()(WalletDisconnectHomePage);
