import { Box, Typography } from "@material-ui/core";
import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
  index: number | string;
  value: number | string;
};

export default function TabPanel({ children, value, index, ...other }: Props) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      style={{ maxWidth: 1230, margin: "0px auto" }}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
