import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        wrapper: {
            paddingTop: 30,
            "&> p": {
                fontSize: 16,
                fontWeight: 400,
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                color: "white",
                opacity: 0.7,
            },
            "&> .desc": {
                padding: "14px 0 30px 0",
                fontSize: 14,
                fontWeight: 400,
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                color: "white",
                opacity: 0.5,
                "&>p:first-child": {
                    marginBottom: 4,
                },
            },
            "&> .amountTicket": {
                width: 265,
                height: 100,
                padding: "18px 22px",
                display: "flex",
                flexDirection: "column",
                background: "rgba(255, 255, 255, 0.07)",
                borderRadius: 13,
                [theme.breakpoints.down("xs")]: {
                    width: "100%",
                },
                "&> p": {
                    fontSize: 14,
                    fontWeight: 400,
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    color: "white",
                    opacity: 0.5,
                },
                "&> div": {
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 6,
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    "&> div:first-child": {
                        fontSize: 32,
                        fontWeight: 600,
                        color: "white",
                    },
                    "&> div:last-child": {
                        color: "#2DDBFF",
                        fontSize: 12,
                        fontWeight: 400,
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        cursor: "pointer",
                        "&> img": {
                            marginLeft: 7,
                        },
                    },
                },
            },
        },
    };
});

export default useStyles;
