const USER_INFO_KYC_LOADING = "USER_INFO_KYC_LOADING";
const USER_INFO_KYC_SUCCESS = "USER_INFO_KYC_SUCCESS";
const USER_INFO_KYC_FAILURE = "USER_INFO_KYC_FAILURE";
const USER_INFO_KYC_RESET = "USER_INFO_KYC_RESET";

export const userInfoKYC = {
    USER_INFO_KYC_LOADING,
    USER_INFO_KYC_SUCCESS,
    USER_INFO_KYC_FAILURE,
    USER_INFO_KYC_RESET
};

export interface UserInfoKYC {
    wallet_address: string;
    is_active: boolean;
    is_kyc: number;
    email: string;
    role: number;
    createdAt: string;
}
