import { AnyAction } from "redux";
import { listIdo } from "../constants/list-ido";

type StateType = {
    data: any;
    loading: boolean;
    error: string;
};

const initialState = {
    data: {},
    loading: false,
    error: "",
};

export const getListIdoReducer = (state: StateType = initialState, action: AnyAction) => {
    switch (action.type) {
        case listIdo.LIST_IDO_LOADING: {
            return {
                ...state,
                loading: true,
            };
        }

        case listIdo.LIST_IDO_SUCCESS: {
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        }

        case listIdo.LIST_IDO_FAILURE: {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        }

        default: {
            return state;
        }
    }
};
