import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { BaseRequest } from "../../request/Request";
import { userInfoKYC } from "../constants/user-kyc";

export const getUserInfoKYC = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
        dispatch({ type: userInfoKYC.USER_INFO_KYC_LOADING });
        try {
            const baseRequest = new BaseRequest();
            const response = (await baseRequest.get(`/users/me`, true)) as any;
            const resObj = await response.json();
            if (!resObj.data) {
                dispatch({
                    type: userInfoKYC.USER_INFO_KYC_LOADING,
                    payload: new Error("Invalid pool payload"),
                });
                return;
            }
            dispatch({
                type: userInfoKYC.USER_INFO_KYC_SUCCESS,
                payload: resObj.data,
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: userInfoKYC.USER_INFO_KYC_FAILURE,
                payload: error,
            });
        }
    };
};
