import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Hidden,
    IconButton,
    Snackbar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useWeb3React } from "@web3-react/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { statusApplySuccess } from "../../../../store/actions/statusApplyWhitelist";
import { convertLang } from "../../../IDOHomePage/CardItem";
import useStyles from "./style";
import TableAccount from "./TableAccount";
import TableApplyWhitelistMobile from "./TableApplyMobile";
import { BaseRequest } from "../../../../request/Request";
import { toastMessage } from "../../../../store/actions/toast-message-buyticket";

type Props = {
    onClose: () => void;
    open: boolean;
    checkApply: any;
    dataAccount: any;
};

type TParams = {
    id: "string";
};
export default function ModalApplyWhitelist({ open, onClose, checkApply, dataAccount }: Props) {
    const styles = useStyles();
    const dispatch = useDispatch();
    const IDODetail = useSelector((state: any) => state.idoDetail.data);
    const { id } = useParams<TParams>();
    const { t } = useTranslation();

    const [messageWaring, setMessageWaring] = useState(false);

    const { account } = useWeb3React();

    const handleClose = () => {
        onClose();
    };
    const postDataApply = async () => {
        const idIdo = Number(id);
        const data = {
            wallet_address: account,
            ido_id: idIdo,
        };
        try {
            const baseRequest = new BaseRequest();
            const response = (await baseRequest.post(`/whitelist/apply`, data)) as any;
            const resObj = await response.json();
            dispatch(statusApplySuccess("success"));
            onClose();
            return resObj;
        } catch (error) {
            console.log(error);
        }
    };
    const handleSubmit = async () => {
        if (
            !dataAccount?.user_discord ||
            !dataAccount?.user_telegram ||
            !dataAccount?.user_twitter
        ) {
            // return dispatch(
            //     toastMessage({
            //         type: "socialMedia",
            //         title: "Social media account not found",
            //         message: "Please update your social media accounts to proceed",
            //     })
            // );
            return setMessageWaring(true);
        }
        await postDataApply();
    };

    const handleCloseMessage = () => {
        setMessageWaring(false);
    };

    const history = useHistory();

    const handleClickMyProfile = () => {
        history.push("/account");
    };

    return (
        <Dialog className={styles.wrapper} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle style={{ padding: "16px" }}>
                {t("ProjectDetails.welcome")} {convertLang(IDODetail?.project_name, i18n.language)}{" "}
                IDO {t("ProjectDetails.onCodeDao")}
                <IconButton aria-label="close" className={styles.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ padding: "8px 16px" }}>
                <DialogContentText className={styles.textContent} id="alert-dialog-description">
                    {t("ProjectDetails.modalApplyWhitelist.content1")}
                    <br />
                    {t("ProjectDetails.modalApplyWhitelist.content2")}
                </DialogContentText>
                <DialogContentText className={styles.textTitle}>
                    {/* Follow and subcribe to {convertLang(IDODetail?.project_name, i18n.language)} and
                    CodeDAO ’s accounts */}
                    {t("ProjectDetails.modalApplyWhitelist.contentTitle").replace(
                        "PROJECT_NAME",
                        convertLang(IDODetail?.project_name, i18n.language)
                    )}
                </DialogContentText>
                <Hidden xsDown>
                    <TableAccount />
                </Hidden>
                <Hidden smUp>
                    <TableApplyWhitelistMobile />
                </Hidden>
                <Snackbar
                    open={messageWaring}
                    onClose={handleCloseMessage}
                    message={
                        <div className={styles.messageWaring}>
                            <img
                                src="/images/home-page/iconI.svg"
                                alt="iconWarning"
                                className="iconWarning"
                            />
                            <div className="content">
                                <p>{t("WarningMessage.socialMedia")}</p>
                                <span>{t("WarningMessage.msgSocialMedia")}</span>
                                <span onClick={handleClickMyProfile} className="goToMyProfile">
                                    Go to My Profile
                                </span>
                            </div>
                            <img
                                src="/images/icons/close_toast.svg"
                                alt="iconClose"
                                className="iconClose"
                                onClick={handleCloseMessage}
                            />
                        </div>
                    }
                />
                <div className={styles.accountUser}>
                    <div>
                        <div className={styles.twitterAccount}>
                            <div>
                                <p>{t("ProjectDetails.modalApplyWhitelist.yourTwitterAccount")}</p>
                            </div>
                            <div>
                                <p>{dataAccount?.user_twitter}</p>
                            </div>
                        </div>
                        <div className={styles.twitterAccount}>
                            <div>
                                <p>{t("ProjectDetails.modalApplyWhitelist.yourTelegramAccount")}</p>
                            </div>
                            <div>
                                <p>{dataAccount?.user_telegram}</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.discordAccount}>
                        <div>
                            <p>{t("ProjectDetails.modalApplyWhitelist.yourDiscordAccount")}</p>
                        </div>
                        <div>
                            <p>{dataAccount?.user_discord}</p>
                        </div>
                    </div>
                </div>
                <div className={styles.btn}>
                    {/* {twitterAccount === "" || telegramAccount === "" || discordAccount === "" ? (
                        <Button disabled className="btnStakeMoreDisabled" onClick={handleSubmit}>
                            {t("ProjectDetails.modalApplyWhitelist.btnApply")}
                        </Button>
                    ) : (
                        <Button className="btnStakeMore" onClick={handleSubmit}>
                            {t("ProjectDetails.modalApplyWhitelist.btnApply")}
                        </Button>
                    )} */}
                    <Button className="btnStakeMore" onClick={handleSubmit}>
                        {t("ProjectDetails.modalApplyWhitelist.btnApply")}
                    </Button>
                    <Button className="btnCancel" onClick={handleClose}>
                        {t("ProjectDetails.modalApplyWhitelist.btnCancel")}
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}
