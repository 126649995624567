import { TableContainer } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TELEGRAM_LINK_URL, TWITTER_LINK_URL } from "../../../../constants";
import { convertLang } from "../../../IDOHomePage/CardItem";
import useStyles from "./table";

// interface Row {
//     Account: string;
//     Cyball: string;
//     codeDAO: string;
// }

export default function TableAccount() {
    // const rows = [
    //     {
    //         Account: "Official Twitter",
    //         Cyball: "@cyball_globalz",
    //         codeDAO: "@codeDAO",
    //     },
    //     {
    //         Account: "Discord Channel",
    //         Cyball: "discord/server/cyball ",
    //         codeDAO: "discord/server/codeDAO",
    //     },
    //     {
    //         Account: "Telegram Group",
    //         Cyball: "telegram/cyball",
    //         codeDAO: "telegram/codeDAO",
    //     },
    // ];
    const styles = useStyles();
    const IDODetail = useSelector((state: any) => state.idoDetail?.data);
    const { t } = useTranslation();

    return (
        <div className={styles.transitionHistory}>
            <div className="boxTransitionHistory">
                <TableContainer>
                    <Table className="tableTransition" aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ paddingLeft: 25 }}>
                                    {t("ProjectDetails.modalApplyWhitelist.account")}
                                </TableCell>

                                <TableCell className={styles.tableCellImg}>
                                    <img
                                        src={
                                            IDODetail?.cover_photo ||
                                            "images/home-page/iconCodeDao.svg"
                                        }
                                        alt="codeDAO"
                                    />
                                    {convertLang(IDODetail?.project_name, i18n.language)}
                                </TableCell>
                                <TableCell
                                    style={{ paddingLeft: 22 }}
                                    className={styles.tableCellCodedao}
                                >
                                    <img src="images/home-page/iconCodeDao.svg" alt="codeDAO" />
                                    <p style={{ paddingLeft: 24 }}>CODEDAO</p>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    {t("ProjectDetails.modalApplyWhitelist.officialTwitter")}
                                </TableCell>
                                <TableCell style={{ color: "#2DDBFF" }}>
                                    <a
                                        style={{ color: "#2DDBFF" }}
                                        href={`${TWITTER_LINK_URL}${IDODetail?.ido_twitter}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {IDODetail?.ido_twitter
                                            ? "@" + IDODetail?.ido_twitter
                                            : "-"}
                                    </a>
                                </TableCell>
                                <TableCell style={{ color: "#2DDBFF" }}>
                                    <a
                                        style={{ color: "#2DDBFF" }}
                                        href={`${TWITTER_LINK_URL}${IDODetail?.platform_twitter}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {IDODetail?.platform_twitter
                                            ? "@" + IDODetail?.platform_twitter
                                            : "-"}
                                    </a>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    {t("ProjectDetails.modalApplyWhitelist.discordChannel")}
                                </TableCell>
                                <TableCell style={{ color: "#2DDBFF" }}>
                                    <a
                                        style={{ color: "#2DDBFF" }}
                                        href={IDODetail?.ido_discord_link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {IDODetail?.ido_discord_link
                                            ? IDODetail?.ido_discord_link
                                            : "-"}
                                    </a>
                                </TableCell>
                                <TableCell style={{ color: "#2DDBFF" }}>
                                    <a
                                        style={{ color: "#2DDBFF" }}
                                        href={IDODetail?.platform_discord_link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {IDODetail?.platform_discord_link
                                            ? IDODetail?.platform_discord_link
                                            : "-"}
                                    </a>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    {t("ProjectDetails.modalApplyWhitelist.telegramGroup")}
                                </TableCell>
                                <TableCell style={{ color: "#2DDBFF" }}>
                                    <a
                                        style={{ color: "#2DDBFF" }}
                                        href={`${TELEGRAM_LINK_URL}${IDODetail?.ido_telegram}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {IDODetail?.ido_telegram
                                            ? "telegram/" + IDODetail?.ido_telegram
                                            : "-"}
                                    </a>
                                </TableCell>
                                <TableCell style={{ color: "#2DDBFF" }}>
                                    <a
                                        style={{ color: "#2DDBFF" }}
                                        href={`${TELEGRAM_LINK_URL}${IDODetail?.platform_telegram}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {IDODetail?.platform_telegram
                                            ? "telegram/" + IDODetail?.platform_telegram
                                            : "-"}
                                    </a>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    {false && (
                        <div className="noDataTransaction">
                            <img src="/images/account_v1/no_transaction.svg" alt="" />
                        </div>
                    )}
                </TableContainer>
            </div>
        </div>
    );
}
