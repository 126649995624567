import { Button } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import i18n from "i18next";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import CountdownHomePage from "../../components/Base/Countdown/CountdownHomepage";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import { PAGE_SIZE } from "../../constants";
import { useSocket } from "../../hooks/useSocket";
import { getListIdo } from "../../store/actions/list-ido";
import { formatPrecisionAmount } from "../../utils";
import { scrollIntoView } from "../../utils/formatNumber";
import { SocketEvent } from "../../utils/types/socket";
import PrivacyPolicy from "../Dashboard/TermsAndPrivacy/PrivacyPolicy";
import Terms from "../Dashboard/TermsAndPrivacy/Terms";
import PaginationCustom from "../PaginationCustom";
import { idoNewEst } from "../ProjectDetail/Api";
import ModalLoadingHomePage from "../StakingPools/ModalLoading/ModalLoadingHomePage";
import CardItems, { convertLang } from "./CardItem";
import SelectIDOStatus from "./Components/IDOStatus";
import { splitStringMaxLength } from "./Components/IDOStatus/utils";
import useStyles from "./style";

export default function HomePage(props: any) {
    const { t } = useTranslation();
    const styles = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const searchAsObject = Object.fromEntries(new URLSearchParams(history.location.search));
    const statusSearch =
        searchAsObject?.status?.split(",")?.length === 5
            ? searchAsObject?.status?.split(",").concat(["All"])
            : searchAsObject?.status?.split(",");
    const queryStatus = searchAsObject?.status
        ? statusSearch
        : ["All", "Upcoming", "Calculation", "Buy Ticket", "Distribution", "Ended"];
    const IDO = useSelector((state: any) => state.listIdo.data);
    const [status, setStatus] = useState<any>(queryStatus);
    const [myIdo, setMyIdo] = useState<String>(searchAsObject?.myIDO === "true" ? "Joined" : "All");
    const [isStatusBoxOpen, setIsStatusBoxOpen] = useState<boolean>(false);
    const [isMyIdoBoxOpen, setIsMyIdoBoxOpen] = useState<boolean>(false);
    const [page_number, setPageNumber] = useState<number>(Number(searchAsObject?.page_number) || 1);
    const [idoNewest, setIdoNewest] = useState<any>(undefined);
    const listIDORef = useRef<any>();
    const [showTerms, setShowTerms] = useState<boolean>(false);
    const [showPrivacy, setShowPrivacy] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const urlPath = props.location.pathname;
    const [search, setSearch] = useState<any>(searchAsObject?.searchValue || "");
    const { account } = useWeb3React();
    const queryRef = useRef<boolean>(false);
    const { socket } = useSocket();

    useEffect(() => {
        socket.disconnect();
    }, []);

    useEffect(() => {
        document.title = t("FASTRAISE | Home");
    }, [t]);

    const fetchListIDO = useCallback(() => {
        let statusValue =
            status.length === 0 ? "" : status?.filter((item: any) => item !== "All").join(",");
        let myIDOValue = myIdo === "All" ? "" : true;
        const query = {
            page_number,
            page_size: PAGE_SIZE,
            searchValue: search,
            status: statusValue,
            myIDO: myIDOValue,
        };
        queryRef.current &&
            history.push({
                search: `?page_number=${encodeURIComponent(
                    page_number
                )}&page_size=${encodeURIComponent(PAGE_SIZE)}&searchValue=${encodeURIComponent(
                    search || ""
                )}&status=${encodeURIComponent(statusValue || "")}&myIDO=${encodeURIComponent(
                    myIDOValue
                )}`,
            });
        dispatch(getListIdo(query));
    }, [status, myIdo, page_number, search, history, dispatch]);

    useEffect(() => {
        fetchListIDO();
    }, [page_number]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const res = await idoNewEst();
            setIdoNewest(res);
            fetchListIDO();
            setTimeout(() => {
                setLoading(false);
            }, 400);
        })();
    }, []);

    const toggleStatusProject = () => {
        setIsStatusBoxOpen(!isStatusBoxOpen);
    };

    const toggleMyIdoProject = () => {
        if (!account) {
            toast.warn("Please connect wallet");
        }
        setIsMyIdoBoxOpen(!isMyIdoBoxOpen);
    };

    const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const valueSearch = event.target.value;
        if (!valueSearch.startsWith(" ")) {
            queryRef.current = true;
            setSearch(valueSearch);
        }
    };

    const handleCloseFilter = () => {
        isStatusBoxOpen && setIsStatusBoxOpen(false);
        isMyIdoBoxOpen && setIsMyIdoBoxOpen(false);
    };

    const handleSetStatus = (event: any) => {
        event.stopPropagation();
        let value = event.target.value;
        const allStatus = ["All", "Upcoming", "Calculation", "Buy Ticket", "Distribution", "Ended"];
        queryRef.current = true;
        if (value === "All" && !status.includes("All")) {
            return setStatus(allStatus);
        } else if (value === "All" && status.includes("All")) {
            return setStatus([]);
        } else if (!status.includes(value)) {
            setStatus((item: any) => [...item, value]);
            status.length === 5 && setStatus((item: any) => [...item, "All"]);
        } else {
            return setStatus(status.filter((item: any) => item !== value && item !== "All"));
        }
    };

    const handleClickFilter = () => {
        setPageNumber(1);
        fetchListIDO();
    };

    const handleSetMyIdo = (myIdo: "All" | "Joined") => {
        queryRef.current = true;
        setMyIdo(myIdo);
    };

    const handleClickProjectDetail = () => {
        if (idoNewest?.data?.id) history.push(`/project-detail/${idoNewest?.data?.id}`);
    };

    const convertTimePoolOpen = moment(idoNewest?.data?.start_staking_snapshot).format(
        "MM/DD/YYYY HH:mm:ss"
    );

    const convertTime = (name: string) => {
        return moment(name).format("MM/DD/YYYY HH:mm:ss");
    };

    const openTermsPopup = () => {
        setShowTerms(true);
    };

    const closeTermsPopup = () => {
        setShowTerms(false);
        props.history.push({
            pathname: "/",
        });
    };

    const openPrivacyPopup = () => {
        setShowPrivacy(true);
    };

    const closePrivacyPopup = () => {
        setShowPrivacy(false);
        props.history.push({
            pathname: "/",
        });
    };
    useEffect(() => {
        switch (urlPath) {
            case "/privacy":
                openPrivacyPopup();
                break;
            case "/terms":
                openTermsPopup();
                break;

            default:
                break;
        }
    }, [urlPath]);

    const renderTextStatusIDO = () => {
        if (status.includes("All")) {
            return "All";
        } else return status.join(", ");
    };

    const renderCategory = (data: any, key: string) => {
        try {
            const result = JSON.parse(data);
            if (Array.isArray(result)) {
                return result;
            } else {
                return JSON.parse(result[key === "en" ? key : "kr"]);
            }
        } catch (e) {
            return [];
        }
    };

    const handleChangePagination = (page: number) => {
        scrollIntoView(listIDORef);
        setPageNumber(page);
        let statusValue =
            status.length === 0 ? "" : status?.filter((item: any) => item !== "All").join(",");
        let myIDOValue = myIdo === "All" ? "" : true;
        history.push({
            search: `?page_number=${encodeURIComponent(page)}&page_size=${encodeURIComponent(
                PAGE_SIZE
            )}&searchValue=${encodeURIComponent(search || "")}&status=${encodeURIComponent(
                statusValue || ""
            )}&myIDO=${encodeURIComponent(myIDOValue)}`,
        });
    };

    const renderCountDown = useCallback(() => {
        if (!idoNewest?.data?.status) return;
        return (
            <>
                {(() => {
                    switch (idoNewest?.data?.status) {
                        case "1":
                            return (
                                <>
                                    <div className={styles.countdown}>
                                        <img src="/images/home-page/imgRocket.svg" alt="" />
                                        <h2 className="title">Countdown to Next Ticket Sale</h2>
                                    </div>
                                    <div className={styles.container}>
                                        <CountdownHomePage
                                            startDate={
                                                new Date(
                                                    convertTime(idoNewest?.data?.start_ticket_open)
                                                )
                                            }
                                        />
                                    </div>
                                </>
                            );
                        case "2":
                            return (
                                <>
                                    <div className={styles.countdown}>
                                        <img src="/images/home-page/imgRocket.svg" alt="" />
                                        <h2 className="title">Countdown to Next Ticket Sale</h2>
                                    </div>
                                    <div className={styles.container}>
                                        <CountdownHomePage
                                            startDate={
                                                new Date(
                                                    convertTime(idoNewest?.data?.start_ticket_open)
                                                )
                                            }
                                        />
                                    </div>
                                </>
                            );
                        case "3":
                            return (
                                <>
                                    <div className={styles.countdown}>
                                        <img src="/images/home-page/imgRocket.svg" alt="" />
                                        <h2 className="title">Next Ticket Sale close in</h2>
                                    </div>
                                    <div className={styles.container}>
                                        <CountdownHomePage
                                            startDate={
                                                new Date(
                                                    convertTime(idoNewest?.data?.start_ticket_close)
                                                )
                                            }
                                        />
                                    </div>
                                </>
                            );
                        case "4":
                            return (
                                <>
                                    <div className={styles.countdown}>
                                        <img src="/images/home-page/imgRocket.svg" alt="" />
                                        <h2 className="title">Next distribution end in</h2>
                                    </div>
                                    <div className={styles.container}>
                                        <CountdownHomePage
                                            startDate={
                                                new Date(
                                                    convertTime(idoNewest?.data?.start_pool_end)
                                                )
                                            }
                                        />
                                    </div>
                                </>
                            );
                        case "5":
                            return (
                                <>
                                    <div className={styles.countdown}>
                                        <img src="/images/home-page/imgRocket.svg" alt="" />
                                        <h2 className="title">{t("IdoHomePage.titleCountdown")}</h2>
                                    </div>
                                    <div className={styles.container}>
                                        <CountdownHomePage
                                            startDate={new Date(convertTimePoolOpen)}
                                        />
                                    </div>
                                </>
                            );
                        default:
                            return null;
                    }
                })()}
            </>
        );
    }, [
        convertTimePoolOpen,
        idoNewest?.data?.start_pool_end,
        idoNewest?.data?.start_ticket_close,
        idoNewest?.data?.start_ticket_open,
        idoNewest?.data?.status,
        styles.container,
        styles.countdown,
        t,
    ]);

    const renderTimeStatusOpen = useCallback(() => {
        if (!idoNewest?.data?.status) return;
        return (
            <>
                {(() => {
                    switch (idoNewest?.data?.status) {
                        case "1":
                            return (
                                <div className="openTicket">
                                    <p>{t("IdoHomePage.ticketOpenAt")}</p>
                                    <p>
                                        {moment(
                                            new Date(
                                                moment(idoNewest?.data?.start_ticket_open).format(
                                                    "MM/DD/YYYY HH:mm:ss"
                                                )
                                            )
                                        ).format("MMM")}{" "}
                                        {moment(idoNewest?.data?.start_ticket_open).format("DD")},{" "}
                                        {moment(idoNewest?.data?.start_ticket_open).format("YYYY")}{" "}
                                        {moment(idoNewest?.data?.start_ticket_open).format(
                                            "HH:mm:ss"
                                        )}{" "}
                                    </p>
                                </div>
                            );
                        case "2":
                            return (
                                <div className="openTicket">
                                    <p>{t("IdoHomePage.ticketOpenAt")}</p>
                                    <p>
                                        {moment(
                                            new Date(
                                                moment(idoNewest?.data?.start_ticket_open).format(
                                                    "MM/DD/YYYY HH:mm:ss"
                                                )
                                            )
                                        ).format("MMM")}{" "}
                                        {moment(idoNewest?.data?.start_ticket_open).format("DD")},{" "}
                                        {moment(idoNewest?.data?.start_ticket_open).format("YYYY")}{" "}
                                        {moment(idoNewest?.data?.start_ticket_open).format(
                                            "HH:mm:ss"
                                        )}{" "}
                                    </p>
                                </div>
                            );
                        case "3":
                            return (
                                <div className="openTicket">
                                    <p>Ticket End at</p>
                                    <p>
                                        {moment(
                                            new Date(
                                                moment(idoNewest?.data?.start_ticket_close).format(
                                                    "MM/DD/YYYY HH:mm:ss"
                                                )
                                            )
                                        ).format("MMM")}{" "}
                                        {moment(idoNewest?.data?.start_ticket_close).format("DD")},{" "}
                                        {moment(idoNewest?.data?.start_ticket_close).format("YYYY")}{" "}
                                        {moment(idoNewest?.data?.start_ticket_close).format(
                                            "HH:mm:ss"
                                        )}{" "}
                                    </p>
                                </div>
                            );
                        case "4":
                            return (
                                <div className="openTicket">
                                    <p>Pool Close at</p>
                                    <p>
                                        {moment(
                                            new Date(
                                                moment(idoNewest?.data?.start_pool_end).format(
                                                    "MM/DD/YYYY HH:mm:ss"
                                                )
                                            )
                                        ).format("MMM")}{" "}
                                        {moment(idoNewest?.data?.start_pool_end).format("DD")},{" "}
                                        {moment(idoNewest?.data?.start_pool_end).format("YYYY")}{" "}
                                        {moment(idoNewest?.data?.start_pool_end).format("HH:mm:ss")}{" "}
                                    </p>
                                </div>
                            );
                        case "5":
                            return (
                                <div className="openTicket">
                                    <p>{t("IdoHomePage.ticketOpenAt")}</p>
                                    <p>
                                        {moment(
                                            new Date(
                                                moment(idoNewest?.data?.start_ticket_open).format(
                                                    "MM/DD/YYYY HH:mm:ss"
                                                )
                                            )
                                        ).format("MMM")}{" "}
                                        {moment(idoNewest?.data?.start_ticket_open).format("DD")},{" "}
                                        {moment(idoNewest?.data?.start_ticket_open).format("YYYY")}{" "}
                                        {moment(idoNewest?.data?.start_ticket_open).format(
                                            "HH:mm:ss"
                                        )}{" "}
                                    </p>
                                </div>
                            );
                        default:
                            return null;
                    }
                })()}
            </>
        );
    }, [
        idoNewest?.data?.start_pool_end,
        idoNewest?.data?.start_ticket_close,
        idoNewest?.data?.start_ticket_open,
        idoNewest?.data?.status,
        t,
    ]);

    return (
        <DefaultLayout>
            <PrivacyPolicy opened={showPrivacy} handleClose={closePrivacyPopup} />
            <Terms opened={showTerms} handleClose={closeTermsPopup} />
            {!loading ? (
                <div className={styles.wrapper} onClick={handleCloseFilter}>
                    <div className={styles.projectCountdown}>
                        {idoNewest ? (
                            renderCountDown()
                        ) : (
                            <div className={styles.countdown}>
                                <img src="/images/home-page/imgRocket.svg" alt="" />
                                <h2 className="title">Gateway to revolutionary projects</h2>
                            </div>
                        )}
                        <div className={styles.cyballCard}>
                            {idoNewest ? (
                                <div className="imgTicket">
                                    <div>
                                        {" "}
                                        <img
                                            src={idoNewest?.data?.cover_photo || ""}
                                            alt=""
                                            onError={(e) => {
                                                (e.target as HTMLImageElement).onerror = null;
                                                (e.target as HTMLImageElement).src =
                                                    "/images/home-page/imgDefault.svg";
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="imgTicket">
                                    <div>
                                        <img
                                            src="/images/home-page/imgHomePageDefault.svg"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            )}

                            {idoNewest ? (
                                <div className="detailTicket">
                                    <p>
                                        {convertLang(idoNewest?.data?.project_name, i18n.language)}
                                    </p>
                                    <div>
                                        {idoNewest &&
                                            renderCategory(
                                                idoNewest?.data?.category,
                                                i18n.language
                                            )?.map((item: string, index: number) => (
                                                <Button key={index}>{item}</Button>
                                            ))}
                                    </div>
                                    <div className="desc">
                                        <div
                                            className="ql-editor"
                                            id="editor"
                                            dangerouslySetInnerHTML={{
                                                __html: convertLang(
                                                    idoNewest?.data?.project_description,
                                                    i18n.language
                                                ),
                                            }}
                                        />
                                        {/* {convertLang(
                                                idoNewest?.data?.project_description,
                                                i18n.language
                                            )} */}
                                    </div>
                                    <div className="follower">
                                        <div>
                                            <img src="/images/home-page/iconTwitter.svg" alt="" />
                                            <p>
                                                {formatPrecisionAmount(idoNewest?.followerNumber)}{" "}
                                            </p>
                                            <p>{t("IdoHomePage.follower")}</p>
                                        </div>
                                        <div>
                                            <img src="/images/home-page/iconTicket.svg" alt="" />
                                            <div className="vote-result">
                                                <p>{t("IdoHomePage.daoVoteResult")}</p>
                                                <div className="iconI">
                                                    <img
                                                        src="/images/home-page/iconI.svg"
                                                        alt=""
                                                        width={14}
                                                        height={10}
                                                        className="iconI"
                                                    />
                                                    <div className="onHoverI">
                                                        <p>{t("IdoHomePage.textTooltip")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="coming-soon">
                                                {t("IdoHomePage.commingSoon")}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="btn-read-more">
                                        <Button
                                            onClick={handleClickProjectDetail}
                                            className="btnReadmore"
                                        >
                                            {t("IdoHomePage.btnReadmore")}
                                        </Button>
                                    </div>
                                    {renderTimeStatusOpen()}
                                </div>
                            ) : (
                                <div className="detailTicket">
                                    <p>FASTRAISE</p>
                                    <div>
                                        <Button>Launchpad</Button>
                                        <Button>DAO</Button>
                                    </div>
                                    <div className="desc">
                                        <p>
                                            FASTRAISE is the one-stop shop for industry-best vetted
                                            projects with full pre-launch access to retail investors
                                        </p>
                                    </div>
                                    <div className="follower">
                                        <div>
                                            <img src="/images/home-page/iconTwitter.svg" alt="" />
                                            <p>100,000</p>
                                            <p>{t("IdoHomePage.follower")}</p>
                                        </div>
                                        <div>
                                            <img src="/images/home-page/iconTicket.svg" alt="" />
                                            <div className="vote-result">
                                                <p>{t("IdoHomePage.daoVoteResult")}</p>
                                                <div className="iconI">
                                                    <img
                                                        src="/images/home-page/iconI.svg"
                                                        alt=""
                                                        width={14}
                                                        height={10}
                                                        className="iconI"
                                                    />
                                                    <div className="onHoverI">
                                                        <p>{t("IdoHomePage.textTooltip")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="coming-soon">
                                                {t("IdoHomePage.commingSoon")}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="btn-read-more">
                                        <Button className="btnReadmore">
                                            <a
                                                href="https://code-dao.gitbook.io/v1/welcome/welcome-to-FASTRAISE"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ color: "#fff", width: "100%" }}
                                            >
                                                Whitepaper
                                            </a>
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={styles.projectListIdo} ref={listIDORef}>
                        <div className="box">
                            <div className={styles.projects}>
                                <p>{t("IdoHomePage.titleProjects")}</p>
                            </div>
                            <div className={styles.headerProjects}>
                                <div>
                                    <div className={styles.filterProjects}>
                                        <div className="status">
                                            <p>{t("IdoHomePage.headerProjectStatus")}</p>
                                            <div>
                                                <div className={styles.filterStatus}>
                                                    <div
                                                        className={styles.selectStatusIDO}
                                                        onClick={toggleStatusProject}
                                                    >
                                                        <span>
                                                            {splitStringMaxLength(
                                                                renderTextStatusIDO(),
                                                                15
                                                            ) || "Select status"}
                                                        </span>
                                                        <img
                                                            src="/images/home-page/arrowDownIcon.svg"
                                                            alt=""
                                                        />
                                                    </div>
                                                    {isStatusBoxOpen && (
                                                        <SelectIDOStatus
                                                            onChange={handleSetStatus}
                                                            listChecked={status}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="status">
                                            <p>{t("IdoHomePage.headerProjectMyIdo")}</p>
                                            <div>
                                                <div
                                                    className={styles.filterStatusMyIdo}
                                                    onClick={toggleMyIdoProject}
                                                >
                                                    <p>{myIdo}</p>
                                                    <img
                                                        src="/images/home-page/arrowDownIcon.svg"
                                                        alt=""
                                                    />
                                                    {isMyIdoBoxOpen && account && (
                                                        <div className="btnStatusBox">
                                                            <p
                                                                className={
                                                                    myIdo === "All"
                                                                        ? styles.colorLabelMyIdo
                                                                        : ""
                                                                }
                                                                style={{ width: "100%" }}
                                                                onClick={() =>
                                                                    handleSetMyIdo("All")
                                                                }
                                                            >
                                                                {t("IdoHomePage.statusAll")}
                                                            </p>
                                                            <p
                                                                className={
                                                                    myIdo === "Joined"
                                                                        ? styles.colorLabelMyIdo
                                                                        : ""
                                                                }
                                                                style={{ width: "100%" }}
                                                                onClick={() =>
                                                                    handleSetMyIdo("Joined")
                                                                }
                                                            >
                                                                {t("IdoHomePage.statusJoined")}
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="search">
                                            <img
                                                src="/images/home-page/iconSearch.svg"
                                                alt=""
                                                width="16"
                                                height="16"
                                            />
                                            <input
                                                type="text"
                                                placeholder={t("IdoHomePage.search")}
                                                value={search}
                                                onChange={onChangeSearch}
                                            />
                                        </div>
                                        <Button className="btnFilter" onClick={handleClickFilter}>
                                            {t("IdoHomePage.filter")}
                                        </Button>
                                    </div>
                                </div>

                                <a href="/#/calendar" target="_blank">
                                    <Button className={styles.btnViewCalendar}>
                                        {t("IdoHomePage.viewOnCalendar")}
                                        <img
                                            style={{ marginLeft: 6 }}
                                            src="/images/home-page/iconCalendar.svg"
                                            alt=""
                                        />
                                    </Button>
                                </a>
                            </div>
                            <div className={styles.card}>
                                {IDO?.data?.map((item: any, index: any) => (
                                    <CardItems
                                        key={index}
                                        item={item}
                                        // stakingSnapshot={item.start_staking_snapshot}
                                    />
                                ))}
                            </div>
                            {!(IDO?.data?.length > 0) && (
                                <div className="noDataTransaction">
                                    <div className="box">
                                        <img src="/images/account_v1/no_transaction.svg" alt="" />
                                    </div>
                                </div>
                            )}
                            <div className={styles.paginationIDO}>
                                {IDO?.total_page > 1 && (
                                    <PaginationCustom
                                        count={Number(IDO?.total_page) || 1}
                                        onChange={handleChangePagination}
                                        currentPage={page_number}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <ModalLoadingHomePage
                    open={loading}
                    onClose={() => {
                        setLoading(false);
                    }}
                />
            )}
        </DefaultLayout>
    );
}
