import { Button, Snackbar } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import { getPoolNoRewardCODE, getPoolNoRewardLP } from "../../store/actions/pool-noreward";
import { getUserTier } from "../../store/actions/sota-tiers";
import { poolNoReward } from "../../store/constants/pool-noreward";
import AccountInformation from "./AccountInformation";
import MyPools from "./MyPools";
import useStyles from "./style";
import Tiers from "./Tiers";

const AccountV2 = (props: any) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const menuMyAccount = [
        {
            name: `${t("account.myProfile.title")}`,
            icon: "/images/account_v1/icons/icon_my_profile.svg",
        },
        {
            name: `${t("account.myTier.title")}`,
            icon: "/images/account_v1/icons/icon_my_tier.svg",
        },
        {
            name: `${t("account.myPools.title")}`,
            icon: "/images/account_v1/icons/icon_my_pools.svg",
        },
    ];

    useEffect(() => {
        document.title = t("CodeDAO | Account");
    }, [t]);

    const query = new URLSearchParams(props.location.search);
    const currentTab = query.get("tab");
    let currentTabIndex = currentTab ? parseInt(currentTab) : 0;
    currentTabIndex = currentTabIndex < menuMyAccount.length ? currentTabIndex : 0;

    const [isKYC, setIsKYC] = useState(false);
    const [tabAccount] = useState(menuMyAccount);
    const [activeMenuAccount, setActiveMenuAccount] = useState(menuMyAccount[currentTabIndex].name);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const { account } = useWeb3React();
    const { page_number } = useSelector((state: any) => state.poolNoRewardLP);

    const selectTab = (name: any, index: any) => {
        setActiveMenuAccount(name);
        history.push({
            search: `?tab=${index}`,
        });
    };

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    useEffect(() => {
        if (!account || !localStorage.getItem("investor_access_token")) {
            dispatch({ type: poolNoReward.POOL_NOREWARD_CODE_LOADING });
            return;
        }
        dispatch(getUserTier());
        dispatch(getPoolNoRewardCODE());
    }, [dispatch, account, localStorage.getItem("investor_access_token")]);

    useEffect(() => {
        if (!account || !localStorage.getItem("investor_access_token")) {
            dispatch({ type: poolNoReward.POOL_NOREWARD_LP_LOADING });
            return;
        }
        dispatch(getPoolNoRewardLP());
    }, [dispatch, page_number, account, localStorage.getItem("investor_access_token")]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <DefaultLayout isKYC={isKYC} defaultLayout={false}>
            <div className={classes.snackBar}>
                <Snackbar open={showSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar}>
                    <div className={classes.messageUpdateSuccess}>
                        <div className="box">
                            <img src="/images/account_v3/icons/icon_updated_success.svg" alt="" />
                            {t("account.messUpdateSuccessfully")}
                        </div>
                        <Button onClick={handleCloseSnackbar}>
                            <img src="/images/closeMsgUpdate.svg" alt="" />
                        </Button>
                    </div>
                </Snackbar>
            </div>
            <div className={classes.accountContainer}>
                <div className={classes.bodyContentMyAccount}>
                    <div className={classes.leftAccount}>
                        <nav className={classes.tabAccount}>
                            {tabAccount.map((item, index) => {
                                return (
                                    <li
                                        className={`${classes.itemTabAccount}  ${
                                            activeMenuAccount === item.name ? "active" : ""
                                        }`}
                                        key={index}
                                        onClick={() => selectTab(item.name, index)}
                                    >
                                        <div
                                            className={`${classes.iconItemTabAccount} ${
                                                activeMenuAccount === item.name ? "active" : ""
                                            }`}
                                            style={{
                                                WebkitMaskImage: `url(${item.icon})`,
                                                maskImage: `url(${item.icon})`,
                                            }}
                                        ></div>
                                        {item.name}
                                    </li>
                                );
                            })}
                        </nav>
                    </div>
                    <div className={classes.rightAccount}>
                        {activeMenuAccount === "My Profile" && (
                            <AccountInformation
                                classNamePrefix="account-infomation"
                                isShowSnackbar={() => setShowSnackbar(true)}
                            />
                        )}
                        {activeMenuAccount === "My Tier" && (
                            <div className={classes.tier}>
                                <Tiers />
                            </div>
                        )}
                        {activeMenuAccount === "My Pools" && <MyPools />}
                    </div>
                </div>
            </div>
        </DefaultLayout>
    );
};

export default withRouter(AccountV2);
