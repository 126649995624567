import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        wrapper: {
            width: 860,
            // height: 650,
            margin: "auto",
            [theme.breakpoints.down("sm")]: {
                width: 620,
                // height: 620,
            },
            [theme.breakpoints.down("xs")]: {
                width: "100%",
                // height: 650,
            },

            "& .MuiPaper-root": {
                maxWidth: 860,
                width: 860,
                // height: 650,
                backgroundColor: "#242640",
                // textAlign: "center",
                padding: 20,
                borderRadius: 15,
                marginLeft: 6,
                [theme.breakpoints.down("sm")]: {
                    maxWidth: 620,
                    width: 620,
                    // height: 620,
                    margin: "0 34px 0 14px",
                },
                [theme.breakpoints.down("xs")]: {
                    width: "100%",
                    // height: 650,
                    padding: "20px 10px 10px 0px",
                    margin: "0 18px",
                },
            },
            "& .MuiDialog-paperScrollPaper": {
                backgroundColor: "#242640",
                color: "white",
            },
            "& .MuiTypography-h6": {
                fontSize: 32,
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                [theme.breakpoints.down("xs")]: {
                    fontSize: 20,
                },
            },
        },
        title: {
            fontSize: 32,
            fontWeight: 700,
            fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
            marginTop: 30,
            padding: "0 24px",
            [theme.breakpoints.down("xs")]: {
                padding: "0 16px",
            },
        },
        ticketsYourOwn: {
            position: "relative",
            "&> img": {
                [theme.breakpoints.down("xs")]: {
                    width: "100%",
                },
            },
        },
        amountBuyticket: {
            color: "white",
            fontWeight: 700,
            fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
            fontSize: 24,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "39%",
            right: "35%",
            top: "65%",
            bottom: "-20%",
            borderRadius: "100%",
            background: "linear-gradient(90deg, #2DDBFF 0%, #0F69E1 100.05%)",
            [theme.breakpoints.down("sm")]: {
                left: "26%",
                right: "56%",
                top: "65%",
                bottom: "-20%",
            },
            [theme.breakpoints.down("xs")]: {
                left: "35%",
                right: "35%",
                top: "62%",
                bottom: "-24%",
            },
        },
        tokenId: {
            display: "flex",
            // flexWrap: "wrap",
            flexDirection: "row",
            // maxWidth: 60,
            "&> p:last-child": {
                // maxWidth: 100,
                wordBreak: "break-word",
                display: "-webkit-box",
                "-webkit-box-orient": "vertical",
                "-webkit-line-clamp": 5,
                overflow: "hidden",
                textOverflow: "ellipsis",
            },
        },
        content: {
            borderTop: "1px solid #393B53",
            paddingTop: 20,
            borderBottom: "1px solid #393B53",
            paddingBottom: 30,
            marginTop: 20,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            "&> div": {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                [theme.breakpoints.down("sm")]: {
                    flexDirection: "column",
                },
                "&>div:first-child": {
                    display: "flex",
                    flexDirection: "column",
                    width: "75%",
                    marginRight: 24,
                    [theme.breakpoints.down("xs")]: {
                        width: "100%",
                        marginRight: 12,
                    },
                    "&> div": {
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 10,
                        "&> p:first-child": {
                            fontWeight: 400,
                            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                            fontSize: 14,
                            color: "white",
                            opacity: 0.5,
                            minWidth: 157,
                            [theme.breakpoints.down("sm")]: {
                                minWidth: 160,
                            },
                            [theme.breakpoints.down("xs")]: {
                                minWidth: 140,
                            },
                        },
                        "& a": {
                            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                            fontSize: 14,
                        },
                        "&> p:last-child": {
                            fontWeight: 400,
                            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                            fontSize: 14,
                            color: "white",
                        },
                        "&> a> img": {
                            marginLeft: 7,
                        },
                    },
                },
                "&> div:last-child": {
                    width: 460,
                    height: 265,
                    background: "#363850",
                    borderRadius: 10,
                    padding: "16px 30px",
                    [theme.breakpoints.down("sm")]: {
                        marginTop: 30,
                    },
                    [theme.breakpoints.down("xs")]: {
                        width: 294,
                    },
                    "&> p": {
                        color: "white",
                        marginBottom: 16,
                        fontWeight: 600,
                        fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    },
                    "&> div:nth-child(3)": {
                        display: "flex",
                        justifyContent: "space-between",
                        fontWeight: 400,
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        fontSize: 14,
                        color: "white",
                        opacity: 0.5,
                        marginTop: 8,
                    },
                    "&> div:last-child": {
                        marginTop: 4,
                        display: "flex",
                        justifyContent: "space-between",
                        fontWeight: 400,
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        fontSize: 14,
                        color: "white",
                    },
                },
            },
        },
        btnBuy: {
            marginTop: 30,
            display: "flex",
            justifyContent: "flex-end",
            "&> .btn": {
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                fontSize: 16,
                color: "white",
                background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                borderRadius: 90,
                padding: "15px 100px",
                textTransform: "capitalize",
                [theme.breakpoints.down("xs")]: {
                    fontSize: 12,
                    width: "100%",
                    padding: "12px 100px",
                },
            },
        },
        closeButton: {
            position: "absolute",
            right: 25,
            top: 25,
            color: "white",
            opacity: 0.5,
            backgroundColor: "#262530",
            padding: 8,
            border: "2px solid rgba(255, 255, 255, 0.2)",
            [theme.breakpoints.down("xs")]: {
                right: 12,
                top: 24,
                padding: 4,
            },
            "&:hover": {
                backgroundColor: "#262530",
            },
        },
        svgIcon: {
            fontSize: 5,
        },
    };
});

export default useStyles;
