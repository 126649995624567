import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useButtonGroupStyle, useStyles } from "./style";

const StakingHeader = (props: any) => {
    const btnGroupStyle = useButtonGroupStyle();
    const styles = useStyles();
    const { t } = useTranslation();

    return (
        <div className={styles.header}>
            <h1>{t("staking.title")}</h1>
            {/* <p>{t("staking.desc")}</p>
            <div className={btnGroupStyle.group}>
                <a
                    href="https://app.uniswap.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ border: "none" }}
                >
                    <Button variant="contained" color="primary" className={btnGroupStyle.btn}>
                        {t("staking.btnBuyOnUniswap")}
                        <img src="/images/staking/header/icon_uniswap.svg" alt="" />
                    </Button>
                </a>
                <a
                    href="https://www.mexc.com/ "
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ border: "none" }}
                >
                    <Button variant="contained" color="primary" className={btnGroupStyle.btn}>
                        {t("staking.btnBuyOnMEXC")}
                        <img src="/images/staking/header/icon_mexc.svg" alt="" />
                    </Button>
                </a>
                <a
                    href="https://www.gate.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ border: "none" }}
                >
                    <Button variant="contained" color="primary" className={btnGroupStyle.btn}>
                        {t("staking.btnBuyOnGate")}
                        <img src="/images/staking/header/icon_gate.svg" alt="" />
                    </Button>
                </a>
            </div> */}
        </div>
    );
};

export default StakingHeader;
