import { Button, Hidden, Slider } from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";
import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { take_decimal_numberFormatThousands } from "../../../constants";
import { convertFromWei } from "../../../services/web3";
import PointHistory from "../PointHistory";
import TierBenefits from "../TierBenefits";
import useStyles from "./style";

const Tiers = (props: any) => {
    const styles = useStyles();
    const { t } = useTranslation();
    const tabMenu = [
        `${t("account.myTier.tabOverview.title")}`,
        `${t("account.myTier.tabTierHistory.title")}`,
        `${t("account.myTier.tabTierBenefits.title")}`,
    ];
    const [loading, setLoading] = useState(true);
    const [tabMyTier] = useState(tabMenu);
    const [activeTab, setActiveTab] = useState<Number>(0);
    const poolNoRewardCODE = useSelector((state: any) => state.poolNoRewardCODE.data);
    const poolNoRewardLP = useSelector((state: any) => state.poolNoRewardLP.data?.data);
    const tierInfo = useSelector((state: any) => state.tiers.data);
    const { data: userInfoTier } = useSelector((state: any) => state.userTier);

    const query = new URLSearchParams(props.location.search);
    query.has("slug");

    const selectTab = (index: any) => {
        setActiveTab(index);
        props.history.push(`/account?tab=1&slug=` + index);
    };

    const checkAmountNextRank = (amount: string | number) => {
        if (Number(amount) <= 0) return Number(tierInfo[tierInfo?.length - 2]?.min || 0);
        if (Number(amount) > (Number(tierInfo[tierInfo?.length - 1]?.min) || 0)) return 0;
        return Number(amount) < tierInfo[1]?.min
            ? take_decimal_numberFormatThousands(tierInfo[1]?.min - Number(amount))
            : take_decimal_numberFormatThousands(
                  Number(tierInfo[tierInfo?.length - 1]?.min) - Number(amount)
              );
    };

    const convertSliderTier = (name: string) => {
        switch (name) {
            case "legend": {
                return 100;
            }
            case "rookie": {
                return 50;
            }
            default: {
                return 0;
            }
        }
    };

    const tier = [
        {
            value: 0,
            label: (
                <div className={styles.infoTierSlider}>
                    <div className={styles.activeDot + " dotTier"}>
                        <img src="/images/staking/tier/dot.svg" className="dot" alt="" />
                        <img src="/images/icons/started.svg" className="iconDot" alt="" />
                        <img
                            src="/images/staking/tier/dotActive.svg"
                            className="dotActive"
                            alt=""
                        />
                    </div>
                    <p>{t("staking.tier.timelineTier.starter")}</p>
                    <p>{t("staking.tier.timelineTier.starterDesc")}</p>
                </div>
            ),
        },
        {
            value: 50,
            label: (
                <div className={styles.infoTierSlider2}>
                    <div
                        className={`${
                            userInfoTier?.min >= Number(tierInfo[tierInfo?.length - 2]?.min || 0) &&
                            styles.activeDot
                        } dotTier`}
                    >
                        <img src="/images/staking/tier/dot.svg" className="dot" alt="" />
                        <img src="/images/icons/super_rookie.svg" className="iconDot" alt="" />
                        {userInfoTier?.min >= Number(tierInfo[tierInfo?.length - 2]?.min || 0) && (
                            <img
                                src="/images/staking/tier/dotActive.svg"
                                className="dotActive"
                                alt=""
                            />
                        )}
                    </div>
                    <p>{t("staking.tier.timelineTier.super")}</p>
                    <p>{tierInfo[1]?.min} CODE</p>
                </div>
            ),
        },
        {
            value: 100,
            label: (
                <div className={styles.infoTierSlider + " " + styles.infoTierSlider3}>
                    <div
                        className={`${
                            userInfoTier?.min >= Number(tierInfo[tierInfo?.length - 1]?.min || 0) &&
                            styles.activeDot
                        } dotTier`}
                    >
                        <img src="/images/staking/tier/dot.svg" className="dot" alt="" />
                        <img src="/images/icons/legend.svg" className="iconDot" alt="" />
                        {userInfoTier?.min >= Number(tierInfo[tierInfo?.length - 1]?.min || 0) && (
                            <img
                                src="/images/staking/tier/dotActive.svg"
                                className="dotActive"
                                alt=""
                            />
                        )}
                    </div>
                    <p>{t("staking.tier.timelineTier.legend")}</p>
                    <p>{tierInfo[tierInfo?.length - 1]?.min || 0} CODE</p>
                </div>
            ),
        },
    ];

    useEffect(() => {
        switch (props.location.search) {
            case "?tab=1&slug=1":
                setActiveTab(1);
                break;
            case "?tab=1&slug=2":
                setActiveTab(2);
                break;
            default:
                setActiveTab(0);
        }
    }, [props.location.search]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={styles.tierComponent}>
            <div className={styles.tierTitle}>{t("account.myTier.title")}</div>

            <ul className={styles.listInfo}>
                <li className={styles.itemInfo}>
                    <div className="box">
                        <div className={styles.nameItemInfo}>
                            {t("account.myTier.textCurrentTier")}
                        </div>
                        <div className={styles.valueItemInfo}>
                            {userInfoTier?.name === "rookie"
                                ? "Super Rookie"
                                : userInfoTier?.name || "Starter"}
                        </div>
                    </div>
                </li>
                <li className={styles.itemInfo}>
                    <div className="box">
                        <div className={styles.nameItemInfo}>
                            {t("account.myTier.textTotalStake")}
                        </div>
                        <div className={styles.valueItemInfo}>
                            {take_decimal_numberFormatThousands(
                                new BigNumber(poolNoRewardCODE?.total_staked)
                                    .dividedBy(10 ** Number(poolNoRewardCODE?.token_decimal))
                                    .toNumber() + Number(poolNoRewardLP?.total_code_staked || 0) ||
                                    0
                            )}{" "}
                            CODE
                        </div>
                    </div>
                </li>
                <li className={styles.itemInfo}>
                    <div className="box">
                        <div className={styles.nameItemInfo}>
                            {t("account.myTier.textNextTier")}
                        </div>
                        <div className={styles.valueItemInfo}>
                            {checkAmountNextRank(
                                Number(
                                    Number(
                                        convertFromWei(
                                            poolNoRewardCODE?.total_staked?.toString() || "0"
                                        )
                                    )
                                ) + Number(poolNoRewardLP?.total_code_staked || 0)
                            )}{" "}
                            CODE
                        </div>
                    </div>
                </li>
            </ul>

            <div className={styles.message}>
                <img src="/images/account_v3/icons/icon_notice.svg" alt="" />
                {t("account.myTier.textFormulaTotalStake")}
            </div>

            <nav className={styles.menuTier}>
                {tabMyTier.map((item, index) => {
                    return (
                        <li
                            className={`${styles.itemTabMyTier} ${
                                index === activeTab ? "active" : ""
                            }`}
                            key={index}
                            onClick={() => selectTab(index)}
                        >
                            <Hidden smDown>{item}</Hidden>
                            <Hidden mdUp>{index === 1 ? "Tier History" : item}</Hidden>
                        </li>
                    );
                })}
            </nav>

            <div className={styles.bodyPage}>
                {(activeTab === 0 || props.location.search === "?tab=1&slug=0") && (
                    <>
                        <Slider
                            defaultValue={0}
                            aria-labelledby="discrete-slider-custom"
                            valueLabelDisplay="auto"
                            marks={tier}
                            value={convertSliderTier(userInfoTier?.name)}
                            className="timeLineTier"
                        />
                        <Link to="/staking-pools">
                            <Button className="btnGoToTier" variant="contained">
                                {t("account.myTier.tabOverview.btnGoToTier")}
                            </Button>
                        </Link>
                    </>
                )}

                {(activeTab === 1 || props.location.search === "?tab=1&slug=1") && <PointHistory />}

                {(activeTab === 2 || props.location.search === "?tab=1&slug=2") && <TierBenefits />}
            </div>
        </div>
    );
};

export default withWidth()(withRouter(Tiers));
