import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        transitionHistory: {
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            "& .boxTransitionHistory": {
                // padding: "25px 30px",
                background: "#1D1F38",
                borderRadius: "20px",
                // [theme.breakpoints.down("sm")]: {
                //     padding: "20px 20px",
                // },
                // [theme.breakpoints.down("sm")]: {
                //     padding: "20px 0px",
                // },
                width: "100%",
                "& .titleTrans": {
                    color: "white",
                    fontSize: 16,
                    fontWeight: 600,
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    paddingBottom: 8,
                    borderBottom: "1px solid rgba(255, 255, 255, .1)",
                    marginBottom: 15,
                },
                "& .MuiTableContainer-root": {
                    margin: "0px auto",
                    borderRadius: "10px",
                    width: "100%",
                    overflow: "auto",
                    [theme.breakpoints.up("sm")]: {},
                    [theme.breakpoints.up("md")]: {
                        margin: "0px auto",
                    },
                    [theme.breakpoints.up("lg")]: {},
                },
                "& .tableTransition": {
                    backgroundColor: "transparent",
                    borderCollapse: "collapse",
                    borderSpacing: 0,
                    width: "100%",
                    minWidth: "430px",
                    "& .MuiTableHead-root": {
                        height: 38,
                    },
                    "& .MuiTableRow-head": {
                        background: "#353751",
                        height: 38,
                        "& .MuiTableCell-head": {
                            fontSize: 14,
                            padding: 0,
                            border: "none",
                            [theme.breakpoints.up("sm")]: {},
                            [theme.breakpoints.up("md")]: {
                                fontSize: 14,
                            },
                            [theme.breakpoints.up("lg")]: {},
                        },
                        "& .MuiTableCell-head:not(:first-child)": {
                            paddingLeft: 20,
                        },
                    },
                    "& .MuiTableBody-root": {
                        background: "#1F213B",
                    },

                    "& .MuiTableCell-body": {
                        fontSize: 14,
                        border: "none",
                        padding: "16px 0px",
                    },
                    "& .MuiTableCell-body:not(:first-child)": {
                        paddingLeft: 20,
                    },
                    "& .MuiTableRow-root": {
                        borderBottom: "1px solid #2A2C45",
                        "& .MuiTableCell-body": {
                            color: "rgba(255, 255, 255, .7)",
                        },
                        "& .MuiTableCell-body:first-child": {
                            paddingLeft: 25,
                        },
                        "& .MuiTableCell-body:last-child": {
                            textTransform: "left",
                        },
                        "& .MuiTableCell-head": {
                            color: "rgba(255, 255, 255, 0.3)",
                        },
                    },
                },
                "& .noDataTransaction": {
                    padding: "64px 0px 27px 0px",
                    margin: "0px auto",
                    width: "316px",
                },
            },
        },
        tableCellImg: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 6,
            "&> img": {
                marginRight: 6,
                width: 18,
                height: 18,
                borderRadius: "100%",
            },
        },
        tableCellCodedao: {
            position: "relative",
            "&> img": {
                position: "absolute",
                top: "24%",
                width: 18,
                height: 18,
                borderRadius: "100%",
                // [theme.breakpoints.dowm("sm")]: {
                //     top: "40",
                // },
            },
        },
        // tableRow: {
        //     display: "flex",
        //     flexDirection: "row",
        // },
    };
});

export default useStyles;
