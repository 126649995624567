import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import erc20ABI from "../../../abi/Erc20.json";
import { take_decimal_number, take_decimal_numberFormatThousands } from "../../../constants";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { getContractConnect } from "../../../services/web3";
import useCommonStyle from "../../../styles/CommonStyle";
import Button from "../Button";
import useStyles from "./style";
const closeIcon = "/images/icons/close.svg";

const ModalStake = (props: any) => {
    const styles = useStyles();
    const commonStyles = useCommonStyle();
    const poolNoRewardCODE = useSelector((state: any) => state.poolNoRewardCODE.data);

    const {
        open,
        onConfirm,
        onClose,
        amount,
        setAmount,
        min,
        max,
        infoToken,
        tokenAddress,
        isReward,
        handleOpenModalConfirm,
    } = props;

    const { appChainID } = useTypedSelector((state) => state.appNetwork).data;
    const { account: connectedAccount } = useWeb3React();
    const [balanceToken, setBalanceToken] = useState("");
    const { t } = useTranslation();

    const handleConfirm = () => {
        onConfirm({
            tokenAddress,
            spenderApprove: isReward
                ? process.env.REACT_APP_REWARD
                : poolNoRewardCODE?.contract_address,
            token_decimal: infoToken.token_decimal || 18,
        });
    };

    useEffect(() => {
        if (!connectedAccount || !infoToken) return;
        (async () => {
            const tokenContract = await getContractConnect(erc20ABI, tokenAddress, appChainID);
            if (tokenContract) {
                const balanceOf = await tokenContract.methods.balanceOf(connectedAccount).call();
                setBalanceToken(
                    new BigNumber(balanceOf)
                        .dividedBy(10 ** Number(infoToken.token_decimal))
                        .toString()
                );
            }
        })();
    }, [connectedAccount, infoToken]);

    const convertMin = () => {
        return Number(min) <= Number(infoToken?.total_staked) ? 0 : min;
    };

    const convertMax = () => {
        return Number(max) - Number(infoToken?.total_staked) <= 0
            ? 0
            : Number(max) - Number(infoToken?.total_staked);
    };

    return (
        <>
            {tokenAddress && (
                <Dialog
                    open={open}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    className={commonStyles.modal + " " + styles.modalStake}
                >
                    <div className="modal-content">
                        <DialogTitle id="alert-dialog-slide-title" className="modal-content__head">
                            <img src={closeIcon} alt="" onClick={onClose} className="btn-close" />
                            <div className="title">{t("staking.modalStake.title")}</div>
                        </DialogTitle>
                        <DialogContent className="modal-content__body">
                            <div className="boxTokenType">
                                <div className="token-type">
                                    <div>{t("staking.modalStake.token")}</div>
                                    <div className="token-detail">
                                        <div>{infoToken.token_symbol}</div>
                                    </div>
                                </div>
                                {isReward && Number(min) > 0 && (
                                    <div className="token-type">
                                        <div>{t("staking.modalStake.minStake")}</div>
                                        <div className="token-detail">{convertMin()}</div>
                                    </div>
                                )}
                                {isReward && Number(max) > 0 && (
                                    <div className="token-type">
                                        <div>{t("staking.modalStake.maxStake")}</div>
                                        <div className="token-detail">{convertMax()}</div>
                                    </div>
                                )}
                                {Number(infoToken?.total_staked) > 0 && (
                                    <div className="token-type">
                                        <div>{t("staking.modalStake.staked")}</div>
                                        <div className="token-detail">
                                            {take_decimal_numberFormatThousands(
                                                isReward
                                                    ? Number(infoToken?.total_staked)
                                                    : new BigNumber(infoToken?.total_staked)
                                                          .dividedBy(
                                                              10 ** Number(infoToken?.token_decimal)
                                                          )
                                                          .toNumber() || 0
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div className="token-type">
                                    <div> {t("staking.modalStake.balance")}</div>
                                    <div className="token-detail">
                                        <div>
                                            {take_decimal_numberFormatThousands(
                                                Number(balanceToken).toString() || 0
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="subtitle">{t("staking.modalStake.stakeAmount")}</div>
                            <div>
                                <div className="input-group">
                                    <input
                                        value={amount}
                                        onChange={(event) => setAmount(event.target.value)}
                                        type="number"
                                        min="0"
                                        placeholder={t("staking.modalStake.textPlaceholderInput")}
                                    />
                                </div>
                                {Number(amount) !== 0 && (
                                    <>
                                        {Number(amount) >
                                            Number(
                                                take_decimal_number(
                                                    balanceToken?.toString() || 0,
                                                    6
                                                )
                                            ) && (
                                            <p className="warningBalance">
                                                {t("staking.modalStake.warningBalance")}
                                            </p>
                                        )}
                                    </>
                                )}
                                {Number(amount) <= 0 && (
                                    <p className="warningBalance">
                                        {t("staking.modalStake.warningBigger0")}
                                    </p>
                                )}
                                {isReward && Number(amount) !== 0 && (
                                    <>
                                        {(Number(amount) > Number(convertMax()) ||
                                            Number(amount) < Number(convertMin())) && (
                                            <p className="warningMinMax">
                                                {t("staking.modalStake.warningMinMax")}
                                            </p>
                                        )}
                                    </>
                                )}
                                {isReward && Number(amount) !== 0 && (
                                    <>
                                        {Number(amount) >
                                            Number(
                                                take_decimal_number(
                                                    balanceToken?.toString() || 0,
                                                    6
                                                )
                                            ) &&
                                            Number(amount) < Number(convertMax) && (
                                                <p className="warningBalance">
                                                    {t("staking.modalStake.warningBalance")}
                                                </p>
                                            )}
                                    </>
                                )}
                            </div>

                            <div className="actionChangeAmount">
                                <Button
                                    text="25%"
                                    onClick={() =>
                                        setAmount(
                                            take_decimal_number(Number(balanceToken) * 0.25, 6) + ""
                                        )
                                    }
                                    backgroundColor="#545459"
                                />
                                <Button
                                    text="50%"
                                    onClick={() =>
                                        setAmount(
                                            take_decimal_number(Number(balanceToken) * 0.5, 6) + ""
                                        )
                                    }
                                    backgroundColor="#545459"
                                />
                                <Button
                                    text="75%"
                                    onClick={() =>
                                        setAmount(
                                            take_decimal_number(Number(balanceToken) * 0.75, 6) + ""
                                        )
                                    }
                                    backgroundColor="#545459"
                                />
                                <Button
                                    text="100%"
                                    onClick={() =>
                                        setAmount(take_decimal_number(Number(balanceToken), 6) + "")
                                    }
                                    backgroundColor="#545459"
                                />
                            </div>
                        </DialogContent>
                        <DialogActions className="modal-content__foot">
                            <Button
                                text={t("staking.modalStake.btnCancel")}
                                onClick={onClose}
                                className="btnCancel"
                            />
                            <Button
                                text={t("staking.modalStake.btnStake")}
                                className="btnStake"
                                onClick={() => {
                                    isReward
                                        ? Number(infoToken?.total_staked) > 0
                                            ? handleOpenModalConfirm()
                                            : handleConfirm()
                                        : handleConfirm();
                                }}
                                disabled={
                                    isNaN(amount) ||
                                    Number(amount) <= 0 ||
                                    Number(amount) >
                                        Number(
                                            take_decimal_number(balanceToken?.toString() || 0, 6)
                                        ) ||
                                    (isReward &&
                                        (Number(amount) > Number(convertMax()) ||
                                            Number(amount) < Number(convertMin())))
                                }
                            />
                        </DialogActions>
                    </div>
                </Dialog>
            )}
        </>
    );
};

export default ModalStake;
