import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { BaseRequest } from "../../request/Request";
import { sotaTiersActions } from "../constants/sota-tiers";

import { getTokenStakeAPIInfo } from "../../utils/campaign";

export const resetTiers = () => {
    return {
        type: sotaTiersActions.USER_TIER_RESET,
    };
};

export const getTiers = (forceUsingEther: string = "eth") => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
        dispatch({ type: sotaTiersActions.TIERS_LOADING });
        try {
            const baseRequest = new BaseRequest();
            const response = (await baseRequest.get(`/tiers`)) as any;
            const resObj = await response.json();
            if (!resObj.data) {
                dispatch({
                    type: sotaTiersActions.TIERS_FAILURE,
                    payload: new Error("Invalid tiers payload"),
                });
                return;
            }
            dispatch({
                type: sotaTiersActions.TIERS_SUCCESS,
                payload: resObj.data,
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: sotaTiersActions.TIERS_FAILURE,
                payload: error,
            });
        }
    };
};

export const getUserTier = (address?: string, forceUsingEther: string = "eth") => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
        dispatch({ type: sotaTiersActions.USER_TIER_LOADING });
        try {
            let userTier = {};
            const baseRequest = new BaseRequest();
            const response = (await baseRequest.get(`/users/tiers`, true)) as any;
            const resObj = await response.json();

            if (
                // resObj.statusCode && resObj.statusCode === 200 &&
                resObj.data
            ) {
                userTier = resObj.data;
            }

            dispatch({
                type: sotaTiersActions.USER_TIER_SUCCESS,
                payload: userTier,
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: sotaTiersActions.USER_TIER_FAILURE,
                payload: error,
            });
        }
    };
};

export const getUserInfo = (address: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
        dispatch({ type: sotaTiersActions.USER_INFO_LOADING });
        try {
            const { tokenStakes, userTier } = await getTokenStakeAPIInfo(address);
            dispatch({
                type: sotaTiersActions.USER_TIER_SUCCESS,
                payload: userTier,
            });

            dispatch({
                type: sotaTiersActions.USER_INFO_SUCCESS,
                payload: tokenStakes,
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: sotaTiersActions.USER_INFO_FAILURE,
                payload: error,
            });
        }
    };
};
