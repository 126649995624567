import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import _ from "lodash";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { take_decimal_number, take_decimal_numberFormatThousands } from "../../../constants";
import { convertTextAddress } from "../../../constants/formatData";
import { scrollIntoView } from "../../../utils/formatNumber";
import { getTransactionNoReward } from "../../../utils/stakingAPI";
import PaginationCustom from "../../PaginationCustom";
import ModalLoadingHomePage from "../../StakingPools/ModalLoading/ModalLoadingHomePage";
import useStyles from "./style";

export const convertColumnAmount = (amount: number, n: number) => {
    if (amount < 1000000) {
        return Math.trunc(amount * 10 ** n) / 10 ** n;
    } else if (amount >= 1000000000000) {
        return `${Math.trunc((amount * 10 ** n) / 1000000000000) / 10 ** n}T`;
    } else if (amount >= 1000000000) {
        return `${Math.trunc((amount * 10 ** n) / 1000000000) / 10 ** n}B`;
    } else return `${Math.trunc((amount * 10 ** n) / 1000000) / 10 ** n}M`;
};

const PointHistory = (props: any) => {
    const styles = useStyles();
    const { t } = useTranslation();
    const { account } = useWeb3React();
    const poolNoRewardCODE = useSelector((state: any) => state.poolNoRewardCODE.data);
    const poolNoRewardLP = useSelector((state: any) => state.poolNoRewardLP.data?.data);
    const [transactions, setTransactions] = useState<any[]>([]);
    const [countTransaction, setCountTransaction] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const transactionRef = useRef<any>();

    useEffect(() => {
        if (!account || !localStorage.getItem("investor_access_token")) return;
        (async () => {
            setLoading(true);
            const [res, error] = await getTransactionNoReward({
                page_size: 5,
                page_number: pageNumber,
            });
            if (res) {
                setTransactions(res.data);
                setCountTransaction(res.meta?.count);
            }

            if (error) console.log(error);
            setLoading(false);
        })();
    }, [account, localStorage.getItem("investor_access_token"), pageNumber]);

    console.log(transactions);

    return (
        <div className={styles.tabPointHistory}>
            <div className={styles.infoTab}>
                <div className={styles.intemInfoTab}>
                    <div className={styles.nameInfoTab}>
                        {t("account.myTier.tabTierHistory.textValueFromCODE")}
                    </div>
                    <div className={styles.valueInfoTab}>
                        {take_decimal_numberFormatThousands(
                            new BigNumber(poolNoRewardCODE?.total_staked)
                                .dividedBy(10 ** Number(poolNoRewardCODE?.token_decimal))
                                .toNumber() || 0
                        )}{" "}
                        CODE ~{" "}
                        {take_decimal_numberFormatThousands(
                            new BigNumber(poolNoRewardCODE?.total_staked)
                                .dividedBy(10 ** Number(poolNoRewardCODE?.token_decimal))
                                .toNumber() || 0
                        )}{" "}
                        USD
                    </div>
                </div>
                <div className={styles.intemInfoTab}>
                    <div className={styles.nameInfoTab}>
                        {t("account.myTier.tabTierHistory.textValueFromLP")}
                    </div>
                    <div className={styles.valueInfoTab}>
                        {take_decimal_numberFormatThousands(
                            Number(poolNoRewardLP?.total_code_staked) || 0
                        )}{" "}
                        CODE
                    </div>
                </div>
                <div className={styles.intemInfoTab}>
                    <div className={styles.nameInfoTab}>
                        {t("account.myTier.tabTierHistory.textTotalValue")}
                    </div>
                    <div className={styles.valueInfoTab}>
                        {take_decimal_numberFormatThousands(
                            new BigNumber(poolNoRewardCODE?.total_staked)
                                .dividedBy(10 ** Number(poolNoRewardCODE?.token_decimal))
                                .toNumber() + Number(poolNoRewardLP?.total_code_staked || 0) || 0
                        )}{" "}
                        CODE
                    </div>
                </div>
            </div>
            <p className={styles.transactionList} ref={transactionRef}>
                {t("account.myTier.tabTierHistory.textTransactionList")}
            </p>
            <TableContainer>
                <Table className="tableTransition" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ paddingLeft: 25 }}>
                                {t("account.myTier.tabTierHistory.table.titleCol1")}
                            </TableCell>
                            <TableCell>
                                {t("account.myTier.tabTierHistory.table.titleCol2")}
                            </TableCell>
                            <TableCell>
                                {t("account.myTier.tabTierHistory.table.titleCol3")}
                            </TableCell>
                            <TableCell>
                                {t("account.myTier.tabTierHistory.table.titleCol4")}
                            </TableCell>
                            <TableCell>
                                {t("account.myTier.tabTierHistory.table.titleCol5")}
                            </TableCell>
                            <TableCell>
                                {t("account.myTier.tabTierHistory.table.titleCol6")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(transactions) &&
                            transactions.length > 0 &&
                            transactions.map((transaction: any, index: number) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        <a
                                            href={`${process.env.REACT_APP_POLSCAN_BASE_URL}/tx/${transaction?.txhash}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {convertTextAddress(7, 0, transaction?.txhash)}
                                        </a>
                                    </TableCell>
                                    <TableCell>{_.capitalize(transaction?.action)}</TableCell>
                                    <TableCell>
                                        {convertColumnAmount(
                                            Number(
                                                (
                                                    Number(transaction?.amount) /
                                                    10 **
                                                        Number(
                                                            transaction?.pool_no_reward
                                                                ?.token_decimal || 18
                                                        )
                                                ).toFixed(0)
                                            ) || 0,
                                            2
                                        )}{" "}
                                        {transaction?.pool_no_reward?.token_symbol}
                                    </TableCell>
                                    <TableCell>
                                        {take_decimal_number(
                                            Number(
                                                Number(transaction?.amount) /
                                                    10 **
                                                        Number(
                                                            transaction?.pool_no_reward
                                                                ?.token_decimal || 18
                                                        ) /
                                                    Number(
                                                        transaction?.pool_no_reward
                                                            ?.conversion_rate || 1
                                                    )
                                            ).toFixed(0),
                                            2
                                        )}{" "}
                                        CODE
                                    </TableCell>
                                    <TableCell>
                                        {transaction?.current_tier === "rookie"
                                            ? "Super Rookie"
                                            : transaction?.current_tier}
                                    </TableCell>
                                    <TableCell>
                                        {moment(transaction?.created_at).format("MM/DD/YYYY HH:mm")}{" "}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                {!(transactions?.length > 0) && (
                    <div className="noDataTransaction">
                        <div className="box">
                            <img src="/images/account_v1/no_transaction.svg" alt="" />
                            {/* <p>{t("staking.reward.transactionHistory.noDataTransaction")}</p> */}
                        </div>
                    </div>
                )}
            </TableContainer>
            {Number(countTransaction) > 5 ? (
                <div className="paginationPools">
                    <PaginationCustom
                        count={Math.ceil(Number(countTransaction) / 5) || 1}
                        onChange={(page: number) => {
                            scrollIntoView(transactionRef);
                            setPageNumber(page - 1);
                        }}
                        currentPage={pageNumber + 1}
                    />
                </div>
            ) : (
                <div style={{ paddingBottom: 40 }}></div>
            )}
            <ModalLoadingHomePage
                open={loading}
                onClose={() => {
                    setLoading(false);
                }}
            />
        </div>
    );
};

export default PointHistory;
