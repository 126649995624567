import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        wrapper: {
            marginTop: 20,
            padding: 30,
            backgroundColor: "#131530",
            width: "100%",
            borderRadius: 20,
            minHeight: 345,
            [theme.breakpoints.down("xs")]: {
                padding: "20px 16px",
            },
            "&> .tab": {
                borderBottom: "1px solid #2B2C45",
                display: "flex",
                flexDirection: "row",
                fontSize: 16,
                fontWeight: 600,
                fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                color: "#fff",
                "&> div": {
                    opacity: 0.3,
                    marginRight: 35,
                    cursor: "pointer",
                },
                "&> .active": {
                    borderBottom: "3px solid #9747FF",
                    paddingBottom: 9,
                    opacity: 1,
                },
            },
            "&> .summary": {
                marginTop: 35,
                fontSize: 14,
                fontWeight: 400,
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                color: "#fff",
                opacity: 0.5,
                marginBottom: 20,
                "& p": {
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                },
                "&> div": {
                    width: "100%",
                    marginTop: 6,
                    [theme.breakpoints.down("md")]: {
                        width: "100%",
                    },
                },
            },
            "&> .snsTotal": {
                display: "flex",
                justifyContent: "center",
                "&> .sns": {
                    marginTop: 35,
                    display: "grid",
                    gridTemplateColumns: "repeat(5, 180px)",
                    gap: 36,
                    marginBottom: 20,
                    [theme.breakpoints.down("md")]: {
                        gridTemplateColumns: "repeat(4, 180px)",
                        gap: 26,
                    },
                    [theme.breakpoints.down("sm")]: {
                        gridTemplateColumns: "repeat(3, 165px)",
                        gap: 4,
                    },
                    [theme.breakpoints.down("xs")]: {
                        gridTemplateColumns: "repeat(1, 180px)",
                        gap: 16,
                    },
                    "&> img": {
                        cursor: "pointer",
                        maxWidth: 180,
                        height: 130,
                        borderRadius: 10,
                        objectFit: "cover",
                        [theme.breakpoints.down("sm")]: {
                            width: 165,
                            height: 125,
                        },
                        [theme.breakpoints.down("sm")]: {
                            width: 180,
                            height: 130,
                        },
                    },
                },
            },
        },
    };
});

export default useStyles;
