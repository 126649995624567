import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        wrapper: {
            background: "#131530",
            borderRadius: "12px",
            padding: "30px",
            height: "100%",
            [theme.breakpoints.down("sm")]: {
                padding: "16px !important",
            },
            "& .MuiSnackbar-root": {
                right: "0px !important",
                left: "70%",
                transform: "translate(0,0)",
                top: 100,
                justifyContent: "flex-end",
                height: 100,
                width: 460,
                borderRadius: "10px 0px 0px 10px",
                [theme.breakpoints.down("md")]: {
                    left: "0% !important",
                    width: "100%",
                },
                [theme.breakpoints.down("sm")]: {
                    left: "0% !important",
                    width: "100%",
                    top: "100px !important",
                    borderRadius: "0px 0px 0px 0px",
                },
                [theme.breakpoints.down("xs")]: {
                    top: "80px !important",
                },
                "& .MuiSnackbarContent-root": {
                    background: "#E4C96A",
                    width: 460,
                    minHeight: 100,
                    borderRadius: "10px 0px 0px 10px",
                    [theme.breakpoints.down("md")]: {
                        height: "auto",
                    },
                    [theme.breakpoints.down("sm")]: {
                        borderRadius: "0px 0px 0px 0px",
                    },
                    "& p": {
                        fontSize: 18,
                        fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                        [theme.breakpoints.down("sm")]: {
                            fontSize: 14,
                        },
                    },
                    "& span": {
                        fontSize: 14,
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        [theme.breakpoints.down("sm")]: {
                            fontSize: 12,
                        },
                    },
                },
                "& .messageWaring": {
                    display: "flex",
                    alignItems: "flex-start",
                    "& .iconWarning": {
                        width: 27,
                        height: 27,
                        marginRight: 15,
                    },
                    "& .content": {
                        marginRight: 84,
                    },
                },
                "& .iconClose": {
                    cursor: "pointer",
                },
            },
        },

        headPage: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            paddingBottom: 8,
            borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
            marginBottom: 32,
            alignItems: "center",
            [theme.breakpoints.down("sm")]: {
                alignItems: "center",
                marginBottom: 20,
            },
        },

        title: {
            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
            fontSize: 20,
            lineHeight: "30px",
            color: "#FFFFFF",

            [theme.breakpoints.down("sm")]: {
                fontSize: 16,
                lineHeight: "24px",
            },
        },

        btnEditProfile: {
            minWidth: 120,
            borderColor: "transparent",
            height: 28,
            background: "transparent",
            fontSize: 14,
            lineHeight: "22px",
            textAlign: "center",
            color: "#2DDBFF",
            textTransform: "initial",
            boxShadow: "none",
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            [theme.breakpoints.down("sm")]: {
                fontWeight: 500,
                fontSize: 16,
                lineHeight: "24px",
                padding: 0,
                "& .MuiTouchRipple-root": {
                    display: "none ",
                },
            },

            "& img": {
                marginLeft: 6,
            },

            "&:disabled": {
                border: "1.4px solid #6398FF",
                background: "transparent",
                color: "#6398FF",
                opacity: 0.5,
                cursor: "not-allowed",
            },

            "&:hover": {
                background: "transparent",
                opacity: 0.85,
            },
        },

        mainInfomation: {},

        inputGroup: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: 16,
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            fontSize: 14,
            lineHeight: "20px",
            color: "#FFFFFF",

            [theme.breakpoints.down("sm")]: {
                marginTop: 12,
            },

            "& .box": {
                display: "flex",
                alignItems: "center",
                minWidth: 160,
                marginRight: 60,
                "& img": {
                    marginLeft: 4,
                    width: 16,
                    height: 16,
                },
                "& span": {
                    whiteSpace: "nowrap",
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    fontSize: 14,
                    lineHeight: "22px",
                    color: "rgba(255, 255, 255, 0.3)",
                },
            },

            "& .flex": {
                display: "flex",
                alignItems: "center",
                color: "white",
                width: "100%",
                "& span": {
                    display: "flex",
                },
            },

            "& > span:first-child": {
                minWidth: 160,
                whiteSpace: "nowrap",
                marginRight: 60,
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                fontSize: 14,
                lineHeight: "22px",
                color: "rgba(255, 255, 255, 0.3)",
                display: "flex",
                [theme.breakpoints.down("sm")]: {
                    marginRight: 8,
                },
            },
            "& > span:last-child": {
                marginRight: 60,
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                fontSize: 14,
                lineHeight: "22px",
                [theme.breakpoints.down("sm")]: {
                    marginRight: 8,
                },
            },

            "& span.verify-email": {
                textDecoration: "underline",
                cursor: "pointer",
                width: "auto",
            },

            "& .verify-email": {
                fontSize: 12,
                cursor: "pointer",
            },

            "& div.unverified": {
                marginRight: 12,
                color: "#FF3333",
                fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                display: "flex",
                alignItems: "center",
                "& span": {
                    lineHeight: "15px",
                },
            },

            "& div.verified": {
                color: "#3C974B",
                fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                display: "flex",
                alignItems: "center",
            },

            "& button": {
                fontStyle: "normal",
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                fontSize: "14px",
                lineHeight: "18px",
                mixBlendMode: "normal",
                minWidth: "90px",
                height: "30px",
                boxSizing: "border-box",
                borderRadius: "36px",
                background: "none",
                cursor: "pointer",
                color: "white",
                backgroundColor: "#0F69E1",
                border: "none",
            },
        },

        tooltip: {
            fontSize: 12,
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            padding: "13.5px 12px",
            backgroundColor: "#3E405B",
            maxWidth: 230,
            borderRadius: 5,
            color: "rgba(255, 255, 255, .7)",
        },

        iconStatus: {
            marginLeft: 4,
        },

        groupInput: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
        },

        errorInput: {
            color: "red",
            fontSize: 12,
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
        },

        inputNewValue: {
            width: "100%",
            maxWidth: 365,
            backgroundColor: "transparent",

            "&>div:before, &>div:after": {
                display: "none",
            },

            [theme.breakpoints.down("sm")]: {
                width: "100%",
                maxWidth: "100%",
            },

            "& input": {
                width: "100%",
                height: 18,
                background: "transparent",
                border: "2px solid rgba(255, 255, 255, 0.15)",
                borderRadius: 10,
                padding: "12px 16px",
                fontSize: 14,
                lineHeight: "22px",
                color: "rgba(255, 255, 255, 0.3)",

                [theme.breakpoints.down("sm")]: {
                    width: "100%",
                    maxWidth: "100%",
                    marginTop: 4,
                    padding: "9px 16px",
                },

                "&::placeholder": {
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    fontSize: 14,
                    lineHeight: "20px",
                    color: "#AEAEAE",
                },
            },
        },

        nameSocial: {
            [theme.breakpoints.down("sm")]: {
                marginTop: 8,
            },
        },

        codeDaoInfo: {
            marginTop: "25px",

            "& .kyc-info": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
            },

            "& .kyc-info span": {
                font: "normal normal normal 14px/24px Helvetica",
                color: "#fff",
            },

            [theme.breakpoints.down("xs")]: {
                "& .kyc-info": {
                    flexDirection: "column",
                    alignItems: "flex-start",
                },
            },
        },

        walletInfo: {
            display: "flex",
            flexDirection: "column",
            background: "rgba(255, 255, 255, 0.06)",
            borderRadius: "8px",
            width: "100%",
            marginTop: "15px",
            padding: "26px 22px",

            "& p": {
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "14px",
                lineHeight: "24px",
                color: "#999999",
            },
            "& span": {
                color: "#FFFFFF",
                fontSize: "28px",
                lineHeight: "32px",
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                fontStyle: "normal",
            },
        },

        [theme.breakpoints.down("sm")]: {
            wrapper: {
                padding: "24px 20px",
            },
            inputGroup: {
                flexDirection: "column",
                alignItems: "flex-start",

                "& span": {
                    fontWeight: "normal !important",
                },
            },
        },

        footerPage: {
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 32,
            [theme.breakpoints.down("sm")]: {
                marginTop: 20,
                paddingBottom: 8,
            },
        },

        btnUpdateProfile: {
            minWidth: 145,
            borderRadius: 36,
            height: 40,
            background: "#0F69E1",
            border: 0,
            fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
            fontSize: 14,
            lineHeight: "18px",
            textAlign: "center",
            color: "#FFFFFF",
            textTransform: "initial",

            [theme.breakpoints.down("sm")]: {
                minWidth: "100%",
                height: 43,
                fontWeight: 500,
                fontSize: 12,
                lineHeight: "24px",
            },
            "&:hover": {
                background: "#3232DC",
                opacity: 0.85,
            },
        },
    };
});

export default useStyles;
