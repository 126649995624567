import { BaseRequest } from "../request/Request";

export const getTiers = async () => {
    try {
        const baseRequest = new BaseRequest();
        const response = (await baseRequest.get(`/tiers`)) as any;
        const resObj = await response.json();
        return [resObj.data, null];
    } catch (error) {
        return [null, error];
    }
};

export const getPoolsNoRewardCODE = async () => {
    try {
        const baseRequest = new BaseRequest();
        const response = (await baseRequest.get(`/pools/no-reward/code`)) as any;
        const resObj = await response.json();
        return [resObj.data, null];
    } catch (error) {
        return [null, error];
    }
};

export const getPoolsNoRewardLPs = async () => {
    try {
        const baseRequest = new BaseRequest();
        const response = (await baseRequest.get(`/pools/no-reward/lps`)) as any;
        const resObj = await response.json();
        return [resObj.data, null];
    } catch (error) {
        return [null, error];
    }
};

export const getTransactionReward = async ({
    page_size,
    page_number,
}: {
    page_size: number;
    page_number: number;
}) => {
    try {
        const baseRequest = new BaseRequest();
        const response = (await baseRequest.get(
            `/pools/reward/stake-history?page_size=${page_size}&page_number=${page_number}`,
            true
        )) as any;
        const resObj = await response.json();
        return [resObj, null];
    } catch (error) {
        return [null, error];
    }
};

export const getTransactionNoReward = async ({
    page_size,
    page_number,
}: {
    page_size: number;
    page_number: number;
}) => {
    try {
        const baseRequest = new BaseRequest();
        const response = (await baseRequest.get(
            `/pools/no-reward/stake-history?page_size=${page_size}&page_number=${page_number}`,
            true
        )) as any;
        const resObj = await response.json();
        return [resObj, null];
    } catch (error) {
        return [null, error];
    }
};
