import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { AppContext } from "../../../../AppContext";
import Countdown from "../../../../components/Base/Countdown";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { getContractConnect } from "../../../../services/web3";
import { caver } from "../../../../store/actions/user";
import { myTicket } from "../../Api";
import ModalYourTicket from "../../Modal/ModalYourTickets";
import GET_BALANCE_USDC_ABI from "./../../../../abi/TokenUSDC.json";
import useStyles from "./style";
import TierLegendBuyTicket from "./TierLegend";
import TierStarterAndRookieBuyTicket from "./TierStarterAndRookie";

type TParams = {
    id: "string";
};

type Props = {
    dataSnapshot: any;
};

export default function TierAccountBuyTicket({ dataSnapshot }: Props) {
    const styles = useStyles();
    // const dataYourTicket = props.data
    const { t } = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const [decimals, setDecimals] = useState<any>();
    const status = useSelector((state: any) => state.statusIdoPool.message);
    const { id } = useParams<TParams>();
    // const [data, setData] = useState<any>();
    const [balance, setBalance] = useState<any>(0);
    // const [proof, setProof] = useState<any>();
    // const [idoDetail, setIdoDetail] = useState<any>();
    const idoDetail = useSelector((state: any) => state.idoDetail);
    const { currentConnectedWallet } = useContext(AppContext);
    const currentAccount = currentConnectedWallet && currentConnectedWallet.addresses[0];
    const { account } = useWeb3React();

    const { appChainID } = useTypedSelector((state: any) => state.appNetwork).data;

    const getContractTokenUsdc = (): string => {
        if (idoDetail?.data?.network === "bsc") {
            const contractTokenUsdc = process.env.REACT_APP_BALANCE_USDC_BSC || "";
            return contractTokenUsdc;
        } else if (idoDetail?.data?.network === "klaytn") {
            const contractTokenUsdc = process.env.REACT_APP_BALANCE_USDC_KLAYTN || "";
            return contractTokenUsdc;
        } else if (idoDetail?.data?.network === "polygon") {
            const contractTokenUsdc = process.env.REACT_APP_BALANCE_USDC_POLYGON || "";
            return contractTokenUsdc;
        }
        return process.env.REACT_APP_BALANCE_USDC_BSC || "";
    };
    const convertTimeTicketClose = moment(idoDetail?.data?.start_ticket_close).format(
        "MM/DD/YYYY HH:mm:ss"
    );

    const [dataYourTicket, setDataYourTicket] = useState<any>();

    useEffect(() => {
        let wallet = localStorage.getItem("walletconnect-name");
        if (!wallet) {
            wallet = "";
        }
        (async () => {
            let number_of_retry = 5;
            while (number_of_retry > 0) {
                try {
                    const contract = await getContractConnect(
                        GET_BALANCE_USDC_ABI,
                        getContractTokenUsdc(),
                        appChainID,
                        wallet
                    );
                    if (contract) {
                        const decimalUsdc = await contract?.methods.decimals().call();
                        setDecimals(decimalUsdc);
                        if (account && localStorage.getItem("investor_access_token")) {
                            const getBalanceUsdc = await contract?.methods
                                .balanceOf(currentAccount)
                                .call();
                            setBalance(
                                new BigNumber(getBalanceUsdc)
                                    .dividedBy(new BigNumber(10).pow(decimals))
                                    .toString()
                            );
                        }
                        number_of_retry = 0;
                    } else {
                        number_of_retry--;
                    }
                } catch (err) {
                    number_of_retry--;
                    if (number_of_retry === 0) {
                        console.log("Get balance error: ", err);
                    }
                }
            }
        })();
    }, [account, appChainID, currentAccount, decimals, localStorage.getItem("walletconnect-name")]);

    const getBalanceUsdc = async () => {
        const contractUsdc = getContractTokenUsdc();

        let wallet = localStorage.getItem("walletconnect-name");
        if (!wallet) {
            wallet = "";
        }

        const contract = await getContractConnect(
            GET_BALANCE_USDC_ABI,
            contractUsdc,
            appChainID,
            wallet
        );
        try {
            const getBalanceUsdc = await contract?.methods.balanceOf(currentAccount).call();

            setBalance(
                new BigNumber(getBalanceUsdc).dividedBy(new BigNumber(10).pow(decimals)).toString()
            );
            return getBalanceUsdc;
        } catch (error) {
            console.error(error);
        }
    };

    const getDataYourTicket = useCallback(async () => {
        if (!currentAccount) return;
        if (status !== "Buy Ticket") return;
        if (dataSnapshot?.aprove_whitelist === false) return;
        if (idoDetail?.data?.state === "SNAPSHOTTED") {
            myTicket(id).then((res) => {
                setDataYourTicket(res);
            });
        }
    }, [currentAccount, dataSnapshot?.aprove_whitelist, id, idoDetail?.data?.state, status]);

    useEffect(() => {
        // if (!currentAccount) return;
        // if (status !== "Buy Ticket") return;
        // if (data?.aprove_whitelist === false) return;
        // if (idoDetail?.data?.state === "SNAPSHOTTED") {
        //     myTicket(id).then((res) => {
        //         setDataYourTicket(res);
        //     });
        // }
        getDataYourTicket();
    }, [getDataYourTicket]);

    const handleClose = () => {
        setOpen(false);
    };

    const renderOpenModalYourTicket = () => (
        <ModalYourTicket open={open} onClose={handleClose} dataYourTicket={dataYourTicket} />
    );
    const handleClickBuyTicket = () => {
        setOpen(true);
    };

    const handleFormatPrice = () => {
        const str = idoDetail?.data?.price.toString().split(".");
        return (
            (dataYourTicket?.ticketBought * idoDetail?.data?.price).toFixed(str.length >= 2 ? str[1].length : 0) || 0
        );
    };
    return (
        <div className={styles.wrapper}>
            <div className={styles.snapshotIn}>
                <p>{t("ProjectDetails.buyTicketEnd")}</p>
                <Countdown startDate={new Date(convertTimeTicketClose)} />
                <div className={styles.deposit}>
                    <div>
                        <p>{t("ProjectDetails.deposit")}</p>
                        <div>
                            <p>
                                {t("ProjectDetails.balance")}
                                {/* (    {idoDetail?.data?.network.toUpperCase()}): */}
                            </p>
                            <p>{balance} USDC</p>
                        </div>
                    </div>
                    <div>
                        <div>
                            <p>
                                {dataYourTicket?.ticketBought || 0} {t("ProjectDetails.tickets")}
                            </p>
                            <p>
                                {/* = {dataYourTicket?.ticketBought * idoDetail?.data?.price || 0} USDC */}
                            </p>
                        </div>
                        <div>
                            <p>={handleFormatPrice()} USDC</p>
                            {/* <img src="images/home-page/iconUSDC.svg" alt="" />
                            <p>USDC</p> */}
                        </div>
                    </div>
                    <div onClick={handleClickBuyTicket}>
                        <p>{t("ProjectDetails.viewYourTicket")}</p>
                        <img src="/images/home-page/iconWebsite.svg" alt="" />
                    </div>
                    {renderOpenModalYourTicket()}
                </div>
                {dataSnapshot?.tier === "Legend" ? (
                    <TierLegendBuyTicket
                        idoDetail={idoDetail?.data}
                        decimals={decimals}
                        dataSnapshot={dataSnapshot}
                        balanceOf={balance}
                        totalAmount={dataYourTicket?.ticketBought}
                        getBalanceUsdc={getBalanceUsdc}
                        getDataYourTicket={getDataYourTicket}
                        contractTokenUsdc={getContractTokenUsdc()}
                    />
                ) : (
                    <TierStarterAndRookieBuyTicket
                        idoDetail={idoDetail?.data}
                        dataSnapshot={dataSnapshot}
                        decimals={decimals}
                        balanceOf={balance}
                        totalAmount={dataYourTicket?.ticketBought}
                        getBalanceUsdc={getBalanceUsdc}
                        getDataYourTicket={getDataYourTicket}
                        contractTokenUsdc={getContractTokenUsdc()}
                    />
                )}
            </div>
        </div>
    );
}
