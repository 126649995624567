import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        wrapper: {
            padding: 30,
            backgroundColor: "#131530",
            width: 650,
            borderRadius: 20,
            [theme.breakpoints.down("sm")]: {
                padding: 20,
            },
            [theme.breakpoints.down("xs")]: {
                padding: 10,
            },
            "&> p": {
                color: "white",
                fontWeight: 600,
                fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                fontSize: 16,
                paddingBottom: 8,
                borderBottom: "1px solid #2B2C45",
            },
            "&> .connect": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: 350,
                [theme.breakpoints.down("xs")]: {
                    height: 200,
                },
                "&> p": {
                    fontWeight: 600,
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    fontSize: 20,
                    color: "white",
                },
                "&> .btnConnect": {
                    marginTop: 37,
                    fontWeight: 700,
                    fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                    fontSize: 14,
                    color: "white",
                    cursor: "pointer",
                    padding: "15px 42px",
                    background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                    borderRadius: 90,
                    textTransform: "capitalize",
                    [theme.breakpoints.down("xs")]: {
                        marginTop: 25,
                        padding: "12px 35px",
                    },
                },
            },
        },
        snapshotIn: {
            marginTop: 25,
            "&> p": {
                marginBottom: 12,
                fontSize: 14,
                fontWeight: 400,
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                color: "white",
                opacity: 0.5,
            },
            "&> .desc": {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 56,
                "&> p": {
                    fontSize: 14,
                    fontWeight: 400,
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    color: "white",
                    opacity: 0.5,
                    whiteSpace: "nowrap",
                },
                "&> .btnSuperRookie": {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "rgba(151, 71, 255, 0.2)",
                    borderRadius: 70,
                    border: "none",
                    padding: "8px 18px",
                    color: "white",
                    margin: "0px 10px",
                    textTransform: "capitalize",
                    fontSize: 12,
                    "&> img": {
                        marginRight: 6,
                    },
                },
            },
            "&> .btn": {
                marginTop: 100,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                "&> .btnStakeMore": {
                    color: "white",
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                    padding: "10px 85px",
                    background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                    borderRadius: 90,
                    textTransform: "capitalize",
                },
                "&> .btnApplyWhitelist": {
                    color: "#9747FF",
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                    padding: "10px 70px",
                    background: "none",
                    border: "2px solid #9747FF",
                    borderRadius: 90,
                    textTransform: "capitalize",
                },
            },
        },
        swapTier: {
            background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
            borderRadius: 90,
            color: "white",
            fontWeight: 700,
            fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
            fontSize: 16,
            textTransform: "capitalize",
            padding: "4px 30px",
            marginBottom: "10px",
        },
    };
});

export default useStyles;
