import { css } from "@emotion/core";
import { Hidden } from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";
import { useWeb3React } from "@web3-react/core";
import { default as i18n, default as i18next } from "i18next";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, withRouter } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { AppContext } from "../../../AppContext";
import ButtonLink from "../../../components/Base/ButtonLink";
import AppNetworkSwitch from "../../../components/Base/HeaderDefaultLayout/AppNetworkSwitch";
import ConnectWalletModal from "../../../components/Base/HeaderDefaultLayout/ConnectWalletModal";
import { HeaderContext } from "../../../components/Base/HeaderDefaultLayout/context/HeaderContext";
import WalletDisconnect from "../../../components/Base/HeaderDefaultLayout/WalletDisconnect";
import { CHAIN_ID_NETWORK } from "../../../constants";
import { connectorNames, connectorsByName } from "../../../constants/connectors";
import useAuth from "../../../hooks/useAuth";
import { getTiers, getUserTier } from "../../../store/actions/sota-tiers";
import { WalletConnectionState } from "../../../store/reducers/wallet";
import { trimMiddlePartAddress } from "../../../utils/accountAddress";
import useStyles from "./styles";

const WalletIcon = "iconConnect.svg";
const logo = "/images/home-page/BBGfastraise250.png";
const iconHamburger = "/images/icons/hamburger.svg";
// const EthereumIcon = "/images/ethereum.svg";

const HeaderDefaultLayout: React.FC<any> = (props: any) => {
    const { pathname } = useLocation();
    const styles = useStyles();
    const dispatch = useDispatch();
    const { account } = useWeb3React();
    const { t } = useTranslation();
    const [switchNetworkDialog, setSwitchNetworkDialog] = useState<boolean>(false);
    const [disconnectDialog, setDisconnectDialog] = useState<boolean>(false);
    const [agreedTerms, setAgreedTerms] = useState<boolean>(false);
    // const { appChainID } = useSelector((state: any) => state.appNetwork).data;
    const walletsInfo = useSelector((state: any) => state.wallet).entities;
    const [openSideBar, setOpenSideBar] = useState(false);
    const { data: message = "" } = useSelector((state: any) => state.messages);
    const { networkID: networkWallet } = useSelector((state: any) => state.networkWallet);

    // const [chainLogo, setChainLogo] = useState<String>(EthereumIcon);
    // const [chainName, setChainName] = useState<String>("Ethereum");
    // const [chainCurrency, setChainCurrency] = useState<String>("ETH");

    const { connectedAccount } = useAuth();

    const [isLangBoxOpen, setIsLangBoxOpen] = useState<boolean>(false);

    const lang = i18next.language;

    const renderLanguage = () => {
        if (lang === "en") {
            return "English";
        } else if (lang === "ko") {
            return "Korea";
        }
    };

    useEffect(() => {
        if (connectedAccount && localStorage.getItem("investor_access_token")) {
            dispatch(getUserTier());
        }
    }, [connectedAccount, dispatch]);

    useEffect(() => {
        dispatch(getTiers());
    }, [dispatch]);

    const {
        handleProviderChosen,
        currentConnector,
        walletName,
        setWalletName,
        loginError,
        currentConnectedWallet,
        setCurrentConnectedWallet,
        openConnectWallet,
        setOpenConnectWallet,
        connectWalletLoading,
    } = useContext(AppContext);

    const tierAccount = useSelector((state: any) => state.userTier.data.name);

    const handleConnectWalletClose = () => {
        setOpenConnectWallet && setOpenConnectWallet(false);
    };
    const handleSetLang = (lang: "en" | "ko") => {
        switch (lang) {
            case "en":
                i18n.changeLanguage("en");
                break;
            case "ko":
                i18n.changeLanguage("ko");
                break;
            default:
                i18n.changeLanguage("en");
        }
    };

    const renderIconUser = useCallback(() => {
        if (Number(networkWallet) === 97 && currentConnectedWallet?.title === "Web3") {
            return <img src="/images/home-page/iconNetworkBsc.svg" alt="User" />;
        } else if (
            Number(networkWallet) === Number(process.env.REACT_APP_KLAYTN_CHAIN_ID) ||
            currentConnectedWallet?.title === "Kaikas"
        ) {
            return <img src="/images/home-page/iconNetworkKlay.svg" alt="User" />;
        } else {
            return <img src="/images/home-page/iconNetworkPolygon.svg" alt="User" />;
        }
    }, [currentConnectedWallet?.title, networkWallet]);

    const toggleLangBtn = () => {
        setIsLangBoxOpen((preState) => !preState);
    };
    const handleConnectWalletOpen = () => {
        setOpenConnectWallet && setOpenConnectWallet(true);
        setOpenSideBar(false);
    };

    const handleDisconnectDialogOpen = () => {
        setDisconnectDialog(true);
        setOpenSideBar(false);
    };

    useEffect(() => {
        if (walletsInfo && walletName) {
            let currentWalletsName: string[] = [];
            let isFound = false;

            Object.keys(walletsInfo).forEach((key) => {
                const wallet = walletsInfo[key];

                if (
                    wallet.addresses.length > 0 &&
                    wallet.connectionState === WalletConnectionState.CONNECTED &&
                    !isFound
                ) {
                    isFound = true;
                    setCurrentConnectedWallet && setCurrentConnectedWallet(wallet);
                    currentWalletsName.push(key);
                }
            });

            if (currentWalletsName.length > 0 && walletName.length === 0 && !currentConnector) {
                const chooseWallet = currentWalletsName[0] as connectorNames;

                setWalletName && setWalletName(currentWalletsName);
                handleProviderChosen &&
                    handleProviderChosen(chooseWallet, connectorsByName[chooseWallet]);
            }
        }
    }, [
        walletsInfo,
        walletName,
        currentConnector,
        setCurrentConnectedWallet,
        setWalletName,
        handleProviderChosen,
    ]);

    return (
        <>
            <div className={styles.navBar} tabIndex={1} onBlur={() => setOpenSideBar(false)}>
                {/* {isWidthDown("sm", props.width) ? ( */}
                <Hidden mdUp>
                    <div className={styles.container}>
                        <Link to={""} className={styles.navbarLink}>
                            <img className="logoSidebar" src={logo} alt="" />
                        </Link>
                        <div className="itemMenu">
                            <ButtonLink
                                text="IDO"
                                to={"/"}
                                className={`${styles.btn} start1 ${
                                    pathname === "/" ? "active" : ""
                                }`}
                                // onClick={handleClickPoolList}
                            />
                            <ButtonLink
                                text="Staking"
                                to={"/staking-pools"}
                                className={`${styles.btn} start1 ${
                                    pathname === "/staking-pools" ? "active" : ""
                                }`}
                            />
                        </div>
                    </div>
                    <div className={styles.rightHeadMobile}>
                        <img src={iconHamburger} onClick={() => setOpenSideBar(true)} alt="" />
                    </div>
                    <div className={styles.rightBar + (openSideBar ? " active" : "")}>
                        <>
                            <img src={logo} className={styles.sideBarLogo} alt="logo" />
                            {/* <img
                                src="/images/home-page/iconCloseSideBarMenu.svg"
                                className={styles.closeBtn}
                                onClick={() => setOpenSideBar(false)}
                                alt="icon"
                                style={{ cursor: "pointer" }}
                            /> */}
                            <div
                                style={{ cursor: "pointer" }}
                                className={styles.closeBtn}
                                onClick={() => setOpenSideBar(false)}
                            >
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M16 1.6L14.4 0L8 6.4L1.6 0L0 1.6L6.4 8L0 14.4L1.6 16L8 9.6L14.4 16L16 14.4L9.6 8L16 1.6Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                        </>
                        <div className={styles.sideBarContent}>
                            <div>
                                <ButtonLink
                                    text="IDO"
                                    to={"/"}
                                    className={`${styles.btn} start ${
                                        pathname === "/" ? "active" : ""
                                    }`}
                                />
                                <ButtonLink
                                    text="Staking"
                                    to={"/staking-pools"}
                                    className={`${styles.btn} start ${
                                        pathname === "/staking-pools" ? "active" : ""
                                    }`}
                                />
                                {account && (
                                    <a href={"#/account"}>
                                        {tierAccount && (
                                            <span className={styles.tierUserSidebar}>
                                                <img
                                                    src="/images/home-page/tierAccount.svg"
                                                    alt=""
                                                />
                                                <p className={styles.tierSidebar}>
                                                    {tierAccount} Tier
                                                </p>
                                            </span>
                                        )}
                                    </a>
                                )}
                                {account &&
                                    !Object.values(CHAIN_ID_NETWORK)?.includes(
                                        Number(networkWallet)
                                    ) && (
                                        <button className={styles.wrongNetwork}>
                                            <img src="/images/icons/wrong_network.svg" alt="" />
                                            <p>Wrong Network</p>
                                        </button>
                                    )}
                                {(Object.values(CHAIN_ID_NETWORK)?.includes(
                                    Number(networkWallet)
                                ) ||
                                    !account) && (
                                    <button
                                        className={`${styles.btn} ${styles.btnConnect}`}
                                        onClick={() => {
                                            if (!connectWalletLoading) {
                                                !account
                                                    ? handleConnectWalletOpen()
                                                    : handleDisconnectDialogOpen();
                                            }
                                        }}
                                        disabled={connectWalletLoading}
                                    >
                                        {!connectWalletLoading ? (
                                            <>
                                                {!account && (
                                                    <img
                                                        src={`/images/home-page/${WalletIcon}`}
                                                        alt="wallet"
                                                    />
                                                )}
                                                {account && renderIconUser()}
                                                <span
                                                    className={`${styles.btnConnectText} ${
                                                        account ? styles.btnAccount : ""
                                                    }`}
                                                >
                                                    {(account &&
                                                        `${trimMiddlePartAddress(account)}`) ||
                                                        t("header.connectWallet")}
                                                </span>
                                                {account && (
                                                    <img
                                                        src="/images/home-page/arrowDownIcon.svg"
                                                        alt=""
                                                    />
                                                )}
                                            </>
                                        ) : (
                                            <BeatLoader
                                                color={"white"}
                                                css={css`
                                                    margin-top: 3px;
                                                `}
                                                size={10}
                                            />
                                        )}
                                    </button>
                                )}
                            </div>
                            <div
                                // tabIndex={1}
                                onClick={toggleLangBtn}
                                // onBlur={() => setIsLangBoxOpen(false)}
                                className={styles.btnLangSidebar}
                            >
                                {renderLanguage()}
                                <img src="/images/home-page/arrowDownIcon.svg" alt="" />
                                {isLangBoxOpen && (
                                    <div className="btnLangBox">
                                        <p onClick={() => handleSetLang("en")}>English</p>
                                        <p onClick={() => handleSetLang("ko")}>Korea</p>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* <SideBar /> */}
                    </div>
                </Hidden>
                {/* ) : ( */}
                <Hidden smDown>
                    <>
                        <Link to={"/"} className={styles.navbarLogo}>
                            <img src={logo} alt="" />
                        </Link>
                        <ButtonLink
                            text="IDO"
                            to={"/"}
                            className={`${styles.btn}  start ${pathname === "/" ? "active" : ""}`}
                            // onClick={handleClickPoolList}
                        />
                        <ButtonLink
                            text="Staking"
                            to={"/staking-pools"}
                            className={`${styles.btn} start ${
                                pathname === "/staking-pools" ? "active" : ""
                            }`}
                        />
                        <div className={styles.spacer}></div>

                        {account && (
                            <div className={styles.tierAccount}>
                                <a href={"#/account"} className={`${styles.btn} start my-account`}>
                                    {tierAccount && (
                                        <span>
                                            <img src="/images/home-page/tierAccount.svg" alt="" />
                                            <p className={styles.tierUser}>{tierAccount} Tier</p>
                                        </span>
                                    )}
                                </a>
                                {/* <span className="valueCoin">
                                    {account &&
                                        (!loginError ? `${balance} ${chainCurrency}` : "0")}
                                </span> */}
                            </div>
                        )}
                        {account &&
                            !Object.values(CHAIN_ID_NETWORK)?.includes(Number(networkWallet)) && (
                                <button className={styles.wrongNetwork}>
                                    <img src="/images/icons/wrong_network.svg" alt="" />
                                    <p>Wrong Network</p>
                                </button>
                            )}

                        {(Object.values(CHAIN_ID_NETWORK)?.includes(Number(networkWallet)) ||
                            !account) && (
                            <button
                                className={`${styles.btn} ${styles.btnConnect}`}
                                onClick={() => {
                                    if (!connectWalletLoading) {
                                        !account
                                            ? handleConnectWalletOpen()
                                            : handleDisconnectDialogOpen();
                                    }
                                }}
                                disabled={connectWalletLoading}
                            >
                                {!connectWalletLoading ? (
                                    <>
                                        {!account && (
                                            <img
                                                src={`/images/home-page/${WalletIcon}`}
                                                alt="wallet"
                                            />
                                        )}
                                        {account && renderIconUser()}
                                        <span
                                            className={`${styles.btnConnectText} ${
                                                account ? styles.btnAccount : ""
                                            }`}
                                        >
                                            {(account && `${trimMiddlePartAddress(account)}`) ||
                                                // t("header.connectWallet")
                                                t("header.connectWallet")}
                                        </span>
                                        {account && (
                                            <img src="/images/home-page/arrowDownIcon.svg" alt="" />
                                        )}
                                    </>
                                ) : (
                                    <BeatLoader
                                        color={"white"}
                                        css={css`
                                            margin-top: 3px;
                                        `}
                                        size={10}
                                    />
                                )}
                            </button>
                        )}

                        <div
                            onClick={toggleLangBtn}
                            tabIndex={1}
                            onBlur={() => setIsLangBoxOpen(false)}
                            className={styles.btnLang}
                        >
                            {renderLanguage()}
                            <img src="/images/home-page/arrowDownIcon.svg" alt="" />
                            {isLangBoxOpen && (
                                <div className="btnLangBox">
                                    <p onClick={() => handleSetLang("en")}>English</p>
                                    <p onClick={() => handleSetLang("ko")}>Korea</p>
                                </div>
                            )}
                        </div>
                    </>
                </Hidden>
                {/* )} */}
            </div>
            <HeaderContext.Provider value={{ agreedTerms, setAgreedTerms }}>
                <ConnectWalletModal
                    opened={openConnectWallet as boolean}
                    handleClose={handleConnectWalletClose}
                />
                <AppNetworkSwitch
                    opened={switchNetworkDialog}
                    handleClose={() => setSwitchNetworkDialog(false)}
                />
                <WalletDisconnect
                    opened={disconnectDialog}
                    handleClose={() => {
                        setDisconnectDialog(false);
                        setAgreedTerms(false);
                        setOpenSideBar(false);
                    }}
                    currentWallet={currentConnectedWallet}
                />
            </HeaderContext.Provider>
            {loginError && props?.isKYC && (
                <div className={styles.loginErrorBanner}>
                    <img
                        src="/images/red-warning.svg"
                        alt="red-warning icon"
                        className={styles.iconWarning}
                    />
                    <span className={styles.loginErrorBannerText}>
                        {loginError} Learn how to &nbsp;
                        <a
                            href="https://help.1inch.exchange/en/articles/4966690-how-to-use-1inch-on-bsc-binance-smart-chain"
                            target="_blank"
                            className={styles.loginErrorGuide}
                            rel="noreferrer"
                        >
                            change network in wallet
                        </a>
                        &nbsp; or &nbsp;
                        <button
                            className={styles.btnChangeAppNetwork}
                            onClick={() => {
                                setOpenSideBar(false);
                                setSwitchNetworkDialog(true);
                            }}
                        >
                            Change App Network
                        </button>
                    </span>
                </div>
            )}
            {window.location.href.indexOf("buy-token") > -1 && !loginError && message !== "" && (
                <div className={styles.loginErrorBanner}>
                    <img
                        src="/images/red-warning.svg"
                        alt="red-warning icon"
                        className={styles.iconWarning}
                    />
                    <span className={styles.loginErrorBannerText}>
                        {message}&nbsp;&nbsp;
                        <button
                            className={styles.btnChangeAppNetwork}
                            onClick={() => {
                                setOpenSideBar(false);
                                setSwitchNetworkDialog(true);
                            }}
                        >
                            Change App Network
                        </button>
                    </span>
                </div>
            )}
        </>
    );
};

export default withWidth()(withRouter(HeaderDefaultLayout));
