import { AbstractConnector } from "@web3-react/abstract-connector";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import BigNumber from "bignumber.js";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { ConnectorNames } from "../../../../constants/connectors";
import { chainId, ChainIdNameMapping, NETWORK_NAME_MAPPINGS } from "../../../../constants/network";
import usePrevious from "../../../../hooks/usePrevious";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { connectWalletSuccess, disconnectWallet } from "../../../../store/actions/wallet";
import getAccountBalance from "../../../../utils/getAccountBalance";
import { getAppNetworkName } from "../../../../utils/network/getAppNetworkName";
import { requestSupportNetwork } from "../../../../utils/setupNetwork";

import { toast } from "react-toastify";
import { alertFailure } from "../../../../store/actions/alert";
import {
    NetworkUpdateType,
    settingAppNetwork,
    settingCurrentConnector,
} from "../../../../store/actions/appNetwork";

const useProviderConnect = (
    setOpenConnectDialog?: Dispatch<SetStateAction<boolean>>,
    openConnectDialog?: boolean,
    binanceAvailable?: boolean
) => {
    const dispatch = useDispatch();

    const { appChainID, walletChainID } = useTypedSelector((state) => state.appNetwork).data;

    const [account, setAccount] = useState<string | undefined>(undefined);

    const [appNetworkLoading, setAppNetworkLoading] = useState(false);
    const [walletNameSuccess, setWalletNameSuccess] = useState<string | undefined>(undefined);
    const [walletName, setWalletName] = useState<(undefined | string)[]>([]);
    const [currentConnector, setCurrentConnector] = useState<undefined | AbstractConnector>(
        undefined
    );
    const [connectWalletLoading, setConnectWalletLoading] = useState(false);
    const [loginError, setLoginError] = useState("");

    const {
        activate,
        active,
        connector,
        error,
        account: connectedAccount,
        deactivate,
    } = useWeb3React();

    // console.log({ activate, connectedAccount });

    const previousAccount = usePrevious(account);
    const activePrevious = usePrevious(active);
    const previousConnector = usePrevious(connector);

    useEffect(() => {
        if (
            connectWalletLoading &&
            ((active && !activePrevious) ||
                (connector && connector !== previousConnector && !error))
        ) {
            setConnectWalletLoading(false);
            setOpenConnectDialog && setOpenConnectDialog(false);
        }
    }, [
        active,
        connector,
        error,
        previousAccount,
        previousConnector,
        activePrevious,
        connectWalletLoading,
        setOpenConnectDialog,
        setConnectWalletLoading,
    ]);

    function clearInfo() {
        dispatch(disconnectWallet());
        dispatch(settingAppNetwork(NetworkUpdateType.Wallet, undefined));
        localStorage.removeItem("user");
        localStorage.removeItem("investor_access_token");
        deactivate();
    }

    useEffect(() => {
        if (currentConnector?.on && !active && !error) {
            // console.log("running once");

            const handleWeb3ReactUpdate = (updated: any) => {
                if (updated.account) {
                    if (updated.account) {
                        if (localStorage.getItem("user")) {
                            localStorage.removeItem("user");
                            localStorage.removeItem("investor_access_token");
                            localStorage.removeItem("walletconnect-name");
                        }
                    } else setAccount(undefined);
                }

                if (updated.chainId) {
                    const chainId = Number(updated.chainId).toString();

                    if (localStorage.getItem("user")) {
                        toast.info("Network changed. Please connect wallet again.", {
                            autoClose: 10 * 1000,
                        });
                        clearInfo();
                        localStorage.removeItem("walletconnect-name");
                    }

                    // if (APP_NETWORKS_ID.indexOf(chainId.toString()) >= 0) {
                    //     if (localStorage.getItem('user')) {
                    //         toast.info("Network changed. Please connect wallet again.", {
                    //             autoClose: 10 * 1000
                    //         });
                    //         clearInfo();
                    //     }
                    //     dispatch(
                    //         settingAppNetwork(
                    //             NetworkUpdateType.App,
                    //             APP_NETWORKS_ID[
                    //                 APP_NETWORKS_ID.indexOf(chainId.toString())
                    //             ] as string
                    //         )
                    //     );

                    //     switchNetwork(
                    //         APP_NETWORKS_ID[APP_NETWORKS_ID.indexOf(chainId.toString())] as string,
                    //         chainId
                    //     );

                    //     return;
                    // }

                    // switchNetwork(appChainID, chainId);
                    // chainId &&
                    //     dispatch(settingAppNetwork(NetworkUpdateType.Wallet, chainId.toString()));
                    // if (localStorage.getItem('user')) {
                    //     toast.info("Network changed. Please connect wallet again.", {
                    //         autoClose: 10 * 1000
                    //     });
                    //     clearInfo();
                    // }
                }
            };

            const handleWeb3ReactError = (err: any) => {
                if (err === "NaN ChainId") {
                    dispatch(settingAppNetwork(NetworkUpdateType.Wallet, undefined));
                    setLoginError(
                        `App network (${appChainID}) doesn't mach to network selected in wallet: NaN. Learn how to change network in wallet or`
                    );
                }
            };

            currentConnector.on("Web3ReactUpdate", handleWeb3ReactUpdate);
            currentConnector.on("Web3ReactError", handleWeb3ReactError);
            currentConnector.on("Web3ReactDeactivate", handleConnectorDisconnect);

            return () => {
                if (currentConnector && currentConnector.removeListener && active) {
                    currentConnector.removeListener("Web3ReactUpdate", handleWeb3ReactUpdate);
                    currentConnector.removeListener("Web3ReactError", handleWeb3ReactError);
                    currentConnector.removeListener(
                        "Web3ReactDeactivate",
                        handleConnectorDisconnect
                    );
                }
            };
        }

        return;
    }, [currentConnector, connectedAccount]);

    useEffect(() => {
        currentConnector && setAppNetworkLoading(true);
    }, [appChainID]);

    // UseEffect for watching change app network loading
    useEffect(() => {
        if (!appNetworkLoading) {
            setOpenConnectDialog && setOpenConnectDialog(false);
            setConnectWalletLoading(false);
        }
    }, [appNetworkLoading]);

    // UseEffect for trying login after fullfilled app chain id and connector
    useEffect(() => {
        const tryLoginAfterSwitch = async () => {
            if (currentConnector) {
                let ok = true;
                // if (appChainID === BSC_CHAIN_ID) {
                //     ok = !!binanceAvailable;
                // }
                ok &&
                    (await tryActivate(
                        currentConnector,
                        appChainID,
                        walletName[walletName.length - 1] as string
                    ));
            }
        };
        currentConnector && appChainID && walletName.length > 0 && tryLoginAfterSwitch();
    }, [currentConnector, appChainID, walletName]);

    useEffect(() => {
        walletChainID &&
            !openConnectDialog &&
            !appNetworkLoading &&
            switchNetwork(appChainID, walletChainID);
    }, [walletChainID, appNetworkLoading, appChainID, openConnectDialog]);

    // UseEffect for setting wallet id after login success
    // useEffect(() => {
    //   if (!connectWalletLoading) {
    //     chainId && dispatch(settingAppNetwork(NetworkUpdateType.Wallet, chainId.toString()));
    //     connectedAccount && setAccount(connectedAccount);
    //     const accessToken = localStorage.getItem("investor_access_token") || localStorage.getItem("access_token");
    //     const userConnect = connectedAccount === localStorage.getItem("user") ? true : false;
    //     if (!accessToken || !userConnect) {
    //       connectedAccount && library && dispatch(login(connectedAccount, library, chainId));
    //     }
    //   }
    // }, [connectWalletLoading, connectedAccount, chainId])

    // Handle Provider choose
    const handleProviderChosen = (name: string, connector: AbstractConnector) => {
        if (typeof (window?.ethereum as any)?.providerMap !== "undefined") {
            switch (name) {
                case ConnectorNames.MetaMask:
                    dispatch(alertFailure("Please remove Coinbase wallet to connect to MetaMask"));
                    break;
                case ConnectorNames.WalletLinkConnect:
                    dispatch(alertFailure("Please remove MetaMask wallet to connect to Coinbase"));
                    break;
                default:
                    localStorage.setItem("walletconnect-name", name);
                    setCurrentConnector(connector);
                    walletName.indexOf(name) < 0 && setWalletName([...walletName, name]);
            }
        } else {
            if (isInstallWallet(name)) {
                localStorage.setItem("walletconnect-name", name);
                setCurrentConnector(connector);
                // walletName.indexOf(name) < 0 && setWalletName([...walletName, name]);
                setWalletName(JSON.parse(JSON.stringify([name])));
            } else {
                dispatch(alertFailure(`Please install ${name} to connect to ${name}`));
            }
        }
    };

    const isInstallWallet = (name: string) => {
        if (name === ConnectorNames.MetaMask) {
            return window.ethereum && window.ethereum.isMetaMask;
        } else if (name === ConnectorNames.WalletLinkConnect) {
            return window.ethereum && window.ethereum.isCoinbaseWallet;
        } else if (name === ConnectorNames.Kaikas) {
            return window && window["caver"];
        } else {
            return true;
        }
    };

    const switchNetwork = (appChainID: string, walletChainID: string) => {
        if (appChainID && walletChainID) {
            Number(appChainID) !== Number(walletChainID)
                ? setLoginError(
                      `App network (${getAppNetworkName(
                          appChainID
                      )}) doesn't mach to network selected in wallet: ${
                          ChainIdNameMapping[Number(walletChainID) as chainId]
                      }.`
                  )
                : setLoginError("");
        }

        return;
    };

    const tryActivate = useCallback(
        async (connector: AbstractConnector, appChainID: string, wallet: string) => {
            try {
                if (!connectWalletLoading) {
                    setConnectWalletLoading(true);

                    if (
                        wallet === ConnectorNames.MetaMask ||
                        wallet === ConnectorNames.WalletLinkConnect
                    ) {
                        let response = await requestSupportNetwork(
                            appChainID,
                            wallet,
                            handleConnectorDisconnect
                        );
                        if (!response) {
                            dispatch(disconnectWallet());
                            setCurrentConnector(undefined);
                            setConnectWalletLoading(false);
                            setWalletName([]);
                            localStorage.removeItem("walletconnect-name");
                            return;
                        }
                    }

                    if (
                        connector instanceof WalletConnectConnector &&
                        (connector.walletConnectProvider as any)?.wc?.uri
                    ) {
                        connector.walletConnectProvider = undefined;
                    }

                    if (connector && walletName) {
                        if (wallet === ConnectorNames.Fortmatic) {
                            connector.on("OVERLAY_READY", () => {
                                setOpenConnectDialog && setOpenConnectDialog(false);
                            });
                        }

                        await activate(connector, undefined, true)
                            .then(() => {
                                dispatch(settingCurrentConnector(wallet));
                                setWalletNameSuccess(wallet);
                            })
                            .catch(async (error) => {
                                if (error instanceof UnsupportedChainIdError) {
                                    dispatch(disconnectWallet());
                                    setCurrentConnector(undefined);
                                    setConnectWalletLoading(false);
                                    setWalletName([]);
                                    localStorage.removeItem("walletconnect-name");

                                    // await activate(connector);
                                    const currentChainId = await connector?.getChainId();
                                    // const b = connector?.supportedChainIds;

                                    dispatch(
                                        alertFailure(
                                            `App network (${NETWORK_NAME_MAPPINGS[appChainID]}) doesn\'t mach to network selected in wallet: ${NETWORK_NAME_MAPPINGS[currentChainId]}. Please change network in wallet  or  change app network.`
                                        )
                                    );

                                    return;
                                } else {
                                    dispatch(disconnectWallet());
                                    setConnectWalletLoading(false);
                                    setWalletName(walletName.filter((name) => wallet !== name));
                                    return;
                                }
                            });
                    }
                }
            } catch (error: any) {
                setLoginError(error.message);
                setCurrentConnector(undefined);
            }

            setAppNetworkLoading(false);
        },
        [connector, appChainID, walletName]
    );

    useEffect(() => {
        const getAccountDetails = async () => {
            if (appChainID && connectedAccount && walletNameSuccess) {
                const accountBalance = await getAccountBalance(
                    appChainID,
                    walletChainID,
                    connectedAccount as string,
                    walletNameSuccess
                );

                dispatch(
                    connectWalletSuccess(walletNameSuccess, [connectedAccount], {
                        [connectedAccount]: new BigNumber(accountBalance._hex)
                            .div(new BigNumber(10).pow(18))
                            .toFixed(5),
                    })
                );

                setConnectWalletLoading(false);
            }
        };
        getAccountDetails();
    }, [walletNameSuccess, connectedAccount, appChainID, walletChainID]);

    const handleConnectorDisconnect = useCallback(() => {
        dispatch(disconnectWallet());
        dispatch(settingCurrentConnector(undefined));
        dispatch(settingAppNetwork(NetworkUpdateType.Wallet, undefined));

        localStorage.removeItem("walletconnect-name");

        deactivate();
        setAccount(undefined);
        setWalletName([]);
        setWalletNameSuccess(undefined);
        setCurrentConnector(undefined);
        setConnectWalletLoading(false);
        setLoginError("");
    }, []);

    return {
        handleProviderChosen,
        setWalletName,
        walletName,
        connectWalletLoading,
        walletNameSuccess,
        loginError,
        currentConnector,
        appNetworkLoading,
        handleConnectorDisconnect,
    };
};

export default useProviderConnect;
