import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        modalLoading: {
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            "& .MuiPaper-root": {
                [theme.breakpoints.down("xs")]: {
                    margin: "10px",
                },
            },
            "& .modal-content": {
                display: "flex",
                alignItems: "center",
                backgroundColor: "#242640",
                width: "450px",
                height: 350,
                padding: "40px 40px 20px",
                [theme.breakpoints.down("xs")]: {
                    padding: "40px 20px 20px",
                },
                borderRadius: "12px",
                color: "#FFFFFF",
            },
            "& .modal-content__head .title": {
                margin: "0 auto",
                fontSize: "24px",
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
            },
            "& .modal-content__body": {
                padding: "0",
                margin: "20px 0",
                overflowX: "hidden",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                "& p": {
                    fontSize: 20,
                    margin: "4px 0px",
                    fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                },
                "& span": {
                    fontSize: 16,
                    color: "rgba(255, 255, 255, .5)",
                },
            },
        },
    };
});

export default useStyles;
