import React from "react";
import useStyles from "./styles";

const Content: React.FC<any> = (props: any) => {
    const styles = useStyles();
    const { defaultLayout = true } = props;
    return (
        <div className={styles.mainLayout}>
            <div>{props.children}</div>
            {defaultLayout && <img className="stoneLeft" src="/images/stoneLeft.svg" alt="" />}
            {defaultLayout && <img className="stoneRight" src="/images/stoneRight.svg" alt="" />}
            {defaultLayout && <img className="stoneRightMobile" src="/images/stoneRightMobile.svg" alt="" />}
            {defaultLayout && <div className="shawDownLeft"></div>}
            {defaultLayout && <div className="shawDownRight"></div>}
        </div>
    );
};

export default Content;
