const POOL_NOREWARD_CODE_LOADING = "POOL_NOREWARD_CODE_LOADING";
const POOL_NOREWARD_CODE_SUCCESS = "POOL_NOREWARD_CODE_SUCCESS";
const POOL_NOREWARD_CODE_FAILURE = "POOL_NOREWARD_CODE_FAILURE";

const POOL_NOREWARD_LP_LOADING = "POOL_NOREWARD_LP_LOADING";
const POOL_NOREWARD_LP_SUCCESS = "POOL_NOREWARD_LP_SUCCESS";
const POOL_NOREWARD_LP_FAILURE = "POOL_NOREWARD_LP_FAILURE";

const SET_PAGE_SIZE_POOL_NOREWARD = "SET_PAGE_SIZE_POOL_NOREWARD";
const SET_PAGE_NUMBER_POOL_NOREWARD = "SET_PAGE_NUMBER_POOL_NOREWARD";

export const poolNoReward = {
  POOL_NOREWARD_CODE_LOADING,
  POOL_NOREWARD_CODE_SUCCESS,
  POOL_NOREWARD_CODE_FAILURE,
  POOL_NOREWARD_LP_LOADING,
  POOL_NOREWARD_LP_SUCCESS,
  POOL_NOREWARD_LP_FAILURE,
  SET_PAGE_SIZE_POOL_NOREWARD,
  SET_PAGE_NUMBER_POOL_NOREWARD,
};

export interface PoolLPI {
  contract_address: string;
  conversion_rate: number;
  token_address: string;
  created_at: string;
  id: number;
  is_display: boolean;
  stake_type: string;
  status: string;
  token_symbol: string;
  total_staked: number;
  updated_at: string;
  token_decimal:string;
  image:string;
}
