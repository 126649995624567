import { UrlJsonRpcProvider } from "@ethersproject/providers";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        wrapper: {
            marginTop: 20,
            width: "100%",
            height: 280,
            background: "#131530",
            borderRadius: 20,
            padding: 30,
            [theme.breakpoints.down("xs")]: {
                padding: 10,
            },
            "&> p": {
                borderBottom: "1px solid #2B2C45",
                paddingBottom: 10,
                display: "flex",
                flexDirection: "row",
                fontSize: 16,
                fontWeight: 600,
                fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                color: "#fff",
                [theme.breakpoints.down("xs")]: {
                    padding: 20,
                },
            },
            "& .timeLineTier": {
                display: "inline-block",
                margin: "52px 13px",
                // padding:"0px 13px",
                width: "100%",
                // marginLeft: 50,
                marginBottom: 20,
                cursor: "auto",
                [theme.breakpoints.down("xs")]: {
                    margin: "52px 0px 52px 6px",
                    width: "100%",
                },

                [theme.breakpoints.up("sm")]: {},
                [theme.breakpoints.up("md")]: {},
                [theme.breakpoints.up("lg")]: {},
                "& .MuiSlider-rail": {
                    marginTop: 9,
                    height: 3,
                    color: "#AEAEAE",
                },
                "& .MuiSlider-track": {
                    height: 3,
                    background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                },
                "& .MuiSlider-markLabel": {
                    color: "#AEAEAE",
                    fontSize: 14,
                    top: 0,
                },

                "& .MuiSlider-thumb ": {
                    display: "none",
                },
                "& .MuiSlider-mark": {
                    display: "none",
                },
            },
            "& .MuiSlider-root": {
                padding: "13px 0!important",
            },
        },
        desc: {
            fontSize: 12,
            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
            color: "#AEAEAE",
            marginTop: "8px",

            [theme.breakpoints.down("sm")]: {
                fontSize: 13,
            },
            [theme.breakpoints.down("xs")]: {
                fontSize: 9,
                marginLeft: 6,
            },
        },
        desc2: {
            fontSize: 12,
            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
            color: "#AEAEAE",
            [theme.breakpoints.down("sm")]: {
                fontSize: 13,
            },
            [theme.breakpoints.down("xs")]: {
                fontSize: 9,
                marginLeft: 6,
            },
        },
        steps: {
            backgroundImage: "url(/images/home-page/imgTier.svg)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "bg-contain",
            backgroundPosition: "center",
            width: "47px",
            height: "47px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "&> p": {
                fontSize: 16,
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                color: "white",
            },
        },
    };
});

export default useStyles;
