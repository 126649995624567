import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        dialog: {
            "& .MuiPaper-root": {
                background: "#242640",
                padding: "77px 80px",
                maxWidth: 900,
                borderRadius: 20,
                [theme.breakpoints.down("xs")]: {
                    background: "#242640 !important",
                    margin: "17px !important",
                    padding: "18px !important",
                },
            },
            "& .MuiDialogContent-root": {
                [theme.breakpoints.down("xs")]: {
                    padding: "0px !important",
                },
            },
        },

        boxQrCode: {
            padding: 15,
            background: "#fff",
        },

        dialogContentTypo: {
            color: "white",
            marginTop: 40,
            fontSize: 16,
            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
            lineHeight: "24px",
            [theme.breakpoints.down("xs")]: {
                fontSize: 14,
                lineHeight: "22px",
            },
            "&:first-child": {
                marginTop: 0,
            },
        },
        dialogContentBlock: {
            marginTop: 20,
        },
        dialogTitle: {
            background: "transparent !important",
            padding: "0px 0px 16px 0px !important",
            "& .MuiTypography-h6": {
                paddingBottom: 16,
                fontSize: 32,
                lineHeight: "42px",
                fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                // borderBottom: "1px solid rgba(255, 255, 255 ,0.1)",
                [theme.breakpoints.down("xs")]: {
                    fontSize: 20,
                    lineHeight: "30px",
                    marginTop: 36,
                },
            },

            "& .MuiSvgIcon-root": {
                fontSize: "1rem",
            },
        },
        dialogPrivacy: {
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down("xs")]: {
                justifyContent: "flex-start !important",
                flexWrap: "nowrap !important",
            },
        },
        dialogPrivacyText: {
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            fontSize: 16,
            lineHeight: "24px",
            color: "rgba(255, 255, 255 ,0.5)",
            cursor: "pointer",
        },
        dialogPrivacyHighlight: {
            color: "#0F69E1",
            cursor: "pointer",
            "&:hover": {
                color: "#0F69E1",
                textDecoration: "underline",
            },
        },
        dialogCheckbox: {
            padding: 0,
            marginRight: 8,
            "& .MuiSvgIcon-root": {
                fill: "#9747FF",
            },
        },

        dialogNetworks: {
            display: "flex",
        },
        [theme.breakpoints.down("xs")]: {
            dialog: {
                "& .MuiPaper-root": {
                    background: "#020616",
                    padding: "20px",
                    margin: "20px",
                    maxWidth: "100%",
                    width: "100%",
                },
            },
            dialogContentBlock: {
                gap: "20px",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "center",
                "& .MuiPaper-root": {
                    padding: "20px 0",
                },
            },
            dialogPrivacy: {
                display: "flex",
                alignItems: "flex-start",
                gap: 0,
            },
        },
    };
});

export default useStyles;
