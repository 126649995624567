import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        wrapper: {
            paddingTop: 30,
            "&>div:first-child": {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                [theme.breakpoints.down("xs")]: {
                    flexDirection: "column",
                },
                "&> .amountTicket": {
                    width: "48%",
                    // height: 100,
                    padding: "18px 22px",
                    display: "flex",
                    flexDirection: "column",
                    background: "rgba(255, 255, 255, 0.07)",
                    borderRadius: 13,
                    [theme.breakpoints.down("lg")]: {
                        width: 265,
                    },
                    ["@media (max-width:1420px)"]: {
                        width: 240,
                    },
                    ["@media (max-width:1330px)"]: {
                        width: 238,
                    },
                    [theme.breakpoints.down("md")]: {
                        width: 265,
                    },
                    [theme.breakpoints.down("xs")]: {
                        width: "100%",
                        marginTop: 12,
                    },
                    "&> p:first-child": {
                        fontSize: 14,
                        fontWeight: 400,
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        color: "white",
                        opacity: 0.5,
                    },
                    "&> p:last-child": {
                        fontSize: 32,
                        fontWeight: 600,
                        fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                        color: "white",
                    },
                    "&> div": {
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 6,
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                        "&> div:first-child": {
                            fontSize: 32,
                            fontWeight: 600,
                            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                            color: "white",
                        },
                        "&> div:last-child": {
                            color: "#2DDBFF",
                            fontSize: 12,
                            fontWeight: 400,
                            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                            cursor: "pointer",
                            "&> img": {
                                marginLeft: 7,
                            },
                        },
                    },
                },
            },
            "&> div:nth-child(2)": {
                "&>div": {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 30,
                    [theme.breakpoints.down("xs")]: {
                        flexDirection: "column",
                        width: "100%",
                        marginTop: 0,
                    },
                    "&> div": {
                        width: 265,
                        height: 180,
                        padding: "18px 22px",
                        display: "flex",
                        flexDirection: "column",
                        background: "rgba(255, 255, 255, 0.07)",
                        borderRadius: 13,
                        ["@media (max-width:1420px)"]: {
                            width: 240,
                        },
                        ["@media (max-width:1330px)"]: {
                            width: 238,
                        },
                        [theme.breakpoints.down("md")]: {
                            width: 265,
                        },
                        [theme.breakpoints.down("xs")]: {
                            marginTop: 12,
                            width: "100%",
                        },
                        "&> p": {
                            fontSize: 14,
                            fontWeight: 400,
                            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                            color: "white",
                            opacity: 0.5,
                        },
                        "&> .amountClaim": {
                            fontSize: 32,
                            fontWeight: 600,
                            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                            color: "white",
                            width: "100%",
                            opacity: 1,
                            marginTop: 5,
                            whiteSpace: "pre",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        },
                        "&> .claimed": {
                            marginTop: 80,
                            fontSize: 18,
                            height: "100%",
                            fontWeight: 600,
                            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                            color: "#3C974B",
                            display: "flex",
                            flexDirection: "row",
                            "&> img": {
                                marginRight: 10,
                            },
                            "&> div": {
                                marginTop: 4,
                            },
                        },
                        "&> .btnClaim": {
                            marginTop: 12,
                            fontSize: 12,
                            fontWeight: 700,
                            fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                            color: "white",
                            background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                            padding: "12px 31px",
                            borderRadius: 90,
                            width: 100,
                            textTransform: "capitalize",
                            whiteSpace: "pre",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        },
                        "&> .btnClaimDisabled": {
                            marginTop: 12,
                            fontSize: 12,
                            fontWeight: 700,
                            fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                            color: "white",
                            background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                            padding: "12px 31px",
                            borderRadius: 90,
                            width: 100,
                            opacity: 0.7,
                            textTransform: "capitalize",
                        },
                        "&> div": {
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            "&> p": {
                                fontSize: 14,
                                fontWeight: 400,
                                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                                color: "white",
                                opacity: 0.5,
                                marginRight: 8,
                            },
                            "&> .iconI": {
                                position: "relative",
                                cursor: "pointer",
                                "&> .onHoverI": {
                                    position: "absolute",
                                    visibility: "hidden",
                                    color: "white",
                                    opacity: 0,
                                    fontWeight: 400,
                                    fontSize: 10,
                                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                                    width: 185,
                                    backgroundColor: "#3E405B",
                                    borderRadius: 5,
                                    padding: 12,
                                    top: "calc(100% + 10px)",
                                    left: -8,
                                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                    transition: "all 200ms",
                                    zIndex: 3,
                                    [theme.breakpoints.down("sm")]: {
                                        left: -98,
                                    },
                                    [theme.breakpoints.down("xs")]: {
                                        left: -80,
                                    },
                                    "&> p": {
                                        opacity: 0.7,
                                    },
                                    " &::after": {
                                        content: '""',
                                        position: "absolute",
                                        display: "block",
                                        backgroundColor: "#3E405B",
                                        left: 10,
                                        top: -9,
                                        width: 10,
                                        height: 10,
                                        clipPath: "polygon(50% 20%, 0% 100%, 100% 100%)",
                                        [theme.breakpoints.down("sm")]: {
                                            left: 100,
                                        },
                                        [theme.breakpoints.down("xs")]: {
                                            left: 82,
                                        },
                                    },
                                },
                                "&:hover > .onHoverI": {
                                    visibility: "visible",
                                    opacity: 1,
                                },
                            },
                        },
                    },
                },
            },
            "&> div:last-child": {
                display: "flex",
                flexDirection: "column",
                marginTop: 25,
                "&> div": {
                    display: "flex",
                    flexDirection: "row",
                    "&> p:first-child": {
                        fontSize: 14,
                        fontWeight: 400,
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        color: "white",
                        opacity: 0.3,
                        minWidth: 126,
                        marginBottom: 10,
                    },
                    "&> p:last-child": {
                        fontSize: 14,
                        fontWeight: 400,
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        color: "white",
                    },
                },
            },
        },
        claimRefundToken: {
            display: "flex",
            flexDirection: "column",
            [theme.breakpoints.down("xs")]: {
                flexDirection: "column",
            },
        },
        swapToken: {
            display: "flex",
            flexDirection: "column",
            height: 180,
            "& p": {
                fontWeight: 400,
                fontSize: 14,
                color: "white",
                opacity: 0.5,
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                marginBottom: 36,
            },
            "&> .btnClaim": {
                marginTop: 12,
                fontSize: 12,
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                color: "white",
                background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                padding: "12px 31px",
                borderRadius: 90,
                width: 100,
                textTransform: "capitalize",
            },
        },
        claimUsdc: {
            "&> .iconI": {
                position: "relative",
                cursor: "pointer",
                "&> .onHoverI": {
                    position: "absolute",
                    visibility: "hidden",
                    color: "white",
                    opacity: 0,
                    fontWeight: 400,
                    fontSize: 10,
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    width: 185,
                    backgroundColor: "#3E405B",
                    borderRadius: 5,
                    padding: 12,
                    top: "calc(100% + 10px)",
                    left: -8,
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    transition: "all 200ms",
                    "&> p": {
                        opacity: 0.7,
                    },
                    " &::after": {
                        content: '""',
                        position: "absolute",
                        display: "block",
                        backgroundColor: "#3E405B",
                        left: 10,
                        top: -9,
                        width: 10,
                        height: 10,
                        clipPath: "polygon(50% 20%, 0% 100%, 100% 100%)",
                    },
                },
                "&:hover > .onHoverI": {
                    visibility: "visible",
                    opacity: 1,
                },
            },
        },
    };
});

export default useStyles;
