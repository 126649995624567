import moment from "moment";
import ItemDetail from "./ItemDetail";
import useStyles from "./style";

export default function ListItemDetail({ dataOfDay, dateCalendarCurrent }: any) {
    const styles = useStyles();
    return (
        <div className={styles.box}>
            <div className={styles.listItemDetail}>
                <div className="month">
                    <p>{moment(new Date(dateCalendarCurrent)).format("DD")}</p>
                    <span>{moment(new Date(dateCalendarCurrent)).format("ddd")}</span>
                </div>
                <div className="item">
                    {Array.isArray(dataOfDay) &&
                        dataOfDay.map((item: any, index: number) => (
                            <ItemDetail item={item} key={index} />
                        ))}
                </div>
            </div>
        </div>
    );
}
