import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Hidden,
    IconButton,
    Snackbar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import i18n from "i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { convertLang } from "../../../../IDOHomePage/CardItem";
import TableAccount from "../TableAccount";
import TableApplyWhitelistMobile from "../TableApplyMobile";
import useStyles from "./style";

type Props = {
    handleCloseModalApply: () => void;
    openViewApply: boolean;
    dataWhitelist?: any;
    checkApply: any;
    dataAccount: any;
};

export default function ModalViewApplyWhitelist({
    openViewApply,
    handleCloseModalApply,
    dataWhitelist,
    checkApply,
    dataAccount,
}: Props) {
    const styles = useStyles();
    const { t } = useTranslation();
    const IDODetail = useSelector((state: any) => state.idoDetail.data);

    const [messageWaring, setMessageWaring] = useState(false);

    const handleCloseMessage = () => {
        setMessageWaring(false);
    };

    const history = useHistory();

    const handleClickMyProfile = () => {
        history.push("/account");
    };

    return (
        <Dialog
            className={styles.wrapper}
            aria-labelledby="simple-dialog-title"
            open={openViewApply}
        >
            <DialogTitle style={{ padding: "16px" }}>
                {t("ProjectDetails.welcome")} {convertLang(IDODetail?.project_name, i18n.language)}{" "}
                IDO {t("ProjectDetails.onCodeDao")}
                <IconButton
                    aria-label="close"
                    className={styles.closeButton}
                    onClick={handleCloseModalApply}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ padding: "8px 16px" }}>
                <DialogContentText className={styles.textContent} id="alert-dialog-description">
                    {t("ProjectDetails.modalApplyWhitelist.content1")}
                    <br />
                    {t("ProjectDetails.modalApplyWhitelist.content2")}
                    <br />
                    {t("ProjectDetails.modalApplyWhitelist.msgError")}
                </DialogContentText>
                <DialogContentText className={styles.textTitle}>
                    {t("ProjectDetails.modalApplyWhitelist.contentTitle").replace(
                        "PROJECT_NAME",
                        convertLang(IDODetail?.project_name, i18n.language)
                    )}
                </DialogContentText>
                <Hidden xsDown>
                    <TableAccount />
                </Hidden>
                <Hidden smUp>
                    <TableApplyWhitelistMobile />
                </Hidden>
                <Snackbar
                    open={messageWaring}
                    onClose={handleCloseMessage}
                    // autoHideDuration={3000}
                    message={
                        <div className="messageWaring">
                            <img
                                src="/images/home-page/iconI.svg"
                                alt="iconWarning"
                                className="iconWarning"
                            />
                            <div className="content">
                                <p>Social media account not found</p>
                                <span>Please update your social media accounts to proceed</span>
                                <span onClick={handleClickMyProfile} className="goToMyProfile">
                                    Go to My Profile
                                </span>
                            </div>
                            <img
                                src="/images/icons/close_toast.svg"
                                alt="iconClose"
                                className="iconClose"
                                onClick={handleCloseMessage}
                            />
                        </div>
                    }
                />
                <div className={styles.accountUser}>
                    <div>
                        <div className={styles.twitterAccount}>
                            <div>
                                <p>{t("ProjectDetails.modalApplyWhitelist.yourTwitterAccount")}</p>
                            </div>
                            <div>
                                <p>{dataAccount?.user_twitter}</p>
                                {dataAccount?.user_twitter === "" ||
                                dataAccount?.user_twitter === null ||
                                dataWhitelist?.data?.ido_twitter_status === 2 ||
                                dataWhitelist?.data?.platform_twitter_status === 2 ? (
                                    <img src="images/home-page/iconApplyError.svg" alt="Apply" />
                                ) : (
                                    dataWhitelist?.data?.ido_twitter_status === 1 &&
                                    dataWhitelist?.data?.platform_twitter_status === 1 && (
                                        <img
                                            src="images/home-page/iconApplySuccess.svg"
                                            alt="Apply"
                                        />
                                    )
                                )}
                            </div>
                        </div>
                        <div className={styles.twitterAccount}>
                            <div>
                                <p>{t("ProjectDetails.modalApplyWhitelist.yourTelegramAccount")}</p>
                            </div>
                            <div>
                                <p>{dataAccount?.user_telegram}</p>
                                {dataAccount?.user_telegram === "" ||
                                dataAccount?.user_telegram === null ||
                                dataWhitelist?.data?.ido_telegram_status === 2 ||
                                dataWhitelist?.data?.platform_telegram_status === 2 ? (
                                    <img src="images/home-page/iconApplyError.svg" alt="Apply" />
                                ) : (
                                    dataWhitelist?.data?.ido_telegram_status === 1 &&
                                    dataWhitelist?.data?.platform_telegram_status === 1 && (
                                        <img
                                            src="images/home-page/iconApplySuccess.svg"
                                            alt="Apply"
                                        />
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={styles.discordAccount}>
                        <div>
                            <p>{t("ProjectDetails.modalApplyWhitelist.yourDiscordAccount")}</p>
                        </div>
                        <div>
                            <p>{dataAccount?.user_discord}</p>
                            {dataAccount?.user_discord === "" ||
                            dataAccount?.user_discord === null ||
                            dataWhitelist?.data?.ido_discord_status === 2 ||
                            dataWhitelist?.data?.platform_discord_status === 2 ? (
                                <img src="images/home-page/iconApplyError.svg" alt="Apply" />
                            ) : (
                                dataWhitelist?.data?.ido_discord_status === 1 &&
                                dataWhitelist?.data?.platform_discord_status === 1 && (
                                    <img src="images/home-page/iconApplySuccess.svg" alt="Apply" />
                                )
                            )}
                        </div>
                    </div>
                    {(dataWhitelist?.data?.ido_twitter_status === 2 ||
                        dataWhitelist?.data?.ido_telegram_status === 2 ||
                        dataWhitelist?.data?.ido_discord_status === 2 ||
                        dataWhitelist?.data?.platform_twitter_status === 2 ||
                        dataWhitelist?.data?.platform_twitter_status === 2 ||
                        dataWhitelist?.data?.platform_twitter_status === 2) && (
                        <>
                            <p className={styles.messageError}>
                                {t("ProjectDetails.modalApplyWhitelist.msgError2")}
                            </p>
                            <p className={styles.messageError2}>
                                {t("ProjectDetails.modalApplyWhitelist.msgError3")}
                            </p>
                        </>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
}
