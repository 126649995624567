import queryString from "query-string";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { BaseRequest } from "../../request/Request";
import { idoDetail } from "../constants/ido-detail";
import { listIdo } from "../constants/list-ido";

export const getListIdo = (query: any) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
        dispatch({ type: listIdo.LIST_IDO_LOADING });
        try {
            const baseRequest = new BaseRequest();
            const queryParams = queryString.stringify(query);
            const response = (await baseRequest.get(`ido/userpage?${queryParams}`, true)) as any;
            const resObj = await response.json();
            if (!resObj.data) {
                dispatch({
                    type: idoDetail.IDO_DETAIL_LOADING,
                    payload: new Error("Invalid idoDetail payload"),
                });
                return;
            }
            const state = resObj.data.map((data: any) => {
                return {
                    ...data,
                    img: data.cover_photo,
                    name: data.project_name,
                    conversion: data.conversion_rate,
                    priceTicket: data.price,
                    stakingSnapshot: data.start_staking_snapshot,
                    ticketOpen: data.start_ticket_open,
                    ticketClose: data.start_ticket_close,
                    poolEnd: data.start_pool_end,
                    participants: data.participants,
                };
            });
            dispatch({
                type: listIdo.LIST_IDO_SUCCESS,
                payload: { data: state, total_page: resObj.total_page },
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: listIdo.LIST_IDO_FAILURE,
                payload: error,
            });
        }
    };
};
