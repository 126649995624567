import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        items: {
            display: "flex",
            alignItems: "flex-start",
            height: 132,
            borderBottom: "1px solid rgba(255, 255, 255, .1)",
            padding: "25px 0px",
            [theme.breakpoints.down("sm")]: {
                height: "auto",
                alignItems: "flex-start",
                padding: "20px 0px",
            },
            "&:first-child,&:last-child": {
                borderTop: "1px solid rgba(255, 255, 255, .1)",
            },

            "& .month": {
                minWidth: 82,
                height: 82,
                background: "#14162C",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 10,
                marginRight: 50,
                color: "#fff",
                cursor: "pointer",
                [theme.breakpoints.down("sm")]: {
                    minWidth: 65,
                    height: 65,
                    marginRight: 24,
                },
                "& p": {
                    fontSize: 32,
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    lineHeight: "42px",
                    [theme.breakpoints.down("sm")]: {
                        fontSize: 20,
                        lineHeight: "30px",
                    },
                },
                "& span": {
                    fontSize: 18,
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    opacity: "0.5",
                    whiteSpace: "nowrap",
                    [theme.breakpoints.down("sm")]: {
                        fontSize: 14,
                        lineHeight: "22px",
                    },
                },
            },
        },
        item: {
            width: "100%",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            overflow: "auto",
            cursor: "pointer",
            maxHeight: 82,
            [theme.breakpoints.down("sm")]: {
                overflowX: "auto",
                maxHeight: "none",
            },
            "&::-webkit-scrollbar": {
                backgroundColor: "transparent",
                width: 10,
            },
            "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 5px grey",
                borderRadius: 10,
            },
            "&::-webkit-scrollbar-thumb": {
                background: "rgba(255, 255, 255, 0.3)",
                borderRadius: "100px",
                "&:hover": {
                    background: "rgba(255, 255, 255, 0.5)",
                },
            },
            "& .info": {
                display: "flex",
                alignItems: "center",
                marginRight: 100,
                marginBottom: 20,
                [theme.breakpoints.down("sm")]: {
                    marginRight: 50,
                    marginBottom: 16,
                    height: 65,
                },
                "& .line": {
                    width: 4,
                    height: 48,
                    background: "#337FE3",
                    marginRight: 15,
                    [theme.breakpoints.down("sm")]: {
                        height: 34,
                    },
                },
                "& .yellow": {
                    color: "#E4C96A",
                    background: "rgba(228, 201, 106, 1) !important",
                },
                "& .red": {
                    color: "#D64646",
                    background: "rgba(214, 70, 70, 1) !important",
                },
                "& .green": {
                    color: "#3C974B",
                    background: "rgba(60, 151, 75, 1) !important",
                },
                "& .blue": {
                    color: "#337FE3",
                    background: "rgba(51, 127, 227, 1) !important",
                },
                "& .purple": {
                    color: "#9747FF",
                    background: "rgba(151, 71, 255, 1) !important",
                },
                " & .box": {
                    "& p": {
                        fontSize: 16,
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        lineHeight: "24px",
                        opacity: "0.5",
                        [theme.breakpoints.down("sm")]: {
                            fontSize: 14,
                            lineHeight: "16px",
                        },
                    },
                    "& span": {
                        fontSize: 18,
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        whiteSpace: "wrap",
                        [theme.breakpoints.down("sm")]: {
                            fontSize: 16,
                            lineHeight: "10px",
                        },
                    },
                },
            },
        },
    };
});
export default useStyles;
