import { Tab, Tabs } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { InfoToken } from "../PoolLP";
import TabPanel from "../TabPanel";
import RewardStaking from "../TabReward";
import TierStaking from "../TabTier";
import { useStyles } from "./style";
import { useSocket } from "../../../hooks/useSocket";
import { SocketEvent } from "../../../utils/types/socket";
import { useDispatch } from "react-redux";
import { getPoolNoRewardLP } from "../../../store/actions/pool-noreward";
type Props = {
    handleOpenModalStake: (
        tokenAddress: string,
        contractAddress: string,
        isReward: boolean
    ) => void;
    handleSetInfoToken: (data: InfoToken) => void;
    handleOpenModalUnStake: (
        tokenAddress: string,
        contractAddress: string,
        isReward: boolean
    ) => void;
    handleOpenModalClaimReward: (value: string) => void;
    handleOpenMessageWaring: () => void;
    reloadDataClaim: any;
    reloadDataReward: any;
    balanceCODE: string;
    minMaxStake: any;
};

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function TabStaking({
    handleOpenModalStake,
    handleOpenModalUnStake,
    handleSetInfoToken,
    handleOpenModalClaimReward,
    reloadDataReward,
    reloadDataClaim,
    balanceCODE,
    minMaxStake,
    handleOpenMessageWaring,
}: Props) {
    const dispatch = useDispatch()
    const [value, setValue] = React.useState(0);

    const styles = useStyles();
    const { t } = useTranslation();
    const handleChange = (e: any, newValue: number) => {
        setValue(newValue);
    };

    const { socket, registerListener } = useSocket();

    useEffect(() => {
        socket
            .connect()
            .on("connect_error", () => {})
            .on("disconnect", () => {})
            .on("connect", () => {});
        return () => {
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        registerListener(SocketEvent.List_Pool_Updated, () => {
            dispatch(getPoolNoRewardLP())
        });
    }, []);

    return (
        <div className={styles.tabStaking}>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
                className={styles.tabs}
            >
                <Tab label={t("staking.tabTier")} {...a11yProps(0)} />
                <Tab label={t("staking.tabReward")} {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <TierStaking
                    handleOpenModalStake={handleOpenModalStake}
                    handleSetInfoToken={handleSetInfoToken}
                    handleOpenModalUnStake={handleOpenModalUnStake}
                    balanceCODE={balanceCODE}
                    handleOpenMessageWaring={handleOpenMessageWaring}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <RewardStaking
                    handleOpenModalStake={handleOpenModalStake}
                    handleOpenModalUnStake={handleOpenModalUnStake}
                    handleSetInfoToken={handleSetInfoToken}
                    handleOpenModalClaimReward={handleOpenModalClaimReward}
                    minMaxStake={minMaxStake}
                    reloadDataReward={reloadDataReward}
                    reloadDataClaim={reloadDataClaim}
                    handleOpenMessageWaring={handleOpenMessageWaring}
                />
            </TabPanel>
        </div>
    );
}
