import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        navBar: {
            gridArea: "header",
            padding: "20px 120px",
            // maxWidth: "calc(100vw - 80px)",
            backgroundColor: "#030517",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            borderBottom: "1px solid #2B2C45",
            paddingBottom: 20,
            // "&:hover": {
            //     opacity: "1!important",
            // },
            [theme.breakpoints.only("md")]: {
                padding: "20px 50px",
                // margin: "10px auto",
                // maxWidth: "calc(100vw - 60px)",
            },
            [theme.breakpoints.only("sm")]: {
                padding: "20px 50px",
                // margin: "10px auto",
                // maxWidth: "calc(100vw - 60px)",
            },
            [theme.breakpoints.only("xs")]: {
                // flexDirection: "column",
                padding: 20,
                // margin: "10px auto",
                // maxWidth: "calc(100vw - 60px)",
            },
        },
        navbarLink: {
            textAlign: "center",
            display: "inline-block",
        },
        navbarLogo: {
            // position: 'absolute',
            // left: '50%',
            // transform: "translateY(-10%)",
            marginRight: 40,
            [theme.breakpoints.only("xs")]: {
                marginRight: 0,
                // margin: "10px auto",
                // maxWidth: "calc(100vw - 60px)",
            },
        },
        navbarBrand: {
            color: "white",
            fontSize: 15,
            textAlign: "center",
            fontWeight: 300,
            marginTop: 5,
        },
        navbarBrandBold: {
            color: "#D01F36",
        },
        rightBar: {
            // display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            display: "none",
        },
        container: {
            display: "flex",
            flexDirection: "row",
            "&> .itemMenu": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: 28,
            },
        },
        tierAccount: {
            display: "flex",
            flexDirection: "row",
            color: "white",
            marginRight: 16,
            "& >p:nth-child(2)": {
                marginLeft: 12,
                marginRight: 5,
            },
            "& >p:last-child": {
                fontWeight: 600,
            },
            "&> .valueCoin": {
                marginLeft: 10,
                fontSize: 18,
                fontWeight: 600,
            },
        },
        tierUser: {
            color: "white",
            opacity: "1!important",
            textTransform: "capitalize",
            fontSize: 16,
            fontWeight: 400,
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            paddingTop: 4,
        },
        tierSidebar: {
            color: "white",
            opacity: "1!important",
            textTransform: "capitalize",
            fontSize: 16,
            fontWeight: 400,
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            marginLeft: 10,
        },
        tierUserSidebar: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        btn: {
            fontStyle: "normal",
            fontWeight: 900,
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: "1px",
            color: "#FFFFFF",
            mixBlendMode: "normal",
            backgroundColor: "none",
            border: "none",
            cursor: "pointer",
            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
            // opacity: 0.5,
            "&.active p": {
                opacity: "1 !important",
            },
            "&.start p": {
                opacity: 0.5,
            },
            "&.start1 p": {
                opacity: 0.5,
            },
            "&:focus": {
                outline: "none",
            },
            "&.my-account": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: 400,
                marginLeft: 10,
                font: "normal normal bold 14px/18px DM Sans",
                fontSize: 18,

                "& img": {
                    marginRight: 12,
                },

                "& .icon": {
                    width: 20,
                    filter: "brightness(0) invert(1)",
                },

                "& span": {
                    display: "flex",
                    alignItems: "center",
                    fontSize: 18,
                },

                "&:hover": {
                    color: "white",
                },

                [theme.breakpoints.only("xs")]: {
                    marginTop: 0,
                    marginBottom: 20,
                },
            },
        },

        btnNetwork: {
            fontFamily: "DM Sans",
            fontWeight: "bold",
            fontSize: 14,
            lineHeight: 18,
            padding: "0 16px",
            height: "36px",
            borderRadius: 20,
            display: "flex",
            alignItems: "center",
            color: "#FFFFFF",
            marginLeft: 20,
            background: "linear-gradient(180deg, #222228 44.79%, #040719 100%)",
            border: "1px solid #44454B",
            "& img": {
                width: "20px",
                height: "20px",
            },
            [theme.breakpoints.only("xs")]: {
                marginLeft: 10,
            },
        },

        wrongNetwork: {
            width: 150,
            height: 40,
            background: "rgba(255, 51, 51, 0.2)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 90,
            "& p": {
                fontSize: 12,
                color: "#FF3333",
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                marginLeft: 8,
            },
        },

        btnConnect: {
            background: "#9747FF",
            fontWeight: 700,
            height: 40,
            padding: " 12px 26px",
            borderRadius: "60px",
            display: "flex",
            alignItems: "center",
            marginLeft: 12,
            transition: ".2s all ease-out",
            font: "normal normal bold 14px/18px DM Sans",

            "&:hover": {
                // background: "#1515ae",
                opacity: 0.9,
            },
        },
        sideBarContent: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "96%",
        },
        btnConnectText: {
            marginLeft: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
            fontSize: 12,
        },
        btnLogout: {
            background: "#9747FF",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "none",
            outline: "none",
            padding: "0 15px",
            height: 42,
        },
        btnAccount: {
            display: "inline-block",
            // backgroundColor: "#0C1018",
            // padding: "8px 6px",
            borderRadius: 20,
            marginRight: "4px",
        },
        btnChangeAppNetwork: {
            padding: "6px 11px",
            border: "2px solid #FFFFFF",
            boxSizing: "border-box",
            borderRadius: 30,
            background: "transparent",
            fontWeight: 600,
            color: "white",
            cursor: "pointer",
            transition: ".2s all ease-in",
            font: "normal normal 700 12px/14px DM Sans",

            "&:focus": {
                outline: "none",
            },

            "&:hover": {
                backgroundColor: "white",
                color: "#D01F36",
            },
        },
        btnLang: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#030517",
            height: 40,
            color: "white",
            fontSize: 12,
            padding: " 12px 20px",
            borderRadius: "60px",
            marginLeft: 20,
            cursor: "pointer",
            border: "2px solid rgba(255, 255, 255, 0.3)",
            position: "relative",
            fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
            [theme.breakpoints.only("xs")]: {
                marginLeft: 8,
            },
            "&> img": {
                marginLeft: "10px",
            },
            "& .btnLangBox": {
                border: "2px solid rgba(255, 255, 255, 0.3)",
                position: "absolute",
                zIndex: 1000,
                top: "110%",
                left: "0",
                right: "0",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#030517",
                borderRadius: 10,
                "&> p": {
                    fontWeight: 700,
                    width: "90%",
                    padding: "8px",
                    textAlign: "center",
                    // "&:hover": {
                    //     backgroundColor: "#ccc",
                    // },
                },
                "&> p:last-child": {
                    fontWeight: 700,
                    width: "90%",
                    marginBottom: 2,
                    textAlign: "center",
                    // "&:hover": {
                    //     backgroundColor: "#ccc",
                    // },
                    [theme.breakpoints.down("xs")]: {
                        marginRight: 0,
                    },
                },
            },
        },
        btnLangSidebar: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#030517",
            height: 50,
            color: "white",
            fontWeight: 700,
            padding: " 12px 20px",
            borderRadius: "60px",
            marginLeft: 20,
            cursor: "pointer",
            border: "2px solid rgba(255, 255, 255, 0.3)",
            position: "relative",
            [theme.breakpoints.only("xs")]: {
                marginLeft: 8,
                width: 124,
            },
            "&> img": {
                marginLeft: "10px",
            },
            "& .btnLangBox": {
                border: "2px solid rgba(255, 255, 255, 0.3)",
                position: "absolute",
                top: "-186%",
                left: "0",
                right: "0",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#030517",
                borderRadius: 10,
                "&> p": {
                    fontWeight: 700,
                    padding: "8px",
                },
                "&> p:last-child": {
                    fontWeight: 700,
                    marginBottom: 10,
                    [theme.breakpoints.down("xs")]: {
                        marginRight: 0,
                    },
                },
            },
        },
        loginErrorBanner: {
            top: "100%",
            width: 1040,
            maxWidth: "calc(100vw - 80px)",
            margin: "8px auto",
            borderRadius: 8,
            backgroundColor: "#591425",
            fontSize: 14,
            color: "white",
            padding: 8,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: 500,
            zIndex: 99999,
            minHeight: 42,
            lineHeight: "20px",

            [theme.breakpoints.down("xs")]: {
                width: "calc(100% - 36px)",
                maxWidth: "calc(100vw - 36px)",
                margin: "auto",
            },
        },

        iconWarning: {
            marginRight: 8,
        },

        loginErrorBannerText: {
            font: "normal normal 400 14px/24px Helvetica",
            color: "white",
            fontWeight: 500,
        },
        loginErrorGuide: {
            color: "white",
            textDecoration: "underline",

            "&:hover": {
                color: "white",
            },
        },
        spacer: {
            flex: "1 0 0",
        },

        rightHeadMobile: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            "&> img": {
                cursor: "pointer",
            },
            [theme.breakpoints.down("sm")]: {
                "& .startMobile": {
                    marginRight: 5,
                    display: "none",
                },
            },
        },

        [theme.breakpoints.down("sm")]: {
            rightBar: {
                position: "fixed",
                backgroundColor: "#030517",
                width: "70%",
                height: "112vh",
                top: 0,
                right: 0,
                margin: 0,
                padding: "113px 32px",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                display: "flex",
                transform: "translateX(+100%)",
                transition: "transform 0.5s",
                zIndex: 101,
                [theme.breakpoints.down("xs")]: {
                    padding: "120px 24px",
                },
                "&.active": {
                    transform: "translateX(0)",
                    zIndex: 101,
                },
            },
            btnConnect: {
                margin: "20px 0 20px 10px",
                padding: "8px 15px",
            },
            btn: {
                "&.start p": {
                    padding: 0,
                    marginBottom: "20px",
                },
            },
            btnLink: {
                "& p": {
                    color: "white",
                    display: "flex",
                    padding: "12px 15px",
                    fontSize: "16px",
                    alignItems: "center",
                    fontWeight: 600,
                    paddingLeft: 0,
                },
            },
            sideBarLogo: {
                position: "absolute",
                top: "50px",
                left: "32",
                width: 137,
                height: 32,
            },
            closeBtn: {
                position: "absolute",
                top: "24px",
                right: "19px",
                opacity: 0.8,
            },
            navBar: {
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
            },
            navbarLink: {
                display: "flex",
                alignItems: "center",
                "& img": {
                    height: 44,
                    [theme.breakpoints.down("sm")]: {
                        width: 132,
                        height: 44,
                    },
                    [theme.breakpoints.down("xs")]: {
                        width: 112,
                        height: 27,
                    },
                },
            },
            loginErrorBanner: {
                alignItems: "flex-start",
                "& > img": {
                    marginTop: "5px",
                },
            },
        },
    };
});

export default useStyles;
