import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: any) => {
    return {
        pagination: {
            display: "flex",
            "& button": {
                width: 48,
                height: 48,
                border: "2px solid rgba(255, 255, 255, 0.1)",
                borderRadius: 6,
                marginRight: 12,
                cursor: "pointer",
                background: "transparent",
                fontSize: 14,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                color: "rgba(255,255,255,0.5)",
                "&:disabled": {
                    opacity: 0.5,
                    cursor: "not-allowed",
                },
                [theme.breakpoints.down("md")]: {
                    width: 40,
                    height: 40,
                    fontSize: 12,
                },
                [theme.breakpoints.down("sm")]: {
                    width: 32,
                    height: 32,
                },
                [theme.breakpoints.down("xs")]: {},
            },
            "& .btn-active": {
                background: "#0F69E1",
                color: "rgba(255,255,255,1)",
            },
            "& .btn-text": {
                border: "none",
                cursor: "initial",
            },
            "& .btn-deActive": {},
            "& .MuiPagination-root": {
                margin: "0px auto",
                [theme.breakpoints.up("sm")]: {
                    margin: "0px auto",
                },
                [theme.breakpoints.up("md")]: {},
                [theme.breakpoints.up("lg")]: {},

                "& .MuiPagination-ul": {
                    "& .MuiPaginationItem-root.MuiPaginationItem-ellipsis": {
                        color: "#fff",
                    },
                },
                "& .Mui-selected": {
                    background: "#0F69E1",
                    color: "rgba(255,255,255,1)",
                },
            },
        },
    };
});
