import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: any) => {
    return {
        title: {
            width: "100%",
            background: "#131530",
            borderRadius: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            [theme.breakpoints.up("sm")]: {},
            [theme.breakpoints.up("md")]: {
                height: 460,
            },
            [theme.breakpoints.up("lg")]: {},
            "& .box": {
                padding: 16,
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
                [theme.breakpoints.up("sm")]: {},
                [theme.breakpoints.up("md")]: {
                    padding: "25px 30px",
                },
                "& .text": {
                    fontSize: 16,
                    color: "#FFF",
                    fontWeight: 600,
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    paddingBottom: 8,
                    borderBottom: "1px solid rgba(255, 255, 255, .1)",
                    [theme.breakpoints.down("xs")]: {
                        fontSize: 14,
                    },
                },
                "& .boxBottom": {
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    flexGrow: 1,
                    "& .boxTier": {
                        "& .currentRank": {
                            marginTop: 34,
                            display: "flex",
                            alignItems: "center",
                            gap: "12px",
                            "& p": {
                                fontSize: 14,
                                lineHeight: "22px",
                                color: "rgba(255, 255, 255, .5)",
                            },
                            "& .infoCurrentRank": {
                                width: 145,
                                height: 45,
                                background: "rgba(151, 71, 255, 0.2)",
                                borderRadius: "70px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                "& span": {
                                    fontSize: 12,
                                    lineHeight: "22px",
                                    fontWeight: 600,
                                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                                    color: "#FFFFFF",
                                    marginLeft: 8,
                                },
                                "& img": {
                                    filter: "invert(48%) sepia(63%) saturate(7396%) hue-rotate(250deg) brightness(100%) contrast(102%) !important",
                                },
                            },
                        },
                        "& .timeLineTier": {
                            display: "inline-block",
                            margin: "52px 13px",
                            // padding:"0px 13px",
                            width: "80%",
                            marginLeft: 35,
                            [theme.breakpoints.up("sm")]: {},
                            [theme.breakpoints.up("md")]: {
                                width: "88%",
                            },
                            [theme.breakpoints.up("lg")]: {},
                            "& .MuiSlider-rail": {
                                height: 3,
                                color: "#AEAEAE",
                                width: "104%",
                            },
                            "& .MuiSlider-track": {
                                height: 3,
                                background:
                                    "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                                transform: "rotate(-180deg)",
                            },
                            "& .MuiSlider-markLabel": {
                                color: "#AEAEAE",
                                fontSize: 14,
                                top: -8,
                            },

                            "& .MuiSlider-thumb ": {
                                display: "none",
                            },
                            "& .MuiSlider-mark": {
                                display: "none",
                            },
                        },
                    },
                    "& .viewTier": {
                        fontSize: 12,
                        lineHeight: "17px",
                        color: "#2DDBFF",
                        fontWeight: "700",
                        fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                        [theme.breakpoints.down("sm")]: {
                            marginTop: 32,
                        },
                        "& .textViewTier": {
                            display: "flex",
                            alignItems: "center",
                            "& img": {
                                marginLeft: 8,
                            },
                        },
                    },
                },
            },
            "& .connectWallet": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                height: "100%",
                [theme.breakpoints.down("sm")]: {
                    marginTop: 20,
                },
                "& .textConnectWallet": {
                    fontSize: 20,
                    fontWeight: 600,
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    color: "white",
                    textAlign: "center",
                    [theme.breakpoints.down("sm")]: {
                        fontSize: 18,
                    },
                },
                "& .btnConnect": {
                    width: "205px",
                    textTransform: "capitalize",
                    backgroundColor: "#C4C4C4",
                    fontSize: 16,
                    lineHeight: "28px",
                    padding: "15px 0px",
                    fontWeight: 700,
                    fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                    borderRadius: "90px",
                    background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                    color: "#fff",
                    marginTop: 25,
                    [theme.breakpoints.down("sm")]: {
                        marginTop: 16,
                        fontSize: 12,
                        width: "160px",
                        height: 40,
                    },
                },
            },
        },
        infoTierSlider: {
            "& p": {
                fontSize: 16,
                fontWeight: 600,
                fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                margin: "5px 0px",
                [theme.breakpoints.down("sm")]: {
                    fontSize: 14,
                },
            },
            "& p:last-child": {
                fontSize: 10,
                color: "rgba(255, 255, 255, .3)",
            },
            "& .dotTier": {
                position: "relative",
                width: 41,
                height: 47,
                "& .dot": {},
                "& .iconDot": {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    filter: "invert(46%) sepia(13%) saturate(444%) hue-rotate(199deg) brightness(89%) contrast(83%)",
                },
                "& .dotActive": {
                    position: "absolute",
                    bottom: "0",
                    right: "0",
                    transform: "translate(50%,0)",
                },
            },
        },
        infoTierSlider2: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& .dotTier": {
                position: "relative",
                width: 41,
                height: 47,
                "& .iconDot": {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    filter: "invert(46%) sepia(13%) saturate(444%) hue-rotate(199deg) brightness(89%) contrast(83%)",
                },
                "& .dotActive": {
                    position: "absolute",
                    bottom: "0",
                    right: "0",
                    transform: "translate(50%,0)",
                },
            },
            "& .active": {
                "& .iconDot": {
                    filter: "invert(100%) sepia(0%) saturate(7500%) hue-rotate(204deg) brightness(102%) contrast(104%)",
                },
                "& .dotActive": {
                    background: "#9747FF",
                },
            },
            "& p": {
                fontSize: 16,
                fontWeight: 600,
                fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                margin: 5,
                [theme.breakpoints.down("sm")]: {
                    fontSize: 14,
                },
            },
            "& p:last-child": {
                fontSize: 10,
                color: "rgba(255, 255, 255, .3)",
                margin: 0,
            },
        },
        infoTierSlider3: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
        },
        activeDot: {
            position: "relative",
            width: 41,
            height: 47,
            "& .iconDot": {
                filter: " invert(0%) sepia(0%) saturate(0%) hue-rotate(5deg) brightness(102%) contrast(102%) !important",
            },
            "& .dot": {
                filter: "invert(48%) sepia(63%) saturate(7396%) hue-rotate(250deg) brightness(100%) contrast(102%) !important",
            },
        },
    };
});
