import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        tierTitle: {
            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
            fontSize: 20,
            lineHeight: "24px",
            color: "#FFFFFF",
            paddingBottom: 7,
            marginBottom: 20,
            borderBottom: "1px solid rgba(255, 255, 255, 0.1)",

            [theme.breakpoints.down("sm")]: {
                fontWeight: 500,
                marginBottom: 20,
                fontSize: 16,
            },
        },

        listInfo: {
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            textAlign: "center",
            background: "#2A2C45",
            borderRadius: 8,
            marginBottom: 12,

            [theme.breakpoints.down("sm")]: {
                gridTemplateColumns: "1fr",
            },
            "& li:not(:last-child)": {
                "& .box": {
                    borderRight: "1px solid rgb(255 255 255 / 10%)",
                    minHeight: 50,
                    paddingRight: 30,
                    [theme.breakpoints.down("sm")]: {
                        borderRight: "none",
                        minHeight: 10,
                        paddingRight: 0,
                    },
                },
            },
        },

        itemInfo: {
            padding: 15,
            // borderRight: "1px solid rgb(255 255 255 / 10%)",
            position: "relative",
            [theme.breakpoints.down("sm")]: {
                minHeight: 10,
                borderBottom: "1px solid rgb(255 255 255 / 10%)",
                borderRight: "none",
                padding: 10,
                margin: "0px 16px",
                "&:last-child": {
                    borderBottom: "none",
                },
            },
            "&:last-child": {
                borderRight: "none",
            },
            "& .box": {
                height: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "column",
            },
        },

        nameItemInfo: {
            fontSize: 12,
            color: "rgba(255, 255, 255, 0.5)",
            lineHeight: "17px",
            marginBottom: 2,
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            [theme.breakpoints.down("sm")]: {
                fontSize: 10,
                lineHeight: "12px",
            },
        },

        valueItemInfo: {
            fontSize: 20,
            lineHeight: "30px",
            color: "#2DDBFF",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            wordBreak: "break-word",
            display: "flex",
            textTransform: "capitalize",
            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
            [theme.breakpoints.down("sm")]: {
                fontSize: 16,
                lineHeight: "24px",
            },
        },

        iconUserTier: {
            height: 20,
            marginRight: 5,
        },

        message: {
            background: "rgba(60, 151, 75, .3)",
            borderRadius: 8,
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            fontSize: 14,
            lineHeight: "20px",
            color: "#FFFFFF",
            padding: "13px 20px",
            display: "flex",
            alignItems: "center",
            marginBottom: 35,
            [theme.breakpoints.down("sm")]: {
                padding: "12px",
                marginBottom: 30,
                alignItems: "flex-start",
                fontSize: 12,
            },

            "& img": {
                marginRight: 6,
            },
        },

        menuTier: {
            display: "flex",
            alignItems: "center",
            // justifyContent: "center",
            listStyle: "none",
            borderBottom: "1px solid rgb(255 255 255 / 10%)",

            [theme.breakpoints.down("sm")]: {
                justifyContent: "space-between",
            },
        },

        itemTabMyTier: {
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            fontWeight: 400,
            fontSize: 14,
            lineHeight: "22px",
            color: "rgba(255, 255, 255, .3)",
            padding: "8px 0px",
            marginBottom: -3,
            cursor: "pointer",
            position: "relative",
            marginRight: 30,

            [theme.breakpoints.down("sm")]: {
                padding: "8px 0px",
                marginRight: 0,
            },

            "&:after": {
                content: '""',
                background: "transperent",
                borderRadius: 20,
                display: "block",
                width: "100%",
                height: 3,
                position: "absolute",
                bottom: 0,
                left: 0,
            },

            "&.active": {
                color: "rgba(255, 255, 255, 1)",
                cursor: "inherit",

                "&:after": {
                    background: "rgba(151, 71, 255, 1)",
                },
            },
        },

        tierComponent: {
            transition: "1s",
            color: "#fff",
            borderRadius: "8px",
            "&.inactive": {
                opacity: 0,
            },
            "&.active": {
                opacity: 1,
            },
            "&.bg-none": {
                background: "none",
                padding: "0",
            },
        },

        bodyPage: {
            padding: "30px 0px",

            [theme.breakpoints.down("sm")]: {
                padding: "25px 0px",
                paddingBottom: 30,
            },
            "& .timeLineTier": {
                display: "inline-block",
                margin: "21px 13px",
                // padding:"0px 13px",
                width: "80%",
                marginLeft: 35,
                [theme.breakpoints.up("sm")]: {},
                [theme.breakpoints.up("md")]: {
                    width: "93%",
                },
                [theme.breakpoints.up("lg")]: {},
                "& .MuiSlider-rail": {
                    height: 3,
                    color: "#AEAEAE",
                },
                "& .MuiSlider-track": {
                    height: 3,
                    background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                    transform: "rotate(-180deg)",
                },
                "& .MuiSlider-markLabel": {
                    color: "#AEAEAE",
                    fontSize: 14,
                    top: -8,
                },

                "& .MuiSlider-thumb ": {
                    display: "none",
                },
                "& .MuiSlider-mark": {
                    display: "none",
                },
            },

            "& .btnGoToTier": {
                float: "right",
                marginTop: 120,
                marginBottom: 40,
                width: 185,
                height: 40,
                backgroundColor: "#0F69E1",
                borderRadius: "90px",
                textTransform: "capitalize",
                color: "#fff",
                fontSize: 12,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                [theme.breakpoints.down("sm")]: {
                    width: "100%",
                    marginTop: 73,
                    marginBottom: 37,
                    maxWidth: 400,
                },
            },
        },

        btnHow: {
            marginTop: 20,
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            width: "100%",
            background: "#444449",
            border: "1px solid #58585A",
            borderRadius: 8,
            minHeight: 46,
            padding: "10px 16px",
            fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
            fontSize: 14,
            lineHeight: "18px",
            color: "#FFFFFF",
            cursor: "pointer",

            "&:hover": {
                color: "#FFFFFF",
                opacity: 0.85,
                textDecoration: "inherit",
            },
        },

        iconBtnHow: {
            marginRight: 8,
        },

        iconArrowRight: {
            marginLeft: "auto",
        },

        infoTierSlider: {
            "& p": {
                fontSize: 16,
                fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                marginBottom: 5,
            },
            "& p:last-child": {
                fontSize: 10,
                color: "rgba(255, 255, 255, .3)",
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            },
            "& .dotTier": {
                position: "relative",
                width: 41,
                height: 47,
                "& .dot": {},
                "& .iconDot": {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    filter: "invert(46%) sepia(13%) saturate(444%) hue-rotate(199deg) brightness(89%) contrast(83%)",
                },
                "& .dotActive": {
                    position: "absolute",
                    bottom: "0",
                    right: "0",
                    transform: "translate(50%,0)",
                },
            },
        },

        infoTierSlider2: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& .dotTier": {
                position: "relative",
                width: 41,
                height: 47,
                "& .iconDot": {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    filter: "invert(46%) sepia(13%) saturate(444%) hue-rotate(199deg) brightness(89%) contrast(83%)",
                },
                "& .dotActive": {
                    position: "absolute",
                    bottom: "0",
                    right: "0",
                    transform: "translate(50%,0)",
                },
            },
            "& .active": {
                "& .iconDot": {
                    filter: "invert(100%) sepia(0%) saturate(7500%) hue-rotate(204deg) brightness(102%) contrast(104%)",
                },
                "& .dotActive": {
                    background: "#9747FF",
                },
            },
            "& p": {
                fontSize: 16,
                fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                margin: 5,
            },
            "& p:last-child": {
                fontSize: 10,
                color: "rgba(255, 255, 255, .3)",
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                margin: 0,
            },
        },

        infoTierSlider3: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
        },

        activeDot: {
            position: "relative",
            width: 41,
            height: 47,
            "& .iconDot": {
                filter: " invert(0%) sepia(0%) saturate(0%) hue-rotate(5deg) brightness(102%) contrast(102%) !important",
            },
            "& .dot": {
                filter: "invert(48%) sepia(63%) saturate(7396%) hue-rotate(250deg) brightness(100%) contrast(102%) !important",
            },
        },
    };
});

export default useStyles;
