import { AnyAction } from "redux";
import { idoDetail } from "../constants/ido-detail";

type StateType = {
    data: any[];
    loading: boolean;
    error: string;
};

const initialState = {
    data: [],
    loading: false,
    error: "",
};

export const getIdoDetailReducer = (state: StateType = initialState, action: AnyAction) => {
    switch (action.type) {
        case idoDetail.IDO_DETAIL_LOADING: {
            return {
                ...state,
                loading: true,
            };
        }

        case idoDetail.IDO_DETAIL_SUCCESS: {
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        }

        case idoDetail.IDO_DETAIL_FAILURE: {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        }

        default: {
            return state;
        }
    }
};
