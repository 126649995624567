import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        box: {
            minHeight: 1000,
            [theme.breakpoints.down("md")]: {
                minHeight: "100vw",
            },
        },
        listItemDetail: {
            width: "100%",
            padding: "25px 0px",
            color: "#fff",
            display: "flex",
            alignItems: "flex-start",
            // maxHeight: "130px",
            [theme.breakpoints.down("sm")]: {
                maxHeight: "100%",
                padding: "20px 0px",
            },
            "&:first-child,&:last-child": {
                borderTop: "1px solid rgba(255, 255, 255, .1)",
            },

            "& .month": {
                minWidth: 82,
                height: 82,
                background: "#14162C",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 10,
                marginRight: 50,
                [theme.breakpoints.down("sm")]: {
                    height: 65,
                    marginRight: 24,
                    minWidth: 65,
                },
                "& p": {
                    fontSize: 32,
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    lineHeight: "42px",
                    [theme.breakpoints.down("sm")]: {
                        fontSize: 20,
                        lineHeight: "30px",
                    },
                },
                "& span": {
                    fontSize: 18,
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    opacity: "0.5",
                    [theme.breakpoints.down("sm")]: {
                        fontSize: 14,
                        lineHeight: "22px",
                    },
                },
            },

            "& .item": {
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                padding: "17px 0px",
                [theme.breakpoints.down("sm")]: {
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: 0,
                },
            },

            "& .info": {
                display: "flex",
                alignItems: "center",
                marginRight: 100,
                marginBottom: 20,
                [theme.breakpoints.down("sm")]: {
                    marginRight: 0,
                    marginBottom: 16,
                    height: 65,
                },
                "& .line": {
                    width: 4,
                    height: 48,
                    background: "#337FE3",
                    marginRight: 15,
                    [theme.breakpoints.down("sm")]: {
                        height: 34,
                    },
                },
                " & .box": {
                    "& p": {
                        fontSize: 16,
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        lineHeight: "24px",
                        opacity: "0.5",
                        [theme.breakpoints.down("sm")]: {
                            fontSize: 14,
                            lineHeight: "16px",
                        },
                    },
                    "& span": {
                        fontSize: 18,
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        whiteSpace: "wrap !important",
                        [theme.breakpoints.down("sm")]: {
                            fontSize: 16,
                            lineHeight: "10px",
                            whiteSpace: "wrap !important",
                        },
                    },
                },
            },
        },
    };
});
export default useStyles;
