import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        container: {
            backgroundColor: "#2A2C45",
            marginTop: 20,
            // height: 170,
            borderRadius: 10,
            padding: "20px 20px 2px 20px",
        },
        content: {
            display: "flex",
            flexDirection: "row",
            marginBottom: 24,
            "&> div:first-child": {
                width: "50%",
                "&> p:first-child": {
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "white",
                    opacity: 0.3,
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    // whiteSpace: "pre",
                    // overflow: "hidden",
                    // textOverflow: "ellipsis",
                },
                "&> p:last-child": {
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "white",
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    whiteSpace: "pre",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                },
            },
            "&> div:last-child": {
                "&> p:first-child": {
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "white",
                    opacity: 0.3,
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    // whiteSpace: "pre",
                    // overflow: "hidden",
                    // textOverflow: "ellipsis",
                },
                "&> p:last-child": {
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "white",
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    // whiteSpace: "pre",
                    // overflow: "hidden",
                    // textOverflow: "ellipsis",
                },
            },
        },
    };
});

export default useStyles;
