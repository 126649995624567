import { Checkbox, FormControlLabel, FormGroup, withStyles } from "@material-ui/core";
import { ListStatusIDO } from "./utils";

interface IProps {
    listChecked?: any;
    onChange: (data: any) => void;
}

const CheckboxWithGreenCheck = withStyles({
    root: {
        "&$checked": {
            "& .MuiSvgIcon-root": {
                color: "#9747FF",
            },
            "& .MuiIconButton-label": {
                position: "relative",
                zIndex: 0,
            },
            "& .MuiIconButton-label:after": {
                content: '""',
                left: 4,
                top: 4,
                height: 15,
                width: 15,
                position: "absolute",
                backgroundColor: "#fff",
                zIndex: -1,
            },
        },
    },
    checked: {},
})(Checkbox);

const SelectIDOStatus = (props: IProps) => {
    const { onChange, listChecked } = props;
    const checked = (currentValue: any) => {
        return listChecked?.includes(currentValue);
    };

    return (
        <FormGroup className="btnStatusBox">
            {ListStatusIDO.map((status, index) => (
                <FormControlLabel
                    key={index}
                    value={status.value}
                    onClick={onChange}
                    control={
                        <CheckboxWithGreenCheck
                            name={status.value}
                            checked={checked(status.value)}
                        />
                    }
                    label={status.label}
                />
            ))}
        </FormGroup>
    );
};

export default SelectIDOStatus;
