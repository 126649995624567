import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        container: {
            backgroundColor: "#2A2C45",
            marginTop: 20,
            // height: 170,
            borderRadius: 10,
            display: "flex",
            flexDirection: "column",
            padding: 20,
            "&> .account": {
                display: "flex",
                flexDirection: "column",
                "&> p:first-child": {
                    fontSize: 14,
                    fontWeight: 400,
                    color: "white",
                    opacity: 0.3,
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                },
                "&> p:last-child": {
                    fontSize: 14,
                    fontWeight: 400,
                    color: "white",
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                },
            },
            "&> .cyball": {
                marginTop: 16,
                "&> div": {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    "&> p": {
                        fontSize: 14,
                        fontWeight: 400,
                        color: "white",
                        opacity: 0.3,
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        marginLeft: 4,
                    },
                },
                "&> p": {
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#2DDBFF",
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                },
            },
        },
    };
});

export default useStyles;
