// import { Box } from "@material-ui/core";
import { Card, CardContent, makeStyles } from "@material-ui/core";
import i18n from "i18next";
import moment from "moment";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import CountdownIdoItems from "../../components/Base/Countdown/CountdownCard";
import { TELEGRAM_LINK_URL, TWITTER_LINK_URL } from "../../constants";
import { formatPrecisionAmount } from "../../utils";

const useStyles = makeStyles((theme: any) => {
    return {
        root: {
            maxWidth: "393px",
            width: "393px",
            height: "100%",
            background: "#13142F",
            borderRadius: 20,
            cursor: "pointer",
            [theme.breakpoints.down("xs")]: {
                maxWidth: "343px",
                width: "343px",
            },
        },
        media: {
            height: 240,
            // position: "relative",
            // zIndex: 10,
            // "&::after": {
            //     content: '""',
            //     display: "block",
            //     position: "absolute",
            //     width: "393px",
            //     height: "240px",
            //     backgroundImage: "url('/images/home-page/imgDefaultCard.svg')",
            //     zIndex: 1,
            // },
            "& img": {
                objectFit: "cover",
            },
        },

        content: {
            color: "white",
            "& .boxContent": {},
            "& .nft": {
                padding: "10px 0",
                fontSize: 16,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                "&> h2": {
                    display: "-webkit-box",
                    "-webkit-line-clamp": 1,
                    "-webkit-box-orient": "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                },
                [theme.breakpoints.down("xs")]: {
                    fontSize: 14,
                },
            },
            "& .total": {
                marginTop: 16,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                fontSize: 18,
                color: "white",
                [theme.breakpoints.down("xs")]: {
                    fontSize: 14,
                },
                "& > p:first-child": {
                    opacity: 0.3,
                    fontWeight: 400,
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    fontSize: 14,
                    [theme.breakpoints.down("xs")]: {
                        fontSize: 12,
                    },
                },
                "& > p:last-child": {
                    opacity: 0.7,
                    fontWeight: 600,
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    fontSize: 16,
                    // fontFamily: "Poppins",
                    [theme.breakpoints.down("xs")]: {
                        fontSize: 12,
                    },
                },
            },
            "& .footerCard": {
                marginTop: 16,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end",
                padding: "16px 0",
                borderTop: "1px solid #2F2E41",
                // opacity: 0.3,
                "&> .statusProject": {
                    display: "flex",
                    flexDirection: "column",
                    "&> p": {
                        opacity: 0.3,
                        fontWeight: 400,
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        fontSize: 12,
                        marginBottom: 10,
                    },
                    "&> button": {
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "none",
                        borderRadius: 5,
                        backgroundColor: "#242548",
                        // color: "#9747FF",
                        height: 28,
                        padding: "0 10px",
                        "&> img": {
                            marginBottom: 2,
                            marginRight: 6,
                        },
                    },
                    "&> .Upcoming": {
                        color: "#9747FF",
                    },
                    "&> .Calculation": {
                        color: "#337FE3",
                    },
                    "&> .BuyTicket": {
                        color: "#3C974B",
                    },
                    "&> .Distribution": {
                        color: "#E4C96A",
                    },
                    "&> .Ended": {
                        color: "#FF3333",
                    },
                },
                "&> .icon": {
                    opacity: 0.3,
                    display: "flex",
                    alignItems: "center",
                    "&>a": {
                        marginLeft: 13,
                    },
                },
            },
        },
    };
});

type Props = {
    item: {
        id: string;
        img: string;
        name: string;
        icon: string;
        amount: number;
        conversion: number;
        status: string;
        time: string;
        statusProject: string;
        stakingSnapshot: string;
        ticketOpen: string;
        ticketClose: string;
        poolEnd: string;
        network: string;
        ido_telegram: string;
        ido_twitter: string;
        ido_website: string;
        token_symbol: string;
        participants: number;
    };
    // stakingSnapshot: string;
};

export const convertLang = (data: any, key: string) => {
    try {
        const result = JSON.parse(data);
        return result[key === "en" ? key : "kr"];
    } catch (e) {
        return data;
    }
};
export default function CardItems({ item }: Props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const renderCountDownStatus = useCallback(() => {
        return (
            <>
                {(() => {
                    switch (item?.status) {
                        case "1": {
                            return (
                                <CountdownIdoItems
                                    status={item?.status}
                                    startDate={
                                        new Date(
                                            moment(item?.stakingSnapshot).format(
                                                "MM/DD/YYYY HH:mm:ss"
                                            )
                                        )
                                    }
                                />
                            );
                        }
                        case "2": {
                            return (
                                <CountdownIdoItems
                                    status={item?.status}
                                    startDate={
                                        new Date(
                                            moment(item?.ticketOpen).format("MM/DD/YYYY HH:mm:ss")
                                        )
                                    }
                                />
                            );
                        }
                        case "3": {
                            return (
                                <CountdownIdoItems
                                    status={item?.status}
                                    startDate={
                                        new Date(
                                            moment(item?.ticketClose).format("MM/DD/YYYY HH:mm:ss")
                                        )
                                    }
                                />
                            );
                        }
                        case "4": {
                            return (
                                <CountdownIdoItems
                                    status={item?.status}
                                    startDate={
                                        new Date(
                                            moment(item?.poolEnd).format("MM/DD/YYYY HH:mm:ss")
                                        )
                                    }
                                />
                            );
                        }
                        case "5": {
                            return (
                                <CountdownIdoItems
                                    status={item?.status}
                                    startDate={
                                        new Date(
                                            moment(item?.poolEnd).format("MM/DD/YYYY HH:mm:ss")
                                        )
                                    }
                                />
                            );
                        }
                        default:
                            return null;
                    }
                })()}
            </>
        );
    }, [item?.poolEnd, item?.stakingSnapshot, item?.status, item?.ticketClose, item?.ticketOpen]);

    const handleClickProjectDetail = (id: any) => {
        history.push(`/project-detail/${id}`);
    };

    const totalRaise = item.amount;

    const renderImgNetwork = () => {
        return (
            <>
                {(() => {
                    switch (item?.network) {
                        case "bsc": {
                            return <img src="/images/home-page/iconNetworkBsc.svg" alt="" />;
                        }
                        case "polygon": {
                            return <img src="/images/home-page/iconNetworkPolygon.svg" alt="" />;
                        }
                        case "klaytn": {
                            return <img src="/images/home-page/iconNetworkKlay.svg" alt="" />;
                        }
                        default:
                            return null;
                    }
                })()}
            </>
        );
    };

    return (
        <Card onClick={() => handleClickProjectDetail(item?.id)} className={classes.root}>
            {/* <CardActionArea> */}
            {/* <CardMedia className={classes.media} image={item?.img} title="Contemplative Reptile" /> */}
            <div className={classes.media}>
                <img
                    width="100%"
                    height="240px"
                    src={item?.img}
                    alt=""
                    onError={(e) => {
                        (e.target as HTMLImageElement).onerror = null;
                        (e.target as HTMLImageElement).src = "/images/home-page/imgDefaultCard.svg";
                    }}
                />
            </div>
            <CardContent className={classes.content}>
                <div className="boxContent" onClick={() => handleClickProjectDetail(item?.id)}>
                    <div className="nft">
                        <h2>
                            {convertLang(item.name, i18n.language)} (
                            {item.token_symbol ? item.token_symbol : ""})
                        </h2>
                        {renderImgNetwork()}
                    </div>
                    <div className="total">
                        <p>{t("IdoHomePage.cardTotal")}</p>
                        <p>${formatPrecisionAmount(totalRaise)}</p>
                    </div>
                    <div className="total">
                        <p>{t("IdoHomePage.participants")}</p>
                        <p>{item.participants ? item.participants : "-"}</p>
                    </div>
                </div>
                <div className="footerCard">
                    <div onClick={() => handleClickProjectDetail(item?.id)}>
                        {renderCountDownStatus()}
                    </div>
                    <div className="icon">
                        <a
                            href={`${TELEGRAM_LINK_URL}${item?.ido_telegram}` || "/"}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <img src="/images/home-page/iconTelegram.svg" alt="" />
                        </a>
                        <a
                            href={`${TWITTER_LINK_URL}${item?.ido_twitter}` || "/"}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <img src="/images/home-page/logoTwitter.svg" alt="" />
                        </a>
                        <a
                            href={item?.ido_website || "/"}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <img src="/images/home-page/iconEarn.svg" alt="" />
                        </a>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
}
