import { AnyAction } from "redux";
import { poolNoReward } from "../constants/pool-noreward";

type StateType = {
  data: any[];
  loading: boolean;
  error: string;
};

const initialState = {
  data: [],
  loading: false,
  error: "",
};

const initialStateLPs = {
  data: [],
  loading: false,
  error: "",
  page_size: 4,
  page_number: 0,
};

export const getPoolNoRewardCODEReducer = (
  state: StateType = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case poolNoReward.POOL_NOREWARD_CODE_LOADING: {
      return {
        ...state,
        data:[],
        error:"",
        loading: true,
      };
    }

    case poolNoReward.POOL_NOREWARD_CODE_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    }

    case poolNoReward.POOL_NOREWARD_CODE_FAILURE: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export const getPoolNoRewardLPReducer = (
  state: StateType = initialStateLPs,
  action: AnyAction
) => {
  switch (action.type) {
    case poolNoReward.POOL_NOREWARD_LP_LOADING: {
      return {
        ...state,
        loading: true,
        data:[],
        error:"",
      };
    }

    case poolNoReward.POOL_NOREWARD_LP_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    }

    case poolNoReward.POOL_NOREWARD_LP_FAILURE: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }

    case poolNoReward.SET_PAGE_SIZE_POOL_NOREWARD: {
      return {
        ...state,
        page_size: action.payload,
      };
    }

    case poolNoReward.SET_PAGE_NUMBER_POOL_NOREWARD: {
      return {
        ...state,
        page_number: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
