import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import Countdown from "../../../components/Base/Countdown";
import { checkApplyCompleted, checkApplyWhitelist } from "../Api";
import useStyles from "./style";

type TParams = {
    id: "string";
};

type Props = {
    dataSnapshot?: any;
};

export default function CalculatingAccount({ dataSnapshot }: Props) {
    const styles = useStyles();
    const { t } = useTranslation();
    const status = useSelector((state: any) => state.statusIdoPool.message);
    const tierAccount = useSelector((state: any) => state.userTier.data.name);
    const idoDetail = useSelector((state: any) => state.idoDetail.data);

    const { id } = useParams<TParams>();

    const [accountCondition, setAccountCondition] = useState<boolean>(false);
    const [checkApply, setCheckApply] = useState<any>();
    const [dataWhitelist, setDataWhitelist] = useState<any>();

    const convertTimeTicketOpen = moment(idoDetail?.start_ticket_open).format(
        "MM/DD/YYYY HH:mm:ss"
    );

    useEffect(() => {
        if (status === "Upcoming") return;
        if (checkApply?.data?.isApply) {
            checkApplyCompleted(id).then((res) => {
                setDataWhitelist(res);
            });
        }
    }, [checkApply?.data?.isApply, id, status]);

    useEffect(() => {
        // if (tierAccount !== "starter") return;
        checkApplyWhitelist(id).then((res) => {
            setCheckApply(res);
        });
    }, [id]);

    useEffect(() => {
        if (!tierAccount) return;
        if (dataSnapshot) {
            if (
                dataSnapshot?.aprove_whitelist === true ||
                dataSnapshot?.tier === "Super Rookie" ||
                dataSnapshot?.tier === "Legend"
            ) {
                setAccountCondition(true);
            } else if (
                checkApply?.data?.isApply === false ||
                dataWhitelist?.data?.social_requirement === "None"
            ) {
                setAccountCondition(false);
            }
        } else {
            if (
                idoDetail?.state === "SNAPSHOTTED" ||
                idoDetail?.state === "CALCULATED" ||
                idoDetail?.state === "ENDED"
            ) {
                setAccountCondition(false);
            } else if (
                dataWhitelist?.data?.social_requirement === "Completed" ||
                tierAccount === "rookie" ||
                tierAccount === "legend"
            ) {
                setAccountCondition(true);
            } else if (dataSnapshot?.aprove_whitelist === false) {
                setAccountCondition(false);
            }
        }
    }, [
        checkApply?.data?.isApply,
        dataSnapshot,
        dataSnapshot?.aprove_whitelist,
        dataSnapshot?.tier,
        dataWhitelist,
        dataWhitelist?.data?.social_requirement,
        idoDetail?.state,
        tierAccount,
    ]);

    const renderStatusAccountWhitelist = () => {
        if (
            accountCondition === true &&
            (idoDetail?.status === "2" || idoDetail?.state !== "SNAPSHOTTED")
        ) {
            return (
                <div className={styles.condition}>
                    <p>{t("ProjectDetails.calculationSuccess.desc1")}</p>
                    <p>{t("ProjectDetails.calculationSuccess.desc2")}</p>
                </div>
            );
        } else if (accountCondition === false && idoDetail?.status === "2") {
            return (
                <div className={styles.condition}>
                    <p>{t("ProjectDetails.calculationFail.desc1")}</p>
                    <p>{t("ProjectDetails.calculationFail.desc2")}</p>
                </div>
            );
        } else if (
            accountCondition === false &&
            (idoDetail?.status === "3" ||
                idoDetail?.status === "4" ||
                idoDetail?.status === "5" ||
                idoDetail?.state !== "SNAPSHOTTED")
        ) {
            return (
                <div className={styles.condition}>
                    <p>{t("ProjectDetails.buyTicketFail.desc1")}</p>
                    <p>{t("ProjectDetails.buyTicketFail.desc2")}</p>
                </div>
            );
        }
    };

    return (
        <div className={styles.snapshotIn}>
            <p>{t("ProjectDetails.ticketOpenIn")}</p>
            <Countdown startDate={new Date(convertTimeTicketOpen)} />
            {renderStatusAccountWhitelist()}
        </div>
    );
}
