import { useTranslation } from "react-i18next";
import useStyles from "./style";

export default function StepsMobile(props: any) {
    const styles = useStyles();
    const { t } = useTranslation();

    return (
        <div className={styles.wrapper}>
            <div>
                <p>{t("ProjectDetails.stepsToJoin")}</p>
            </div>
            <div>
                <div className={styles.container}>
                    <div className={styles.timeline}>
                        <div>
                            <p>1</p>
                        </div>
                        <div></div>
                    </div>
                    <div className={styles.desc}>Buy $CODE & HODL</div>
                </div>
                <div className={styles.container}>
                    <div className={styles.timeline}>
                        <div>
                            <p>2</p>
                        </div>
                        <div></div>
                    </div>
                    <div className={styles.desc}>Apply Whitelist (Starter only)</div>
                </div>
                <div className={styles.container}>
                    <div className={styles.timeline}>
                        <div>
                            <p>3</p>
                        </div>
                        <div></div>
                    </div>
                    <div className={styles.desc}>Staking $CODE</div>
                </div>
                <div className={styles.container}>
                    <div className={styles.timeline}>
                        <div>
                            <p>4</p>
                        </div>
                        <div></div>
                    </div>
                    <div className={styles.desc}>Buy Ticket</div>
                </div>
                <div className={styles.container5}>
                    <div className={styles.timeline5}>
                        <div>
                            <p>5</p>
                        </div>
                    </div>
                    <div className={styles.desc}>Claim</div>
                </div>
            </div>
        </div>
    );
}
