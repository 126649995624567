import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        snackBar: {
            marginTop: 100,
            width: "100%",
            "& .MuiSnackbar-root": {
                position: "relative",
                right: 0,
                bottom: 0,
                top: 0,
            },
        },
        messageUpdateSuccess: {
            position: "relative",
            width: "100%",
            padding: "14px 140px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: "#3C974B",
            minHeight: 48,
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            fontSize: 14,
            lineHeight: "20px",
            color: "#FFFFFF",
            [theme.breakpoints.down("sm")]: {
                padding: "23px 18px",
            },
            "& button": {
                padding: 0,
                minWidth: 0,
                height: "100%",
            },
            "& .box": {
                display: "flex",
                alignItems: "center",
            },
            "& img": {
                marginRight: 6,
            },
        },

        alertVerifyEmail: {
            position: "relative",
            width: "100%",
            padding: 9,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            background: "#591425",
            marginBottom: 15,
            borderRadius: "8px",
            minHeight: 42,

            [theme.breakpoints.down("sm")]: {
                alignItems: "flex-start",
            },

            "& img": {
                marginTop: 3,
                [theme.breakpoints.down("sm")]: {
                    marginTop: 6,
                },
            },

            "& .btn-close": {
                position: "absolute",
                top: "50%",
                right: "15px",
                transform: "translateY(-50%)",
            },

            "& span": {
                font: "normal normal 400 14px/24px Helvetica",
                color: "#FFFFFF",
            },

            "& a": {
                color: "#6398FF",
            },
        },

        errorSwich: {
            marginBottom: 20,
        },

        errorBanner: {
            color: "white",
            backgroundColor: "#FF4C00",
            textAlign: "center",
            padding: 12,
            marginBottom: 0,
            flex: 1,
        },
        title: {
            font: "normal normal bold 28px/32px DM Sans",
            color: "#FFF",
            position: "relative",

            "&:after": {
                content: '""',
                display: "block",
                width: "100%",
                height: "1px",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                position: "absolute",
                bottom: "-10px",
            },
        },
        mainContent: {
            display: "grid",
            gridTemplateColumns: "5fr 4fr",
            gap: "100px",
            marginTop: "10px",
            marginBottom: "120px",
            position: "relative",
            [theme.breakpoints.down("md")]: {
                gridTemplateColumns: "1fr",
            },
            [theme.breakpoints.down("sm")]: {
                gridTemplateColumns: "1fr",
                padding: "40px",
                paddingTop: "150px",
            },
            [theme.breakpoints.only("xs")]: {
                gridTemplateColumns: "1fr",
                padding: "20px",
                paddingTop: "100px",
            },
        },
        leftPanel: {},
        rightPanel: {
            maxWidth: "100%",
            width: "100%",
        },
        accountContainer: {
            padding: "45px 0 80px 0",
            width: 1240,
            minHeight: "calc(100vh - 400px)",
            maxWidth: "calc(100vw - 80px)",
            margin: "auto",

            [theme.breakpoints.down("xs")]: {
                width: "calc(100% - 36px)",
                maxWidth: "calc(100vw - 36px)",
                margin: "auto",
            },
        },
        [theme.breakpoints.down("xs")]: {
            mainContent: {
                padding: "20px 0",
                marginBottom: "80px",
                gap: "60px",
            },
        },

        tier: {
            background: "#131530",
            borderRadius: "12px",
            padding: "30px",
            [theme.breakpoints.down("sm")]: {
                padding: "16px",
            },
        },

        // styles v3
        bodyContentMyAccount: {
            display: "grid",
            gridTemplateColumns: "240px 1fr",
            gap: 20,
            color: "#FFFFFF",
            minHeight: 500,
            [theme.breakpoints.down("sm")]: {
                gridTemplateColumns: "1fr",
                minHeight: 300,
            },
        },

        leftAccount: {
            padding: "30px 30px",
            maxHeight: 640,
            height: "100%",
            backgroundColor: "#131530",
            width: 240,
            marginRight: 20,
            borderRadius: 8,
            [theme.breakpoints.down("sm")]: {
                padding: 0,
                width: "100%",
                backgroundColor: "transparent",
            },
        },

        titlLeft: {
            fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
            fontSize: 20,
            lineHeight: "24px",
            color: "#FFFFFF",
            marginBottom: 27,

            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },

        tabAccount: {
            display: "flex",
            flexDirection: "column",
            [theme.breakpoints.down("sm")]: {
                flexWrap: "wrap",
                flexDirection: "row",
                // display: "grid",
                // gridTemplateColumns: "1fr 1fr 1fr",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-around",
                height: "100%",
            },
        },

        itemTabAccount: {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
            fontSize: 14,
            lineHeight: "22px",
            color: "rgba(255, 255, 255, 0.3)",
            cursor: "pointer",
            marginBottom: 28,

            [theme.breakpoints.down("sm")]: {
                margin: "5px 0px",
                padding: "7px 8px",
                // margin: 0,
                "&:last-child": {
                    marginRight: 0,
                },
            },

            "&.active": {
                color: "#FFFFFF",
                cursor: "inherit",
                [theme.breakpoints.down("sm")]: {
                    background: "rgba(255, 255, 255, 0.1)",
                    borderRadius: 10,
                },
            },
        },

        iconItemTabAccount: {
            marginRight: 14,
            width: 16,
            height: 16,
            maskPositionX: "center",
            maskPositionY: "center",
            maskSize: "contain",
            maskRepeatX: "no-repeat",
            maskRepeatY: "no-repeat",
            maskOrigin: "initial",
            maskClip: "initial",
            background: "rgba(255, 255, 255, 0.3)",

            "&.active": {
                background: "#fff",
            },

            [theme.breakpoints.down("sm")]: {
                // display: "none",
                marginRight: 8,
            },
        },

        rightAccount: {
            height: "100%",
            overflow: "hidden",
            minHeight: 500,
            background: "#131530",
            borderRadius: 8,
            [theme.breakpoints.down("sm")]: {
                minHeight: 300,
            },
        },
    };
});

export default useStyles;
