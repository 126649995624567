import { Slider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useStyles from "./style";

export default function Steps(props: any) {
    const styles = useStyles();
    const { t } = useTranslation();

    const tier = [
        {
            value: 5,
            label: (
                <div>
                    <div className={styles.steps}>
                        <p>1</p>
                    </div>
                    <p className={styles.desc}>{t("ProjectDetails.BuyCode")}</p>
                    <p className={styles.desc2}>{t("ProjectDetails.hodl")}</p>
                </div>
            ),
        },
        {
            value: 25,
            label: (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <div className={styles.steps}>
                        <p>2</p>
                    </div>
                    <p className={styles.desc}>{t("ProjectDetails.applyWhitelist")}</p>
                    {/* <p className={styles.desc2}>{t("ProjectDetails.starterOnly")}</p> */}
                    <p className={styles.desc2}>(Starter only)</p>
                </div>
            ),
        },
        {
            value: 48,
            label: (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <div className={styles.steps}>
                        <p>3</p>
                    </div>
                    <p className={styles.desc}>{t("ProjectDetails.stakingCode")}</p>
                </div>
            ),
        },
        {
            value: 71,
            label: (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <div className={styles.steps}>
                        <p>4</p>
                    </div>
                    <p className={styles.desc}>{t("ProjectDetails.statusBuyTicket")}</p>
                </div>
            ),
        },
        {
            value: 95,
            label: (
                <div>
                    <div className={styles.steps}>
                        <p>5</p>
                    </div>
                    <p className={styles.desc}>{t("ProjectDetails.claim")}</p>
                </div>
            ),
        },
    ];

    return (
        <div className={styles.wrapper}>
            <p>{t("ProjectDetails.stepsToJoin")}</p>
            <Slider
                defaultValue={0}
                aria-labelledby="discrete-slider-custom"
                valueLabelDisplay="auto"
                marks={tier}
                value={0}
                className="timeLineTier"
            />
        </div>
    );
}
