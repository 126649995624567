import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        modalSignatureRequired: {
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            "& .modal-content": {
                backgroundColor: "#242640",
                width: "450px",
                minHeight: "350px",
                display: "flex",
                alignItems: "center",
                [theme.breakpoints.down("xs")]: {
                    padding: "40px 20px 20px",
                },
                borderRadius: "20px",
                color: "#FFFFFF",
            },
            "& .modal-content__body": {
                padding: 0,
                margin: 0,
                fontSize: 16,
                lineHeight: "30px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: "rgba(255, 255, 255, .5)",
                [theme.breakpoints.down("xs")]: {
                    fontSize: 14,
                },
                "& p": {
                    fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                    fontSize: 24,
                    color: "white",
                    marginTop: 38,
                    lineHeight: "33px",
                    marginBottom: 5,
                },
            },
        },
    };
});

export default useStyles;
