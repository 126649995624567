import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useCommonStyle from "../../../styles/CommonStyle";
import Button from "../Button";
import useStyles from "./style";

const closeIcon = "/images/icons/close.svg";

const ModalStake = (props: any) => {
    const styles = useStyles();
    const commonStyles = useCommonStyle();
    const { t } = useTranslation();
    const { open, onConfirm, onClose, tokenAddress, isReward } = props;
    const poolNoRewardCODE = useSelector((state: any) => state.poolNoRewardCODE.data);

    return (
        <Dialog
            open={open}
            keepMounted
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className={commonStyles.modal + " " + styles.modalConfirm}
        >
            <div className="modal-content">
                <DialogTitle id="alert-dialog-slide-title" className="modal-content__head">
                    <img src={closeIcon} alt="" onClick={onClose} className="btn-close" />
                </DialogTitle>
                <DialogContent className="modal-content__body">
                    <div>{t("staking.modalConfirm.content")}</div>
                    <p>{t("staking.modalConfirm.question")}</p>
                </DialogContent>
                <DialogActions className="modal-content__foot" style={{ border: "none" }}>
                    <Button text={t("staking.modalConfirm.btnCancel")} onClick={onClose} />
                    <Button
                        text={t("staking.modalConfirm.btnConfirm")}
                        onClick={() =>
                            onConfirm({
                                tokenAddress,
                                spenderApprove: isReward
                                    ? process.env.REACT_APP_REWARD
                                    : poolNoRewardCODE?.contract_address,
                                token_decimal:18,
                            })
                        }
                    />
                </DialogActions>
            </div>
        </Dialog>
    );
};

export default ModalStake;
