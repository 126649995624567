import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: any) => {
    return {
        header: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
            marginTop: 44,
            [theme.breakpoints.up("lg")]: {
                marginTop: 130,
            },
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            "& h1": {
                fontSize: 24,
                lineHeight: "32px",
                padding: "0px 8px",
                fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                fontWeight: 600,
                background:
                    "linear-gradient(260.09deg, #AF5CFF -4.1%, #FFFFFF 40.03%, #FFFFFF 64.8%, #5FE3F3 111.46%)",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                color: "transparent",
                [theme.breakpoints.up("lg")]: {
                    fontSize: 48,
                    lineHeight: "56px",
                    width: "580px",
                },
            },
            "& p": {
                fontSize: 12,
                padding: "0px 15px",
                marginTop: 12,
                color: "#AEAEAE",
                height: 70,
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                [theme.breakpoints.up("sm")]: {
                    padding: "0px 64px",
                    fontSize: 14,
                    lineHeight: "18px",
                },
                [theme.breakpoints.up("md")]: {
                    padding: "0px 120px",
                    lineHeight: "22px",
                },
                [theme.breakpoints.up("lg")]: {
                    fontSize: 14,
                    lineHeight: "22px",
                    padding: 0,
                    width: "622px",
                },
            },
        },
    };
});

export const useSwitchStyle = makeStyles((theme: any) => {
    return {
        root: {
            width: 38,
            height: 21,
            padding: 0,
            margin: theme.spacing(1),
            [theme.breakpoints.down("xs")]: {
                marginTop: "20px",
                marginBottom: "30px",
            },
            [theme.breakpoints.up("xs")]: {
                marginLeft: "20px",
            },
        },
        switchBase: {
            padding: 2,
            "&$checked": {
                transform: "translateX(16px)",
                color: theme.palette.common.white,
                "& + $track": {
                    backgroundColor: "#3232DC",
                    opacity: 1,
                    border: "none",
                },
            },
            "&$focusVisible $thumb": {
                color: "#3232DC",
                border: "6px solid #fff",
            },
        },
        thumb: {
            width: 18,
            height: 18,
        },
        track: {
            borderRadius: 26 / 2,
            border: `1px solid #5F5F62`,
            backgroundColor: "#5F5F62",
            opacity: 1,
            transition: theme.transitions.create(["background-color", "border"]),
        },
        checked: {},
        focusVisible: {},
    };
});

export const useButtonGroupStyle = makeStyles((theme: any) => {
    return {
        group: {
            marginTop: 14,
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            [theme.breakpoints.down("sm")]: {
                marginTop: 32,
            },
            "& .MuiButtonGroup-groupedHorizontal:not(:last-child)": {
                borderTopRightRadius: "60px",
                borderBottomRightRadius: "60px",
            },
            "& .MuiButtonGroup-groupedHorizontal:not(:first-child)": {
                borderTopLeftRadius: "60px",
                borderBottomLeftRadius: "60px",
            },
        },
        btn: {
            margin: "7.5px 10px",
            borderRadius: "60px",
            textTransform: "capitalize",
            fontSize: 12,
            height: 40,
            background: "#0F69E1",
            width: 165,
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            whiteSpace: "nowrap",
            [theme.breakpoints.up("md")]: {
                fontSize: 16,
                lineHeight: "24px",
                width: 210,
                height: 42,
            },
            "& img": {
                marginLeft: 10,
            },
            "& span": {
                textTransform: "none",
            },
        },
        btnActive: {
            background: "#3232DC",
            color: "#FFFFFF",
            textTransform: "none",
            fontWeight: "bold",
            outline: "none",
            border: "none",
            padding: "5px 15px",
            [theme.breakpoints.down("xs")]: {
                padding: "5px 10px",
            },
            borderRadius: "30px",
            "&:hover": {
                textTransform: "none",
                background: "#3232DC",
                border: "none",
                outline: "none",
            },
        },
        btnDisabled: {
            color: "#FFFFFF",
            textTransform: "none",
            outline: "none",
            borderStyle: "none",
            padding: "5px 15px",
            [theme.breakpoints.down("xs")]: {
                padding: "5px 10px",
            },
            "&:hover": {
                border: "none",
                outline: "none",
                opacity: 0.8,
            },
        },
    };
});
