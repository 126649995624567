import BigNumber from "bignumber.js";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ConnectorNames } from "./constants/connectors";
import { getWeb3Instance } from "./services/web3";
import { caver } from "./store/actions/user";
import { useTypedSelector } from "./hooks/useTypedSelector";
import { getGasFeePolygonAction } from "./store/actions/gas-fee-polygon";
import { gasFeePolygon } from "./store/constants/gasFee";
BigNumber.config({ EXPONENTIAL_AT: 50 });
BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_DOWN });

const AppContainer = (props: any) => {
    const dispatch = useDispatch();
    const { appChainID } = useTypedSelector((state: any) => state.appNetwork).data;

    // useEffect(() => {
    //     if (Number(appChainID) === 137) {
    //         dispatch(getGasFeePolygonAction());
    //     } else {
    //         dispatch({
    //             type: gasFeePolygon.GAS_FEE_POLYGON_SUCCESS,
    //             payload: undefined,
    //         });
    //     }
    // }, [appChainID, dispatch]);

    useEffect(() => {
        (async () => {
            const wallet = localStorage.getItem("walletconnect-name");

            if (wallet === ConnectorNames.Kaikas) {
                const networkChainID = await caver.rpc.net.getNetworkId();
                dispatch({ type: "CHANGE_NETWORK_ID", payload: networkChainID });
            } else {
                const web3 = getWeb3Instance();
                if (web3) {
                    const networkChainID = await web3?.eth.getChainId();
                    dispatch({ type: "CHANGE_NETWORK_ID", payload: networkChainID });
                }
            }
        })();
    }, [dispatch]);

    return <>{props.children}</>;
};

export default withRouter(AppContainer);
