import { AnyAction } from "redux";
import { userInfoKYC } from "../constants/user-kyc";

type StateType = {
    data: {};
    loading: boolean;
    error: string;
};

const initialState = {
    data: {},
    loading: false,
    error: "",
};

export const getUserInfoKYCReducer = (state: StateType = initialState, action: AnyAction) => {
    switch (action.type) {
        case userInfoKYC.USER_INFO_KYC_LOADING: {
            return {
                ...state,
                data: {},
                loading: true,
            };
        }

        case userInfoKYC.USER_INFO_KYC_SUCCESS: {
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        }

        case userInfoKYC.USER_INFO_KYC_FAILURE: {
            return {
                ...state,
                error: action.payload,
                data: {},
                loading: false,
            };
        }
        case userInfoKYC.USER_INFO_KYC_RESET: {
            return {
                ...state,
                error: "",
                data: {},
                loading: false,
            };
        }

        default: {
            return state;
        }
    }
};
