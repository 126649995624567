import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        wrapper: {
            position: "fixed",
            right: 0,
            top: 100,
            zIndex: 9999,
            animation: "$active 1.5s ease",
            [theme.breakpoints.down("xs")]: {
                top: 0,
                width: "100%",
            },
        },
        "@keyframes active": {
            "0%": {
                opacity: 0,
                transform: "translateX(+100%)",
            },
            "100%": {
                opacity: 1,
                transform: "translateX(0)",
            },
        },

        transactionPending: {
            position: "fixed",
            right: 0,
            top: 100,
            zIndex: 9999,
            animation: "$active 1.5s ease",
            [theme.breakpoints.down("xs")]: {
                top: 0,
                width: "100%",
            },
            "&> .content": {
                transform: "translateX(0)",
                borderRadius: " 10px 0px 0px 10px",
                padding: "12px 15px",
                backgroundColor: "#E4C96A",
                display: "flex",
                flexDirection: "row",
                paddingRight: 30,
                [theme.breakpoints.down("xs")]: {
                    borderRadius: 0,
                    // padding: 19,
                    minHeight: 90,
                },
                "&> div": {
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: 15,
                    paddingRight: 130,
                    [theme.breakpoints.down("md")]: {
                        paddingLeft: 10,
                        paddingRight: 60,
                    },
                    [theme.breakpoints.down("xs")]: {
                        paddingLeft: 14,
                    },
                    "&> p:first-child": {
                        fontSize: 18,
                        fontWeight: 600,
                        color: "white",
                        fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    },
                    "&> p:last-child": {
                        fontSize: 14,
                        fontWeight: 400,
                        color: "white",
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        [theme.breakpoints.down("md")]: {
                            marginTop: -6,
                        },
                    },
                },
                "&> img:last-child": {
                    position: "absolute",
                    top: 14,
                    right: 14,
                    [theme.breakpoints.down("xs")]: {
                        top: 34,
                        right: 14,
                    },
                },
            },
        },

        failApply: {
            position: "fixed",
            right: 0,
            top: 100,
            animation: "$active 1.5s ease",
            zIndex: 1500,
            [theme.breakpoints.down("xs")]: {
                top: 100,
                width: "100%",
            },
        },

        container: {
            borderRadius: " 10px 0px 0px 10px",
            padding: "12px 15px",
            backgroundColor: "red",
            display: "flex",
            flexDirection: "row",
            paddingRight: 30,
            [theme.breakpoints.down("xs")]: {
                borderRadius: 0,
                // padding: 19,
                padding: "4px 20px",
                minHeight: 90,
            },
            "&> div": {
                display: "flex",
                flexDirection: "column",
                paddingLeft: 15,
                paddingRight: 130,
                "&> p:first-child": {
                    fontSize: 18,
                    fontWeight: 600,
                    color: "white",
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                },
                "&> p:last-child": {
                    fontSize: 14,
                    fontWeight: 400,
                    color: "white",
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                },
            },
            "&> img:last-child": {
                position: "absolute",
                top: 14,
                right: 14,

                [theme.breakpoints.down("xs")]: {
                    top: 34,
                    right: 14,
                },
            },
        },
        buyTicketSuccess: {
            transform: "translateX(0)",
            borderRadius: " 10px 0px 0px 10px",
            padding: "12px 15px",
            backgroundColor: "green",
            display: "flex",
            flexDirection: "row",
            paddingRight: 30,
            [theme.breakpoints.down("xs")]: {
                borderRadius: 0,
                padding: 19,
                // minHeight: 90,
            },
            "&> div": {
                display: "flex",
                flexDirection: "column",
                paddingLeft: 15,
                paddingRight: 130,
                [theme.breakpoints.down("md")]: {
                    paddingLeft: 10,
                    paddingRight: 60,
                },
                [theme.breakpoints.down("xs")]: {
                    paddingLeft: 14,
                },
                "&> p:first-child": {
                    fontSize: 18,
                    fontWeight: 600,
                    color: "white",
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                },
                "&> p:last-child": {
                    fontSize: 14,
                    fontWeight: 400,
                    color: "white",
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    [theme.breakpoints.down("md")]: {
                        marginTop: -6,
                    },
                },
            },
            "&> img:last-child": {
                position: "absolute",
                top: 14,
                right: 14,
                [theme.breakpoints.down("xs")]: {
                    top: 34,
                    right: 14,
                },
            },
        },
        messageApply: {
            transform: "translateX(0)",
            borderRadius: " 10px 0px 0px 10px",
            padding: "12px 15px",
            backgroundColor: "green",
            display: "flex",
            flexDirection: "row",
            paddingRight: 30,
            [theme.breakpoints.down("xs")]: {
                borderRadius: 0,
                padding: 19,
            },
            "&> div": {
                display: "flex",
                flexDirection: "column",
                paddingLeft: 15,
                paddingRight: 130,
                [theme.breakpoints.down("md")]: {
                    paddingLeft: 10,
                    paddingRight: 60,
                },
                [theme.breakpoints.down("xs")]: {
                    paddingLeft: 14,
                },
                "&> p:first-child": {
                    fontSize: 18,
                    fontWeight: 600,
                    color: "white",
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                },
                "&> p:last-child": {
                    fontSize: 14,
                    fontWeight: 400,
                    color: "white",
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    [theme.breakpoints.down("md")]: {
                        marginTop: -6,
                    },
                },
            },
            "&> img:last-child": {
                position: "absolute",
                top: 14,
                right: 14,
                [theme.breakpoints.down("xs")]: {
                    top: 34,
                    right: 14,
                },
            },
        },
        insufficient: {
            transform: "translateX(0)",
            borderRadius: " 10px 0px 0px 10px",
            padding: "12px 15px",
            backgroundColor: "red",
            display: "flex",
            flexDirection: "row",
            paddingRight: 30,
            [theme.breakpoints.down("xs")]: {
                borderRadius: 0,
                padding: 19,
                minHeight: 90,
            },
            ["@media (max-width:404px)"]: {
                padding: 6,
            },
            "&> div": {
                display: "flex",
                flexDirection: "column",
                paddingLeft: 15,
                paddingRight: 130,
                [theme.breakpoints.down("md")]: {
                    paddingLeft: 10,
                    paddingRight: 10,
                },
                [theme.breakpoints.down("xs")]: {
                    paddingLeft: 10,
                    paddingRight: 0,
                },
                "&> p:first-child": {
                    fontSize: 18,
                    fontWeight: 600,
                    color: "white",
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    [theme.breakpoints.down("sm")]: {
                        fontSize: 16,
                    },
                },
                "&> p:last-child": {
                    fontSize: 14,
                    fontWeight: 400,
                    color: "white",
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    [theme.breakpoints.down("md")]: {
                        marginTop: -10,
                    },
                    [theme.breakpoints.down("sm")]: {
                        marginTop: -6,
                        fontSize: 13,
                    },
                    [theme.breakpoints.down("xs")]: {
                        marginTop: 0,
                        fontSize: 12,
                    },
                },
            },
            "&> img:last-child": {
                position: "absolute",
                top: 14,
                right: 14,
                [theme.breakpoints.down("xs")]: {
                    top: 14,
                    right: 14,
                },
            },
        },
    };
});

export default useStyles;
