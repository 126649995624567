import {
    Button,
    Chip,
    FormControl,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    withWidth,
} from "@material-ui/core";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslation } from "react-i18next";
import { POOL_STATUS_KLAY } from "../../../constants";
import useWalletSignature from "../../../hooks/useWalletSignature";
import { BaseRequest } from "../../../request/Request";
import PaginationCustom from "../../PaginationCustom";
import useStyles from "./style";
import { scrollIntoView } from "../../../utils/formatNumber";
import { useWeb3React } from "@web3-react/core";
import { convertLang } from "../../IDOHomePage/CardItem";
import i18n from "../../../i18n";
import ModalLoadingHomePage from "../../StakingPools/ModalLoading/ModalLoadingHomePage";

const MyPools = (props: any) => {
    const styles = useStyles();
    const { t } = useTranslation();
    const { signature, signMessage } = useWalletSignature();
    const { account } = useWeb3React();

    const listStatuses = [
        { value: "all", babel: `${t("account.myPools.pool_status.all_status")}` },
        {
            value: POOL_STATUS_KLAY.BUY_TICKET,
            babel: `${t("account.myPools.pool_status.buy_ticket")}`,
        },
        {
            value: POOL_STATUS_KLAY.CALCULATION,
            babel: `${t("account.myPools.pool_status.calculation")}`,
        },
        {
            value: POOL_STATUS_KLAY.CLOSED,
            babel: `${t("account.myPools.pool_status.closed")}`,
        },
        {
            value: POOL_STATUS_KLAY.DISTRIBUTION,
            babel: `${t("account.myPools.pool_status.distribution")}`,
        },
        {
            value: POOL_STATUS_KLAY.UPCOMING,
            babel: `${t("account.myPools.pool_status.upcoming")}`,
        },
    ];

    // For Detech Claim
    const [inputValue, setInputValue] = useState("");
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [myPools, setMyPools] = useState<any>();
    const [filterStatus, setFilterStatus] = useState("all");
    const [loading, setLoading] = useState<boolean>(false);
    const poolsRef = useRef<any>();

    const handleChangeStatus = (e: any) => {
        setFilterStatus(e.target.value);
        setPageNumber(1);
    };

    const handleInputChange = debounce((e: any) => {
        setInputValue(e.target.value);
        setPageNumber(1);
    }, 1000);

    const formatTextStatus = (status: string) => {
        switch (Number(status)) {
            case 1:
                return (
                    <span style={{ color: "#9747FF" }}>
                        {/* {t("account.myPools.pool_status.upcoming")} */}
                        Upcoming
                    </span>
                );
            case 2:
                return (
                    <span style={{ color: "#0F69E1" }}>
                        {/* {t("account.myPools.pool_status.calculation")} */}
                        Calculation
                    </span>
                );
            case 3:
                return (
                    <span style={{ color: "#3C974B" }}>
                        {/* {t("account.myPools.pool_status.buy_ticket")} */}
                        Buy Ticket
                    </span>
                );
            case 4:
                return (
                    <span style={{ color: "#E4C96A" }}>
                        {/* {t("account.myPools.pool_status.distribution")} */}
                        Distribution
                    </span>
                );
            default:
                return (
                    <span style={{ color: "#FF3333" }}>
                        {/* {t("account.myPools.pool_status.closed")} */}
                        Ended
                    </span>
                );
        }
    };

    const formatColumnAction = (status: string, amountIDOtoken: any) => {
        if (Number(status) === 3) {
            return "Buy tickets";
        } else if (Number(status) === 4 || Number(status) === 5) {
            if (Number(amountIDOtoken) > 0) {
                return "Claim Token";
            } else {
                return null;
            }
        }
    };

    const getMyPools = async (page_number: number, status: any, valueSearch: string) => {
        try {
            setLoading(true);
            const baseRequest = new BaseRequest();
            const response = (await baseRequest.get(
                `ido/myPool?page_size=6&page_number=${page_number}${
                    valueSearch && `&searchValue=${valueSearch}`
                }${status && status !== "all" ? `&status=${status}` : ""}
                `,
                true
            )) as any;
            const resObj = await response.json();
            setMyPools(resObj);
        } catch (error) {}
        setLoading(false);
    };

    useEffect(() => {
        if (!account) return;
        (async () => {
            await getMyPools(pageNumber, filterStatus, inputValue);
        })();
    }, [pageNumber, filterStatus, inputValue, account]);

    return (
        <div className={styles.pageMyPools} ref={poolsRef}>
            <h2 className={styles.title}>{t("account.myPools.title")}</h2>
            <div className={styles.des}>{t("account.myPools.text1")}</div>
            <div className={styles.headTable}>
                <div className={styles.leftFillter}>
                    <FormControl className={styles.formControlSelect}>
                        <Select
                            IconComponent={ExpandMoreIcon}
                            value={filterStatus}
                            onChange={handleChangeStatus}
                            inputProps={{
                                name: "status",
                            }}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                        >
                            {listStatuses?.map((item, index) => {
                                return (
                                    <MenuItem value={item.value} key={index}>
                                        {item.babel}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>

                <div className={styles.groupSearch}>
                    <Button>
                        <img src="/images/icons/search.svg" alt="" />
                    </Button>
                    <input
                        type="text"
                        placeholder={t("account.myPools.inputSearchPlaceholder")}
                        onChange={handleInputChange}
                    />
                </div>
            </div>

            <TableContainer component={Paper} className={styles.tableContainer}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={styles.tableCellHead}>
                                {t("account.myPools.table.titleCol1")}
                            </TableCell>
                            <TableCell className={styles.tableCellHead}>
                                {t("account.myPools.table.titleCol2")}
                            </TableCell>
                            <TableCell className={styles.tableCellHead}>
                                {t("account.myPools.table.titleCol3")}
                            </TableCell>
                            <TableCell className={styles.tableCellHead}>
                                {t("account.myPools.table.titleCol4")}
                            </TableCell>
                            <TableCell className={styles.tableCellHead} align="center">
                                {t("account.myPools.table.titleCol5")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(myPools?.data) &&
                            myPools?.data.length > 0 &&
                            myPools?.data?.map((pool: any, index: number) => (
                                <TableRow key={index} className={styles.tableRow}>
                                    <TableCell
                                        className={styles.tableCellBody}
                                        component="th"
                                        scope="row"
                                    >
                                        <img
                                            className={styles.iconToken}
                                            src={pool?.cover_photo || "images/defaultPool.svg"}
                                            alt=""
                                        />
                                        <span className={styles.nameToken}>
                                            {/* {pool?.project_name} */}
                                            {convertLang(pool?.project_name, i18n.language)}
                                        </span>
                                    </TableCell>
                                    <TableCell className={styles.tableCellBody}>
                                        {formatTextStatus(pool?.status)}
                                    </TableCell>
                                    <TableCell className={styles.tableCellBody}>
                                        {pool?.allocation
                                            ? pool?.allocation + " " + pool?.token_symbol
                                            : "-"}
                                    </TableCell>
                                    <TableCell className={styles.tableCellBody}>
                                        {pool?.guaranteed || "-"}
                                    </TableCell>
                                    <TableCell className={styles.tableCellBody} align="center">
                                        {formatColumnAction(pool?.status, pool?.amountIDOtoken) ? (
                                            <Link to={`/project-detail/${pool?.id}`}>
                                                <Chip
                                                    label={formatColumnAction(
                                                        pool?.status,
                                                        pool?.amountIDOtoken
                                                    )}
                                                />
                                            </Link>
                                        ) : (
                                            "-"
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                {!(myPools?.data?.length > 0) && (
                    <div className="noDataTransaction">
                        <div className="box">
                            <img src="/images/account_v1/no_transaction.svg" alt="" />
                            <p>{t("staking.reward.transactionHistory.noDataTransaction")}</p>
                        </div>
                    </div>
                )}
            </TableContainer>

            {Array.isArray(myPools?.data) &&
                myPools?.data.length > 0 &&
                myPools?.total_page &&
                Number(myPools?.total_page) > 1 && (
                    <div className="paginationPools">
                        <PaginationCustom
                            count={Number(myPools?.total_page)}
                            onChange={(page: number) => {
                                scrollIntoView(poolsRef);
                                setPageNumber(page);
                            }}
                            currentPage={pageNumber}
                        />
                    </div>
                )}
            <ModalLoadingHomePage
                open={loading}
                onClose={() => {
                    setLoading(false);
                }}
            />
        </div>
    );
};

export default withWidth()(withRouter(MyPools));
