import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: any) => {
    return {
        tabStaking: {
            padding: "0px 16px",
            [theme.breakpoints.up("md")]: {
                padding: 0,
            },
        },
        tabs: {
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            margin: "0px auto",
            marginTop: "38px",
            height: 52,
            maxWidth: "1230px",
            [theme.breakpoints.up("sm")]: {
                marginTop: "38px",
            },
            [theme.breakpoints.up("md")]: {
                marginTop: "130px",
            },
            "& .MuiTabs-indicator": {
                display: "none",
            },
            "& .MuiTabs-flexContainer": {
                height: "100%",
                display: "flex",

                borderBottom: "2px solid rgba(255, 255, 255, .1)",
                "& .Mui-selected": {
                    color: "#fff",
                    borderBottom: "4px solid #9747FF",
                },
                "& button": {
                    width: "50%",
                    maxWidth: "800px",
                    color: "rgba(255, 255, 255, .3)",
                    fontSize: 14,
                    fontWeight: "700",
                    fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                    textTransform: "capitalize",
                    [theme.breakpoints.up("sm")]: {},
                    [theme.breakpoints.up("md")]: {
                        fontSize: 18,
                    },
                },
            },
        },
    };
});
