import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: any) => {
    return {
        container: {
            marginTop: 47,
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            [theme.breakpoints.down("sm")]: {
                padding: "0px 0px",
            },
            [theme.breakpoints.up("sm")]: {
                padding: "0px 24px",
            },
            [theme.breakpoints.up("md")]: {
                padding: "0px 16px",
            },
            [theme.breakpoints.up("lg")]: {
                padding: "0px",
            },
        },
        stakeCode: {
            background: "#131530",
            borderRadius: "20px",
            marginTop: 20,
            "& .stakeCodeBox": {
                padding: "16px",
                [theme.breakpoints.up("sm")]: {},
                [theme.breakpoints.up("md")]: { padding: "25px 30px" },
                [theme.breakpoints.up("lg")]: {},
                "& .title": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    borderBottom: "1px solid rgba(255, 255, 255, .1)",
                    paddingBottom: 12,
                    "& div:last-child": {
                        display: "flex",
                        alignItems: "center",
                    },
                    "& .text": {
                        fontSize: 16,
                        fontWeight: "600",
                        fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                        color: "#FFFFFF",
                    },
                    "& .MuiChip-root": {
                        height: 33,
                        width: 84,
                        marginLeft: 4,
                        fontSize: 14,
                        fontWeight: 600,
                        fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                        color: "#0F69E1",
                        background: "rgba(75, 131, 217, 0.2)",
                        borderRadius: 5,
                    },
                    "& .descIsMobile": {
                        display: "block",
                        fontSize: 10,
                        color: "rgba(255, 255, 255, .5)",
                        [theme.breakpoints.up("md")]: {
                            display: "none",
                        },
                    },
                    "& .descIsDesktop": {
                        display: "none",
                        color: "rgba(255, 255, 255, .5)",
                        [theme.breakpoints.up("md")]: {
                            fontSize: 14,
                            display: "block",
                        },
                    },
                },
                "& .stakeCodeContainer": {
                    display: "flex",
                    marginTop: 15,
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0px 10px",
                    [theme.breakpoints.up("sm")]: {},
                    [theme.breakpoints.up("md")]: {
                        justifyContent: "space-between",
                    },
                    "& .info": {
                        margin: 0,
                        width: "100%",
                        [theme.breakpoints.up("sm")]: {},
                        [theme.breakpoints.up("md")]: {
                            width: 370,
                        },
                        "& .MuiGrid-item": {
                            height: 40,
                            padding: 0,
                        },
                        "& .item": {
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "space-between",
                            gap: "0px 4px",
                            height: 70,
                            borderBottom: "1px solid rgba(255, 255, 255, .1)",
                            [theme.breakpoints.up("xs")]: {
                                borderBottom: "none",
                            },
                            "&:last-child": { borderBottom: "none" },
                            "& p": {
                                color: "rgba(255, 255, 255, .3)",
                                fontSize: 14,
                                paddingTop: 15,
                                [theme.breakpoints.down("xs")]: {
                                    fontSize: 12,
                                },
                            },
                            "& .infoStake": {
                                textAlign: "right",
                                "& p": {
                                    color: "rgba(255, 255, 255, 1)",
                                    fontSize: 14,
                                    fontWeight: 600,
                                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                                },
                                "& span": {
                                    color: "#0F69E1",
                                    fontSize: 12,
                                    lineHeight: "17px",
                                },
                            },
                        },
                    },
                    "& .box": {
                        width: "100%",
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        [theme.breakpoints.up("sm")]: {},
                        [theme.breakpoints.up("md")]: {
                            width: "50%",
                        },
                        "& .timeLineStakeCODE": {
                            width: "100%",
                            display: "inline-block",
                            padding: 0,
                            marginLeft: "5px",
                            [theme.breakpoints.up("sm")]: {},
                            [theme.breakpoints.up("md")]: {
                                width: "90%",
                                marginTop: 15,
                                marginLeft: 52,
                                margin: "0px auto",
                            },
                            [theme.breakpoints.up("lg")]: {},

                            "& .MuiSlider-rail": {
                                height: 3,
                                color: "#AEAEAE",
                            },
                            "& .MuiSlider-track": {
                                height: 3,
                                color: "#9747FF",
                            },
                            "& .MuiSlider-markLabel": {
                                color: "#AEAEAE",
                                fontSize: 14,
                                top: "-3px",
                                // left: "50%",

                                "& .tierUpMilestone1, & .tierUpMilestone2,& .tierUpMilestone3": {
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    left: "-5px",
                                    position: "absolute",
                                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                                    "& .dot": {
                                        width: 10,
                                        height: 10,
                                        background: "#9747FF",
                                        borderRadius: "50%",
                                        marginBottom: 5,
                                    },
                                    "& p": {
                                        fontSize: 16,
                                        fontWeight: 600,
                                        fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                                        lineHeight: "24px",
                                        color: "rgba(255, 255, 255, .7)",
                                    },
                                    "& span": {
                                        fontSize: 10,
                                        color: "rgba(255, 255, 255, .3)",
                                    },
                                },
                                "& .tierUpMilestone2": {
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    left: "-40px",
                                },
                                "& .tierUpMilestone3": {
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-end",
                                    alignItems: "end",
                                    left: "-80px",
                                },
                            },
                            "& .MuiSlider-thumb ": {
                                display: "none",
                            },
                            "& .MuiSlider-mark": {
                                display: "none",
                            },
                        },
                        "& .actionConnect": {
                            justifyContent: "flex-end !important",
                            "& button": {
                                [theme.breakpoints.down("sm")]: {
                                    width: "100%",
                                    maxWidth: 370,
                                },
                            },
                        },
                        "& .actions,& .actionConnect": {
                            marginTop: 52,
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            [theme.breakpoints.up("sm")]: {},
                            [theme.breakpoints.up("md")]: {
                                marginTop: "100px",
                            },
                            "& button": {
                                width: "265px",
                                textTransform: "capitalize",
                                backgroundColor: "#C4C4C4",
                                fontSize: 12,
                                padding: "12px 0px",
                                fontWeight: 700,
                                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                                borderRadius: "90px",
                                [theme.breakpoints.down("xs")]: {
                                    height: 40,
                                },
                                [theme.breakpoints.up("md")]: { fontSize: 16, padding: "15px 0px" },
                                [theme.breakpoints.up("lg")]: {},
                            },

                            "& .btnStake": {
                                background:
                                    "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                                color: "#fff",
                                marginRight: 12,
                                [theme.breakpoints.up("sm")]: {},
                                [theme.breakpoints.up("md")]: { marginRight: 20 },
                                [theme.breakpoints.up("lg")]: {},
                                "&:disabled": {
                                    cursor: "not-allowed",
                                    opacity: 0.5,
                                },
                            },
                            "& .btnUnStake": {
                                color: "#9747FF",
                                border: "2px solid #9747FF",
                                backgroundColor: "transparent",
                                padding: "13px 0px",
                                "&:disabled": {
                                    cursor: "not-allowed",
                                    opacity: 0.5,
                                },
                            },
                            "& .btnConnect": {
                                background:
                                    "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                                color: "#fff",
                            },
                        },
                    },
                },
                "& .MuiSkeleton-root": {
                    backgroundColor: "#6d7279bf",
                },
            },
        },
        stakeToken: {
            background: "#131530",
            borderRadius: "20px",
            marginTop: 20,
            "& .stakeTokenBox": {
                padding: "16px",
                [theme.breakpoints.up("sm")]: {},
                [theme.breakpoints.up("md")]: { padding: "25px 30px" },
                [theme.breakpoints.up("lg")]: {},
                "& .title": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    borderBottom: "1px solid rgba(255, 255, 255, .1)",
                    paddingBottom: 12,
                    "& div:last-child": {
                        display: "flex",
                        alignItems: "center",
                    },
                    "& .text": {
                        fontSize: 16,
                        fontWeight: "600",
                        fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                        color: "#FFFFFF",
                    },
                    "& .MuiChip-root": {
                        height: 33,
                        width: 84,
                        marginLeft: 4,
                        fontSize: 14,
                        fontWeight: 600,
                        fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                        color: "#0F69E1",
                        background: "rgba(75, 131, 217, 0.2)",
                        borderRadius: 5,
                    },
                    "& .descIsMobile": {
                        display: "block",
                        fontSize: 10,
                        color: "rgba(255, 255, 255, .5)",
                        [theme.breakpoints.up("md")]: {
                            display: "none",
                        },
                    },
                    "& .descIsDesktop": {
                        display: "none",
                        color: "rgba(255, 255, 255, .5)",
                        [theme.breakpoints.up("md")]: {
                            fontSize: 14,
                            display: "block",
                        },
                    },
                },
                "& .stakeTokenContainer": {
                    display: "flex",
                    marginTop: 15,
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0px 10px",
                    [theme.breakpoints.up("sm")]: {},
                    [theme.breakpoints.up("md")]: {
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                    },
                    "& .info": {
                        margin: 0,
                        width: "100%",
                        [theme.breakpoints.up("sm")]: {},
                        [theme.breakpoints.up("md")]: {
                            width: 370,
                        },
                        [theme.breakpoints.down("xs")]: {
                            marginBottom: 30,
                        },
                        "& .MuiGrid-item": {
                            height: 40,
                            padding: 0,
                        },
                        "& .item": {
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "space-between",
                            gap: "0px 4px",
                            height: 70,
                            borderBottom: "1px solid rgba(255, 255, 255, .1)",
                            [theme.breakpoints.down("xs")]: {
                                borderBottom: "none",
                                height: "auto",
                            },

                            "&:last-child": { borderBottom: "none" },
                            "& p": {
                                color: "rgba(255, 255, 255, .3)",
                                fontSize: 14,
                                paddingTop: 15,
                            },
                            "& .infoStake": {
                                textAlign: "right",
                                "& p": {
                                    color: "rgba(255, 255, 255, 1)",
                                    fontSize: 14,
                                    fontWeight: 600,
                                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                                },
                                "& span": {
                                    color: "#0F69E1",
                                    fontSize: 12,
                                    lineHeight: "17px",
                                },
                            },
                        },
                    },
                    "& .box": {
                        width: "100%",
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        [theme.breakpoints.up("sm")]: {},
                        [theme.breakpoints.up("md")]: {
                            width: "50%",
                            flexWrap: "nowrap",
                        },
                        "& .timeLineStakeToken": {
                            width: "95%",
                            display: "inline-block",
                            marginLeft: "5px",
                            padding: 0,
                            [theme.breakpoints.up("sm")]: {},
                            [theme.breakpoints.up("md")]: {
                                marginTop: 15,
                                marginLeft: 52,
                                width: "90%",
                            },
                            [theme.breakpoints.up("lg")]: {},

                            "& .MuiSlider-rail": {
                                height: 3,
                                color: "#AEAEAE",
                            },
                            "& .MuiSlider-track": {
                                height: 3,
                                color: "#9747FF",
                            },
                            "& .MuiSlider-markLabel": {
                                color: "#AEAEAE",
                                fontSize: 14,
                                top: "-3px",
                                // left: "50%",

                                "& .tierUpMilestone1,& .tierUpMilestone3": {
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    left: "-5px",
                                    position: "absolute",
                                    "& .dot": {
                                        width: 10,
                                        height: 10,
                                        background: "#9747FF",
                                        borderRadius: "50%",
                                        marginBottom: 5,
                                    },
                                    "& p": {
                                        fontSize: 16,
                                        fontWeight: 600,
                                        fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                                        lineHeight: "24px",
                                        color: "rgba(255, 255, 255, .7)",
                                    },
                                    "& span": {
                                        fontSize: 10,
                                        color: "rgba(255, 255, 255, .3)",
                                    },
                                },
                                "& .tierUpMilestone3": {
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-end",
                                    alignItems: "end",
                                    left: "-80px",
                                },
                            },
                            "& .MuiSlider-thumb ": {
                                display: "none",
                            },
                            "& .MuiSlider-mark": {
                                display: "none",
                            },
                        },
                        "& .actions": {
                            marginTop: 52,
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            [theme.breakpoints.up("sm")]: {},
                            [theme.breakpoints.up("md")]: {
                                marginTop: "100px",
                            },
                            [theme.breakpoints.up("lg")]: {},
                            "& button": {
                                width: "265px",
                                textTransform: "capitalize",
                                backgroundColor: "#C4C4C4",
                                fontSize: 16,
                                lineHeight: "28px",
                                padding: "15px 0px",
                                fontWeight: 700,
                                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                                borderRadius: "90px",
                                [theme.breakpoints.down("xs")]: {
                                    height: 40,
                                },
                                [theme.breakpoints.up("md")]: {},
                                [theme.breakpoints.up("lg")]: {},
                            },
                            "& .btnStake": {
                                background:
                                    "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                                color: "#fff",
                                marginRight: 20,
                                "&:disabled": {
                                    cursor: "not-allowed",
                                    opacity: 0.5,
                                },
                            },
                            "& .btnUnStake": {
                                color: "#9747FF",
                                border: "2px solid #9747FF",
                                backgroundColor: "transparent",
                                padding: "13px 0px",
                                "&:disabled": {
                                    cursor: "not-allowed",
                                    opacity: 0.5,
                                },
                            },
                        },
                    },
                },
            },
        },
        poolLP: {
            marginTop: 30,
            padding: "16px",
            [theme.breakpoints.up("sm")]: {},
            [theme.breakpoints.up("md")]: {
                padding: "0px 30px",
            },
            [theme.breakpoints.up("lg")]: {},
            "& .titlePoolLP": {
                fontSize: 16,
                color: "#fff",
                [theme.breakpoints.down("xs")]: {
                    fontSize: 14,
                },
            },
            "& .stakeToken": {
                marginTop: 12,
                width: "100%",
                backgroundColor: "#2A2C45",
                borderRadius: 5,
                "& .box": {
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px 0px",
                    flexDirection: "row",
                    padding: "25px",
                    [theme.breakpoints.up("lg")]: { flexWrap: "nowrap" },
                    [theme.breakpoints.down("xs")]: {
                        flexDirection: "column",
                        padding: "25px 12px",
                    },
                    "& .col1": {
                        display: "flex",
                        alignItems: "center",
                        marginRight: 50,
                        width: "250px",
                        [theme.breakpoints.down("xs")]: {
                            marginRight: 0,
                            width: "100%",
                        },
                        "& .infoLP": {
                            "& p": {
                                fontSize: 14,
                                fontWeight: 600,
                                fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                                color: "#FFFFFF",
                                marginBottom: 2,
                            },
                            "& .viewContract": {
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                "& span": {
                                    fontSize: 12,
                                    color: "#2DDBFF",
                                },
                                "& img": {
                                    width: 9,
                                    height: 9,
                                    borderRadius: 0,
                                    marginLeft: 8,
                                },
                            },
                        },
                        "& img": {
                            width: 40,
                            height: 40,
                            borderRadius: "50%",
                            marginRight: 7,
                            objectFit: "cover",
                        },
                    },
                    "& .col2": {
                        marginRight: 62,
                        width: "220px",
                        [theme.breakpoints.down("xs")]: {
                            marginRight: 0,
                            width: "100%",
                        },
                        "& p": {
                            fontSize: 14,
                            color: "rgba(255, 255, 255, .3)",
                            marginBottom: 2,
                        },
                        "& span": {
                            fontSize: 14,
                            color: "rgba(255, 255, 255, 1)",
                        },
                    },
                    "& .col3": {
                        marginRight: 116,
                        width: "100px",
                        [theme.breakpoints.down("xs")]: {
                            marginRight: 0,
                            width: "100%",
                        },
                        "& p": {
                            fontSize: 14,
                            color: "rgba(255, 255, 255, .3)",
                            marginBottom: 2,
                        },
                        "& span": {
                            fontSize: 14,
                            color: "rgba(255, 255, 255, 1)",
                        },
                    },
                    "& .actionConnectLP": {
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "flex-end",
                        [theme.breakpoints.down("sm")]: {
                            justifyContent: "center",
                        },
                        "& .btn": {
                            width: "140px",
                            textTransform: "capitalize",
                            fontSize: 12,
                            lineHeight: "16px",
                            padding: "12px 0px",
                            fontWeight: 700,
                            fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                            borderRadius: "90px",
                            background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                            color: "#fff",
                            maxHeight: 40,
                            [theme.breakpoints.down("xs")]: {
                                width: "100%",
                                maxWidth: "287px",
                            },
                        },
                    },
                },
                "& .actionsStakeToken": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& button": {
                        width: "140px",
                        textTransform: "capitalize",
                        fontSize: 12,
                        lineHeight: "16px",
                        padding: "12px 0px",
                        fontWeight: 700,
                        fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                        borderRadius: "90px",
                        [theme.breakpoints.up("sm")]: {},
                        [theme.breakpoints.up("md")]: {},
                        [theme.breakpoints.up("lg")]: {},
                    },
                    "& .btnStake": {
                        background: "#0F69E1",
                        color: "#fff",
                        marginRight: 10,
                        marginBottom: 0,
                        [theme.breakpoints.up("sm")]: {},
                        [theme.breakpoints.up("md")]: {
                            marginBottom: 0,
                            marginRight: 15,
                        },
                        [theme.breakpoints.up("lg")]: {},
                        "&:disabled": {
                            cursor: "not-allowed",
                            opacity: 0.5,
                        },
                    },
                    "& .btnUnStake": {
                        color: "rgba(255, 255, 255, .7)",
                        border: "2px solid rgba(255, 255, 255, .7)",
                        backgroundColor: "transparent",
                        padding: "10px 0px",
                        "&:disabled": {
                            cursor: "not-allowed",
                            opacity: 0.5,
                        },
                    },
                },
            },
            "& .customPagination": {
                "& li:nth-child(2)": {
                    marginRight: 56,
                },
            },
            "& .paginationPools": {
                marginTop: 20,
                marginBottom: 52,
            },
            "& .noDataPoolLP": {
                marginTop: 20,
                marginBottom: 150,
                width: "100%",
                height: "360px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#2A2C45",
                borderRadius: "5px",
                [theme.breakpoints.down("sm")]: {
                    marginBottom: 80,
                },
                [theme.breakpoints.down("xs")]: {
                    marginBottom: 0,
                },
                "& .box": {
                    width: "100%",
                    height: "234px",
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    "& img": {
                        mixBlendMode: "luminosity",
                    },
                    "& p": {
                        position: "absolute",
                        bottom: 40,
                        left: "50%",
                        transform: "translateX(-50%)",
                        fontSize: 16,
                        color: "rgba(255, 255, 255, .3)",
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    },
                },
            },
        },
    };
});
