import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { convertLang } from "../../../IDOHomePage/CardItem";
import useStyles from "./style";
import i18n from "i18next";
import { useCallback } from "react";

type Props = {
    onClose: () => void;
    open: boolean;
    dataYourTicket?: any;
};

const KLAYTN_URL = process.env.REACT_APP_KLAYTN_BASE_URL || "";
const BSCSCAN_URL = process.env.REACT_APP_BSCSCAN_BASE_URL || "";
const POLSCAN_URL = process.env.REACT_APP_POLSCAN_BASE_URL || "";

export default function ModalYourTicket({ open, onClose, dataYourTicket }: Props) {
    const styles = useStyles();
    const dataIdoDetail = useSelector((state: any) => state.idoDetail.data);
    const { t } = useTranslation();
    const status = useSelector((state: any) => state.statusIdoPool.message);
    // const [dataYourTicket, setDataYourTicket] = useState<any>();

    // useEffect(() => {
    //     (async () => {
    //         const baseRequest = new BaseRequest();
    //         const response = (await baseRequest.get(`ticket/my/${dataIdoDetail?.id}`, true)) as any;
    //         const resObj = await response.json();
    //         setDataYourTicket(resObj);
    //         return resObj;
    //     })();
    // }, []);

    // console.log("please wait...", dataYourTicket);

    const getNetworkIdo = useCallback((): string => {
        if (!dataIdoDetail?.contract_address) return "";
        if (dataIdoDetail?.network === "bsc") {
            const contractNetworkIdo =
                `${BSCSCAN_URL}/address/${dataIdoDetail?.contract_address}` || "";
            return contractNetworkIdo;
        } else if (dataIdoDetail?.network === "klaytn") {
            const contractNetworkIdo =
                `${KLAYTN_URL}/${dataIdoDetail?.contract_address}` || "";
            return contractNetworkIdo;
        } else if (dataIdoDetail?.network === "polygon") {
            const contractNetworkIdo =
                `${POLSCAN_URL}/address/${dataIdoDetail?.contract_address}` || "";
            return contractNetworkIdo;
        }
        return `${BSCSCAN_URL}/${dataIdoDetail?.contract_address}`;
    }, [dataIdoDetail?.network, dataIdoDetail?.contract_address]);

    const handleClickClose = () => {
        onClose();
    };

    const shortenAddress = (string?: string, start?: number, end?: number): string => {
        if (typeof string !== "string") return "";
        return (string.slice(0, start || 7) + "..." + string.slice(-(end || 6))).toLowerCase();
    };

    const convertDayTicketEnd = moment(dataIdoDetail?.start_ticket_close).format("MM/DD/YYYY");

    const convertHoursTicketEnd = moment(dataIdoDetail?.start_ticket_close).format("HH:mm:ss");
    // const contractAddress = string.slice(0, start || 7) + "..." + string.slice(-(end || 6))).toLowerCase();

    const tokenID = dataYourTicket?.tokenID;
    const winningTicket = dataYourTicket?.winningTicket;
    const loseTicket = dataYourTicket?.loseTicket;
    // console.log(tokenID);

    // const renderNetwork = () => {
    //     return (
    //         <>
    //             {(() => {
    //                 switch (dataIdoDetail?.network) {
    //                     case "bsc": {
    //                         return (
    //                             <a
    //                                 href="https://bscscan.com/"
    //                                 rel="noopener noreferrer"
    //                                 target="_blank"
    //                                 style={{ color: "#2DDBFF" }}
    //                             >
    //                                 Bsc
    //                             </a>
    //                         );
    //                     }
    //                     case "polygon": {
    //                         return (
    //                             <a
    //                                 href="https://www.polygon.com/"
    //                                 rel="noopener noreferrer"
    //                                 target="_blank"
    //                                 style={{ color: "#2DDBFF" }}
    //                             >
    //                                 Polygon
    //                             </a>
    //                         );
    //                     }
    //                     case "klaytn": {
    //                         return (
    //                             <a
    //                                 href="https://klaytn.foundation/"
    //                                 rel="noopener noreferrer"
    //                                 target="_blank"
    //                                 style={{ color: "#2DDBFF" }}
    //                             >
    //                                 Klaytn
    //                             </a>
    //                         );
    //                     }
    //                     default:
    //                         return null;
    //                 }
    //             })()}
    //         </>
    //     );
    // };
    return (
        <Dialog className={styles.wrapper} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle className={styles.title}>
                {t("ProjectDetails.modalYourTicket.title")}
                <IconButton aria-label="close" className={styles.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ padding: "8px 16px" }}>
                <DialogContentText className={styles.content}>
                    <div>
                        <div>
                            <div>
                                <p>{t("ProjectDetails.modalYourTicket.project")}</p>
                                <p>{convertLang(dataIdoDetail?.project_name, i18n.language)}</p>
                            </div>
                            <div>
                                <p>{t("ProjectDetails.modalYourTicket.ticketEnd")}</p>
                                <p>
                                    {convertDayTicketEnd} <br />
                                    {convertHoursTicketEnd}
                                </p>
                            </div>
                            <div>
                                <p>{t("ProjectDetails.modalYourTicket.contractAddress")}</p>
                                <a
                                    href={getNetworkIdo()}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{ color: "#2DDBFF" }}
                                >
                                    {shortenAddress(dataIdoDetail?.contract_address, 4, 4)}
                                    <img src="/images/home-page/iconWebsite.svg" alt="" />
                                </a>
                            </div>
                            <div>
                                <p>{t("ProjectDetails.modalYourTicket.network")}</p>
                                <p style={{ color: "#2DDBFF" }}>
                                    {dataIdoDetail?.network
                                        ? dataIdoDetail?.network?.toUpperCase()
                                        : "-"}
                                </p>
                            </div>
                            <div>
                                <p>{t("ProjectDetails.modalYourTicket.tokenStandard")}</p>
                                <p>NFT-1155</p>
                            </div>
                            <div className={styles.tokenId}>
                                <p>{t("ProjectDetails.modalYourTicket.ticketIds")}</p>
                                <p>{tokenID ? tokenID?.join(",") : "-"}</p>
                            </div>
                            {winningTicket?.length > 0 &&
                                status !== "Buy Ticket" &&
                                dataIdoDetail?.state === "CALCULATED" && (
                                    <div className={styles.tokenId}>
                                        <p>Winning tickets</p>
                                        <p>{winningTicket ? winningTicket?.join(",") : "-"}</p>
                                    </div>
                                )}
                            {loseTicket?.length > 0 &&
                                status !== "Buy Ticket" &&
                                dataIdoDetail?.state === "CALCULATED" && (
                                    <div className={styles.tokenId}>
                                        <p>Refund tickets</p>
                                        <p>{loseTicket ? loseTicket?.join(",") : "-"}</p>
                                    </div>
                                )}
                        </div>
                        <div>
                            <p>{t("ProjectDetails.modalYourTicket.ticketsYouOwn")}</p>
                            <div className={styles.ticketsYourOwn}>
                                <img src="images/home-page/imgBuyTicket.svg" alt="" />
                                <div className={styles.amountBuyticket}>
                                    x
                                    {dataYourTicket?.ticketBought
                                        ? dataYourTicket?.ticketBought
                                        : 0}
                                </div>
                            </div>
                            <div>
                                <p>1 {t("ProjectDetails.modalYourTicket.ticket")}</p>
                                <p>{t("ProjectDetails.modalYourTicket.totalValue")}</p>
                            </div>
                            <div>
                                <p>= {dataIdoDetail?.price} USDC</p>
                                <p>
                                    {dataYourTicket?.ticketBought
                                        ? dataYourTicket?.ticketBought * dataIdoDetail?.price
                                        : 0}{" "}
                                    USDC
                                </p>
                            </div>
                        </div>
                    </div>
                </DialogContentText>

                <div onClick={handleClickClose} className={styles.btnBuy}>
                    <Button className="btn">{t("ProjectDetails.modalYourTicket.btnClose")}</Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}
