import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toastMessage } from "../../../store/actions/toast-message-buyticket";
import useStyles from "./style";

export default function ToastMessage(props: any) {
    const styles = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { type, title, message } = useSelector((state: any) => state.toastMessage);

    const handleClose = useCallback(() => {
        dispatch(
            toastMessage({
                type: "",
                title: "",
                message: "",
            })
        );
    }, [dispatch]);

    useEffect(() => {
        if (type === "socialMedia") return;
        if (type === "transactionPending") {
            const timer = setTimeout(() => {
                dispatch(
                    toastMessage({
                        type: "",
                        title: "",
                        message: "",
                    })
                );
            }, 30000);

            return () => {
                clearTimeout(timer);
            };
        } else if (type === "wrongNetwork" || type === "insufficient" || type === "success") {
            const timer = setTimeout(() => {
                dispatch(
                    toastMessage({
                        type: "",
                        title: "",
                        message: "",
                    })
                );
            }, 5000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [dispatch, type]);

    const renderType = useCallback(() => {
        switch (type) {
            case "wrongNetwork":
                return (
                    <div className={styles.wrapper}>
                        <div className={styles.container}>
                            <img src="images/home-page/iconWarning.svg" alt="" />
                            <div>
                                <p>{t("WarningMessage.wrongNetwork")}</p>
                                {/* <p>{t("WarningMessage.pleaseSwitchNetwork")}</p> */}
                                <p>{message}</p>
                            </div>
                            <img
                                style={{ cursor: "pointer" }}
                                onClick={handleClose}
                                src="images/home-page/iconClose.svg"
                                alt=""
                            />
                        </div>
                    </div>
                );
            case "insufficient":
                return (
                    <div className={styles.wrapper}>
                        <div className={styles.insufficient}>
                            <img src="images/home-page/iconWarning.svg" alt="" />
                            <div>
                                <p>{t("WarningMessage.insufficientFund")}</p>
                                <p>{t("WarningMessage.msgInsufficient")}</p>
                            </div>
                            <img
                                style={{ cursor: "pointer" }}
                                onClick={handleClose}
                                src="images/home-page/iconClose.svg"
                                alt=""
                            />
                        </div>
                    </div>
                );
            case "success":
                return (
                    <div className={styles.wrapper}>
                        <div className={styles.buyTicketSuccess}>
                            <img src="images/home-page/iconBuyticketSuccess.svg" alt="" />
                            <div>
                                <p>{t("WarningMessage.buyTicketSuccess")}</p>
                                <p>{t("WarningMessage.boughtTickets")}</p>
                            </div>
                            <img
                                style={{ cursor: "pointer" }}
                                onClick={handleClose}
                                src="images/home-page/iconClose.svg"
                                alt=""
                            />
                        </div>
                    </div>
                );
            case "transactionPending":
                return (
                    <div className={styles.transactionPending}>
                        <div className="content">
                            <img src="images/home-page/iconWarning.svg" alt="" />
                            <div>
                                <p>{title}</p>
                                <p>{message}</p>
                            </div>
                            <img
                                style={{ cursor: "pointer" }}
                                onClick={handleClose}
                                src="images/home-page/iconClose.svg"
                                alt=""
                            />
                        </div>
                    </div>
                );
            case "socialMedia":
                return (
                    <div className={styles.failApply}>
                        <div className={styles.messageApply}>
                            <img src="images/home-page/iconBuyticketSuccess.svg" alt="" />
                            <div>
                                <p>{type}</p>
                                <p>{title}</p>
                                <p>{message}</p>
                            </div>
                            <img
                                onClick={handleClose}
                                src="images/home-page/iconClose.svg"
                                alt=""
                            />
                        </div>
                    </div>
                );
            default:
                return;
        }
    }, [
        handleClose,
        message,
        styles.buyTicketSuccess,
        styles.container,
        styles.failApply,
        styles.insufficient,
        styles.messageApply,
        styles.transactionPending,
        styles.wrapper,
        t,
        title,
        type,
    ]);
    const renderToastMessage = useCallback(() => {
        return <>{renderType()}</>;
    }, [renderType]);

    return <div>{renderToastMessage()}</div>;
}
