import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        pageMyPools: {
            background: "#131530",
            borderRadius: "12px",
            padding: "30px",
            [theme.breakpoints.down("sm")]: {
                padding: "16px",
            },
            "& .customPagination": {
                "& li:nth-child(2)": {
                    marginRight: 56,
                },
            },
            "& .paginationPools": {
                marginTop: 20,
                marginBottom: 52,
            },
            "& .noDataTransaction": {
                padding: "64px 0px 27px 0px",
                margin: "0px auto",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                "& .box": {
                    width: "100%",
                    height: "234px",
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    "& img": {
                        mixBlendMode: "luminosity",
                    },
                    "& p": {
                        position: "absolute",
                        bottom: 40,
                        left: "50%",
                        transform: "translateX(-50%)",
                        fontSize: 16,
                        color: "rgba(255, 255, 255, .3)",
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    },
                },
            },
        },

        title: {
            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
            fontSize: 20,
            lineHeight: "24px",
            color: "#FFFFFF",
            paddingBottom: 7,
            marginBottom: 25,
            borderBottom: "1px solid rgba(255, 255, 255, 0.1)",

            [theme.breakpoints.down("sm")]: {
                fontWeight: 500,
                marginBottom: 12,
                fontSize: 16,
            },
        },

        des: {
            fontSize: 14,
            lineHeight: "22px",
            color: "rgba(255, 255, 255, .7)",
            marginBottom: 14,
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            [theme.breakpoints.down("sm")]: {
                fontSize: 12,
                lineHeight: "17px",
                marginBottom: 12,
            },
        },

        listDes: {
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            fontSize: 14,
            lineHeight: "20px",
            color: "#AEAEAE",
            listStyle: "disc",
            paddingLeft: 25,
            marginBottom: 30,

            [theme.breakpoints.down("sm")]: {
                marginBottom: 20,
            },
        },

        headTable: {
            marginBottom: 25,
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "0px 30px",
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            [theme.breakpoints.down("xs")]: {
                flexDirection: "column",
                marginBottom: 20,
            },
        },

        leftFillter: {
            [theme.breakpoints.down("sm")]: {
                marginBottom: 12,
                display: "flex",
                justifyContent: "space-between",
            },
        },

        formControlSelect: {
            background: "transparent",
            border: "2px solid rgba(255, 255, 255, 0.15)",
            borderRadius: 8,
            marginRight: 8,
            maxWidth: "400px",
            width: "184px",
            color: "#fff",
            [theme.breakpoints.down("sm")]: {
                marginRight: 0,
                width: "100%",
            },
            "& .MuiInput-underline:before,& .MuiInput-underline:after, &.MuiInput-focused:after": {
                display: "none",
            },
            "& .MuiSelect-select": {
                color: "white",
                paddingLeft: 16,
                paddingTop: 9,
                paddingBottom: 9,
                fontSize: 14,
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            },
            "& .MuiSelect-icon": {
                filter: "invert(99%) sepia(93%) saturate(3%) hue-rotate(248deg) brightness(108%) contrast(100%)",
                marginRight: 16,
                marginLeft: 16,
            },
        },

        selectBox: {
            fontSize: 14,
            lineHeight: "22px",
            color: "#FFFFFF",
            height: 40,
            width: 180,
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            // [theme.breakpoints.down("sm")]: {
            //     width: "100%",
            // },
            // "&::before, &::after": {
            //     display: "none",
            // },

            // "& select": {
            //     paddingLeft: 12,
            //     paddingTop: 0,
            //     paddingBottom: 0,
            //     height: 22,
            // },

            // "& .MuiSelect-select option": {
            //     backgroundColor: "#222228",
            // },

            // "& .MuiSelect-icon": {
            //     color: "#FFFFFF",
            //     fontSize: 20,
            //     top: "calc(50% - 10px)",
            //     right: 4,
            // },
        },

        selectBoxType: {
            width: 120,
            [theme.breakpoints.down("sm")]: {
                width: "100%",
            },
        },

        groupSearch: {
            width: "100%",
            maxWidth: 320,
            background: "transparent",
            border: "2px solid rgba(255, 255, 255, 0.15)",
            borderRadius: 8,
            display: "flex",
            height: 44,
            [theme.breakpoints.down("sm")]: {
                width: "100%",
                maxWidth: 400,
            },
            [theme.breakpoints.up("md")]: {
                maxWidth: "225px",
            },
            "& .MuiButtonBase-root": {
                padding: 0,
                minWidth: 0,
            },
            "& .MuiButton-text": {
                padding: 0,
                paddingLeft: 17,
            },

            "& input": {
                height: 40,
                width: "100%",
                border: "none",
                outline: "none",
                padding: 12,
                background: "transparent",
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                fontSize: 14,
                lineHeight: "20px",
                color: "#AEAEAE",
            },
            "& img": {
                opacity: 0.7,
            },
        },

        tableContainer: {
            background: "#1F213B",
            boxShadow: "none",
            borderRadius: 10,
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            // border:"1px solid black"
        },

        tableCellHead: {
            padding: "10px 16px",
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            fontSize: 14,
            lineHeight: "17px",
            color: "rgba(255, 255, 255, .3)",
            backgroundColor: "#2A2C45",
            borderBottom: "none",
        },

        tableRow: {
            backgroundColor: "#1F213B",
        },

        tableCellBody: {
            fontSize: 14,
            lineHeight: "17px",
            color: "#FFFFFF",
            borderBottom: "1px solid #2A2C45",
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            minWidth: 140,
            // borderRight: "1px solid #2A2C45",
            "&:nth-child(2)": {
                paddingRight: 90,
            },
            "&:last-child": {
                borderRight: "none",
            },
            "&:first-child": {
                display: "flex",
                alignItems: "center",
            },
            "& .MuiChip-root": {
                width: 120,
                height: 30,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                backgroundColor: "#0F69E1",
                color: "#fff",
                fontSize: 12,
                lineHeight: "16px",
                cursor: "pointer",
            },
        },

        nameToken: {
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            fontSize: 14,
            color: "#FFFFFF",
            lineHeight: "17px",
        },

        toDetailPool: {
            color: "#FFFFFF",

            "&:hover": {
                opacity: 0.85,
            },
        },

        iconToken: {
            position: "relative",
            marginRight: 6,
            width: "100%",
            maxWidth: 24,
            height: 24,
            borderRadius: "50%",
            objectFit: "cover",
        },

        datasMobile: {
            padding: "0 20px",
            background: "#222228",
            borderRadius: 8,
        },

        boxDataMobile: {
            padding: "20px 0",
            borderBottom: "1px solid rgb(255 255 255 / 10%)",
        },

        titleBoxMobile: {
            display: "flex",
            alignItems: "center",
            marginBottom: 16,
        },

        iconTokenMobile: {
            width: 36,
            height: 36,
            marginRight: 8,
            borderRadius: "50%",
        },

        nameTokenMobile: {
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            fontWeight: 500,
            fontSize: 16,
            lineHeight: "24px",
            color: "#FFFFFF",
        },

        infoMobile: {
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridRowGap: 18,
        },

        nameMobile: {
            fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
            fontSize: 14,
            lineHeight: "18px",
            color: "#AEAEAE",
            marginBottom: 5,
        },

        valueMobile: {
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            fontSize: 14,
            lineHeight: "20px",
            display: "flex",
            alignItems: "center",
            color: "#FFFFFF",
        },

        pagination: {
            "& *": {
                color: "white",
            },
        },

        btnAction: {
            background: "#ebebeb",
            borderRadius: 36,
            minWidth: 140,
            height: 28,
            border: "none",
            boxShadow: "none",
            outline: "none",
            fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
            fontSize: 14,
            lineHeight: "18px",
            textAlign: "center",
            color: "#090B1C",
            textTransform: "inherit",

            "&.btnCancelWhitelist": {
                background: "#D01F36",
                color: "#FFFFFF",
                cursor: "pointer",
            },

            "&.btnPreOrder": {
                background: "#FF9330",
                color: "#090B1C",
                cursor: "pointer",
            },

            "&.btnClaimToken": {
                background: "#FFD058",
                color: "#090B1C",
                cursor: "pointer",
            },

            "&:disabled": {
                opacity: 0.5,
            },
        },

        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
    };
});

export default useStyles;
