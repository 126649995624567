import { withStyles, WithStyles } from "@material-ui/core/styles";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import useStyles from "./dialog.style";
import dialogTitleStyle from "./style.dialog-title";
import { ReactNode } from "react";

interface DialogTitleProps extends WithStyles<typeof dialogTitleStyle> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
    customClass?: string;
}

interface DialogProps {
    showModal: boolean;
    closeModalHandler: () => void;
    children: any;
    style?: any;
    title: any;
    bodyClass?: string;
    footer?: ReactNode;
}

const DialogTitle = withStyles(dialogTitleStyle)((props: DialogTitleProps) => {
    const { children, classes, customClass, onClose, ...other } = props;

    return (
        <MuiDialogTitle disableTypography className={customClass} {...other}>
            <Typography variant="h6" className={classes.title}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const Dialog = ({
    showModal,
    closeModalHandler,
    children,
    style,
    title,
    bodyClass = "",
    footer,
}: DialogProps) => {
    const styles = useStyles();
    return (
        <MuiDialog open={showModal} onClose={closeModalHandler} className={styles.dialog}>
            <DialogTitle
                customClass={styles.header}
                onClose={closeModalHandler}
                id="customized-dialog-title"
            >
                {title}
            </DialogTitle>
            <MuiDialogContent className={bodyClass}>{children}</MuiDialogContent>
            {footer}
        </MuiDialog>
    );
};

export default Dialog;
