// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { I18nextProvider } from 'react-i18next';
// import i18n from './i18n';

// ReactDOM.render(
//   <React.StrictMode>
//     <I18nextProvider i18n={i18n}>
//       <App />
//     </I18nextProvider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import "./i18n";

import * as serviceWorker from "./serviceWorker";
import { SocketProvider } from "./hooks/useSocket";

ReactDOM.render(
    <Suspense fallback>
        <SocketProvider>
            <App />
        </SocketProvider>
    </Suspense>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
