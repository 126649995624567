import { AnyAction } from 'redux'
import { statusIdoPoolActions } from '../constants/status-ido-pool';

type StateType = {
  type: string;
  message: string;
}

const initialState = {
  type: '',
  message: '',
}
export const statusIdoPoolReducer = (state: StateType = initialState, action: AnyAction) => {
  switch (action.type) {
    case statusIdoPoolActions.UPCOMING: {
      return {
        type: 'upcoming',
        message: action.payload
      }
    }

    case statusIdoPoolActions.CALCULATION: {
      return {
        type: 'calculation',
        message: action.payload
      }
    }

    case statusIdoPoolActions.BUYTICKET: {
      return {
        type: 'buy-ticker',
        message: action.payload
      }
    }

    case statusIdoPoolActions.DISTRIBUTION: {
      return {
        type: 'distribution',
        message: action.payload
      }
    }

    case statusIdoPoolActions.ENDED: {
      return {
        type: 'ended',
        message: action.payload
      }
    }

    default: {
      return state;
    }
  }
}
