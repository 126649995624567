import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        tabTierBenefits: {
            marginTop: -5,
            [theme.breakpoints.down("sm")]: {
                marginTop: 24,
            },
        },

        tableContainer: {
            background: "#1F213B",
            boxShadow: "none",
            borderRadius: 10,
            // border:"1px solid black"
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
        },

        tableCellHead: {
            padding: "12px 15px 9px 15px",
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            fontSize: 12,
            lineHeight: "17px",
            color: "rgba(255, 255, 255, .3)",
            backgroundColor: "#2A2C45",
            borderBottom: "none",
        },

        tableRow: {
            backgroundColor: "transparent",
        },

        tableCellBody: {
            fontSize: 12,
            lineHeight: "17px",
            color: "#FFFFFF",
            borderBottom: "1px solid #2A2C45",
            borderRight: "1px solid #2A2C45",
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            minWidth: 120,
            "&:last-child": {
                borderRight: "none",
            },
            "&:nth-child(3)": {
                paddingRight: 114,
                width: 260,
            },
        },

        tierBenefitsMobile: {},

        itemTierMobile: {
            marginBottom: 30,
            fontSize: 14,
            lineHeight: "20px",
            color: "#FFFFFF",
        },

        nameTierMobile: {
            fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
            marginBottom: 17,
        },

        listActiveTierMobile: {},

        valueActiveMobile: {
            display: "flex",
            alignItems: "flex-start",
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            marginBottom: 6,

            "& img": {
                marginRight: 6,
                marginTop: 4,
            },

            "& span": {
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                color: "#6398FF",
            },
        },
    };
});

export default useStyles;
