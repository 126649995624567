import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        modalConfirm: {
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            "& .modal-content": {
                backgroundColor: "#242640",
                width: "500px",
                position: "relative",
                padding: "75px 55px 39px 55px",
                [theme.breakpoints.down("xs")]: {
                    padding: "40px 20px 20px",
                },
                borderRadius: "20px",
                color: "#FFFFFF",
            },
            "& .modal-content__head": {
                paddingBottom: 0,
                "& .btn-close": {
                    top: 25,
                    right: 25,
                    [theme.breakpoints.down("xs")]: {
                        top: 16,
                        right: 16,
                        width: 24,
                        height: 24,
                    },
                },
            },
            "& .modal-content__body": {
                padding: 0,
                margin: 0,
                paddingRight: 33,
                fontSize: 16,
                lineHeight: "30px",
                color: "rgba(255, 255, 255, .7)",
                [theme.breakpoints.down("xs")]: {
                    fontSize: 14,
                },
            },
            "& .modal-content__foot": {
                padding: "10px 0px 0px",
                [theme.breakpoints.down("xs")]: {
                    padding: "17px 0px 0px",
                    flexDirection: "row !important",
                },
                "& button": {
                    height: "54px",
                    width: "185px",
                    borderRadius: "36px",
                    padding: "12px 30px",
                    margin: 0,
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                    [theme.breakpoints.down("xs")]: {
                        height: "40px",
                        width: "147px",
                        fontSize: 12,
                    },
                },
                "& button:first-child": {
                    background: "transparent",
                    borderRadius: "90px",
                    color: "rgba(255, 255, 255, .7)",
                    border: "2px solid rgba(255, 255, 255, .7)",
                },
                "& button:last-child": {
                    background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                    borderRadius: "90px",
                    color: "#FFFFFF",
                },
            },
        },
    };
});

export default useStyles;
