import MuiButton from "@material-ui/core/Button";
import React, { useCallback, useState } from "react";
import useStyles from "./style";

import Dialog from "../../shared/Dialog";
import { useTranslation } from "react-i18next";
import PrivacyPolicy from "../PrivacyPolicy";

interface ComponentProps {
    opened: boolean;
    handleClose: () => void;
}

const Terms: React.FC<any> = (props: ComponentProps) => {
    const styles = useStyles();
    const { t } = useTranslation();
    const [openPolicy, setOpenPolicy] = useState<boolean>(false);

    const handleCloseModal = useCallback(() => {
        setOpenPolicy(false);
    }, []);
    const renderOpenModalPolicy = useCallback(
        () => <PrivacyPolicy opened={openPolicy} handleClose={handleCloseModal} />,
        [handleCloseModal, openPolicy]
    );

    const handleOpenPolicy = () => {
        setOpenPolicy(true);
    };

    return (
        <Dialog
            showModal={props.opened}
            closeModalHandler={props.handleClose}
            title={t("header.terms")}
            bodyClass={styles.scrollBar}
            footer={
                <MuiButton
                    variant="contained"
                    color="primary"
                    className={styles.closeBtn}
                    onClick={props.handleClose}
                >
                    {t("header.iUnderstand")}
                </MuiButton>
            }
        >
            <div className={styles.privacyPolicy}>
                <section className={styles.policyDesc}>
                    <div className="header">
                        <div>
                            <p className="title">
                                PLEASE READ THESE TERMS OF USE BEFORE USING THE WEBSITE.
                            </p>
                            <p>
                                Acceptance of the Terms of Use These terms of use is entered into by
                                and between you and the CodeDAO ("CodeDAO", "we" or "us"). The
                                following terms and conditions, together with any documents they
                                expressly incorporate by reference (collectively, these "Terms of
                                Use"), govern your access to and use of Code Dao website, including
                                any content, functionality and services offered on or through Code
                                Dao website (the "Website"). Please read the Terms of Use carefully
                                before you start to use the Websites. By using the Websites or by
                                clicking to accept or agree to the Terms of Use when this option is
                                made available to you, you accept and agree to be bound and abide by
                                these Terms of Use in addition to
                            </p>
                            <ul>
                                <li>
                                    our{" "}
                                    <span
                                        style={{
                                            color: "#2DDBFF",
                                            marginLeft: "4px",
                                            cursor: "pointer",
                                        }}
                                        onClick={handleOpenPolicy}
                                    >
                                        Privacy Policy
                                    </span>
                                    , incorporated herein by reference; and
                                </li>
                                <li>
                                    our
                                    <span
                                        style={{
                                            color: "#2DDBFF",
                                            marginLeft: "4px",
                                            cursor: "pointer",
                                        }}
                                        onClick={handleOpenPolicy}
                                    >
                                        Cookie Policy
                                    </span>
                                    , incorporated herein by reference (note: no cookies are used on
                                    launchpad.Code Dao website).
                                </li>
                            </ul>
                            <p>
                                If you do not agree to these Terms of Use or the Privacy Policy, you
                                must not access or use the Websites.
                                <br /> Who May Use the Websites
                                <br /> This website is offered and available to users who are 13
                                years of age or older. The website is not intended for children
                                under 13 years of age. By using this Website, you represent and
                                warrant that you (i) are 13 years of age or older, (ii) are not
                                barred to use the Website under any applicable law, and (iii) are
                                using the Website only for your own personal use. If you do not meet
                                these requirements, you must not access or use the Websites.
                                <br /> Changes to the Terms of Use
                                <br /> We may revise and update these Terms of Use from time to time
                                at our sole discretion. All changes are effective immediately when
                                we post them.
                                <br /> Your continued use of the Websites following the posting of
                                the revised Terms of Use means that you accept and agree to the
                                changes. You are expected to check this page frequently so you are
                                aware of any changes, as they are binding on you.
                                <br /> Accessing the Websites and Account Security
                                <br /> We reserve the right to withdraw or amend this Website, and
                                any service or material we provide on the Website, in our sole
                                discretion without notice. We do not guarantee that our site or any
                                content on it, will always be available or be interrupted. We will
                                not be liable if for any reason all or any part of the Website is
                                unavailable at any time or for any period. From time to time, we may
                                restrict access to some parts of the Website, or the entire Website,
                                to users.
                                <br /> You are responsible for:
                            </p>
                            <ul>
                                <li>
                                    Make all arrangements necessary for you to have access to the
                                    Websites.
                                </li>
                                <li>
                                    Ensuring that all persons who access the Websites through your
                                    internet connection are aware of these Terms of Use and comply
                                    with them.
                                </li>
                            </ul>
                            <p>
                                To access the Websites or some of the resources it offers, you may
                                be asked to provide certain registration details or other
                                information. It is a condition of your use of the Websites that all
                                the information you provide on the Websites is correct, current and
                                complete. You agree that all information you provide to register
                                using this website or otherwise, including, but not limited to,
                                using any interactive features on the Website, is governed by our
                                Privacy Policy, and you consent to all actions we take with respect
                                to your information consistent with our Privacy Policy.
                                <br /> You should use particular caution when inputting personal
                                information to the Websites on a public or shared computer so that
                                others are not able to view or record your personal information.
                                Intellectual Property Rights
                                <br /> The Websites and its entire contents, features and
                                functionality (including but not limited to all information,
                                software, text, displays, images, video and audio, and the design,
                                selection and arrangement thereof), are owned by the Foundation, its
                                licensors or other providers of such material and are protected by
                                copyright, trademark, patent, trade secret and other intellectual
                                property or proprietary rights laws.
                                <br /> Unless otherwise marked: (a) all material, data, and
                                information on the Websites, such as data files, text, music, audio
                                files or other sounds, photographs, videos, or other images, but
                                excluding any software or computer code (collectively, the “Non-
                                Code Content”) is licensed under the Creative Commons Attribution
                                4.0 International License; and (b) all software or computer code
                                (collectively, the “Code Content”) is licensed under the MIT
                                License.
                                <br />
                                Trademarks
                                <br /> The Foundation name, the terms CodeDAO Switzerland, CodeDAO
                                Asia, the Foundation logo and all related names, logos, product and
                                service names, designs and slogans are trademarks of the Foundation
                                or its affiliates or licensors. You must not use such marks without
                                the prior written permission of the Foundation. All other names,
                                logos, product and service names, designs and slogans on this
                                Website are the trademarks of their respective owners.
                            </p>
                        </div>
                        <div>
                            <h2 style={{ padding: "12px 0" }}>Prohibited Uses</h2>
                            <p>
                                You may use the Websites only for lawful purposes and in accordance
                                with these Terms of Use. You agree not to use the Website:
                            </p>
                            <ul>
                                <li>
                                    In any way that violates any applicable federal, state, local or
                                    international law or regulation (including, without limitation,
                                    any laws regarding the export of data or software to and from
                                    the US or other countries).
                                </li>
                                <li>
                                    For the purpose of exploiting, harming or attempting to exploit
                                    or harm minors in any way by exposing them to inappropriate
                                    content, asking for personally identifiable information or
                                    otherwise.
                                </li>
                                <li>
                                    To send, knowingly receive, upload, download, use or re-use any
                                    material which does not comply with these Terms of Use.
                                </li>
                                <li>
                                    To transmit, or procure the sending of, any advertising or
                                    promotional material without our prior written consent,
                                    including any "junk mail", "chain letter" or "spam" or any other
                                    similar solicitation.
                                </li>
                                <li>
                                    To impersonate or attempt to impersonate the Foundation, a
                                    Foundation employee, another user or any other person or entity
                                    (including, without limitation, by using e-mail addresses or
                                    screen names associated with any of the foregoing).
                                </li>
                                <li>
                                    To engage in any other conduct that restricts or inhibits
                                    anyone's use or enjoyment of the Website, or which, as
                                    determined by us, may harm the Foundation or users of the
                                    Websites or expose them to liability.
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 style={{ padding: "12px 0" }}>Additionally, you agree not to:</h2>
                            <ul>
                                <li>
                                    Use the Websites in any manner that could disable, overburden,
                                    damage, or impair the site or interfere with any other party's
                                    use of the Website, including their ability to engage in
                                    real-time activities through the Websites.
                                </li>
                                <li>
                                    Use any robot, spider or other automatic devices, process or
                                    means to access the Websites for any purpose, including
                                    monitoring or copying any of the material on the Websites.
                                </li>
                                <li>
                                    Use any manual process to monitor or copy any of the material on
                                    the Websites or for any other unauthorized purpose without our
                                    prior written consent.
                                </li>
                                <li>
                                    Use any device, software or routine that interferes with the
                                    proper working of the Websites. Introduce any viruses, trojan
                                    horses, worms, logic bombs or other material which is malicious
                                    or technologically harmful.
                                </li>
                                <li>
                                    Attempt to gain unauthorized access to, interfere with, damage
                                    or disrupt any parts of the Website, the server on which the
                                    Website is stored, or any server, computer or database connected
                                    to the website.
                                </li>
                                <li>
                                    Attack the Websites via a denial-of-service attack or a
                                    distributed denial-of-service attack.
                                </li>
                                <li>
                                    Otherwise, attempt to interfere with the proper working of the
                                    Websites.
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 style={{ padding: "12px 0" }}>Reliance on Information Posted</h2>
                            <p>
                                The information presented on or through the Websites is made
                                available solely for general information purposes. We do not warrant
                                the accuracy, completeness or usefulness of this information. Any
                                reliance you place on such information is strictly at your own risk.
                                We disclaim all liability and responsibility arising from any
                                reliance placed on such materials by you or any other visitor to the
                                Website, or by anyone who may be informed of any of its contents.
                            </p>
                            <p>
                                This Website includes content provided by third parties, including
                                materials provided by other users, bloggers and third-party
                                licensors, syndicators, aggregators and/or reporting services. All
                                statements and/or opinions expressed in these materials, and all
                                articles and responses to questions and other content, other than
                                the content provided by the Foundation, are solely the opinions and
                                the responsibility of the person or entity providing those
                                materials. These materials do not necessarily reflect the opinion of
                                the Foundation. We are not responsible, or liable to you or any
                                third party, for the content or accuracy of any materials provided
                                by any third parties.
                            </p>
                            <p>
                                Changes to the Websites <br />
                                We may update the content on this Website from time to time, but its
                                content is not necessarily complete or up-to-date. Any of the
                                material on the Websites may be out of date at any given time, and
                                we are under no obligation to update such material.
                                <br /> Information About You and Your Visits to the Websites
                            </p>
                            <p>
                                All information we collect on these Websites is subject to our
                                <span
                                    style={{
                                        color: "#2DDBFF",
                                        marginLeft: "4px",
                                        cursor: "pointer",
                                    }}
                                    onClick={handleOpenPolicy}
                                >
                                    Privacy Policy
                                </span>{" "}
                                . By using the Website, you consent to all actions taken by us with
                                respect to your information in compliance with the Privacy Policy.
                                Online Purchases and Other Terms and Conditions <br /> Additional
                                terms and conditions may also apply to specific portions, services
                                or features of the Website, including the registration and
                                sponsorship for conference events. All such additional terms and
                                conditions are hereby incorporated by this reference into these
                                Terms of Use. In the event of terms that are directly conflicting
                                between these Terms of Use and terms of conditions for the
                                registration or sponsorship of a conference event, the terms and
                                conditions for the event shall control.
                                <br /> Linking to the Websites and Social Media Features
                                <br /> You may link to our homepage, provided you do so in a way
                                that is fair and legal and does not damage our reputation or take
                                advantage of it, but you must not establish a link in such a way as
                                to suggest any form of association, approval or endorsement on our
                                part without our express written consent.
                                <br /> Links from the Websites <br />
                                If the Website contains links to other sites and resources provided
                                by third parties, these links are provided for your convenience
                                only. This includes links contained in advertisements, including
                                banner advertisements and sponsored links. We have no control over
                                the contents of those sites or resources and accept no
                                responsibility for them or for any loss or damage that may arise
                                from your use of them. If you decide to access any of the
                                third-party websites linked to this Website, you do so entirely at
                                your own risk and subject to the terms and conditions of use for
                                such websites. We reserve the right to withdraw linking permission
                                without notice.
                                <br /> Geographic Restrictions
                                <br /> The owner of the Website is based in Switzerland. We make no
                                claims that the Website or any of its content is accessible or
                                appropriate outside of Switzerland. Access to the Websites may not
                                be legal by certain persons or in certain countries. If you access
                                the Websites from outside Switzerland, you do so on your own
                                initiative and are responsible for compliance with local laws.
                            </p>
                        </div>
                        <div>
                            <h2 style={{ padding: "12px 0" }}>Disclaimer of Warranties</h2>
                            <p>
                                You understand that we cannot and do not guarantee or warrant that
                                files available for downloading from the internet or the Websites
                                will be free of viruses or other destructive code. You are
                                responsible for implementing sufficient procedures and checkpoints
                                to satisfy your particular requirements for anti-virus protection
                                and accuracy of data input and output, and for maintaining a means
                                external to our site for any reconstruction of any lost data. WE
                                WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED
                                DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL
                                MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS,
                                DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITES
                                OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITES OR TO YOUR
                                DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITES LINKED
                                TO IT. YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR
                                ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE,
                                ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITES
                                ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY
                                WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE
                                FOUNDATION NOR ANY PERSON ASSOCIATED WITH THE FOUNDATION MAKES ANY
                                WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
                                SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE
                                WEBSITES. WITHOUT LIMITING THE FOREGOING, NEITHER THE FOUNDATION NOR
                                ANYONE ASSOCIATED WITH THE FOUNDATION REPRESENTS OR WARRANTS THAT
                                THE WEBSITE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH
                                THE WEBSITES WILL BE ACCURATE, RELIABLE, ERROR-FREE OR
                                UNINTERRUPTED, AND THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR
                                THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER
                                HARMFUL COMPONENTS OR THAT THE WEBSITES OR ANY SERVICES OR ITEMS
                                OBTAINED THROUGH THE WEBSITES WILL OTHERWISE MEET YOUR NEEDS OR
                                EXPECTATIONS. THE FOUNDATION HEREBY DISCLAIMS ALL WARRANTIES OF ANY
                                KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING
                                BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
                                NON-INFRINGEMENT AND FITNESS FOR A PARTICULAR PURPOSE. SOME
                                JURISDICTIONS DO NOT ALLOW EXCLUSION OF WARRANTIES OR LIMITATIONS ON
                                THE DURATION OF IMPLIED WARRANTIES, SO THE ABOVE DISCLAIMER MAY NOT
                                APPLY TO YOU IN THEIR ENTIRETY, BUT WILL APPLY TO THE MAXIMUM EXTENT
                                PERMITTED BY APPLICABLE LAW.
                                <br /> Limitation on Liability
                                <br /> IN NO EVENT WILL THE FOUNDATION, ITS AFFILIATES OR THEIR
                                LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR
                                DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY,
                                ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE,
                                THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITES
                                OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
                                WEBSITES OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT,
                                SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING
                                BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL
                                DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR
                                ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA,
                                AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF
                                CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE. THE FOREGOING DOES NOT
                                AFFECT ANY LIABILITY WHICH CAN NOT BE EXCLUDED OR LIMITED UNDER
                                APPLICABLE LAW WHICH MAY INCLUDE FRAUD.
                                <br /> Indemnification <br /> You agree to defend, indemnify and
                                hold harmless the Foundation, its affiliates, licensors and service
                                providers, and its and their respective officers, directors,
                                employees, contractors, agents, licensors, suppliers, successors and
                                assigns from and against any claims, liabilities, damages,
                                judgments, awards, losses, costs, expenses or fees (including
                                reasonable attorneys' fees) arising out of or relating to your
                                violation of these Terms of Use or your use of the Website,
                                including, but not limited to, any use of the Website's content,
                                services and products other than as expressly authorized in these
                                Terms of Use or your use of any information obtained from the
                                Websites.
                            </p>
                        </div>
                        <div>
                            <h2 style={{ padding: "12px 0" }}>Waiver and Severability</h2>
                            <p>
                                No waiver by the Foundation of any term or condition set forth in
                                these Terms of Use shall be deemed a further or continuing waiver of
                                such term or condition or a waiver of any other term or condition,
                                and any failure of the Foundation to assert a right or provision
                                under these Terms of Use shall not constitute a waiver of such right
                                or provision.
                                <br /> If any provision of these Terms of Use is held by a court or
                                other tribunal of competent jurisdiction to be invalid, illegal or
                                unenforceable for any reason, such provision shall be eliminated or
                                limited to the minimum extent such that the remaining provisions of
                                the Terms of Use will continue in full force and effect.
                            </p>
                        </div>
                        <div>
                            <h2 style={{ padding: "12px 0" }}>Entire Agreement</h2>
                            <p>
                                The Terms of Use, our Privacy Policy and Terms of conditions for the
                                registration of events constitute the sole and entire agreement
                                between you and the CodeDAO Foundation with respect to the Websites
                                and supersede all prior and contemporaneous understandings,
                                agreements, representations and warranties, both written and oral,
                                with respect to the Websites.
                                <br />
                                Your Comments and Concerns
                                <br />
                                This website is operated by the Foundation. All other feedback,
                                comments, requests for technical support and other communications
                                relating to the Websites should be directed to support@Code Dao
                                website.
                            </p>
                        </div>
                    </div>
                </section>
                {renderOpenModalPolicy()}
            </div>
        </Dialog>
    );
};

export default Terms;
