import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { BaseRequest } from "../../request/Request";
import { idoDetail } from "../constants/ido-detail";

export const getIdoDetail = (id: any) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
        dispatch({ type: idoDetail.IDO_DETAIL_LOADING });
        try {
            const baseRequest = new BaseRequest();
            const response = (await baseRequest.get(`ido/${id}/detail`, true)) as any;
            if (response.status && response.status === 404) {
                return window.location.replace("/#/not-found");
            }
            const resObj = await response.json();
            if (!resObj.data) {
                dispatch({
                    type: idoDetail.IDO_DETAIL_LOADING,
                    payload: new Error("Invalid idoDetail payload"),
                });
                return;
            }

            dispatch({
                type: idoDetail.IDO_DETAIL_SUCCESS,
                payload: resObj.data,
            });
        } catch (error) {
            dispatch({
                type: idoDetail.IDO_DETAIL_FAILURE,
                payload: "Fail to fetch",
            });
        }
    };
};
