import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import useStyles from "./style";

export default function ModalStakeMore(props: any) {
    const styles = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const tierAccount = useSelector((state: any) => state.userTier.data.name);

    const handleClickStake = () => {
        history.push("/staking-pools");
    };
    return (
        <div className={styles.stakeMore}>
            {tierAccount === "rookie" ? (
                <>
                    <p>{t("ProjectDetails.upComingRookie.desc1")}</p>
                    <p>{t("ProjectDetails.upComingRookie.desc2")}</p>
                    <p className="paragraph">{t("ProjectDetails.upComingRookie.desc3")}</p>
                </>
            ) : (
                <>
                    <p>{t("ProjectDetails.upComingLegend.desc1")}</p>
                    <p>{t("ProjectDetails.upComingLegend.desc2")}</p>
                </>
            )}
            <Button onClick={handleClickStake} className="btnStakeMoreRankLegend">
                {t("ProjectDetails.btnStakeMore")}
            </Button>
        </div>
    );
}
