import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    withWidth,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { take_decimal_numberFormatThousands } from "../../../constants";
import useStyles from "./style";

function createData(name: string, starter: string, superRookie: string, legend: string) {
    return { name, starter, superRookie, legend };
}

const TierBenefits = (props: any) => {
    const styles = useStyles();
    const { t } = useTranslation();
    const tierInfo = useSelector((state: any) => state.tiers.data);

    const rows = [
        createData(
            `${t("staking.tier.table.row1.tier")}`,
            `${t("staking.tier.table.row1.starter")}`,
            `${t("staking.tier.table.row1.super").replace(
                "AMOUNT",
                take_decimal_numberFormatThousands(tierInfo[tierInfo?.length - 2]?.min || 0)
            )}`,
            `${t("staking.tier.table.row1.legend").replace(
                "AMOUNT",
                take_decimal_numberFormatThousands(tierInfo[tierInfo?.length - 1]?.min || 0)
            )}`
        ),
        createData(
            `${t("staking.tier.table.row2.tier")}`,
            `${t("staking.tier.table.row2.starter")}`,
            `${t("staking.tier.table.row2.super")}`,
            `${t("staking.tier.table.row2.legend")}`
        ),
        createData(
            `${t("staking.tier.table.row3.tier")}`,
            `${t("staking.tier.table.row3.starter")}`,
            `${t("staking.tier.table.row3.super")}`,
            `${t("staking.tier.table.row3.legend")}`
        ),
        createData(
            `${t("staking.tier.table.row4.tier")}`,
            `${t("staking.tier.table.row4.starter")}`,
            `${t("staking.tier.table.row4.super")}`,
            `${t("staking.tier.table.row4.legend")}`
        ),
        createData(
            `${t("staking.tier.table.row5.tier")}`,
            `${t("staking.tier.table.row5.starter")}`,
            `${t("staking.tier.table.row5.super")}`,
            `${t("staking.tier.table.row5.legend")}`
        ),
    ];

    return (
        <div className={styles.tabTierBenefits}>
            <TableContainer component={Paper} className={styles.tableContainer}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={styles.tableCellHead}>Tier</TableCell>
                            <TableCell className={styles.tableCellHead}>Starter</TableCell>
                            <TableCell className={styles.tableCellHead}>Super Rookey</TableCell>
                            <TableCell className={styles.tableCellHead}>Legend</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.name} className={styles.tableRow}>
                                <TableCell
                                    className={styles.tableCellBody}
                                    component="th"
                                    scope="row"
                                >
                                    {row.name}
                                </TableCell>
                                <TableCell className={styles.tableCellBody}>
                                    <span>{row.starter}</span>
                                </TableCell>
                                <TableCell className={styles.tableCellBody}>
                                    <span>{row.superRookie}</span>
                                </TableCell>
                                <TableCell className={styles.tableCellBody}>
                                    <span>{row.legend}</span>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default withWidth()(TierBenefits);
