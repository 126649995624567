import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        countdownPart: {
            display: "inline-block",
            listStyleType: "none",
            padding: "6px 4px",
            width: 70,
            height: 63,
            color: "white",
            font: "normal normal bold 18px/24px DM Sans",
            background: "#14162C",
            borderRadius: 20,

            "& span": {
                display: "block",
                textAlign: "center",
            },

            "&.number": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                minWidth: 100,
                minHeight: 105,
                fontWeight: "bold",
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                fontSize: 24,
                paddingTop: 14,
                [theme.breakpoints.down("xs")]: {
                    minWidth: 71,
                    minHeight: 75,
                    fontSize: 20,
                },
                // lineHeight: "28px",
            },
        },
        colon: {
            color: "white",
            fontSize: 20,
            opacity: 0.7,
            margin: "38px 5px 0 5px",
            [theme.breakpoints.down("xs")]: {
                margin: "20px 5px 0 5px",
                opacity: 0.3,
            },
        },

        listCountDown: {
            display: "flex",
            alignItems: "flex-start",

            [theme?.breakpoints?.down("sm")]: {
                textAlign: "center",
                justifyContent: "center",
            },
        },

        countdownInfo: {
            color: "#fff",
            opacity: 0.4,
            fontWeight: 400,
            fontSize: 12,
            lineHeight: "24px",
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            // marginTop: 4,
        },
        [theme.breakpoints.down("xs")]: {
            countdownPart: {
                padding: "10px 5px",

                "&.number": {
                    // padding: "5px 5px 15px 5px",
                },
            },
        },
        statusProject: {
            display: "flex",
            flexDirection: "column",
            "&> p": {
                opacity: 0.3,
                fontWeight: 400,
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                fontSize: 12,
                marginBottom: 10,
            },
            "&> div": {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                border: "none",
                borderRadius: 5,
                backgroundColor: "#242548",
                // color: "#9747FF",
                height: 28,
                padding: "0 10px",
                "&> div": {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    lineHeight: "20px",
                    marginBottom: "-4px",
                    "&> .middleDayHours": {
                        padding: "0 4px",
                    },
                    "&> .middleHoursMinutes": {
                        padding: "0 4px",
                    },
                },
                "&> .BuyTicket": {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    color: "#3C974B",
                    "&> .middleDayHours": {
                        padding: "0 4px",
                    },
                    "&> .middleHoursMinutes": {
                        padding: "0 4px",
                    },
                },
                "&> img": {
                    marginRight: 6,
                },
            },
            "&> .Upcoming": {
                color: "#9747FF",
            },
            "&> .Calculation": {
                color: "#337FE3",
            },
            "&> .Distribution": {
                color: "#E4C96A",
            },
            "&> .Ended": {
                color: "#FF3333",
            },
        },
    };
});

export default useStyles;
