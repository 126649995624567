// import moment from "moment";
import { useSelector } from "react-redux";
import useHandleTime from "../Status/useHandleTime";
import useStyles from "./style";

export default function TimeLine(props: any) {
    const styles = useStyles();
    const status = useSelector((state: any) => state.statusIdoPool.message);
    // const idoDetail = useSelector((state: any) => state.idoDetail?.data);

    const {
        timeHoursSnapshot,
        timeDaySnapshot,
        timeHoursTicketOpen,
        timeDayTicketOpen,
        timeHoursTicketClose,
        timeDayTicketClose,
        timeHoursPoolEnd,
        timeDayPoolEnd,
    } = useHandleTime();

    // console.log({status})

    const renderTimeLine = (number: 1 | 2 | 3 | 4, condition: boolean) => {
        if (condition) {
            return (
                <div style={{ backgroundColor: "#0F69E1" }}>
                    <p style={{ marginTop: "3px" }}>{number}</p>
                </div>
            );
        }
        return (
            <div style={{ backgroundColor: "#2F3149" }}>
                <p style={{ opacity: 0.5, marginTop: 4 }}>{number}</p>
            </div>
        );
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container1}>
                <div className={styles.timeLineTier1}>
                    {status === "Buy Ticket" || status === "Distribution" || status === "Ended" ? (
                        <div style={{ backgroundColor: "#0F69E1" }}>
                            <img src="images/home-page/iconSucsess.svg" alt="" />
                        </div>
                    ) : (
                        renderTimeLine(
                            1,
                            status === "Calculation" || status === "Upcoming" ? true : false
                        )
                    )}
                    {status === "Calculation" ? (
                        <div
                            style={{
                                background:
                                    "linear-gradient(90deg, #0F69E1 -30%, rgba(15, 105, 225, 0) 110%)",
                            }}
                        ></div>
                    ) : status === "Buy Ticket" ||
                      status === "Distribution" ||
                      status === "Ended" ? (
                        <div style={{ backgroundColor: "#0F69E1" }}></div>
                    ) : (
                        <div></div>
                    )}
                </div>
                <div className="desc1">
                    <div
                        style={{
                            color: `${status === "Calculation" ? "#0F69E1" : "#B8B8C0"}`,
                        }}
                    >
                        Staking Snapshot
                    </div>
                    <div>
                        <p>{timeDaySnapshot}</p>
                        <p>{timeHoursSnapshot}</p>
                    </div>
                </div>
            </div>
            <div className={styles.container2}>
                <div className={styles.timeLineTier2}>
                    {status === "Distribution" || status === "Ended" ? (
                        <div style={{ backgroundColor: "#0F69E1" }}>
                            <img src="images/home-page/iconSucsess.svg" alt="" />
                        </div>
                    ) : (
                        renderTimeLine(2, status === "Buy Ticket" ? true : false)
                    )}
                    {status === "Buy Ticket" ? (
                        <div
                            style={{
                                background:
                                    "linear-gradient(90deg, #0F69E1 -30%, rgba(15, 105, 225, 0) 110%)",
                            }}
                        ></div>
                    ) : status === "Distribution" || status === "Ended" ? (
                        <div style={{ backgroundColor: "#0F69E1" }}></div>
                    ) : (
                        <div></div>
                    )}
                </div>
                <div className="desc2">
                    <div
                        style={{
                            color: `${status === "Buy Ticket" ? "#0F69E1" : "#B8B8C0"}`,
                        }}
                    >
                        Ticket Open
                    </div>
                    <div>
                        <p>{timeDayTicketOpen}</p>
                        <p>{timeHoursTicketOpen}</p>
                    </div>
                </div>
            </div>
            <div className={styles.container3}>
                <div className={styles.timeLineTier3}>
                    {status === "Ended" ? (
                        <div style={{ backgroundColor: "#0F69E1" }}>
                            <img src="images/home-page/iconSucsess.svg" alt="" />
                        </div>
                    ) : (
                        renderTimeLine(3, status === "Distribution" ? true : false)
                    )}
                    {status === "Distribution" ? (
                        <div
                            style={{
                                background:
                                    "linear-gradient(90deg, #0F69E1 -30%, rgba(15, 105, 225, 0) 110%)",
                            }}
                        ></div>
                    ) : status === "Ended" ? (
                        <div style={{ backgroundColor: "#0F69E1" }}></div>
                    ) : (
                        <div></div>
                    )}
                </div>
                <div className="desc3">
                    <div
                        style={{
                            color: `${status === "Distribution" ? "#0F69E1" : "#B8B8C0"}`,
                        }}
                    >
                        Ticket End
                    </div>
                    <div>
                        <p>{timeDayTicketClose}</p>
                        <p>{timeHoursTicketClose}</p>
                    </div>
                </div>
            </div>
            <div className={styles.container4}>
                <div className={styles.timeLineTier4}>
                    {status === "Ended" ? (
                        <div style={{ backgroundColor: "#0F69E1" }}>
                            <p style={{ marginTop: "3px" }}>4</p>
                        </div>
                    ) : (
                        renderTimeLine(4, status === "Ended" ? true : false)
                    )}
                </div>
                <div className="desc4">
                    <div
                        style={{
                            color: `${status === "Ended" ? "#0F69E1" : "#B8B8C0"}`,
                        }}
                    >
                        Distribution End
                    </div>
                    <div>
                        <p>{timeDayPoolEnd}</p>
                        <p>{timeHoursPoolEnd}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
