import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: any) => {
    return {
        tierSystem: {
            background: "#131530",
            height: "auto",
            borderRadius: 20,
            minHeight: 460,
            [theme.breakpoints.up("sm")]: {},
            [theme.breakpoints.up("md")]: {
                maxHeight: 460,
            },
            [theme.breakpoints.up("lg")]: {},
            "& .boxTierSystem": {
                padding: "16px",
                [theme.breakpoints.up("sm")]: {},
                [theme.breakpoints.up("md")]: {
                    padding: "25px 30px",
                },
                [theme.breakpoints.up("lg")]: {},
                "& p": {
                    fontSize: 16,
                    color: "white",
                    paddingBottom: 8,
                    fontWeight: "600",
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    borderBottom: "1px solid rgba(255, 255, 255, .1)",
                    [theme.breakpoints.down("xs")]: {
                        fontSize: 14,
                    },
                },
                "& .MuiTableContainer-root": {
                    margin: "20px auto 0px auto",
                    borderRadius: "10px",
                    width: "100%",
                    overflow: "auto",
                    [theme.breakpoints.up("sm")]: {},
                    [theme.breakpoints.up("md")]: {
                        margin: "20px auto 0px auto",
                    },
                    [theme.breakpoints.up("lg")]: {},
                },
                "& .tableTier": {
                    background: "#1F213B",
                    boxShadow: "none",
                    borderRadius: 10,
                    width: "100%",
                    "& .tableCellHead": {
                        padding: "10px 16px",
                        fontSize: 12,
                        lineHeight: "17px",
                        color: "rgba(255, 255, 255, 1)",
                        backgroundColor: "#2A2C45",
                        borderBottom: "none",
                        // fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        whiteSpace: "pre",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        // "& img": {
                        //     margin: "4px 0px 0px 4px",
                        // },
                        "&> div": {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: 600,
                            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                            "&> img": {
                                marginRight: 5,
                            },
                        },
                    },

                    "& .tableRow": {
                        backgroundColor: "transparent",
                        "&:nth-child(4)": {
                            "& .tableCellBody": {
                                padding: "7px 15px",
                            },
                        },
                        "&:last-child": {
                            "& .tableCellBody": {
                                padding: "7px 15px",
                                borderBottom: "none",
                            },
                        },
                    },

                    "& .tableCellBody": {
                        fontSize: 12,
                        lineHeight: "17px",
                        borderBottom: "1px solid #2A2C45",
                        borderRight: "1px solid #2A2C45",
                        minWidth: "130px",
                        color: "rgba(255, 255, 255, .8)",
                        padding: "14px 15px",
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        "&:nth-child(2)": {
                            paddingRight: 10,
                        },
                        "&:last-child": {
                            borderRight: "none",
                        },
                    },
                    "& .noDataTransaction": {
                        padding: "64px 0px 27px 0px",
                        margin: "0px auto",
                        width: "316px",
                        "& img": {
                            mixBlendMode: "luminosity",
                        },
                    },
                },
            },
        },
    };
});
