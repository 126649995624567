import i18n from "i18next";
import moment from "moment";
import { useHistory } from "react-router";
import { convertLang } from "../../../CardItem";
import { randomColorEvent } from "../../Calendar";
import useStyles from "./style";

export default function ItemDetail({ item }: any) {
    const styles = useStyles();
    const history = useHistory();

    return (
        <div
            className={styles.itemDetail}
            onClick={() => history.push(`/project-detail/${item?.id}`)}
        >
            <div className="listItem">
                <div className="info">
                    <div className={`${randomColorEvent(item?.event)} line`}></div>
                    <div className="box">
                        <p>{moment(new Date(item?.date)).format("hh:mm A")}</p>
                        <span>
                            {convertLang(item?.project_name, i18n.language)}{" "}
                            <span style={{ textTransform: "capitalize" }}>{item?.event}</span>{" "}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
