import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        desc: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 56,
            "&> p": {
                fontSize: 14,
                fontWeight: 400,
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                color: "white",
                opacity: 0.5,
            },
            "&> .btnSuperRookie": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "rgba(151, 71, 255, 0.2)",
                borderRadius: 70,
                border: "none",
                padding: "8px 18px",
                color: "white",
                margin: "0px 10px",
                textTransform: "capitalize",
                fontSize: 12,
                "&> img": {
                    marginRight: 6,
                },
            },
        },
        btn: {
            marginTop: 100,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            "&> .btnStakeMore": {
                color: "white",
                fontSize: 16,
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                padding: "10px 85px",
                background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                borderRadius: 90,
                textTransform: "capitalize",
            },
            "&> .btnApplyWhitelist": {
                color: "#9747FF",
                fontSize: 16,
                fontWeight: 700,
                fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                padding: "10px 70px",
                background: "none",
                border: "2px solid #9747FF",
                borderRadius: 90,
                textTransform: "capitalize",
            },
        },
        applySuccess: {
            minHeight: 270,
            color: "white",
            opacity: 0.3,
            display: "flex",
            alignItems: "flex-end",
            fontSize: 18,
            "&> img": {
                marginRight: 10,
            },
        },
        condition: {
            borderTop: "1px solid #2B2C45",
            paddingTop: 24,
            marginTop: 26,
            display: "flex",
            flexDirection: "column",
            fontSize: 14,
            color: "white",
            opacity: 0.5,
            fontWeight: 400,
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            "&> p:first-child": {
                marginBottom: 10,
            },
        },
        snapshotIn: {
            marginTop: 25,
            "&> p": {
                marginBottom: 12,
                fontSize: 14,
                fontWeight: 400,
                fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                color: "white",
                opacity: 0.5,
            },
        },
    };
});

export default useStyles;
