import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        wrapper: {
            backgroundColor: "#030517",
            padding: 100,
            maxWidth: 1440,
            margin: "auto",
            [theme.breakpoints.down("md")]: {
                padding: "100px 50px 50px 50px",
            },
            [theme.breakpoints.down("sm")]: {
                padding: "100px 14px 14px 14px",
            },
        },
        projectName: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            color: "#FFFFFF",
            fontSize: 40,
            fontWeight: 600,
            "&> img": {
                width: 50,
                height: 50,
                borderRadius: "100%",
                objectFit: "cover",
                [theme.breakpoints.down("xs")]: {
                    width: 32,
                    height: 32,
                    borderRadius: "100%",
                },
            },
            "& >p": {
                fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                marginLeft: 16,
                [theme.breakpoints.down("xs")]: {
                    fontSize: 24,
                },
            },
            // [theme.breakpoints.down("md")]: {
            //     fontSize: 32,
            // },
            // [theme.breakpoints.down("sm")]: {
            //     fontSize: 28,
            // },
            // [theme.breakpoints.down("xs")]: {
            //     fontSize: 22,
            // },
        },
        statusProject: {
            marginTop: 20,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            "&> .status": {
                display: "flex",
                flexDirection: "row",
                color: "white",
                fontSize: 14,
                fontWeight: 600,
                fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                alignItems: "center",
                [theme.breakpoints.down("xs")]: {
                    fontSize: 12,
                },
                "&> .Upcoming": {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",

                    color: "#9747FF",
                    "&> img": {
                        marginRight: 6,
                    },
                },
                "&> .Calculation": {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",

                    color: "#337FE3",
                    "&> img": {
                        marginRight: 6,
                    },
                },
                "&> .Buyticket": {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",

                    color: "#3C974B",
                    "&> img": {
                        marginRight: 6,
                    },
                },
                "&> .Distribution": {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",

                    color: "#E4C96A",
                    "&> img": {
                        marginRight: 6,
                    },
                },
                "&> .Ended": {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",

                    color: "#FF3333",
                    "&> img": {
                        marginRight: 6,
                    },
                },
                "&> p": {
                    borderRight: "2px solid #2F3149",
                    paddingRight: 16,
                    position: "relative",
                    opacity: 0.5,
                    marginLeft: 16,
                    [theme.breakpoints.down("xs")]: {
                        marginLeft: 0,
                        padding: "0 6px",
                        fontSize: 12,
                    },
                },
                "&> p:first-child": {
                    opacity: 1,
                },
            },
            "&> .icon": {
                opacity: 0.5,
                display: "flex",
                alignItems: "center",
                [theme.breakpoints.down("xs")]: {},
                "&>a": {
                    marginLeft: 13,
                    [theme.breakpoints.down("xs")]: {
                        marginLeft: 9,
                    },
                },
            },
        },
        container: {
            marginTop: 30,
            display: "flex",
            flexDirection: "row",
            gap: "0px 20px",
            [theme.breakpoints.down("md")]: {
                flexDirection: "column",
            },
            "&> div:first-child": {
                padding: 30,
                backgroundColor: "#131530",
                width: "50%",
                maxWidth: 610,
                borderRadius: 20,
                [theme.breakpoints.down("md")]: {
                    padding: 24,

                    marginTop: 20,
                    width: "100%",
                    maxWidth: "inherit",
                },
                [theme.breakpoints.down("xs")]: {
                    padding: 20,
                },
            },
            "&> div:last-child": {
                padding: 30,
                backgroundColor: "#131530",
                width: "50%",
                maxWidth: 610,
                borderRadius: 20,
                [theme.breakpoints.down("md")]: {
                    padding: 18,
                    marginTop: 20,
                    width: "100%",
                    maxWidth: "inherit",
                    minHeight: 500,
                },
                [theme.breakpoints.down("xs")]: {
                    padding: 20,
                    minHeight: 300,
                },
            },
        },

        statusName: {
            fontSize: 12,
            fontWeight: 600,
            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
            marginTop: 10,
            [theme.breakpoints.down("xs")]: {
                fontSize: 9,
            },
        },
        timeStatusProject: {
            fontSize: 10,
            fontWeight: 400,
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            marginTop: 6,
            color: "#FFF",
            opacity: 0.3,
            [theme.breakpoints.down("xs")]: {
                opacity: 0.4,
                fontSize: 8,
            },
        },
        poolTimeLine: {
            display: "flex",
            flexDirection: "column",
            "&> p": {
                color: "white",
                fontWeight: 600,
                fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                fontSize: 16,
                borderBottom: "1px solid #2B2C45",
                paddingBottom: 8,
            },
            "& .timeLineTier": {
                // width: "80%",
                width: "97%",
                display: "inline-block",
                marginTop: 30,
                marginBottom: 60,
                // [theme.breakpoints.up("sm")]: {
                //   width: "80%",
                // },
                // [theme.breakpoints.up("md")]: {
                //   width: "90%",
                //   margin: "53px 60px",
                // },
                [theme.breakpoints.up("lg")]: {},
                "& .MuiSlider-rail": {
                    height: 6,
                    backgroundColor: "#464971",
                },

                "& .MuiSlider-track": {
                    height: 6,
                    // background: "linear-gradient(90deg, #0F69E1 10.69%, rgba(15, 105, 225, 0) 93.93%)",
                    background: "#0F69E1",
                },

                "&> .MuiSlider-markLabel .MuiSlider-markLabelActive": {
                    left: 6,
                },
                "& .MuiSlider-markLabel": {
                    color: "#AEAEAE",
                    fontSize: 14,
                    top: 0,
                },
                "& .MuiSlider-thumb ": {
                    display: "none",
                },
                "& .MuiSlider-mark": {
                    display: "none",
                },
            },
            "& .timeLineTierVertical": {
                display: "inline-block",
                marginTop: 30,
                marginBottom: 60,
                height: 200,
                // [theme.breakpoints.up("sm")]: {
                //     width: "80%",
                // },
                // [theme.breakpoints.up("md")]: {
                //     width: "90%",
                //     margin: "53px 60px",
                // },
                // [theme.breakpoints.up("lg")]: {},

                "& .MuiSlider-rail": {
                    height: 0,
                },
                "& .MuiSlider-track": {
                    // background: "linear-gradient(90deg, #0F69E1 10.69%, rgba(15, 105, 225, 0) 93.93%)",
                    background: "#0F69E1",
                    width: "5px",
                },

                "&> .MuiSlider-markLabel .MuiSlider-markLabelActive": {
                    left: 6,
                },
                "& .MuiSlider-markLabel": {
                    color: "#AEAEAE",
                    fontSize: 14,
                    top: 0,
                },
                "& .MuiSlider-thumb ": {
                    display: "none",
                },
                "& .MuiSlider-mark": {
                    display: "none",
                },
            },
            "& .MuiSlider-root": {
                padding: "13px 0!important",
                [theme.breakpoints.down("md")]: {
                    padding: "20px 0!important",
                },
            },
        },

        timeline: {
            marginTop: 40,
            width: "100%",
            height: 5,
            backgroundColor: "#2F3149",
            position: "relative",
            color: "white",
            fontWeight: 700,
            fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
            fontSize: 14,
            // opacity: 0.5,
        },
        lineSnapshot: {
            marginLeft: 80,
            [theme.breakpoints.down("xs")]: {
                marginLeft: 50,
            },
        },

        snapshot: {
            width: 30,
            height: 30,
            borderRadius: "100%",
            fontWeight: 700,
            // fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
            backgroundColor: "#0F69E1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 10,
            // marginTop: 8,
            [theme.breakpoints.down("md")]: {
                marginTop: 8,
            },
        },

        numberStatus: {
            width: 30,
            height: 30,
            borderRadius: "100%",
            fontWeight: 700,
            // fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
            backgroundColor: "#0F69E1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 10,
            [theme.breakpoints.down("md")]: {
                marginTop: 8,
            },
        },
        openTime: {
            width: 30,
            height: 30,
            borderRadius: "100%",
            backgroundColor: "#0F69E1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            [theme.breakpoints.down("md")]: {
                marginTop: 8,
            },
        },
        noOpenTime: {
            width: 30,
            height: 30,
            borderRadius: "100%",
            backgroundColor: "#0F69E1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // marginTop: 8,
            [theme.breakpoints.down("md")]: {
                marginTop: 8,
                // marginLeft: -10,
            },
        },
        tickEnd: {
            width: 30,
            height: 30,
            borderRadius: "100%",
            backgroundColor: "#0F69E1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 20,
            fontWeight: 700,
            // marginTop: 8,
            [theme.breakpoints.down("md")]: {
                marginTop: 8,
                // marginLeft: -10,
            },
            // fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
        },

        poolClose: {
            width: 30,
            height: 30,
            borderRadius: "100%",
            backgroundColor: "#0F69E1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 10,
            fontWeight: 700,
            // marginTop: 8,
            // fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
            color: "white",
            [theme.breakpoints.down("md")]: {
                marginTop: 8,
                // marginLeft: -10,
            },
        },

        details: {
            marginTop: 32,
            "&> p": {
                color: "white",
                fontWeight: 600,
                fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                fontSize: 16,
                paddingTop: 60,
                borderTop: "1px solid #2B2C45",
                [theme.breakpoints.down("xs")]: {
                    paddingTop: 20,
                },
            },
        },
        desc: {
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            "&> div": {
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                "&> p:first-child": {
                    width: 130,
                    color: "white",
                    fontWeight: 400,
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    fontSize: 14,
                    opacity: 0.4,
                    marginRight: 12,
                    [theme.breakpoints.down("xs")]: {
                        width: 120,
                        whiteSpace: "pre",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    },
                },
                "&> p:last-child": {
                    color: "white",
                    fontWeight: 400,
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    fontSize: 14,
                    display: "flex",
                    alignItems: "center",
                    [theme.breakpoints.down("xs")]: {
                        whiteSpace: "pre",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    },
                    "&> img": {
                        marginLeft: 6,
                    },
                },
                "&> a": {
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    fontSize: 14,
                    color: "#2DDBFF",
                    [theme.breakpoints.down("xs")]: {
                        whiteSpace: "pre",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    },
                },
                "&> .viewContractToken": {
                    "& > a": {
                        color: "#2DDBFF",
                        fontSize: 10,
                        marginLeft: 4,
                        fontWeight: 400,
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        "&> img": {
                            marginLeft: 6,
                        },
                    },
                },
            },
        },

        paricipateIdo: {
            "&> p": {
                color: "white",
                fontWeight: 600,
                fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                fontSize: 16,
                paddingBottom: 8,
                borderBottom: "1px solid #2B2C45",
            },
            "&> div": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: 350,
                "&> p": {
                    fontWeight: 600,
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    fontSize: 20,
                    color: "white",
                },
                "&> button": {
                    marginTop: 37,
                    fontWeight: 700,
                    fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                    fontSize: 12,
                    color: "white",
                    cursor: "pointer",
                    padding: "12px 46px",
                    background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                    borderRadius: 90,
                },
            },
        },
    };
});

export default useStyles;
