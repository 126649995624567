import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import erc20ABI from "../../../abi/Erc20.json";
import KlayDaoReward_ABI from "../../../abi/KlayDaoReward.json";
import KlayDaoTiers from "../../../abi/KlayDaoTiers.json";
import { take_decimal_number, take_decimal_numberFormatThousands } from "../../../constants";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { convertFromWei, convertToWei, getContractConnect } from "../../../services/web3";
import useCommonStyle from "../../../styles/CommonStyle";
import Button from "../Button";
import useStyles from "./style";
const closeIcon = "/images/icons/close.svg";

const ModalStake = (props: any) => {
    const styles = useStyles();
    const commonStyles = useCommonStyle();

    const {
        open,
        onConfirm,
        onClose,
        amount,
        setAmount,
        infoToken,
        tokenAddress,
        isReward,
        contractAddress,
        textError,
        setTextError,
    } = props;

    const [stakingAmount, setStakingAmount] = useState("");
    const [stakedTime, setStakedTime] = useState<any>();
    const [withdrawFee, setWithdrawFee] = useState<string>("");
    const [withdrawFeeAPR, setWithdrawFeeAPR] = useState<string>("");
    const [reward, setReward] = useState<string>("");
    const { appChainID } = useTypedSelector((state) => state.appNetwork).data;
    const { account: connectedAccount } = useWeb3React();
    const { t } = useTranslation();
    const getInfo = async (
        abi: any = erc20ABI,
        contractAddress: string,
        tokenAddress: string,
        userLogin: string
    ) => {
        const contract = await getContractConnect(abi, contractAddress, appChainID);
        try {
            const totalStaked = isReward
                ? (await contract?.methods.userInfo(userLogin).call()).amount
                : await contract?.methods.getTotalStaked(userLogin, tokenAddress).call();
            const lastDateStaked = isReward
                ? (await contract?.methods.userInfo(userLogin).call()).timeStake
                : (await contract?.methods.userInfo(connectedAccount, tokenAddress).call())
                      .stakedTime;
            setStakedTime(lastDateStaked);
            setStakingAmount(
                new BigNumber(totalStaked)
                    .dividedBy(10 ** Number(infoToken?.token_decimal))
                    .toString()
            );
        } catch (error: any) {
            toast.error(error);
        }
    };

    const getWithdrawFee = async (
        abi: any,
        contractAddress: string,
        tokenAddress: string,
        userLogin: string,
        amount: string
    ) => {
        const contract = await getContractConnect(abi, contractAddress, appChainID);
        try {
            const withdrawFee = isReward
                ? await contract?.methods
                      .calculateWithdrawFee(convertToWei(amount), userLogin)
                      .call()
                : await contract?.methods
                      .calculateWithdrawFee(convertToWei(amount), userLogin, tokenAddress)
                      .call();
            setWithdrawFee(withdrawFee);
        } catch (error: any) {
            toast.error(error);
        }
    };

    const getWithdrawFeeAPR = async (
        abi: any,
        contractAddress: string,
        tokenAddress: string,
        userLogin: string
    ) => {
        const contract = await getContractConnect(abi, contractAddress, appChainID);
        try {
            const withdrawFeeAPR = isReward
                ? await contract?.methods.calculateAPR(userLogin).call()
                : await contract?.methods.calculateWithdrawFeeAPR(userLogin, tokenAddress).call();
            setWithdrawFeeAPR(withdrawFeeAPR);
        } catch (error: any) {
            toast.error(error);
        }
    };

    useEffect(() => {
        if (!tokenAddress || !connectedAccount || !infoToken) return;
        (async () => {
            await getInfo(
                isReward ? KlayDaoReward_ABI : KlayDaoTiers,
                contractAddress,
                tokenAddress,
                connectedAccount
            );
        })();
    }, [tokenAddress, connectedAccount, isReward, infoToken]);

    useEffect(() => {
        if (
            !tokenAddress ||
            !connectedAccount ||
            !contractAddress ||
            !amount ||
            Number(amount) === 0
        ) {
            setWithdrawFee("0");
            return;
        }
        (async () => {
            getWithdrawFee(
                isReward ? KlayDaoReward_ABI : KlayDaoTiers,
                contractAddress,
                tokenAddress,
                connectedAccount,
                amount
            );
        })();
    }, [stakedTime, tokenAddress, connectedAccount, isReward, stakedTime, amount]);

    useEffect(() => {
        if (!tokenAddress || !connectedAccount || !contractAddress) return;
        (async () => {
            getWithdrawFeeAPR(
                isReward ? KlayDaoReward_ABI : KlayDaoTiers,
                contractAddress,
                tokenAddress,
                connectedAccount
            );
        })();
    }, [tokenAddress, connectedAccount, isReward, contractAddress]);

    useEffect(() => {
        if (!connectedAccount || !isReward) return;
        const intervalId = setInterval(async () => {
            const contract = await getContractConnect(
                KlayDaoReward_ABI,
                process.env.REACT_APP_REWARD as string,
                appChainID
            );
            try {
                const reward = await contract?.methods.pendingReward(connectedAccount).call();
                setReward(reward as string);
            } catch (error) {}
        }, 5000);
        return () => clearInterval(intervalId);
    }, [connectedAccount, isReward, appChainID]);

    const convertCheck = () => {
        return take_decimal_number(
            isReward
                ? Number(infoToken?.total_staked)
                : new BigNumber(infoToken?.total_staked)
                      .dividedBy(10 ** Number(infoToken?.token_decimal))
                      .toNumber(),
            6
        );
    };

    return (
        <>
            {stakingAmount && (
                <Dialog
                    open={open}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    className={commonStyles.modal + " " + styles.modalUnStake}
                >
                    <div className="modal-content">
                        <DialogTitle id="alert-dialog-slide-title" className="modal-content__head">
                            <img
                                src={closeIcon}
                                alt=""
                                onClick={() => {
                                    setTextError && setTextError("");
                                    onClose();
                                }}
                                className="btn-close"
                            />
                            <div className="title">{t("staking.modalUnStake.title")}</div>
                            <div className="headerWarning">
                                <div className="waringBox">
                                    <img src="/images/staking/iconWarning.svg" alt="" />
                                    {isReward ? (
                                        <p>{t("staking.modalUnStake.warningPoolReward")}</p>
                                    ) : (
                                        <p>{t("staking.modalUnStake.warning")}</p>
                                    )}
                                </div>
                            </div>
                        </DialogTitle>
                        <DialogContent className="modal-content__body">
                            <div className="boxTokenType">
                                <div className="token-type">
                                    <div> {t("staking.modalUnStake.token")}</div>
                                    <div className="token-detail">
                                        <div>{infoToken?.token_symbol}</div>
                                    </div>
                                </div>
                                <div className="token-type">
                                    <div>{t("staking.modalUnStake.staking")}</div>
                                    <div className="token-detail">
                                        <div>
                                            {take_decimal_numberFormatThousands(
                                                isReward
                                                    ? Number(infoToken?.total_staked)
                                                    : new BigNumber(infoToken?.total_staked)
                                                          .dividedBy(
                                                              10 ** Number(infoToken?.token_decimal)
                                                          )
                                                          .toNumber() || 0
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {isReward && (
                                    <div className="token-type">
                                        <div>{t("staking.modalUnStake.claimReward")}</div>
                                        <div className="token-detail">
                                            <div>
                                                {take_decimal_numberFormatThousands(
                                                    convertFromWei(reward as string)
                                                ) || 0}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {Number(withdrawFeeAPR) > 0 && (
                                    <div className="token-type">
                                        <div>{t("staking.modalUnStake.withdrawalFee")}</div>
                                        <div className="token-detail">
                                            <div>
                                                {take_decimal_number(
                                                    Number(withdrawFeeAPR) / 10 ** 12,
                                                    2
                                                )}
                                                %
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="subtitle">
                                {t("staking.modalUnStake.unStakeAmount")}
                            </div>
                            <div>
                                <div className="input-group">
                                    <input
                                        value={amount}
                                        onChange={(event) => {
                                            setTextError && setTextError("");
                                            setAmount(event.target.value);
                                        }}
                                        type="number"
                                        min="0"
                                        placeholder={t("staking.modalUnStake.textPlaceholderInput")}
                                    />
                                </div>
                                {Number(amount) >
                                    take_decimal_number(
                                        isReward
                                            ? Number(infoToken?.total_staked)
                                            : new BigNumber(infoToken?.total_staked)
                                                  .dividedBy(10 ** Number(infoToken?.token_decimal))
                                                  .toNumber(),
                                        6
                                    ) && (
                                    <p className="warningStaked">
                                        {t("staking.modalUnStake.warningStaked")}
                                    </p>
                                )}
                                {Number(amount) <= 0 && (
                                    <p className="warningStaked">Must be a number bigger than 0.</p>
                                )}
                                {textError && <p className="warningStaked">{textError}</p>}
                            </div>

                            <div className="actionChangeAmount">
                                <Button
                                    text="25%"
                                    onClick={() =>
                                        setAmount(
                                            take_decimal_number(
                                                (isReward
                                                    ? Number(infoToken?.total_staked)
                                                    : new BigNumber(infoToken?.total_staked)
                                                          .dividedBy(
                                                              10 ** Number(infoToken?.token_decimal)
                                                          )
                                                          .toNumber()) * 0.25,
                                                6
                                            ) + ""
                                        )
                                    }
                                />
                                <Button
                                    text="50%"
                                    onClick={() =>
                                        setAmount(
                                            take_decimal_number(
                                                (isReward
                                                    ? Number(infoToken?.total_staked)
                                                    : new BigNumber(infoToken?.total_staked)
                                                          .dividedBy(
                                                              10 ** Number(infoToken?.token_decimal)
                                                          )
                                                          .toNumber()) * 0.5,
                                                6
                                            ) + ""
                                        )
                                    }
                                />
                                <Button
                                    text="75%"
                                    onClick={() =>
                                        setAmount(
                                            take_decimal_number(
                                                (isReward
                                                    ? Number(infoToken?.total_staked)
                                                    : new BigNumber(infoToken?.total_staked)
                                                          .dividedBy(
                                                              10 ** Number(infoToken?.token_decimal)
                                                          )
                                                          .toNumber()) * 0.75,
                                                6
                                            ) + ""
                                        )
                                    }
                                />
                                <Button
                                    text="100%"
                                    onClick={() =>
                                        setAmount(
                                            take_decimal_number(
                                                isReward
                                                    ? Number(infoToken?.total_staked)
                                                    : new BigNumber(infoToken?.total_staked)
                                                          .dividedBy(
                                                              10 ** Number(infoToken?.token_decimal)
                                                          )
                                                          .toNumber(),

                                                6
                                            ) + ""
                                        )
                                    }
                                />
                            </div>
                            {take_decimal_number(convertFromWei(withdrawFee).toString(), 5) > 0 && (
                                <div className="token-type">
                                    <div>
                                        <div>{t("staking.modalUnStake.withdrawalFeeAmount")}</div>
                                        <div className="withdrawFeePolicy">
                                            <span>Withdrawal fee policy</span>
                                            <img src="/images/icons/redirect.svg" alt="" />
                                        </div>
                                    </div>
                                    <div className="token-detail">
                                        <div className="">
                                            {take_decimal_number(
                                                convertFromWei(withdrawFee).toString(),
                                                5
                                            ) || "0"}{" "}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="token-type">
                                <div>{t("staking.modalUnStake.receiveAmount")}</div>
                                <div className="token-detail">
                                    <div>
                                        {(Number(amount) > 0 &&
                                            take_decimal_number(
                                                (
                                                    Number(amount) -
                                                    Number(convertFromWei(withdrawFee).toString())
                                                ).toString(),
                                                5
                                            )) ||
                                            "0"}{" "}
                                        CODE
                                    </div>
                                </div>
                            </div>
                        </DialogContent>

                        <DialogActions className="modal-content__foot">
                            <Button
                                text={t("staking.modalUnStake.btnUnstake")}
                                onClick={() =>
                                    onConfirm({
                                        tokenAddress,
                                        token_decimal: infoToken?.token_decimal,
                                        isReward,
                                    })
                                }
                                backgroundColor="#38383F"
                                disabled={
                                    isNaN(amount) ||
                                    Number(amount) <= 0 ||
                                    Number(stakingAmount) <= 0 ||
                                    Number(amount) > Number(convertCheck())
                                }
                            />
                        </DialogActions>
                    </div>
                </Dialog>
            )}
        </>
    );
};

export default ModalStake;
