import i18n from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import DefaultLayout from "../../../components/Layout/DefaultLayout";
import { BaseRequest } from "../../../request/Request";
import { convertLang } from "../CardItem";
import ListItemDetail from "./ListItemDetail";
import ListView from "./ListView";
import useStyles from "./style";

// const locales = {
//     "en-US": require("date-fns/locale/en-US"),
// };

export const randomColorEvent = (event: string) => {
    switch (event) {
        case "pool open":
            return "yellow";
        case "snapshot":
            return "blue";
        case "ticket end":
            return "green";
        default:
            return "purple";
    }
};

export function CalendarProject(props: any) {
    const styles = useStyles();
    const { t } = useTranslation();
    const [tab, setTab] = useState("Calendar");
    const [dateCalendarCurrent, setDateCalendarCurrent] = useState<Date>(new Date());
    const [data, setData] = useState<any>();
    const [dataOfDay, setDataOfDay] = useState<any>();
    const [dataView, setDataView] = useState<any>();
    const [valueSearch, setValueSearch] = useState<any>();
    const [valueSearchChange, setValueSearchChange] = useState<any>();
    const history = useHistory();

    moment.locale("ko", {
        week: {
            dow: 1,
            doy: 1,
        },
    });
    const localizer = momentLocalizer(moment);

    const goToBack = () => {
        const newDate =
            tab === "ListItemDetail"
                ? moment(dateCalendarCurrent).subtract(1, "days")
                : new Date(
                      dateCalendarCurrent.getFullYear(),
                      dateCalendarCurrent.getMonth() - 1,
                      1
                  );
        setDateCalendarCurrent(newDate as Date);
    };

    const goToNext = () => {
        const newDate =
            tab === "ListItemDetail"
                ? moment(dateCalendarCurrent).add(1, "days")
                : new Date(
                      dateCalendarCurrent.getFullYear(),
                      dateCalendarCurrent.getMonth() + 1,
                      2
                  );
        setDateCalendarCurrent(newDate as Date);
    };

    const goToToday = () => {
        setDateCalendarCurrent(new Date());
    };

    const getIDO = async () => {
        const baseRequest = new BaseRequest();
        try {
            const response =
                tab === "ListItemDetail"
                    ? ((await baseRequest.get(
                          `/ido/calendar?StartDate=${moment(new Date(dateCalendarCurrent))
                              .startOf("month")
                              .format("YYYY-MM-DD")}&EndDate=${moment(new Date(dateCalendarCurrent))
                              .add(1, "months")
                              .startOf("month")
                              .format("YYYY-MM-DD")}&CurrentDate=${moment(
                              new Date(dateCalendarCurrent)
                          ).format("YYYY-MM-DD")}${
                              valueSearch ? `&searchValue=${valueSearch}` : " "
                          }`
                      )) as any)
                    : ((await baseRequest.get(
                          `/ido/calendar?StartDate=${moment(new Date(dateCalendarCurrent))
                              .startOf("month")
                              .format("YYYY-MM-DD")}&EndDate=${moment(new Date(dateCalendarCurrent))
                              .add(1, "months")
                              .startOf("month")
                              .format("YYYY-MM-DD")}${
                              valueSearch ? `&searchValue=${valueSearch}` : " "
                          }`
                      )) as any);
            const resObj = await response.json();

            const dataCalendar = resObj.map((item: any) => {
                return {
                    title: (
                        <div
                            className={randomColorEvent(item?.event as string) + " contentCalendar"}
                        >
                            <div className="box">
                                <span>
                                    {convertLang(item?.project_name, i18n.language)} {item?.event}
                                </span>{" "}
                                {moment(new Date(item?.date)).format("h:mm A")}
                            </div>
                        </div>
                    ),
                    allDay: false,
                    start: moment(item?.date),
                    end: moment(item?.date),
                    id: item.id,
                };
            });

            const result = Object.values(
                resObj?.reduce((acc: any, { date, id, project_name, event }: any) => {
                    const date1 = new Date(date);
                    const key = date1.getMonth() + "/" + date1.getDate();
                    acc[key] = acc[key] || { date, values: [] };
                    acc[key].values.push({ date, id, project_name, event });
                    return acc;
                }, {})
            );
            setData(dataCalendar);
            setDataOfDay(resObj);
            setDataView(result);
        } catch (error: any) {
            toast.error(error?.message);
        }
    };

    useEffect(() => {
        (async () => {
            await getIDO();
        })();
    }, [dateCalendarCurrent, tab, valueSearch]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <DefaultLayout defaultLayout={false}>
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <div className="dateNow">
                        <p>{moment(new Date(dateCalendarCurrent)).format("MMMM YYYY")}</p>
                        <div className="">
                            <button onClick={goToBack} className="btn">
                                <img
                                    src="/images/home-page/iconLeft.svg"
                                    alt=""
                                    width="6"
                                    height="10"
                                />
                            </button>
                            <button
                                className="btnToday"
                                onClick={goToToday}
                                disabled={tab === "ListItemDetail"}
                            >
                                {tab === "ListItemDetail"
                                    ? moment(new Date(dateCalendarCurrent)).format("MMM DD")
                                    : "Today"}
                            </button>
                            <button onClick={goToNext} className="btn">
                                <img
                                    src="/images/home-page/iconRight.svg"
                                    alt=""
                                    width="6"
                                    height="10"
                                />
                            </button>
                        </div>
                    </div>
                    <div className="toolBar">
                        <div className="search">
                            <img
                                src="/images/home-page/iconSearch.svg"
                                alt=""
                                width="16"
                                height="16"
                                onClick={() => setValueSearch(valueSearchChange)}
                            />
                            <input
                                type="text"
                                placeholder={t("IdoHomePage.search")}
                                onKeyDown={(e: any) => {
                                    if (e.keyCode === 13) {
                                        setValueSearch(e.target.value);
                                    }
                                    return;
                                }}
                                onChange={(e: any) => setValueSearchChange(e.target.value)}
                            />
                        </div>
                        <button
                            onClick={() => setTab(tab === "Calendar" ? "ListView" : "Calendar")}
                        >
                            <p>
                                {tab === "Calendar"
                                    ? t("IdoHomePage.listView")
                                    : t("IdoHomePage.gridView")}
                            </p>
                            <img src="/images/home-page/iconList.svg" alt="" />
                        </button>
                    </div>
                </div>

                {tab === "Calendar" && (
                    <div className={styles.calendar}>
                        <Calendar
                            localizer={localizer}
                            events={data}
                            startAccessor="start"
                            endAccessor="end"
                            culture="en"
                            selectable={true}
                            views={["month"]}
                            defaultView="month"
                            showAllEvents={true}
                            onSelectEvent={(slotInfo: any) => {
                                history.push(`/project-detail/${slotInfo?.id}`);
                            }}
                            onNavigate={(date) => {
                                setDateCalendarCurrent(date);
                            }}
                            onSelectSlot={(slotInfo) => {
                                setDateCalendarCurrent(new Date(slotInfo.start));
                                setTab("ListItemDetail");
                            }}
                            // onShowMore={(event, date) => {
                            //     console.log("onSelectSlot", event, date);
                            //     // setDateCalendarCurrent(new Date(slotInfo.start));
                            //     setTab("ListItemDetail");
                            // }}
                            tooltipAccessor={null}
                            onDrillDown={(date) => {
                                setDateCalendarCurrent(new Date(date));
                                setTab("ListItemDetail");
                            }}
                            defaultDate={dateCalendarCurrent}
                            date={dateCalendarCurrent}
                            formats={{
                                weekdayFormat: (date, culture, localizer: any) =>
                                    localizer.format(date, "ddd", culture),
                            }}
                        />
                    </div>
                )}
                {tab === "ListView" && (
                    <ListView
                        handleSetTab={(tab: string, date: Date) => {
                            setTab(tab);
                            setDateCalendarCurrent(date);
                        }}
                        dateCalendarCurrent={dateCalendarCurrent}
                        dataView={dataView}
                    />
                )}
                {tab === "ListItemDetail" && (
                    <ListItemDetail
                        dateCalendarCurrent={dateCalendarCurrent}
                        dataOfDay={dataOfDay}
                    />
                )}
            </div>
        </DefaultLayout>
    );
}
