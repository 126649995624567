import { Dialog, DialogContent } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useCommonStyle } from "../../styles";
import useStyles from "./style";

const ModalSignatureRequired = (props: any) => {
    const styles = useStyles();
    const commonStyles = useCommonStyle();
    const { t } = useTranslation();
    const { open } = props;

    return (
        <Dialog
            open={open}
            keepMounted
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className={commonStyles.modal + " " + styles.modalSignatureRequired}
        >
            <div className="modal-content">
                <DialogContent className="modal-content__body">
                    <img src="/images/icons/warning3.svg" alt="" />
                    <p>{t("modalSignatureRequired.title")}</p>
                    <span>{t("modalSignatureRequired.content")}</span>
                </DialogContent>
            </div>
        </Dialog>
    );
};

export default ModalSignatureRequired;
