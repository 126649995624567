import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    DISCORD_CODE_DAO_LINK,
    MEDIUM_CODE_DAO_LINK,
    TELEGRAM_CODE_DAO_LINK,
    TWITTER_CODE_DAO_LINK,
} from "../../../constants";
import PrivacyPolicy from "../../../pages/Dashboard/TermsAndPrivacy/PrivacyPolicy";
import Terms from "../../../pages/Dashboard/TermsAndPrivacy/Terms";
import useStyles from "./styles";
// const WalletIcon = "wallet.svg";

export default function FooterHomePage(props: any): React.ReactElement {
    const styles = useStyles();
    const { t } = useTranslation();

    const [openTerms, setOpenTerms] = useState<boolean>(false);
    const [openPolicy, setOpenPolicy] = useState<boolean>(false);

    const handleCloseModal = useCallback(() => {
        setOpenTerms(false);
        setOpenPolicy(false);
    }, []);

    const renderOpenModalTerms = useCallback(
        () => <Terms opened={openTerms} handleClose={handleCloseModal} />,
        [handleCloseModal, openTerms]
    );

    const renderOpenModalPolicy = useCallback(
        () => <PrivacyPolicy opened={openPolicy} handleClose={handleCloseModal} />,
        [handleCloseModal, openPolicy]
    );

    const openModalTerms = () => {
        setOpenTerms(true);
    };

    const openModalPolicy = () => {
        setOpenPolicy(true);
    };
    return (
        <>
            <div className={styles.borderTop}></div>
            <footer className={styles.wrapper}>
                <div className={styles.footer}>
                    <div className="desc">
                        <img
                            src="/images/home-page/BBGfastraise.png"
                            alt=""
                            width="257px"
                            height="60px"
                        />
                        <p>{t("footer.text1")}</p>
                        <p>{t("footer.text2")}</p>
                    </div>
                    <div className="community">
                        <div>
                            <p>{t("footer.textJoin")}</p>
                        </div>
                        <div>
                            <a
                                href={TELEGRAM_CODE_DAO_LINK}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img src="/images/home-page/iconTelegram.svg" alt="" />
                            </a>
                            <a
                                href={TWITTER_CODE_DAO_LINK}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img src="/images/home-page/logoTwitter.svg" alt="" />
                            </a>
                            <a
                                href={MEDIUM_CODE_DAO_LINK}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img src="/images/home-page/iconMedium.svg" alt="" />
                            </a>
                            <a
                                href={DISCORD_CODE_DAO_LINK}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img src="/images/home-page/iconDiscord.svg" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className={styles.help}>
                    <div className={styles.subContent}>
                        <span onClick={openModalPolicy}>{t("footer.btnPrivacy")}</span>
                        <span onClick={openModalTerms}>{t("footer.btnTerms")}</span>
                        {renderOpenModalTerms()}
                        {renderOpenModalPolicy()}
                    </div>
                    <div className={styles.endContent}>
                        <p>{t("footer.textCopy")}</p>
                    </div>
                </div>
            </footer>
        </>
    );
}
