import { ZIndex } from "@material-ui/core/styles/zIndex";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        title: {
            fontSize: 10,
        },
        wrapper: {
            backgroundColor: "#030517",
            width: 1237,
            margin: "0px auto",
            padding: "100px 0px",
            [theme.breakpoints.down("md")]: {
                padding: "50px",
                width: "100%",
            },
            [theme.breakpoints.down("sm")]: {
                padding: "32px 16px",
                width: "100%",
            },
            "& .rbc-active": {
                color: "red",
            },
        },
        container: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            "&> .back-home": {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: 18,
                "&>p:nth-child(2)": {
                    marginLeft: 20,
                },
                "& >div": {
                    height: 22,
                    border: ".2px solid #757575",
                    margin: "0 24px",
                },
                "&>p:last-child": {
                    fontWeight: 700,
                },
            },
            "&> .search": {
                display: "flex",
                alignItems: "center",
                backgroundColor: "white",
                height: "46px",
                lineHeight: "46px",
                borderRadius: "10px",
                "&>img": {
                    marginLeft: 16,
                    marginRight: 26,
                },

                "&>input": {
                    border: "none",
                    outline: "none",
                    borderRadius: "10px",
                    height: "46px",
                    lineHeight: "46px",
                    marginRight: 26,
                    fontSize: 12,
                    fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                },
            },
        },
        header: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 40,
            flexWrap: "wrap",
            gap: "20px 0px",
            [theme.breakpoints.down("xs")]: {
                flexDirection: "column",
                marginBottom: 16,
                alignItems: "flex-start",
            },
            "&> .dateNow": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "center",
                gap: "24px 32px",
                [theme.breakpoints.down("xs")]: {
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    width: "100%",
                },
                "&> p": {
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 40,
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    minWidth: 340,
                    [theme.breakpoints.down("sm")]: {
                        fontSize: 24,
                    },
                },
                "& button": {
                    background: "none",
                    color: "white",
                    opacity: 0.7,
                    fontWeight: "bold",
                    border: " 2px solid rgba(255, 255, 255, 0.5)",
                    borderRadius: "6px",
                    padding: "7px 15px",
                    fontSize: 12,
                    fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                    margin: "0 3px",
                    cursor: "pointer",
                },
                "& .btn": {
                    border: " 2px solid rgba(255, 255, 255, 0.1)",
                },
                "& .btnToday": {
                    padding: "7px 29px",
                },
            },
            "&>div:last-child": {
                display: "flex",
                flexDirection: "row",
                "&> .search": {
                    display: "flex",
                    alignItems: "center",
                    width: 225,
                    height: 40,
                    borderRadius: "10px",
                    border: "2px solid rgba(255, 255, 255, 0.15)",
                    marginRight: 12,
                    [theme.breakpoints.down("sm")]: {
                        width: 207,
                        marginRight: 16,
                    },
                    "&>img": {
                        marginLeft: 12,
                        marginRight: 12,
                        cursor: "pointer",
                        [theme.breakpoints.down("sm")]: {
                            marginLeft: 16,
                            marginRight: 16,
                            width: "40%",
                            height: "40%",
                        },
                    },

                    "&>input": {
                        border: "none",
                        color: "white",
                        outline: "none",
                        borderTopRightRadius: "10px",
                        borderBottomRightRadius: "10px",
                        height: "40px",
                        background: "none",
                        fontSize: 12,
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                    },
                },
                "&> button": {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#9747FF",
                    width: 120,
                    height: 40,
                    borderRadius: 60,
                    cursor: "pointer",
                    border: "none",
                    fontSize: 12,
                    fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                    "&> p": {
                        fontWeight: 700,
                        fontSize: 12,
                        color: "white",
                        marginRight: 6,
                    },
                },
                "&>p": {
                    paddingRight: 60,
                    color: "#FFFFFF",
                    fontSize: 24,
                    fontWeight: "bold",
                },
            },
            "& .listItem": {},
        },
        calendar: {
            position: "relative",
            width: 1237,
            margin: "0px auto",
            [theme.breakpoints.down("lg")]: {
                width: "100%",
            },
            "& .rbc-calendar": {
                [theme.breakpoints.down("sm")]: {
                    // height: 760,
                },
            },
            "& .rbc-toolbar": {
                display: "none",
            },
            "& .rbc-day-bg": {
                width: 205,
                height: 230,
                backgroundColor: "#14162C",
                borderLeft: "2px solid #030517 !important",
                borderWidth: 2,
            },
            "& .rbc-off-range-bg": {
                backgroundColor: "#0C0E22",
                borderLeft: "2px solid #030517 !important",
                borderWidth: 2,
                "& .rbc-button-link": {
                    color: "#474857 !important",
                },
            },
            "& .rbc-month-header": {
                backgroundColor: "transparent",
                height: 30,
                "& .rbc-header": {
                    textAlign: "left",
                    borderColor: "#030517",
                    borderWidth: 2,
                    [theme.breakpoints.down("sm")]: {
                        paddingRight: 4,
                    },
                    "& span": {
                        fontSize: 14,
                        fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                        color: "rgba(255,255,255,0.3)",
                        textTransform: "uppercase",
                        [theme.breakpoints.down("sm")]: {
                            fontSize: 12,
                            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                        },
                    },
                },
            },
            "& .rbc-month-row": {
                cursor: "pointer",
                minHeight: 230,
                [theme.breakpoints.down("md")]: {
                    minHeight: 200,
                },
                [theme.breakpoints.down("sm")]: {
                    minHeight: 150,
                },
                [theme.breakpoints.down("xs")]: {
                    minHeight: 80,
                },
                "& .rbc-row-content-scrollable": {
                    maxHeight: "100%",
                },
            },
            "& .rbc-row-bg": {
                [theme.breakpoints.down("xs")]: {
                    height: 80,
                },
            },
            "& .rbc-today": {
                backgroundColor: "#1C1E37",
                borderColor: "#030517",
                borderWidth: 2,
            },
            "& .rbc-now": {
                "& .rbc-button-link": {
                    color: "#9747FF",
                },
            },
            "& .rbc-month-view,& .rbc-month-row": {
                borderTop: "2px solid #030517 !important",
                borderWidth: 1,
            },
            "& .rbc-month-view": {
                border: "2px solid #030517 !important",
            },
            "& .rbc-row-content": {
                // height: 64,
                "& .rbc-row": {
                    marginBottom: 5,
                    [theme.breakpoints.down("sm")]: {
                        marginBottom: 2,
                    },
                },
                "& .rbc-row-segment": {
                    minHeight: 64,
                    backgroundColor: "transparent",
                    [theme.breakpoints.down("sm")]: {
                        minHeight: 10,
                    },
                    "& .rbc-event": {
                        backgroundColor: "transparent",
                        padding: 0,
                        width: "100%",
                        height: "100%",
                        "& .rbc-event-content": {
                            width: "100%",
                            height: "100%",
                            [theme.breakpoints.down("xs")]: {
                                minWidth: 49,
                            },
                        },
                        "& .contentCalendar": {
                            textTransform: "capitalize",
                            [theme.breakpoints.down("md")]: {
                                fontSize: 12,
                            },
                            [theme.breakpoints.down("sm")]: {
                                fontSize: 10,
                            },
                            [theme.breakpoints.down("xs")]: {
                                padding: "4px 2px",
                                minWidth: 49,
                            },
                            "& .box": {
                                [theme.breakpoints.down("xs")]: {
                                    maxWidth: 39,
                                    whiteSpace: "nowrap",
                                },
                                "& span": {
                                    [theme.breakpoints.down("xs")]: {
                                        display: "none",
                                    },
                                },
                            },
                        },
                        "& .yellow,& .green,& .blue,& .purple,& .red": {
                            height: "100%",
                            width: "100%",
                            padding: "10px 17px 10px 22px",
                            fontSize: 14,
                            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                            whiteSpace: "normal",
                            lineHeight: "22px",
                            [theme.breakpoints.down("sm")]: {
                                padding: 4,
                            },
                        },
                        "& .yellow": {
                            color: "#E4C96A",
                            background: "rgba(228, 201, 106, 0.15)",
                        },
                        "& .red": {
                            color: "#D64646",
                            background: "rgba(214, 70, 70, 0.15)",
                        },
                        "& .green": {
                            color: "#3C974B",
                            background: "rgba(60, 151, 75, 0.15)",
                        },
                        "& .blue": {
                            color: "#337FE3",
                            background: "rgba(51, 127, 227, 0.15)",
                        },
                        "& .purple": {
                            color: "#9747FF",
                            background: "rgba(151, 71, 255, 0.15)",
                        },
                    },
                    "& .rbc-selected": {
                        backgroundColor: "none",
                    },
                },
            },

            "& .rbc-date-cell": {
                textAlign: "left",
                [theme.breakpoints.down("sm")]: {
                    height: 40,
                },
                [theme.breakpoints.down("xs")]: {
                    height: "auto",
                },
                "& .rbc-button-link": {
                    fontSize: 24,
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    padding: "12px 25px",
                    color: "rgba(255,255,255,0.5)",
                    [theme.breakpoints.down("sm")]: {
                        padding: 8,
                        lineHeight: "30px",
                    },
                    [theme.breakpoints.down("xs")]: {
                        fontSize: 12,
                        padding: 8,
                        lineHeight: "14px",
                    },
                },
            },
        },
    };
});
export default useStyles;
