import { Button } from "@material-ui/core";
import BigNumber from "bignumber.js";
import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import BUY_TICKET_ABI from "../../../../../abi/BuyTicket.json";
import erc20ABI from "../../../../../abi/Erc20.json";
import { AppContext } from "../../../../../AppContext";
import { NETWORK } from "../../../../../constants";
import { ConnectorNames } from "../../../../../constants/connectors";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { BaseRequest } from "../../../../../request/Request";
import {
    convertFromWei,
    getContractConnect,
    getContractInstanceWeb3,
    getWeb3Instance,
} from "../../../../../services/web3";
import { toastMessage } from "../../../../../store/actions/toast-message-buyticket";
import { calculateUSDC } from "../../../../../utils/formatNumber";
import { findNetworkFromChainID } from "../../../../../utils/networkFromChainId";
import ModalLoading from "../../../../StakingPools/ModalLoading";
import useStyles from "./style";
import { POLYGON_CHAIN_ID } from "../../../../../constants/network";
import Web3 from "web3";

type Props = {
    dataSnapshot: any;
    idoDetail: any;
    decimals: any;
    balanceOf: any;
    totalAmount: any;
    getBalanceUsdc: any;
    getDataYourTicket: any;
    contractTokenUsdc: any;
};

export const TRANSACTION_TIMEOUT = 1 * 60 * 1000;

export default function TierStarterAndRookieBuyTicket({
    dataSnapshot,
    idoDetail,
    decimals,
    balanceOf,
    totalAmount,
    getBalanceUsdc,
    getDataYourTicket,
    contractTokenUsdc,
}: Props): React.ReactElement {
    const styles = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [amount, setAmount] = useState<any>();
    const [loadingTransaction, setLoadingTransaction] = useState<boolean>(false);
    const [width, setWidth] = useState(0);

    const inputEl = useRef<HTMLInputElement>(null);

    const { appChainID } = useTypedSelector((state: any) => state.appNetwork).data;
    const { currentConnectedWallet } = useContext(AppContext);
    const currentAccount = currentConnectedWallet && currentConnectedWallet.addresses[0];
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setAmount(parseInt(value));
        setWidth(value.length);
    };
    const handleClickMax = (e) => {
        e.stopPropagation();
        setAmount(dataSnapshot?.maxQuantity - totalAmount);
    };

    const handleClickMin = (e) => {
        e.stopPropagation();
        if (dataSnapshot?.minQuantity <= 0) {
            return setAmount(0);
        } else if (dataSnapshot?.maxQuantity - totalAmount < dataSnapshot?.minQuantity) {
            return setAmount(dataSnapshot?.maxQuantity - totalAmount);
        } else if (totalAmount >= dataSnapshot?.minQuantity) {
            return setAmount(dataSnapshot?.minQuantity);
        } else {
            return setAmount(dataSnapshot?.minQuantity);
        }
    };

    const handleClickInputBuyTicket = () => {
        inputEl.current?.focus();
    };

    const handleApprove = async (
        abi: any = erc20ABI,
        contractAddress: string,
        spenderAddress: string,
        account: string,
        idoNetwork: string
    ): Promise<{ time_out: boolean }> => {
        return new Promise(async (resolve, reject) => {
            let timeOut;
            try {
                let wallet = localStorage.getItem("walletconnect-name");
                if (!wallet) {
                    wallet = "";
                }
                let obj = {};
                if (idoNetwork === NETWORK.KLAYTN && wallet === ConnectorNames.MetaMask) {
                    const web3 = getContractInstanceWeb3(idoNetwork);
                    if (web3) {
                        const gas = await web3.eth.getGasPrice();
                        obj = {
                            maxFeePerGas: gas,
                            maxPriorityFeePerGas: gas,
                        };
                    }
                }
                const contract = await getContractConnect(
                    abi,
                    contractAddress,
                    appChainID,
                    wallet,
                    "write"
                );

                if (wallet === ConnectorNames.Kaikas) {
                    await contract?.methods.approve(spenderAddress, "0x" + "f".repeat(64)).send({
                        from: account,
                        ...obj,
                        gas: 8000000,
                    });
                } else {
                    let payload;
                    if (appChainID === POLYGON_CHAIN_ID) {
                        const web3Instance = await getWeb3Instance();
                        const gasPrice = await web3Instance?.eth.getGasPrice();
                        const gas_multiplier = process.env.GAS_MULTIPLIER ? parseFloat(process.env.GAS_MULTIPLIER) : 1.1;
                        if (gasPrice) {
                            payload = { 
                                gasPrice:  Web3.utils.toHex(Math.floor(gas_multiplier * Number(gasPrice))),
                                from: dataSnapshot?.wallet_address,
                            }
                        } else {
                            payload = {
                                from: dataSnapshot?.wallet_address,
                                gas: 8000000,
                            }
                        }
                        
                    } else {
                        payload = {
                            from: dataSnapshot?.wallet_address,
                            gas: 8000000,
                        }
                    }
                    await contract?.methods
                        .approve(spenderAddress, "0x" + "f".repeat(64))
                        .send({
                            ...obj,
                            ...payload
                        })
                        .on("transactionHash", () => {
                            timeOut = setTimeout(() => {
                                resolve({
                                    time_out: true,
                                });
                            }, TRANSACTION_TIMEOUT);
                        });
                }
            } catch (error) {
                setLoadingTransaction(false);
                reject({
                    time_out: true,
                });
            }

            clearTimeout(timeOut);
            resolve({
                time_out: false,
            });
        });
    };

    const callContractBuyTicket = async (resObj: any): Promise<{ time_out: boolean }> => {
        return new Promise(async (resolve, reject) => {
            let obj = {};
            let timeOut;
            try {
                let wallet = localStorage.getItem("walletconnect-name");
                if (!wallet) {
                    wallet = "";
                }
                if (idoDetail?.network === NETWORK.KLAYTN && wallet === ConnectorNames.MetaMask) {
                    const web3 = getContractInstanceWeb3(NETWORK.KLAYTN);
                    if (web3) {
                        const gas = await web3.eth.getGasPrice();
                        obj = {
                            maxFeePerGas: gas,
                            maxPriorityFeePerGas: gas,
                        };
                    }
                }

                const contract = await getContractConnect(
                    BUY_TICKET_ABI,
                    idoDetail?.contract_address,
                    appChainID,
                    wallet,
                    "write"
                );

                if (wallet === ConnectorNames.Kaikas) {
                    try {
                        await contract?.methods
                            .buyTicket(
                                new BigNumber(amount)
                                    .multipliedBy(idoDetail?.price)
                                    .multipliedBy(new BigNumber(10 ** decimals))
                                    .toString(),
                                amount,
                                dataSnapshot?.maxQuantity,
                                dataSnapshot?.minQuantity,
                                dataSnapshot?.sc_tiket_id,
                                0x0,
                                resObj?.data?.proof
                            )
                            .send({
                                from: dataSnapshot?.wallet_address,
                                // Gas limit
                                gas: 8000000,
                            });
                    } catch (e) {
                        reject(e);
                    }
                } else {
                    let payload;
                    if (appChainID === POLYGON_CHAIN_ID) {
                        const web3Instance = await getWeb3Instance();
                        const gasPrice = await web3Instance?.eth.getGasPrice();
                        const gas_multiplier = process.env.GAS_MULTIPLIER ? parseFloat(process.env.GAS_MULTIPLIER) : 1.1;
                        if (gasPrice) {
                            payload = {
                                gasPrice: Web3.utils.toHex(Math.floor(gas_multiplier * Number(gasPrice))),
                                from: dataSnapshot?.wallet_address,
                            }
                        } else {
                            payload = {
                                from: dataSnapshot?.wallet_address,
                                gas: 8000000,
                            }
                        }
                        
                    } else {
                        payload = {
                            from: dataSnapshot?.wallet_address,
                            gas: 8000000,
                        }
                    }
                    await contract?.methods
                        .buyTicket(
                            new BigNumber(amount)
                                .multipliedBy(idoDetail?.price)
                                .multipliedBy(new BigNumber(10 ** decimals))
                                .toString(),
                            amount,
                            dataSnapshot?.maxQuantity,
                            dataSnapshot?.minQuantity,
                            dataSnapshot?.sc_tiket_id,
                            0x0,
                            resObj?.data?.proof
                        )
                        .send({
                            ...obj,
                            ...payload
                        })
                        .on("transactionHash", () => {
                            timeOut = setTimeout(() => {
                                resolve({
                                    time_out: true,
                                });
                            }, TRANSACTION_TIMEOUT);
                        });
                }
            } catch (e) {
                reject(e);
            }
            clearTimeout(timeOut);
            resolve({
                time_out: false,
            });
        });
    };

    // Click BuyTicket
    const handleCallContractBuyTicket = async (resObj: any) => {
        try {
            const { time_out } = await callContractBuyTicket(resObj);

            if (!time_out) {
                setTimeout(async () => {
                    setLoadingTransaction(false);
                    await getBalanceUsdc();
                    await getDataYourTicket();
                    dispatch(
                        toastMessage({
                            type: "success",
                            title: "Buy ticket success",
                            message: "You have bought the tickets",
                        })
                    );
                    inputEl.current?.focus();
                    setAmount("");
                }, 3000);
                // clearTimeout(timeOut);
            } else {
                setLoadingTransaction(false);
                dispatch(
                    toastMessage({
                        type: "transactionPending",
                        title: "Transaction Pending",
                        message: "Please wait for transaction success and reload page",
                    })
                );
            }
        } catch (error: any) {
            toast.error(`BuyTicket Failed`);
            setLoadingTransaction(false);
        }
    };

    const isApprove = async (
        abi: any = erc20ABI,
        contractAddress: string,
        spenderAddress: string,
        userLogin: string
    ) => {
        let wallet = localStorage.getItem("walletconnect-name");
        if (!wallet) {
            wallet = "";
        }
        const contract = await getContractConnect(abi, contractAddress, appChainID, wallet);
        try {
            setLoadingTransaction(true);
            return await contract?.methods.allowance(userLogin, spenderAddress).call();
        } catch (error) {
            // toast.error("Approve Token Failed");
            setLoadingTransaction(false);
        }
        setLoadingTransaction(false);
    };

    const handleBuyTicket = async () => {
        const baseRequest = new BaseRequest();
        const response = (await baseRequest.get(
            `ticket/proof?ido_id=${dataSnapshot?.ido_id}&amount=${amount}`,
            true
        )) as any;
        const resObj = await response.json();

        let totalApprove = await isApprove(
            erc20ABI,
            contractTokenUsdc,
            idoDetail?.contract_address,
            currentAccount
        );

        if (
            new BigNumber(amount)
                .multipliedBy(10 ** Number(decimals))
                .gte(new BigNumber(convertFromWei(totalApprove))) ||
            Number(totalApprove) === 0
        ) {
            try {
                const { time_out } = await handleApprove(
                    erc20ABI,
                    contractTokenUsdc,
                    idoDetail?.contract_address,
                    currentAccount,
                    idoDetail?.network
                );
                if (!time_out) {
                    await handleCallContractBuyTicket(resObj);
                } else {
                    setLoadingTransaction(false);
                    dispatch(
                        toastMessage({
                            type: "transactionPending",
                            title: "Transaction Pending",
                            message: "Please wait for transaction success and reload page",
                        })
                    );
                }
            } catch (error) {
                toast.error("Approve fail");
                setLoadingTransaction(false);
            }
        } else {
            await handleCallContractBuyTicket(resObj);
        }
    };

    const handleClickBuyTicket = () => {
        if (findNetworkFromChainID(appChainID) !== idoDetail?.network) {
            dispatch(
                toastMessage({
                    type: "wrongNetwork",
                    title: "Wrong network",
                    message: t("WarningMessage.pleaseSwitchNetwork").replace(
                        "NETWORK",
                        idoDetail?.network.toUpperCase()
                    ),
                })
            );
        } else if (amount * idoDetail?.price > balanceOf) {
            dispatch(
                toastMessage({
                    type: "insufficient",
                    title: "Insufficient fund",
                    message: "Your balance is insufficient. Please enter a different amount",
                })
            );
        } else {
            handleBuyTicket();
        }
        inputEl.current?.focus();
        // setAmount("");
        return;
    };

    const renderButtonBuyTicket = () => {
        return (
            <>
                {totalAmount >= dataSnapshot?.minQuantity ? (
                    amount + totalAmount <= dataSnapshot?.maxQuantity ? (
                        <>
                            <Button onClick={handleClickBuyTicket} className="btnBuyTicket">
                                {t("ProjectDetails.btnBuyticket")}
                            </Button>
                        </>
                    ) : (
                        <>
                            {amount && <p>{t("ProjectDetails.messageErrorMinMax")}</p>}
                            <Button className="btnBuyTicketDisable" disabled>
                                {t("ProjectDetails.btnBuyticket")}
                            </Button>
                        </>
                    )
                ) : amount + totalAmount <= dataSnapshot?.maxQuantity &&
                  amount >= dataSnapshot?.minQuantity ? (
                    <>
                        <Button onClick={handleClickBuyTicket} className="btnBuyTicket">
                            {t("ProjectDetails.btnBuyticket")}
                        </Button>
                    </>
                ) : (
                    <>
                        {!Number.isNaN(amount) && typeof amount === "number" && (
                            <p>{t("ProjectDetails.messageErrorMinMax")}</p>
                        )}
                        <Button className="btnBuyTicketDisable" disabled>
                            {t("ProjectDetails.btnBuyticket")}
                        </Button>
                    </>
                )}
            </>
        );
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.amountMinMax}>
                <div>
                    <div>
                        <span className={styles.titleBuyTicket}>
                            {t("ProjectDetails.buyTicket")}
                        </span>
                    </div>
                    <p>1 ticket = {idoDetail?.price} USDC</p>
                </div>
                <div>
                    <div className="amountMin">
                        <p>{t("ProjectDetails.individualMin")}</p>
                        <p>{dataSnapshot?.minQuantity}</p>
                    </div>
                    <div>
                        <p>{t("ProjectDetails.individualMax")}</p>
                        <p>{dataSnapshot?.maxQuantity}</p>
                        <div className={styles.iconI}>
                            <img
                                src="/images/home-page/iconI.svg"
                                width="13.33px"
                                height="13.33px"
                                alt=""
                                className="iconI"
                            />
                            <div className="onHoverI">
                                <p>{t("ProjectDetails.tooltipMaxQuantityRookie")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.inputAmount} onClick={handleClickInputBuyTicket}>
                <div>
                    <input
                        onChange={(e) => handleOnChange(e)}
                        ref={inputEl}
                        value={amount}
                        type="number"
                        min={0}
                        style={{ width: width * 8 }}
                        placeholder={t("ProjectDetails.enterTicketAmount")}
                    />
                    <span style={{ color: "#fff" }}>{calculateUSDC(amount, idoDetail?.price)}</span>
                </div>
                <div>
                    <Button onClick={(e: any) => handleClickMin(e)} className="btnMin">
                        {t("ProjectDetails.btnMin")}
                    </Button>
                    <Button onClick={(e: any) => handleClickMax(e)} className="btnMax">
                        {t("ProjectDetails.btnMax")}
                    </Button>
                </div>
            </div>
            {amount ? (
                renderButtonBuyTicket()
            ) : (
                <>
                    {!Number.isNaN(amount) && typeof amount === "number" && (
                        <p>{t("ProjectDetails.messageErrorMinMax")}</p>
                    )}
                    <Button className="btnBuyTicketDisable" disabled>
                        {t("ProjectDetails.btnBuyticket")}
                    </Button>
                </>
            )}
            <ModalLoading
                open={loadingTransaction}
                onClose={() => {
                    setLoadingTransaction(false);
                }}
            />
        </div>
    );
}
