import { Button, Chip, Grid } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StakingContext } from "..";
import { AppContext } from "../../../AppContext";
import { take_decimal_numberFormatThousands } from "../../../constants";
import { convertFromWei } from "../../../services/web3";
import { PoolLPI, poolNoReward } from "../../../store/constants/pool-noreward";
import { scrollIntoView } from "../../../utils/formatNumber";
import PaginationCustom from "../../PaginationCustom";
import PoolLP, { InfoToken } from "../PoolLP";
import CurrentRank from "./CurrentRank";
import { useStyles } from "./style";
import TierSystem from "./TierSystem";

type Props = {
    handleOpenModalStake: (
        tokenAddress: string,
        contractAddress: string,
        isReward: boolean
    ) => void;
    handleOpenModalUnStake: (
        tokenAddress: string,
        contractAddress: string,
        isReward: boolean
    ) => void;
    handleSetInfoToken: (data: InfoToken) => void;
    balanceCODE: string;
    handleOpenMessageWaring: () => void;
};

export default function TierStaking({
    handleOpenModalStake,
    handleOpenModalUnStake,
    handleSetInfoToken,
    balanceCODE,
    handleOpenMessageWaring,
}: Props) {
    const styles = useStyles();
    const { t } = useTranslation();
    const { openConnectWallet, setOpenConnectWallet, connectWalletLoading } =
        useContext(AppContext);
    const poolNoRewardCODE = useSelector((state: any) => state.poolNoRewardCODE.data);
    const dataPoolNoRewardLP = useSelector((state: any) => state.poolNoRewardLP.data?.data);
    const { page_size, page_number } = useSelector((state: any) => state.poolNoRewardLP);
    const count = useSelector((state: any) => state.poolNoRewardLP.data.meta);
    const tierInfo = useSelector((state: any) => state.tiers.data);
    const { account } = useWeb3React();
    const dispatch = useDispatch();
    const { checkNetwork } = useContext(StakingContext);
    const poolLPsRef = useRef<any>();

    const marks = [
        {
            value: 0,
            label: (
                <div className="tierUpMilestone1">
                    <div className="dot"></div>
                    <div className="">
                        <p>{tierInfo[0]?.min} CODE</p>
                        <span>{tierInfo[0]?.min} USD</span>
                    </div>
                </div>
            ),
        },
        {
            value: (tierInfo[tierInfo?.length - 1]?.min || 0) / 2,
            label: (
                <div className="tierUpMilestone2">
                    <div className="dot"></div>
                    <div className="">
                        <p>{tierInfo[1]?.min} CODE</p>
                        <span>{tierInfo[1]?.min} USD</span>
                    </div>
                </div>
            ),
        },
        {
            value: tierInfo[tierInfo?.length - 1]?.min || 0,
            label: (
                <div className="tierUpMilestone3">
                    <div className="dot"></div>
                    <div className="">
                        <p>{tierInfo[tierInfo?.length - 1]?.min || 0} CODE</p>
                        <span>{tierInfo[tierInfo?.length - 1]?.min || 0} USD</span>
                    </div>
                </div>
            ),
        },
    ];

    const allocationPool = [
        {
            value: 0,
            label: (
                <div className="tierUpMilestone1">
                    <div className="dot"></div>
                    <p>0 CODE</p>
                </div>
            ),
        },
        {
            value: 5000,
            label: (
                <div className="tierUpMilestone3">
                    <div className="dot"></div>
                    <p>5000 CODE</p>
                </div>
            ),
        },
    ];

    const checkAmountNextRank = (amount: string | number) => {
        if (Number(amount) <= 0)
            return take_decimal_numberFormatThousands(
                Number(tierInfo[tierInfo?.length - 2]?.min || 0)
            );
        if (Number(amount) > Number(tierInfo[tierInfo?.length - 1]?.min || 0)) return 0;
        return Number(amount) < tierInfo[1]?.min
            ? take_decimal_numberFormatThousands(tierInfo[1]?.min - Number(amount))
            : take_decimal_numberFormatThousands(
                  Number(tierInfo[tierInfo?.length - 1]?.min) - Number(amount)
              );
    };

    const convertSliderTier = (amount: number) => {
        if (amount <= 0) return 0;
        if (amount > tierInfo[tierInfo?.length - 1]?.min)
            return tierInfo[tierInfo?.length - 1]?.min;
        return amount <= tierInfo[tierInfo?.length - 2]?.min
            ? amount + tierInfo[tierInfo?.length - 1]?.min / 10
            : amount;
    };

    return (
        <div className={styles.container}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <CurrentRank />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TierSystem />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} className={styles.stakeCode}>
                    <div className="stakeCodeBox">
                        <div className="title">
                            <div className="">
                                <p className="text">{t("staking.tier.stakeCODE.title")}</p>
                                <span className="descIsMobile">
                                    {t("staking.tier.stakeCODE.gainIDO")}
                                </span>
                            </div>
                            <div className="">
                                <span className="descIsDesktop">
                                    {t("staking.tier.stakeCODE.gainIDO")}
                                </span>
                                <Chip label={t("staking.tier.stakeCODE.btnFlexible")} />
                            </div>
                        </div>
                        <div className="stakeCodeContainer">
                            <div className="info">
                                <div className="item">
                                    <p>
                                        {t("staking.tier.stakeCODE.stakedAmount")} {}
                                    </p>
                                    <div className="infoStake">
                                        <p>
                                            {poolNoRewardCODE?.total_staked
                                                ? take_decimal_numberFormatThousands(
                                                      Number(
                                                          Number(poolNoRewardCODE?.total_staked) /
                                                              10 **
                                                                  Number(
                                                                      poolNoRewardCODE?.token_decimal ||
                                                                          18
                                                                  )
                                                      ).toFixed(0) || 0
                                                  )
                                                : 0}{" "}
                                            CODE
                                        </p>
                                        <span>
                                            {poolNoRewardCODE?.total_staked
                                                ? take_decimal_numberFormatThousands(
                                                      Number(
                                                          Number(poolNoRewardCODE?.total_staked) /
                                                              10 **
                                                                  Number(
                                                                      poolNoRewardCODE?.token_decimal ||
                                                                          18
                                                                  )
                                                      ).toFixed(0) || 0
                                                  )
                                                : 0}{" "}
                                            USD
                                        </span>
                                    </div>
                                </div>
                                <div className="item">
                                    <p>{t("staking.tier.stakeCODE.nextRank")} </p>
                                    <div className="infoStake">
                                        <p>
                                            {checkAmountNextRank(
                                                Number(
                                                    Number(
                                                        convertFromWei(
                                                            poolNoRewardCODE?.total_staked?.toString() ||
                                                                "0"
                                                        )
                                                    )
                                                ) +
                                                    Number(
                                                        dataPoolNoRewardLP?.total_code_staked || 0
                                                    )
                                            )}{" "}
                                            CODE
                                        </p>
                                        <span>
                                            {checkAmountNextRank(
                                                Number(
                                                    Number(
                                                        convertFromWei(
                                                            poolNoRewardCODE?.total_staked?.toString() ||
                                                                "0"
                                                        )
                                                    )
                                                ) +
                                                    Number(
                                                        dataPoolNoRewardLP?.total_code_staked || 0
                                                    )
                                            )}{" "}
                                            USD
                                        </span>
                                    </div>
                                </div>
                                <div className="item">
                                    <p>{t("staking.tier.stakeCODE.balance")} </p>
                                    <div className="infoStake">
                                        <p>
                                            {take_decimal_numberFormatThousands(
                                                balanceCODE || "0"
                                            ) || 0}{" "}
                                            CODE
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="box">
                                {/* <Slider
                                    defaultValue={0}
                                    aria-labelledby="discrete-slider-custom"
                                    valueLabelDisplay="auto"
                                    marks={marks}
                                    className="timeLineStakeCODE"
                                    min={tierInfo[0]?.min}
                                    max={tierInfo[tierInfo?.length - 1]?.min}
                                    value={convertSliderTier(
                                        Number(poolNoRewardCODE?.total_staked?.toString())
                                    )}
                                /> */}
                                {account ? (
                                    <div className="actions">
                                        <Button
                                            variant="contained"
                                            className="btnStake"
                                            onClick={() => {
                                                handleOpenModalStake(
                                                    poolNoRewardCODE?.token_address as string,
                                                    poolNoRewardCODE?.contract_address as string,
                                                    false
                                                );
                                                handleSetInfoToken({
                                                    token_symbol: poolNoRewardCODE?.stake_type,
                                                    token_decimal: "18",
                                                    total_staked: poolNoRewardCODE?.total_staked,
                                                });
                                            }}
                                            disabled={
                                                !checkNetwork ||
                                                localStorage.getItem("walletconnect-name") ===
                                                    "Kaikas"
                                            }
                                        >
                                            {t("staking.tier.stakeCODE.title")}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            className="btnUnStake"
                                            onClick={() => {
                                                if (Number(poolNoRewardCODE?.total_staked) <= 0) {
                                                    handleOpenMessageWaring();
                                                    return;
                                                }
                                                handleOpenModalUnStake(
                                                    poolNoRewardCODE?.token_address as string,
                                                    poolNoRewardCODE?.contract_address as string,
                                                    false
                                                );
                                                handleSetInfoToken({
                                                    token_symbol: poolNoRewardCODE?.stake_type,
                                                    token_decimal: "18",
                                                    total_staked: poolNoRewardCODE?.total_staked,
                                                });
                                            }}
                                            disabled={
                                                !checkNetwork ||
                                                localStorage.getItem("walletconnect-name") ===
                                                    "Kaikas"
                                            }
                                        >
                                            {t("staking.tier.stakeCODE.unStakeCOED")}
                                        </Button>
                                    </div>
                                ) : (
                                    <div className="actionConnect">
                                        <Button
                                            variant="contained"
                                            className="btnConnect"
                                            onClick={() => {
                                                !openConnectWallet &&
                                                    !connectWalletLoading &&
                                                    setOpenConnectWallet &&
                                                    setOpenConnectWallet(true);
                                            }}
                                        >
                                            {t("staking.tier.textConnectWallet")}
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} className={styles.stakeToken}>
                    <div className="stakeTokenBox">
                        <div className="title">
                            <div className="">
                                <p className="text">{t("staking.tier.stakePool.title")}</p>
                                <span className="descIsMobile">
                                    {t("staking.tier.stakePool.gainIDO")}
                                </span>
                            </div>
                            <div className="">
                                <span className="descIsDesktop">
                                    {t("staking.tier.stakePool.gainIDO")}
                                </span>
                                <Chip label={t("staking.tier.stakePool.btnFlexible")} />
                            </div>
                        </div>
                        <div className="stakeTokenContainer">
                            <div className="info">
                                <div className="item">
                                    <p>{t("staking.tier.stakePool.stakedValue")}</p>
                                    <div className="infoStake">
                                        <p>
                                            {take_decimal_numberFormatThousands(
                                                dataPoolNoRewardLP?.total_code_staked || 0
                                            ) || 0}{" "}
                                            CODE
                                        </p>
                                    </div>
                                </div>
                                <div className="item">
                                    <p>{t("staking.tier.stakePool.nextRank")} </p>
                                    <div className="infoStake">
                                        <p>
                                            {checkAmountNextRank(
                                                Number(
                                                    Number(
                                                        convertFromWei(
                                                            poolNoRewardCODE?.total_staked?.toString() ||
                                                                "0"
                                                        )
                                                    )
                                                ) +
                                                    Number(
                                                        dataPoolNoRewardLP?.total_code_staked || 0
                                                    )
                                            )}{" "}
                                            CODE
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="box">
                                {/* {account && (
                                    <Slider
                                        defaultValue={0}
                                        aria-labelledby="discrete-slider-custom"
                                        valueLabelDisplay="auto"
                                        marks={allocationPool}
                                        min={0}
                                        max={5000}
                                        className="timeLineStakeToken"
                                        step={500}
                                        value={dataPoolNoRewardLP?.total_code_staked || 0}
                                    />
                                )} */}
                            </div>
                        </div>
                    </div>
                    <div className={styles.poolLP}>
                        <p className="titlePoolLP" ref={poolLPsRef}>
                            {t("staking.tier.listPoolsLP.title")}
                        </p>
                        {Array.isArray(dataPoolNoRewardLP?.pools) &&
                            dataPoolNoRewardLP.pools.length > 0 &&
                            dataPoolNoRewardLP.pools.map((pool: PoolLPI, index: number) => {
                                return (
                                    <div key={index}>
                                        <PoolLP
                                            pool={pool}
                                            handleOpenModalStake={handleOpenModalStake}
                                            handleOpenModalUnStake={handleOpenModalUnStake}
                                            handleSetInfoToken={handleSetInfoToken}
                                            handleOpenMessageWaring={handleOpenMessageWaring}
                                        />
                                    </div>
                                );
                            })}
                        {!(dataPoolNoRewardLP?.pools?.length > 0) && (
                            <div className="noDataPoolLP">
                                <div className="box">
                                    <img src="/images/account_v1/no_transaction.svg" alt="" />
                                    <p>{t("staking.tier.stakePool.noDataPoolLP")}</p>
                                </div>
                            </div>
                        )}
                        {Array.isArray(dataPoolNoRewardLP?.pools) &&
                        dataPoolNoRewardLP.pools.length > 0 &&
                        count?.count > page_size ? (
                            <div className="paginationPools">
                                <PaginationCustom
                                    count={Math.ceil(count?.count / page_size) || 1}
                                    onChange={(page: number) => {
                                        scrollIntoView(poolLPsRef);
                                        dispatch({
                                            type: poolNoReward.SET_PAGE_NUMBER_POOL_NOREWARD,
                                            payload: page - 1,
                                        });
                                    }}
                                    currentPage={page_number + 1}
                                />
                            </div>
                        ) : (
                            <div style={{ paddingBottom: 40 }}></div>
                        )}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}
