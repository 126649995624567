import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: any) => {
    return {
        container: {
            width: "100%",
            padding: "0px 0px",
            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
            [theme.breakpoints.up("sm")]: {
                margin: "15px 0px",
                padding: "0px 70px",
            },
            [theme.breakpoints.up("md")]: {
                margin: "15px 0px",
                padding: "0px 30px",
            },
            [theme.breakpoints.up("lg")]: {
                padding: "0px 0px",
            },
        },
        infoReward: {
            marginTop: 47,
            width: "100%",
            "& .boxInfo": {
                background: "#131530",
                width: "100%",
                padding: "24px 30px",
                borderRadius: "20px",
                [theme.breakpoints.down("sm")]: {
                    padding: 16,
                },
                [theme.breakpoints.up("md")]: {
                    padding: "24px 100px",
                },
                [theme.breakpoints.up("lg")]: {
                    padding: "24px 30px",
                },
                "& .title": {
                    fontSize: 16,
                    lineHeight: "24px",
                    fontWeight: "600",
                    ffontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    paddingBottom: 12,
                    color: "#FFF",
                    borderBottom: "1px solid rgba(255, 255, 255, .1)",
                    [theme.breakpoints.down("sm")]: {
                        fontSize: 14,
                    },
                },
            },
            "& .content": {
                paddingTop: 36,
                [theme.breakpoints.down("sm")]: {
                    paddingTop: 20,
                },
            },
        },
        rowTop: {
            "& .header": {
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                [theme.breakpoints.up("sm")]: {},
                [theme.breakpoints.up("md")]: {
                    flexDirection: "row",
                    alignItems: "center",
                },
                [theme.breakpoints.up("lg")]: {},
                "& .circle": {
                    width: 55,
                    height: 55,
                    borderRadius: "50%",
                    backgroundColor: "#D9D9D9",
                    marginRight: "18px",
                },
                "& .titleRowTop": {
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    marginBottom: "10px",
                    [theme.breakpoints.up("sm")]: {},
                    [theme.breakpoints.up("md")]: {
                        gap: "2px 0px",
                        marginRight: 40,
                    },
                    "& .text": {
                        "& h4": {
                            color: "#fff",
                            fontSize: 24,
                            lineHeight: "32px",
                            fontWeight: "600",
                            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                        },
                        "& span": {
                            color: "rgba(255, 255, 255, 0.3)",
                            fontSize: 14,
                            lineHeight: "22px",
                        },
                    },
                },
                "& .MuiChip-root": {
                    height: 33,
                    padding: "0px 15px",
                    marginLeft: 8,
                    color: "#0F69E1",
                    fontSize: 14,
                    background: "rgba(75, 131, 217, 0.2)",
                    borderRadius: "5px",
                    fontWeight: "600",
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                },
            },
            "& .info": {
                padding: "30px 0px",
                borderBottom: "1px solid rgba(255, 255, 255, .1)",
                [theme.breakpoints.down("sm")]: {
                    padding: "20px 0px 0px 0px",
                },
                "& .item": {
                    marginBottom: 12,
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    [theme.breakpoints.up("md")]: {
                        flexDirection: "column",
                        marginBottom: 0,
                    },
                    [theme.breakpoints.up("lg")]: {},
                    "& p:first-child": {
                        fontSize: 14,
                        color: "rgba(255, 255, 255, .3)",
                        lineHeight: "22px",
                        [theme.breakpoints.down("xs")]: {
                            fontSize: 12,
                        },
                    },
                    "& .infoItem": {
                        textAlign: "right",
                        [theme.breakpoints.up("sm")]: {},
                        [theme.breakpoints.up("md")]: {
                            marginTop: 6,
                            textAlign: "inherit",
                        },
                        "& p": {
                            fontSize: 18,
                            color: "rgba(255, 255, 255, 1)",
                            lineHeight: "28px",
                            fontWeight: "600",
                            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                            [theme.breakpoints.down("xs")]: {
                                fontSize: 14,
                            },
                        },
                        "& .withdrawalFee": {
                            cursor: "pointer",
                        },
                        "& span": {
                            fontSize: 12,
                            lineHeight: "17px",
                            color: "#0F69E1",
                            fontWeight: "600",
                            fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                        },
                    },
                },
            },
        },
        rowBottom: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            height: "auto",
            [theme.breakpoints.up("sm")]: {
                alignItems: "flex-start",
                flexWrap: "wrap",
                flexDirection: "row",
                justifyContent: "center",
            },
            [theme.breakpoints.up("md")]: {
                justifyContent: "left",
            },
            [theme.breakpoints.up("lg")]: {
                height: "161px",
            },
            "& .col1": {
                fontSize: 18,
                lineHeight: "21px",
                color: "#000000",
                marginRight: 12,
                borderBottom: "1px solid rgba(255, 255, 255, .1)",
                width: "100%",
                [theme.breakpoints.up("sm")]: {},
                [theme.breakpoints.up("md")]: {
                    paddingRight: 66,
                },
                [theme.breakpoints.up("lg")]: {
                    borderRight: "1px solid rgba(255, 255, 255, .1)",
                    width: "auto",
                    marginRight: 0,
                    borderBottom: "none",
                    height: "100%",
                },
                "& .item:first-child": {
                    margin: "20px 0px 0px 0px",
                    [theme.breakpoints.down("sm")]: {
                        margin: "8px 0px 0px 0px",
                    },
                },
                "& .item": {
                    padding: "12px 0px",
                    display: "flex",
                    gap: "0px 40px",
                    fontSize: 12,
                    lineHeight: "22px",
                    color: "rgba(255, 255, 255, .3)",
                    justifyContent: "space-between",
                    [theme.breakpoints.up("sm")]: {
                        fontSize: 14,
                        flexDirection: "row",
                    },
                    [theme.breakpoints.up("md")]: {
                        fontSize: 14,
                    },
                    [theme.breakpoints.up("lg")]: {
                        borderBottom: "1px solid rgba(255, 255, 255, .1)",
                    },
                    "& span:last-child": {
                        fontSize: 14,
                        lineHeight: "22px",
                        color: "rgba(255, 255, 255, 1)",
                        fontWeight: 600,
                        fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    },
                },
                "& .item:last-child": {
                    borderBottom: "none",
                },
            },
            "& .col2,& .col2Connect": {
                padding: "20px 0px",
                borderBottom: "1px solid rgba(255, 255, 255, .1)",
                display: "flex",
                gap: "0px 12px",
                height: "100%",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                [theme.breakpoints.up("sm")]: {
                    padding: "16px 20px 8px 14px",
                    borderBottom: "none",
                    marginRight: 0,
                },
                [theme.breakpoints.up("md")]: {
                    gap: "0px 30px",
                    marginRight: 12,
                    flexWrap: "wrap",
                    width: "auto",
                },
                [theme.breakpoints.up("lg")]: {
                    padding: "33px 25px 0px 25px",
                    flexDirection: "column",
                },
                "& button": {
                    width: "240px",
                    textTransform: "capitalize",
                    backgroundColor: "#C4C4C4",
                    fontSize: 16,
                    lineHeight: "24px",
                    padding: "15px 0px",
                    fontWeight: 700,
                    fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                    borderRadius: "90px",
                    maxHeight: "54px",
                    [theme.breakpoints.down("sm")]: {
                        width: "50%",
                        height: 40,
                        fontSize: 12,
                    },
                },
                "& .btnStake,& .btnConnect": {
                    background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                    color: "#fff",
                    [theme.breakpoints.up("sm")]: {
                        maxWidth: "278px",
                    },
                    [theme.breakpoints.up("md")]: {},
                    [theme.breakpoints.up("lg")]: {
                        marginBottom: 20,
                    },
                    "&:disabled": {
                        cursor: "not-allowed",
                        opacity: 0.5,
                    },
                },
                "& .btnUnStake": {
                    color: "#9747FF",
                    border: "2px solid #9747FF",
                    backgroundColor: "transparent",
                    padding: "13px 0px",
                    [theme.breakpoints.up("sm")]: {
                        maxWidth: "278px",
                    },
                    "&:disabled": {
                        cursor: "not-allowed",
                        opacity: 0.5,
                    },
                },
                "& .btnConnect": {
                    marginBottom: 0,
                },
            },
            "& .col2Connect": {
                justifyContent: "flex-end",
                [theme.breakpoints.down("md")]: {
                    width: "100% !important",
                },
                [theme.breakpoints.down("sm")]: {
                    borderBottom: "none",
                },
                "& .btnConnect": {
                    marginBottom: 0,
                    [theme.breakpoints.down("sm")]: {
                        width: "100%",
                        maxWidth: "270px",
                    },
                },
            },
            "& .col3": {
                padding: "20px 0px",
                height: "100%",
                width: "100%",
                [theme.breakpoints.up("sm")]: {
                    padding: "15px 0px 0px 20px",
                },
                [theme.breakpoints.up("md")]: {
                    borderLeft: "1px solid rgba(255, 255, 255, .1)",
                    width: "auto",
                },
                [theme.breakpoints.up("lg")]: {
                    padding: "30px 0px 0px 30px",
                },
                "& .btnClaim": {
                    width: "240px",
                    textTransform: "capitalize",
                    backgroundColor: "#C4C4C4",
                    fontSize: 16,
                    lineHeight: "24px",
                    padding: "15px 0px",
                    fontWeight: 700,
                    fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                    borderRadius: "90px",
                    background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                    color: "#fff",
                    maxWidth: "237px",
                    cursor: "pointer",
                    [theme.breakpoints.down("sm")]: {
                        height: 40,
                        fontSize: 12,
                        width: "50%",
                    },
                    "&:disabled": {
                        opacity: 0.5,
                        cursor: "not-allowed",
                    },
                },
                "& div:first-child": {
                    display: "flex",
                    gap: "0px 18px",
                    flexWrap: "wrap",
                    [theme.breakpoints.up("sm")]: {
                        flexDirection: "row",
                    },
                    [theme.breakpoints.up("md")]: {},
                    [theme.breakpoints.up("lg")]: {},
                    "& p": {
                        paddingTop: 4,
                    },
                },
                "& .text": {
                    color: "#000000",
                    fontSize: 20,
                    marginTop: 8,
                    [theme.breakpoints.up("md")]: {
                        marginBottom: 7,
                        marginTop: 0,
                        fontSize: 24,
                        lineHeight: "28px",
                    },
                },
            },
        },
        transitionHistory: {
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            "& .boxTransitionHistory": {
                padding: "25px 30px",
                background: "#131530",
                borderRadius: "20px",
                width: "100%",
                [theme.breakpoints.down("sm")]: {
                    padding: "16px",
                },
                "& .titleTrans": {
                    color: "white",
                    fontSize: 16,
                    fontWeight: "600",
                    fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                    paddingBottom: 8,
                    borderBottom: "1px solid rgba(255, 255, 255, .1)",
                    marginBottom: 15,
                    [theme.breakpoints.down("xs")]: {
                        fontSize: 14,
                    },
                },
                "& .MuiTableContainer-root": {
                    margin: "0px auto",
                    borderRadius: "10px",
                    width: "100%",
                    overflow: "auto",
                    [theme.breakpoints.up("sm")]: {},
                    [theme.breakpoints.up("md")]: {
                        margin: "0px auto",
                    },
                    [theme.breakpoints.up("lg")]: {},
                },
                "& .tableTransition": {
                    backgroundColor: "transparent",
                    borderCollapse: "collapse",
                    borderSpacing: 0,
                    width: "100%",
                    minWidth: "900px",
                    "& .MuiTableHead-root": {
                        height: 38,
                    },
                    "& .MuiTableRow-head": {
                        background: "rgba(42, 44, 69, 1)",
                        height: 38,
                        "& .MuiTableCell-head": {
                            fontSize: 14,
                            padding: 0,
                            border: "none",
                            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                            [theme.breakpoints.up("sm")]: {},
                            [theme.breakpoints.up("md")]: {
                                fontSize: 14,
                            },
                            [theme.breakpoints.up("lg")]: {},
                        },
                        "& .MuiTableCell-head:not(:first-child)": {
                            paddingLeft: 20,
                        },
                    },
                    "& .MuiTableBody-root": {
                        background: "#1F213B",
                    },

                    "& .MuiTableCell-body": {
                        fontSize: 14,
                        border: "none",
                        padding: "16px 0px",
                        fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        textTransform: "capitalize",
                    },
                    "& .MuiTableCell-body:not(:first-child)": {
                        paddingLeft: 20,
                    },
                    "& .MuiTableRow-root": {
                        borderBottom: "1px solid #2A2C45",
                        "& .MuiTableCell-body": {
                            color: "rgba(255, 255, 255, .7)",
                        },
                        "& .MuiTableCell-body:first-child": {
                            paddingLeft: 25,
                        },
                        "& .MuiTableCell-body:last-child": {
                            textTransform: "left",
                        },
                        "& .MuiTableCell-head": {
                            color: "rgba(255, 255, 255, 0.3)",
                        },
                    },
                },
                "& .customPagination": {
                    "& li:nth-child(2)": {
                        marginRight: 56,
                    },
                },
                "& .paginationPools": {
                    marginTop: 20,
                    marginBottom: 52,
                },
                "& .noDataTransaction": {
                    padding: "64px 0px 27px 0px",
                    margin: "0px auto",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    "& .box": {
                        width: "100%",
                        height: "234px",
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        "& img": {
                            mixBlendMode: "luminosity",
                        },
                        "& p": {
                            position: "absolute",
                            bottom: 40,
                            left: "50%",
                            transform: "translateX(-50%)",
                            fontSize: 16,
                            color: "rgba(255, 255, 255, .3)",
                            fontFamily: `${theme.custom.fontFamilys.poppinsRegular}, sans-serif`,
                        },
                    },
                },
                "& .connectWallet": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%",
                    [theme.breakpoints.down("sm")]: {
                        marginTop: 20,
                    },
                    "& .textConnectWallet": {
                        fontSize: 20,
                        fontWeight: 600,
                        fontFamily: `${theme.custom.fontFamilys.poppinsSemiBold}, sans-serif`,
                        color: "white",
                        textAlign: "center",
                        [theme.breakpoints.down("sm")]: {
                            fontSize: 18,
                        },
                    },
                    "& .btnConnect": {
                        width: "205px",
                        textTransform: "capitalize",
                        backgroundColor: "#C4C4C4",
                        fontSize: 16,
                        lineHeight: "28px",
                        padding: "12px 0px",
                        fontWeight: 700,
                        fontFamily: `${theme.custom.fontFamilys.poppinsBold}, sans-serif`,
                        borderRadius: "90px",
                        background: "linear-gradient(269.99deg, #1EE5DD 0.01%, #791CD4 98.12%)",
                        color: "#fff",
                        marginTop: 25,
                        [theme.breakpoints.down("sm")]: {
                            marginTop: 16,
                            fontSize: 12,
                            width: "160px",
                            height: 40,
                        },
                    },
                },
            },
        },
    };
});
