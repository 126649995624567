import { AnyAction } from "redux";
import { gasFeePolygon } from "../constants/gasFee";

type StateType = {
    gasFee: number | undefined;
    loading: boolean;
    error: string;
};

const initialState = {
    gasFee: undefined,
    loading: false,
    error: "",
};

export const gasFeePolygonReducer = (state: StateType = initialState, action: AnyAction) => {
    switch (action.type) {
        case gasFeePolygon.GAS_FEE_POLYGON_LOADING: {
            return {
                ...state,
                loading: true,
            };
        }

        case gasFeePolygon.GAS_FEE_POLYGON_SUCCESS: {
            return {
                ...state,
                gasFee: action.payload,
                loading: false,
            };
        }

        case gasFeePolygon.GAS_FEE_POLYGON_FAILURE: {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        }

        default: {
            return state;
        }
    }
};
