export const ETH_CHAIN_ID = process.env.REACT_APP_ETH_CHAIN_ID as string;
export const BSC_CHAIN_ID = process.env.REACT_APP_BSC_CHAIN_ID as string;
export const POLYGON_CHAIN_ID = process.env.REACT_APP_POLYGON_CHAIN_ID as string;

export const KLAYTN_CHAIN_ID = process.env.REACT_APP_KLAYTN_CHAIN_ID as string;

export const BSC_RPC_URL = process.env.REACT_APP_BSC_RPC_URL || "";
export const POLYGON_RPC_URL = process.env.REACT_APP_POLYGON_RPC_URL || "";

export const USDT_ADDRESS = process.env.REACT_APP_USDT_SMART_CONTRACT;
export const USDC_ADDRESS = process.env.REACT_APP_USDC_SMART_CONTRACT;

export const USDC_POLYGON_ADDRESS = process.env.REACT_APP_USDC_POLYGON_SMART_CONTRACT;
export const USDT_POLYGON_ADDRESS = process.env.REACT_APP_USDT_POLYGON_SMART_CONTRACT;

export const USDC_BSC_ADDRESS = process.env.REACT_APP_USDC_BSC_SMART_CONTRACT;
export const USDT_BSC_ADDRESS = process.env.REACT_APP_USDT_BSC_SMART_CONTRACT;
export const BUSD_BSC_ADDRESS = process.env.REACT_APP_BUSD_BSC_SMART_CONTRACT;

export enum ChainId {
    MAINNET = 1,
    ROPSTEN = 3,
    RINKEBY = 4,
    GOERLI = 5,
    KOVAN = 42,
    BSC_TESTNET = 97,
    BSC_MAINNET = 56,
    POLYGON_MAINNET = 137,
    KLAYTN_MAINNET = 8217,
    MUMBAI_POLYGON_TESTNET = 80001,
}

export type chainId = Extract<
    ChainId,
    | ChainId.MAINNET
    | ChainId.ROPSTEN
    | ChainId.RINKEBY
    | ChainId.GOERLI
    | ChainId.KOVAN
    | ChainId.BSC_MAINNET
    | ChainId.BSC_TESTNET
    | ChainId.POLYGON_MAINNET
    | ChainId.MUMBAI_POLYGON_TESTNET
    | ChainId.KLAYTN_MAINNET
>;

export const ChainIdNameMapping: { [key in ChainId]: string } = {
    [ChainId.MAINNET]: "Mainnet",
    [ChainId.ROPSTEN]: "Ropsten",
    [ChainId.GOERLI]: "Goerli",
    [ChainId.KOVAN]: "Kovan",
    [ChainId.RINKEBY]: "Rinkeby",
    [ChainId.BSC_MAINNET]: "BSC Mainnet",
    [ChainId.BSC_TESTNET]: "BSC Testnet",
    [ChainId.POLYGON_MAINNET]: "Polygon Mainnet",
    [ChainId.KLAYTN_MAINNET]: "Klaytn Mainnet",
    [ChainId.MUMBAI_POLYGON_TESTNET]: "Mumbai Testnet",
};

export const NETWORK_NAME_MAPPINGS: any = {
    "1": "Mainnet",
    "3": "Ropsten",
    "5": "Goerli",
    "42": "Kovan",
    "4": "Rinkeby",
    "56": "BSC Mainnet",
    "97": "BSC Testnet",
    "137": "Polygon Mainnet",
    "80001": "Mumbai Testnet",
    "1001": "Klaytn Testnet Baobab",
    "8217": "Klaytn Mainnet",
};

export interface NetworkInfo {
    name: string;
    id?: string | undefined;
    icon: string;
    disableIcon: string;
    currency?: string;
    [k: string]: any;
}

export enum APP_NETWORKS_NAME {
    // BSC = "BSC",
    POLYGON = "POLYGON",
    KLAYTN = "KLAYTN",
}

export type appNetworkType = Extract<
    APP_NETWORKS_NAME,
    APP_NETWORKS_NAME.POLYGON | APP_NETWORKS_NAME.KLAYTN
>;

export const APP_NETWORKS: { [key in APP_NETWORKS_NAME]: NetworkInfo } = {
    // [APP_NETWORKS_NAME.BSC]: {
    //     name: "BSC",
    //     id: BSC_CHAIN_ID,
    //     icon: "/images/bsc.svg",
    //     disableIcon: "/images/binance-disabled.png",
    // },
    [APP_NETWORKS_NAME.POLYGON]: {
        name: "Polygon",
        id: POLYGON_CHAIN_ID,
        icon: "/images/polygon-matic.svg",
        disableIcon: "/images/polygon-matic-disabled.svg",
    },
    [APP_NETWORKS_NAME.KLAYTN]: {
        name: "Klaytn",
        id: KLAYTN_CHAIN_ID,
        icon: "/images/klaytn-network.svg",
        disableIcon: "/images/klaytn-network-disabled.svg",
    },
};

export const APP_NETWORKS_ID: (string | undefined)[] = [
    // ETH_CHAIN_ID,
    // BSC_CHAIN_ID,
    POLYGON_CHAIN_ID,
    KLAYTN_CHAIN_ID,
];
export const NETWORK_BSC_URL = process.env.REACT_APP_BSC_RPC_URL;
export const NETWORK_POLYGON_URL = process.env.REACT_APP_POLYGON_RPC_URL;
export const FORMATIC_KEY = process.env.REACT_APP_FORMATIC_KEY;
export const FORMATIC_KEY_TEST = process.env.REACT_APP_FORMATIC_KEY_TEST;

export const NETWORK_BSC_NAME = process.env.REACT_APP_NETWORK_BSC_NAME;
export const NETWORK_POLYGON_NAME = process.env.REACT_APP_NETWORK_POLYGON_NAME;
export const NETWORK_KLAYTN_NAME = process.env.REACT_APP_NETWORK_KLAYTN_NAME;

export const appNetwork: { [key: string]: string } = {
    [BSC_CHAIN_ID]: NETWORK_BSC_NAME as string,
    [POLYGON_CHAIN_ID]: NETWORK_POLYGON_NAME as string,
    [KLAYTN_CHAIN_ID]: NETWORK_KLAYTN_NAME as string,
};

export const APP_NETWORKS_SUPPORT: { [key: number]: NetworkInfo } = {
    [ETH_CHAIN_ID]: {
        name: "Ethereum",
        id: ETH_CHAIN_ID,
        icon: "/images/ethereum.svg",
        disableIcon: "/images/ethereum-disabled.png",
        currency: "ETH",
        networkName: NETWORK_NAME_MAPPINGS[ETH_CHAIN_ID],
        details: {
            chainId: `0x${(+ETH_CHAIN_ID).toString(16)}`,
            chainName: NETWORK_NAME_MAPPINGS[ETH_CHAIN_ID],
            nativeCurrency: {
                name: "ETH",
                symbol: "ETH",
                decimals: 18,
            },
            rpcUrls: [],
            blockExplorerUrls: [],
        },
    },
    [BSC_CHAIN_ID]: {
        name: "BSC Mainnet",
        id: BSC_CHAIN_ID,
        icon: "/images/bsc.svg",
        disableIcon: "/images/binance-disabled.png",
        currency: "BNB",
        networkName: NETWORK_NAME_MAPPINGS[BSC_CHAIN_ID],
        details: {
            chainId: `0x${(+BSC_CHAIN_ID).toString(16)}`,
            chainName: NETWORK_NAME_MAPPINGS[BSC_CHAIN_ID],
            nativeCurrency: {
                name: "BNB",
                symbol: "BNB",
                decimals: 18,
            },
            rpcUrls: [process.env.REACT_APP_BSC_RPC_URL],
            blockExplorerUrls: [process.env.REACT_APP_BSCSCAN_BASE_URL],
        },
    },
    [POLYGON_CHAIN_ID]: {
        name: "Polygon",
        id: POLYGON_CHAIN_ID,
        icon: "/images/polygon-matic.svg",
        disableIcon: "/images/polygon-matic-disabled.svg",
        currency: "MATIC",
        networkName: NETWORK_NAME_MAPPINGS[POLYGON_CHAIN_ID],
        details: {
            chainId: `0x${(+POLYGON_CHAIN_ID).toString(16)}`,
            chainName: NETWORK_NAME_MAPPINGS[POLYGON_CHAIN_ID],
            nativeCurrency: {
                name: "MATIC",
                symbol: "MATIC",
                decimals: 18,
            },
            rpcUrls: [process.env.REACT_APP_POLYGON_RPC_URL],
            blockExplorerUrls: [process.env.REACT_APP_POLSCAN_BASE_URL],
        },
    },
    [KLAYTN_CHAIN_ID]: {
        name: "Klaytn",
        id: KLAYTN_CHAIN_ID,
        icon: "/images/polygon-matic.svg",
        disableIcon: "/images/polygon-matic-disabled.svg",
        currency: "KLAY",
        networkName: NETWORK_NAME_MAPPINGS[KLAYTN_CHAIN_ID],
        details: {
            chainId: `0x${(+KLAYTN_CHAIN_ID).toString(16)}`,
            chainName: NETWORK_NAME_MAPPINGS[KLAYTN_CHAIN_ID],
            nativeCurrency: {
                name: "KLAY",
                symbol: "KLAY",
                decimals: 18,
            },
            rpcUrls: [process.env.REACT_APP_KLAYTN_RPC_URL],
            blockExplorerUrls: [process.env.REACT_APP_KLAYTN_BASE_URL],
        },
    },
};
